import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 12px;
  transform: rotateZ(-90deg) translateY(50%);
  max-width: 143px;
  margin-bottom: 82px;
  border-radius: 4px;
  background-color: #ebeff2;
  width: 100%;
  div {
    background: var(--chakra-colors-trueBlue);
    border-radius: 20px;
    transition-property: width;
  }
  &:hover {
    div {
      background: var(--chakra-colors-trueHover);
    }
  }
`;

const Bar = styled(motion.div)`
  height: 100%;
  width: ${({ value }) => `${value}%`};
  background-color: bg;
  border-radius: inherit;
  text-align: right;
`;

const ProgressBar = ({ value }) => {
  const onOpen = {
    initial: { width: 0 },
    animate: { width: `${value}%` },
    exit: { width: 0 },
    transition: { duration: 0.5, delay: 0.25, type: 'backIn' },
  };

  const animation = onOpen;
  return (
    <Container>
      <Bar value={value} {...animation} />
    </Container>
  );
};

export default ProgressBar;
