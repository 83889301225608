import { apiSlice } from './api';

export const personApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSignupDetails: builder.mutation({
      query: ({
        personId,
        notificationId,
        intercomId,
        intercomVisitorId,
        srcApp,
      }) => ({
        url: `persons/${encodeURIComponent(personId)}/getSignupDetails`,
        body: { notificationId, intercomId, intercomVisitorId, srcApp },
        method: 'POST',
      }),
    }),
    getEmail: builder.mutation({
      query: ({
        personId,
        personName,
        personLinkedinUrl,
        companyId,
        companyName,
        companyDomains,
        notificationId,
      }) => ({
        url: `persons/${encodeURIComponent(personId)}/getEmail`,
        body: {
          notificationId,
          personName,
          personLinkedinUrl,
          companyId,
          companyName,
          companyDomains,
        },
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetSignupDetailsMutation, useGetEmailMutation } = personApi;
