import { useMemo, useRef } from 'react';
import { isEmpty, get, capitalize } from 'lodash';
import {
  Box,
  Flex,
  Highlight,
  IconButton,
  Tag,
  Text,
  Icon,
  Link,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useDrop, useDrag } from 'react-dnd';
import { ReactComponent as CloseSmallIcon } from '../../../assets/icons/close/close-small.svg';
import { TruebaseTooltip } from '../../../common/TruebaseTooltip';
import { companyCategoryIconMap, leadCategoryIconMap } from './filterIcons';
import { produce } from 'immer';
import { getParentFromPath, getSubCategoryFromPath } from './utils';
import { ReactComponent as DragIcon } from '~/v2/assets/icons/drag handle/drag-3.svg';
import { ReactComponent as PlusChip } from '~/v2/assets/icons/plus-chip.svg';
import { useEditMutation } from '~/v2/redux/services/user';
import { useIsDirtyContext } from '~/v2/contexts/useIsDirtyContext';

const OperatorAnd = () => <span key={Math.random()}>{`&`}</span>;

const parentRefMaxWidth = 1082;
const allowedNumberOfChars = 70;

const NewBracketBox = () => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      border="1px dashed"
      borderColor="#7E8BADB2"
      borderRadius="2px"
      p="11px 16px"
      h="42px"
    >
      <Text color="trueDim" fontWeight="400" fontSize="12px" lineHeight="14px">
        You can drag filters around or drop them into a new bracket
      </Text>
    </Flex>
  );
};

const NewArrayContainer = ({ filter }) => {
  return (
    <Flex rowGap={2} columnGap={1} alignItems="center" flexWrap="wrap">
      <OperatorAnd key={Math.random()} />
      <Flex
        rowGap={2}
        columnGap={1}
        alignItems="center"
        flexWrap="wrap"
        sx={{
          border: '1px dashed rgba(2, 128, 245, 1)',
          borderRadius: '4px',
          padding: '4px 8px',
        }}
      >
        <FilterTag type="companyFilterTag" filter={filter} />
      </Flex>
    </Flex>
  );
};

const FilterTag = ({
  type,
  filter,
  deleteFilter,
  filterIndex,
  arrIndex,
  isHitsAndMissesView,
}) => {
  const [, drag] = useDrag(
    () => ({
      type: type,
      item: { filter, arrIndex, filterIndex },
      canDrag: () => !!deleteFilter,
    }),
    [filter]
  );

  return (
    <motion.div
      initial={{ x: 50, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -50, opacity: 0 }}
      transition={{ x: { type: 'backIn', duration: 0.3 } }}
      ref={(node) => drag(node)}
    >
      <Tag
        variant="filter"
        cursor={isHitsAndMissesView ? 'pointer' : 'grab'}
        p={isHitsAndMissesView ? '5px 8px' : 'unset'}
        maxH="24px"
        border="1px solid"
        borderColor="trueLight"
      >
        {!isHitsAndMissesView && (
          <Flex
            justifyContent="center"
            alignItems="center"
            backgroundColor="trueLighter"
            borderRadius="24px 0 0 24px"
            borderRight="1px solid"
            borderRightColor="trueLight"
            h="24px"
            w="24px"
            mr="4px"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Icon as={DragIcon} w="16px" h="16px" ml="2px" />
          </Flex>
        )}
        {filter.subCategory && (
          <Box
            mr={1}
            sx={{
              svg: {
                width: '16px',
                height: '16px',
                fill: isHitsAndMissesView
                  ? filter.hit
                    ? 'trueSpace'
                    : 'trueDisabled'
                  : 'trueDim',
                color: isHitsAndMissesView
                  ? filter.hit
                    ? 'trueSpace'
                    : 'trueDisabled'
                  : 'trueDim',
              },
            }}
          >
            {companyCategoryIconMap[getSubCategoryFromPath(filter.path)] ||
              companyCategoryIconMap[getParentFromPath(filter.path)] ||
              leadCategoryIconMap[getSubCategoryFromPath(filter.path)] ||
              leadCategoryIconMap[getParentFromPath(filter.path)]}
          </Box>
        )}
        <Box
          color={
            isHitsAndMissesView
              ? filter.hit
                ? 'trueSpace'
                : 'trueDisabled'
              : 'trueDim'
          }
        >
          {filter.publicName}
        </Box>
        {!!deleteFilter && (
          <IconButton
            ml={1}
            variant="truebaseText"
            _hover={{
              svg: { fill: 'trueDark' },
            }}
            _active={{}}
            w="24px"
            h="24px"
            minW={0}
            sx={{ svg: { fill: 'trueMedium' } }}
            onClick={(e) => {
              e.stopPropagation();
              deleteFilter(filter);
            }}
          >
            <CloseSmallIcon />
          </IconButton>
        )}
      </Tag>
    </motion.div>
  );
};

const CompanyFilterHeaderArrayContainer = ({
  filterGroup,
  filters,
  arrIndex,
  deleteFilter,
  setFilters,
  isHitsAndMissesView,
  hideRightBorder,
}) => {
  const { setIsFilterAccordionDirty } = useIsDirtyContext();
  const [{ isOverCurrent }, drop] = useDrop(
    () => ({
      accept: 'companyFilterTag',
      drop(_item, monitor) {
        const didDrop = monitor.didDrop();
        if (didDrop) {
          return;
        }
        setIsFilterAccordionDirty?.(true);
        setFilters(
          produce((draft) => {
            const indexOfDraggedArray = draft.findIndex((arr) =>
              arr.some((filter) => filter._id === _item.filter._id)
            );
            const indexInsideDraggedArray = draft[
              indexOfDraggedArray
            ].findIndex((filter) => filter._id === _item.filter._id);

            const indexOfTargetArray = draft.findIndex((arr) =>
              arr.some((filter) => filter._id === filterGroup[0]._id)
            );

            // do not add the dragged item to a new bracket if
            // it is already in that bracket or it is an exclude bracket or it is an exclude item
            if (
              draft[indexOfTargetArray].some(
                (filter) => filter._id === _item.filter._id || !filter.include
              ) ||
              !_item.filter.include
            )
              return;
            draft[indexOfTargetArray].push(_item.filter);
            draft[indexOfDraggedArray].splice(indexInsideDraggedArray, 1);
            if (draft[indexOfDraggedArray].length === 0) {
              draft.splice(indexOfDraggedArray, 1);
            }
          })
        );
      },
      collect: (monitor) => {
        return {
          item: monitor.getItem(),
          isOver: monitor.isOver(),
          isOverCurrent: monitor.isOver({ shallow: true }),
        };
      },
    }),
    [
      filterGroup,
      filters,
      arrIndex,
      deleteFilter,
      setFilters,
      isHitsAndMissesView,
      hideRightBorder,
    ]
  );

  return (
    <Flex alignItems="center" gap="4px">
      <Flex
        ref={drop}
        rowGap={2}
        columnGap={1}
        alignItems="center"
        flexWrap="wrap"
        sx={
          isOverCurrent
            ? {
                border: '1px dashed rgba(2, 128, 245, 1)',
                borderRadius: '4px',
                p: '4px 8px',
              }
            : {
                border: '1px dashed',
                borderRight: hideRightBorder ? 'none' : '1px dashed',
                borderRadius: '2px',
                borderColor: filterGroup.find((group) => group.include)
                  ? 'trueGrass'
                  : 'trueCorral',
                p: '6px',
              }
        }
      >
        {filterGroup.map((filter, filterIndex) => (
          <TruebaseTooltip
            key={filter._id}
            maxW="400px"
            closeDelay={filter?.spots || filter?.foundInUrls ? 1600 : 'unset'}
            pointerEvents="all"
            label={
              filter.spots ? (
                <Flex flexDir="row" gap="16px">
                  {filter.spots.map(({ spot, text }) => (
                    <Box key={spot}>
                      ...
                      <Highlight
                        styles={{
                          bg: 'trueGrass',
                          py: '2px',
                          px: '4px',
                          color: 'trueSpace',
                          fontWeight: 500,
                        }}
                        query={spot}
                      >
                        {text}
                      </Highlight>
                      ...
                    </Box>
                  ))}
                </Flex>
              ) : (
                <Flex flexDir="column" gap="8px">
                  <Box>
                    <Text display="inline" fontWeight="400">
                      {filter.subCategory}
                    </Text>
                    :{' '}
                    <Text display="inline" fontWeight="500">
                      {filter.publicName}
                    </Text>
                  </Box>
                  {filter?.foundInUrls && (
                    <Text display="block" fontWeight="400">
                      Found on:
                    </Text>
                  )}
                  {filter?.foundInUrls && (
                    <Flex flexDir="column" gap="8px">
                      {filter.foundInUrls?.map((url, i) => (
                        <Box key={i} cursor="pointer">
                          <Link
                            borderRadius="2px"
                            color="trueSpace"
                            bgColor="trueGrass"
                            cursor="pointer"
                            padding="2px 4px"
                            href={url}
                            target="_blank"
                          >
                            {url}
                          </Link>
                        </Box>
                      ))}
                    </Flex>
                  )}
                </Flex>
              )
            }
          >
            <Box>
              <FilterTag
                type="companyFilterTag"
                arrIndex={arrIndex}
                filterIndex={filterIndex}
                filter={filter}
                deleteFilter={deleteFilter}
                isHitsAndMissesView={isHitsAndMissesView}
              />
            </Box>
          </TruebaseTooltip>
        ))}
      </Flex>
      {arrIndex !== filters.length - 1 && <OperatorAnd key={Math.random()} />}
    </Flex>
  );
};

const LeadFilterHeaderArrayContainer = ({
  filterGroup,
  filters,
  arrIndex,
  deleteFilter,
  setFilters,
  hideRightBorder,
}) => {
  const { setIsFilterAccordionDirty } = useIsDirtyContext();

  const [{ isOverCurrent }, drop] = useDrop(
    () => ({
      accept: 'personFilterTag',
      drop(_item, monitor) {
        const didDrop = monitor.didDrop();
        if (didDrop) {
          return;
        }
        setIsFilterAccordionDirty(true);
        setFilters(
          produce((draft) => {
            const indexOfDraggedArray = draft.findIndex((arr) =>
              arr.some((filter) => filter._id === _item.filter._id)
            );
            const indexInsideDraggedArray = draft[
              indexOfDraggedArray
            ].findIndex((filter) => filter._id === _item.filter._id);

            const indexOfTargetArray = draft.findIndex((arr) =>
              arr.some((filter) => filter._id === filterGroup[0]._id)
            );
            // do not add the dragged item to a new bracket if
            // it is already in that bracket or it is an exclude bracket or it is an exclude item
            if (
              draft[indexOfTargetArray].some(
                (filter) => filter._id === _item.filter._id || !filter.include
              ) ||
              !_item.filter.include
            )
              return;

            draft[indexOfTargetArray].push(_item.filter);
            draft[indexOfDraggedArray].splice(indexInsideDraggedArray, 1);
            if (draft[indexOfDraggedArray].length === 0) {
              draft.splice(indexOfDraggedArray, 1);
            }
          })
        );
      },
      collect: (monitor) => {
        return {
          item: monitor.getItem(),
          isOver: monitor.isOver(),
          isOverCurrent: monitor.isOver({ shallow: true }),
        };
      },
    }),
    [filterGroup, filters, arrIndex, deleteFilter, setFilters, hideRightBorder]
  );
  return (
    <Flex alignItems="center" gap="4px">
      <Flex
        ref={drop}
        rowGap={2}
        columnGap={1}
        alignItems="center"
        flexWrap="wrap"
        sx={
          isOverCurrent
            ? {
                border: '1px dashed rgba(2, 128, 245, 1)',
                borderRadius: '4px',
                padding: '4px 8px',
              }
            : {}
        }
        border="1px dashed"
        borderRight={hideRightBorder ? 'none' : '1px dashed'}
        borderRadius="2px"
        borderColor={
          filterGroup.find((group) => group.include)
            ? 'trueGrass'
            : 'trueCorral'
        }
        p="6px"
      >
        {filterGroup.map((filter, filterIndex) => (
          <TruebaseTooltip
            key={filter._id}
            maxW="400px"
            label={
              filter.spots ? (
                <Flex flexDir="row" gap="16px">
                  {filter.spots.map(({ spot, text }) => (
                    <Box key={spot}>
                      ...
                      <Highlight
                        styles={{
                          bg: 'trueGrass',
                          py: '2px',
                          px: '4px',
                          color: 'trueSpace',
                          fontWeight: 500,
                        }}
                        query={spot}
                      >
                        {text}
                      </Highlight>
                      ...
                    </Box>
                  ))}
                </Flex>
              ) : (
                <>
                  <Text display="inline" fontWeight="400">
                    {filter.subCategory}
                  </Text>
                  :{' '}
                  <Text display="inline" fontWeight="500">
                    {filter.publicName}
                  </Text>
                </>
              )
            }
          >
            <Box>
              <FilterTag
                type="personFilterTag"
                arrIndex={arrIndex}
                filterIndex={filterIndex}
                filter={filter}
                deleteFilter={deleteFilter}
              />
            </Box>
          </TruebaseTooltip>
        ))}
      </Flex>
      {arrIndex !== filters.length - 1 && <OperatorAnd key={Math.random()} />}
    </Flex>
  );
};

const MoreChip = ({ allFilters, collapsedFilters }) => {
  return (
    <>
      {allFilters.length === collapsedFilters.length && (
        <OperatorAnd key={Math.random()} />
      )}
      <Flex
        border="1px dashed #7E8BADB2"
        h="42px"
        alignItems="center"
        p="6px"
        borderRadius="2px"
      >
        <Flex
          alignItems="center"
          gap="4px"
          border="1px solid"
          borderColor="trueLight"
          borderRadius="40px"
          pr="8px"
        >
          <Icon as={PlusChip} w="24px" h="24px" />
          <Text
            fontWeight="500"
            fontSize="12px"
            lineHeight="14px"
            color="trueDim"
          >
            {allFilters.flat().length - collapsedFilters.flat().length} more
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

export const CompanyFiltersHeader = ({
  filters,
  deleteFilter,
  accordionIndex,
  setFilters,
  isHitsAndMissesView = false,
  userUsedDragNDrop,
  setUserUsedDragNDrop,
  user,
  type,
}) => {
  const featureFlags = useMemo(
    () => user?.featureFlags || {},
    [user?.featureFlags]
  );
  const [editUser] = useEditMutation();
  const { setIsFilterAccordionDirty } = useIsDirtyContext();
  const [{ isOverCurrent, item }, drop] = useDrop(
    () => ({
      accept: 'companyFilterTag',
      drop(_item, monitor) {
        const didDrop = monitor.didDrop();
        if (didDrop) {
          return;
        }
        setUserUsedDragNDrop(true);
        setIsFilterAccordionDirty?.(true);
        setFilters(
          produce((draft) => {
            const indexOfDraggedArray = draft.findIndex((arr) =>
              arr.some((filter) => filter._id === _item.filter._id)
            );
            const indexInsideDraggedArray = draft[
              indexOfDraggedArray
            ].findIndex((filter) => filter._id === _item.filter._id);
            draft.push([_item.filter]);
            draft[indexOfDraggedArray].splice(indexInsideDraggedArray, 1);
            if (draft[indexOfDraggedArray].length === 0) {
              draft.splice(indexOfDraggedArray, 1);
            }
          })
        );
        !featureFlags?.filtersDragAndDrop &&
          editUser({
            featureFlags: { ...featureFlags, filtersDragAndDrop: true },
          });
      },
      collect: (monitor) => ({
        item: monitor.getItem(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [
      filters,
      deleteFilter,
      accordionIndex,
      setFilters,
      isHitsAndMissesView,
      userUsedDragNDrop,
      setUserUsedDragNDrop,
      user,
      type,
    ]
  );
  const parentRef = useRef(null);
  const showNewBracketBox =
    (!isEmpty(user) &&
      !featureFlags?.filtersDragAndDrop &&
      !userUsedDragNDrop &&
      !isHitsAndMissesView &&
      accordionIndex === 0) ||
    !filters.length;

  const collapsedFilters = useMemo(() => {
    const output = [];
    let totalChars = 0;
    let maxLengthReached = false;

    filters.forEach((innerFilter) => {
      const filteredInner = [];
      innerFilter.forEach((filter) => {
        const filterLength = get(filter, 'publicName.length', 5);

        if (
          totalChars + filterLength <
            ((parentRef?.current?.clientWidth || parentRefMaxWidth) /
              parentRefMaxWidth) *
              allowedNumberOfChars &&
          !maxLengthReached
        ) {
          totalChars += filterLength;
          filteredInner.push(filter);
        } else {
          maxLengthReached = true;
          return;
        }
      });
      if (filteredInner.length) {
        output.push(filteredInner);
      }
    });

    return output;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, parentRef?.current?.clientWidth]);

  return (
    <Box ref={parentRef} w="100%">
      <Box
        fontSize="18px"
        lineHeight="21px"
        display="flex"
        rowGap={2}
        columnGap={1}
        flexWrap="wrap"
        position="relative"
        alignItems="center"
        ref={drop}
        width="100%"
        padding={isHitsAndMissesView ? 'unset' : '12px 0'}
      >
        {!isHitsAndMissesView && (
          <Text
            fontSize="12px"
            lineHeight="14px"
            color={type === 'include' ? 'trueGrass' : 'trueAttention'}
            w="100%"
          >
            {capitalize(type)}:
          </Text>
        )}

        {filters.map((filterGroup, arrIndex) => {
          return (
            <CompanyFilterHeaderArrayContainer
              key={arrIndex}
              arrIndex={arrIndex}
              deleteFilter={deleteFilter}
              filterGroup={filterGroup}
              filters={filters}
              setFilters={setFilters}
              isHitsAndMissesView={isHitsAndMissesView}
              hideRightBorder={
                !isHitsAndMissesView &&
                accordionIndex !== 0 &&
                filters[arrIndex].length !== collapsedFilters[arrIndex]?.length
              }
              accordionIndex={accordionIndex}
            />
          );
        })}

        {item && isOverCurrent && <NewArrayContainer filter={item?.filter} />}

        {showNewBracketBox && <NewBracketBox />}

        {accordionIndex !== 0 &&
          !isEmpty(user) &&
          !isHitsAndMissesView &&
          filters.flat().length !== collapsedFilters.flat().length && (
            <MoreChip
              allFilters={filters}
              collapsedFilters={collapsedFilters}
            />
          )}
      </Box>
    </Box>
  );
};

export const LeadFiltersHeader = ({
  filters,
  deleteFilter,
  accordionIndex,
  setFilters,
  userUsedDragNDrop,
  setUserUsedDragNDrop,
  user,
  type,
}) => {
  const featureFlags = useMemo(
    () => user?.featureFlags || {},
    [user?.featureFlags]
  );
  const [editUser] = useEditMutation();
  const { setIsFilterAccordionDirty } = useIsDirtyContext();
  const [{ isOverCurrent, item }, drop] = useDrop(
    () => ({
      accept: 'personFilterTag',
      drop(_item, monitor) {
        const didDrop = monitor.didDrop();
        if (didDrop) {
          return;
        }
        setIsFilterAccordionDirty(true);
        setUserUsedDragNDrop(true);
        setFilters(
          produce((draft) => {
            const indexOfDraggedArray = draft.findIndex((arr) =>
              arr.some((filter) => filter._id === _item.filter._id)
            );
            const indexInsideDraggedArray = draft[
              indexOfDraggedArray
            ].findIndex((filter) => filter._id === _item.filter._id);
            draft.push([_item.filter]);
            draft[indexOfDraggedArray].splice(indexInsideDraggedArray, 1);
            if (draft[indexOfDraggedArray].length === 0) {
              draft.splice(indexOfDraggedArray, 1);
            }
          })
        );
        !featureFlags?.filtersDragAndDrop &&
          editUser({
            featureFlags: { ...featureFlags, filtersDragAndDrop: true },
          });
      },
      collect: (monitor) => ({
        item: monitor.getItem(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [
      filters,
      deleteFilter,
      accordionIndex,
      setFilters,
      userUsedDragNDrop,
      setUserUsedDragNDrop,
      user,
      type,
    ]
  );
  const parentRef = useRef(null);
  const showNewBracketBox =
    (!isEmpty(user) &&
      !featureFlags?.filtersDragAndDrop &&
      !userUsedDragNDrop &&
      accordionIndex === 1) ||
    !filters.length;

  const collapsedFilters = useMemo(() => {
    const output = [];
    let totalChars = 0;
    let maxLengthReached = false;

    filters.forEach((innerFilter) => {
      const filteredInner = [];
      innerFilter.forEach((filter) => {
        const filterLength = get(filter, 'publicName.length', 5);
        if (
          totalChars + filterLength <
            ((parentRef?.current?.clientWidth || parentRefMaxWidth) /
              parentRefMaxWidth) *
              allowedNumberOfChars &&
          !maxLengthReached
        ) {
          totalChars += filterLength;
          filteredInner.push(filter);
        } else {
          maxLengthReached = true;
          return;
        }
      });
      if (filteredInner.length) {
        output.push(filteredInner);
      }
    });

    return output;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, parentRef?.current?.clientWidth]);

  return (
    <Box ref={parentRef} w="100%">
      <Box
        fontSize="18px"
        lineHeight="21px"
        display="flex"
        rowGap={2}
        columnGap={1}
        flexWrap="wrap"
        position="relative"
        alignItems="center"
        ref={drop}
        width="100%"
        padding="12px 0"
      >
        <Text
          fontSize="12px"
          lineHeight="14px"
          color={type === 'include' ? 'trueGrass' : 'trueAttention'}
          w="100%"
        >
          {capitalize(type)}:
        </Text>
        {[...(accordionIndex !== 1 ? collapsedFilters : filters)].map(
          (filterGroup, arrIndex) => {
            return (
              <LeadFilterHeaderArrayContainer
                key={arrIndex}
                arrIndex={arrIndex}
                deleteFilter={deleteFilter}
                filterGroup={filterGroup}
                filters={filters}
                setFilters={setFilters}
                hideRightBorder={
                  accordionIndex !== 1 &&
                  filters[arrIndex].length !==
                    collapsedFilters[arrIndex]?.length
                }
                accordionIndex={accordionIndex}
              />
            );
          }
        )}

        {item && isOverCurrent && <NewArrayContainer filter={item?.filter} />}
        {showNewBracketBox && <NewBracketBox />}

        {accordionIndex !== 1 &&
          !isEmpty(user) &&
          filters.flat().length !== collapsedFilters.flat().length && (
            <MoreChip
              allFilters={filters}
              collapsedFilters={collapsedFilters}
            />
          )}
      </Box>
    </Box>
  );
};
