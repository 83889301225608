import { stepperAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const truebase = definePartsStyle({
  indicator: {
    h: '36px',
    w: '36px',
    '--stepper-accent-color': '#3F21FF',
  },
  title: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    color: 'trueMedium',
  },
  description: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: 'trueDim',
  },
  number: {
    color: 'trueMedium',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
  },
  step: {
    gap: '8px',
  },
  separator: {
    minW: '50px',
    maxW: '50px',
  },
});

export const stepperTheme = defineMultiStyleConfig({
  variants: {
    truebase,
  },
});
