import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { usersApi } from '../services/user';
import smartlookClient from 'smartlook-client';
import { safeJSONParse } from '~/v2/common/utils';
import { SEQUENCE_TEMPLATE_LOCAL_STORAGE_KEY } from './sequenceTemplateSlice';
import { ICP_LOCAL_STORAGE_KEY } from './icpSlice';
import { ACCOUNT_QUALIFICATION_LOCAL_STORAGE_KEY } from './accountQualificationSlice';

export const logout = createAction('users/logout', () => {
  localStorage.removeItem('AccessToken');
  localStorage.removeItem('User');
  localStorage.removeItem('inviteLinkData');
  localStorage.removeItem('trbs-exportActive');
  localStorage.removeItem('exportDownloadCsvUrl');
  localStorage.removeItem('exportDownloadPbValue');
  localStorage.removeItem(ICP_LOCAL_STORAGE_KEY);
  localStorage.removeItem(ACCOUNT_QUALIFICATION_LOCAL_STORAGE_KEY);
  localStorage.removeItem(SEQUENCE_TEMPLATE_LOCAL_STORAGE_KEY);

  smartlookClient.anonymize();
  return {};
});

export const setTrainingDisabled = createAction(
  'users/setTrainingDisabled',
  (trainingDisabled) => {
    return { trainingDisabled };
  }
);
export const setExportActive = createAction('users/setExportActive');

const matchUserReceived = isAnyOf(
  usersApi.endpoints.getMe.matchFulfilled,
  usersApi.endpoints.getCurrentUser.matchFulfilled,
  usersApi.endpoints.login.matchFulfilled
);

const slice = createSlice({
  name: 'customUser',
  initialState: {
    user: null,
    token: localStorage.getItem('AccessToken'),
    trainingDisabled: false,
    exportActive: safeJSONParse(localStorage.getItem('trbs-exportActive')),
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setExportActive, (state, { payload }) => {
      localStorage.setItem('trbs-exportActive', JSON.stringify(payload));
      state.exportActive = payload;
    });
    builder.addMatcher(matchUserReceived, (state, { payload }) => {
      if (payload?.isOk) {
        if (payload.result.token) {
          localStorage.setItem('AccessToken', payload.accessToken);
          state.token = payload.result.token;
        }
        if (payload.result.user) {
          localStorage.setItem('User', JSON.stringify(payload.result.user));
          state.user = payload.result.user;
        }
      }
    });
    builder.addMatcher(
      usersApi.endpoints.signup.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.result?.user;
        state.token = payload.result?.authToken;
      }
    );
    builder.addMatcher(logout.match, (state) => {
      state.token = null;
      state.user = null;
    });
    builder.addMatcher(setTrainingDisabled.match, (state, { payload }) => {
      state.trainingDisabled = payload.trainingDisabled;
    });
  },
});

export const getExportActive = (state) => state.customUser.exportActive;
export const getUserId = (state) => state.customUser.user?.id;
export const getUser = (state) => state.customUser.user;
export const getUserTrainingDisabled = (state) =>
  state.customUser.trainingDisabled;

export default slice.reducer;
