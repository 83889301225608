export const webkitScrollbarConfig = (
  backgroundColor = 'white',
  thumbBackgroundColor = 'transparent'
) => ({
  '::-webkit-scrollbar-thumb': {
    background: 'rgb(225, 230, 243)',
    border: `6px solid ${backgroundColor}`,

    height: '100px',
    borderRadius: '42px',
  },
  '::-webkit-scrollbar': {
    width: '16px',
  },
  '::-webkit-scrollbar-track': {
    // margin: '10px 0px',
    backgroundColor: thumbBackgroundColor,
  },
});
