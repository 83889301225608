import { progressAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const truebase = definePartsStyle({
  filledTrack: {
    bg: 'trueGrass',
  },
  track: {
    bg: 'trueLight',
    height: '4px',
  },
});

export const progressTheme = defineMultiStyleConfig({
  variants: { truebase },
});
