import { apiSlice } from './api';
import { omit } from 'lodash';

const mapFunction = (entityType) => {
  switch (entityType) {
    case 'company':
      return (filter) =>
        filter.map((filter) => ({
          _id: filter._id.toString(),
          include: filter.include,
        }));
    case 'person':
      return (filterArr) =>
        filterArr.map((filter) => {
          if (filter.freeForm) {
            return {
              ...(filter.includeMongoQuery && {
                includeMongoQuery: filter.includeMongoQuery,
              }),
              ...(filter.excludeMongoQuery && {
                excludeMongoQuery: filter.excludeMongoQuery,
              }),
              include: filter.include,
            };
          } else {
            return {
              _id: filter._id.toString(),
              include: filter.include,
            };
          }
        });
    default:
      throw new Error('Invalid entity type');
  }
};

const prepareFiltersForBackend = (filters, entityType) => {
  return filters ? filters.map(mapFunction(entityType)) : undefined;
};

// Define a service using a base URL and expected endpoints
export const icpApi = apiSlice.injectEndpoints({
  injectTags: ['Icp'],
  endpoints: (builder) => ({
    getIcps: builder.query({
      query: (args) => {
        return { url: `icps/${args.teamId}`, method: 'GET' };
      },
      transformResponse: (response) => {
        return response.result.icps;
      },
      providesTags: ['Icps'],
    }),
    addIcp: builder.mutation({
      query: (body) => ({
        url: 'icps',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Icps'],
    }),
    duplicateIcp: builder.mutation({
      query: (body) => ({
        url: 'icps/duplicate',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Icps'],
    }),
    deleteIcp: builder.mutation({
      query: ({ _id, teamId }) => ({
        url: `icps/${_id}?teamId=${teamId}`,
        method: 'DELETE',
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const patchGetIcp = dispatch(
          apiSlice.util.updateQueryData(
            'getIcps',
            { teamId: arg.teamId },
            (draft) => {
              draft = draft.filter((icp) => icp._id !== arg._id);
              return draft;
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (e) {
          patchGetIcp?.undo();
        }
      },
    }),
    editIcp: builder.mutation({
      async queryFn(args, queryApi, extraOptions, baseQuery) {
        const response = await baseQuery({
          url: `icps/${args._id}`,
          method: 'PATCH',
          body: {
            ...omit(args, 'meta'),
            name: args.name || undefined,
            companyFilterStatements: prepareFiltersForBackend(
              args.companyFilterStatements,
              'company'
            ),
            personFilterStatements: prepareFiltersForBackend(
              args.personFilterStatements,
              'person'
            ),
            leadWebhook: args.leadWebhook || undefined,
          },
        });
        return { data: { response, _id: args._id } };
      },
      invalidatesTags: (_, __, args) => {
        return args.meta?.noRefresh
          ? []
          : [{ type: 'Icp', id: args._id }, 'Icps'];
      },
    }),
    getAccountsLeadsCount: builder.mutation({
      query: (args) => ({
        url: `icps/${args.teamId}/count`,
        method: 'POST',
        body: {
          companyFilterStatements: prepareFiltersForBackend(
            args.companyFilterStatements,
            'company'
          ),
          personFilterStatements: prepareFiltersForBackend(
            args.personFilterStatements,
            'person'
          ),
        },
      }),
      transformResponse: (response) => {
        return response.result;
      },
    }),
  }),
});

export const {
  useGetIcpsQuery,
  useLazyGetIcpsQuery,
  useAddIcpMutation,
  useEditIcpMutation,
  useDeleteIcpMutation,
  useDuplicateIcpMutation,
  useGetAccountsLeadsCountMutation,
} = icpApi;
