import {
  Box,
  Button,
  Flex,
  Icon,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';
import { chakraComponents, Select } from 'chakra-react-select';
import { webkitScrollbarConfig } from '~/v2/common/constants/webkitScrollbar';
import { ReactComponent as TooltipArrow } from '~/v2/assets/tooltip arrow.svg';
import { ReactComponent as AddBoxIcon } from '~/v2/assets/icons/add/add-box.svg';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { useSelector } from 'react-redux';
import { getSelectedTeam } from '~/v2/redux/slices/teamsSlice';
import { generateUniqueName } from '~/v2/common/utils';

export const AutopilotStepCard = ({
  title,
  subtitle,
  icon,
  defaultValue,
  onChange,
  options,
  content,
  onEditClick,
  extraContent,
  selectLogo,
  isEnabled = true,
  iconColor,
  createFn,
  isCreateLoading,
  onCreate,
  data,
  name,
}) => {
  const { handleAsyncError } = useErrorHandler();
  const toast = useToast();

  const team = useSelector(getSelectedTeam);

  const handleCreateNew = handleAsyncError(async () => {
    const response = await createFn({
      name: generateUniqueName(name, data),
      teamId: team._id,
    }).unwrap();
    onCreate?.(response?.result);
    toast({
      title: response?.isOk ? 'Added Successfully!' : 'Something went wrong!',
      status: response?.isOk ? 'success' : 'error',
      duration: 1000,
    });
  });

  return (
    <Flex
      direction="column"
      border="1px solid"
      borderColor={isEnabled ? 'trueLight' : 'trueLighter'}
      borderRadius="4px"
      bg="white"
      w="100%"
      h="fit-content"
    >
      <Flex gap="24px">
        <Flex p="16px" flexDir="column" w="100%" gap="16px">
          <Icon as={icon} w="24px" h="24px" color={iconColor} />
          <Flex
            flexDir="column"
            gap="4px"
            color={isEnabled ? 'trueSpace' : 'trueDim'}
          >
            {title}
            <Text fontWeight="400" fontSize="13px" lineHeight="16px">
              {subtitle}
            </Text>
          </Flex>
          {extraContent}
        </Flex>
        <Box flex="1" borderLeft="1px solid" borderColor="trueLighter" />
        {content || (
          <Flex p="0 16px 16px 0" alignItems="end" w="100%">
            <Flex alignItems="center" gap="8px">
              <Select
                isDisabled={!isEnabled}
                defaultValue={defaultValue}
                options={options}
                onChange={({ value }) => {
                  onChange(value);
                }}
                blurInputOnSelect
                getOptionLabel={(option) => (
                  <Flex alignItems="center" gap="8px">
                    <Icon as={selectLogo} w="24px" h="24px" />
                    <span className="option-label">{option.label}</span>
                  </Flex>
                )}
                chakraStyles={{
                  control: (baseStyle) => ({
                    ...baseStyle,
                    cursor: 'pointer',
                    border: 'none',
                    w: '260px',
                  }),
                  valueContainer: (baseStyle) => ({
                    ...baseStyle,
                    fontSize: '12px',
                    color: 'trueSpace',
                    lineHeight: '14px',
                    fontWeight: '500',
                    p: 0,
                  }),
                  dropdownIndicator: () => ({ color: 'trueDim' }),
                  menuList: (baseStyle) => ({
                    ...baseStyle,
                    overflowY: 'overlay',
                    p: 0,
                    maxH: '200px',
                    borderRadius: '4px 4px 0 0',
                    boxShadow: 'none',
                    borderBottom: 0,
                    ...webkitScrollbarConfig(),
                  }),
                  menu: (baseStyle) => ({
                    ...baseStyle,
                    mt: '-30px',
                    overflow: 'hidden',
                  }),
                  option: (baseStyle, { isSelected }) => ({
                    ...baseStyle,
                    p: '8px 16px',
                    color: 'trueSpace !important',
                    fontSize: '12px',
                    lineHeight: '14px',
                    fontWeight: '500',
                    ...(isSelected && { bg: 'white !important' }),
                    '&:hover': { backgroundColor: '#c3e6fa !important' },
                  }),
                }}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: (props) => (
                    <chakraComponents.DropdownIndicator {...props}>
                      <TooltipArrow />
                    </chakraComponents.DropdownIndicator>
                  ),
                  MenuList: (props) => (
                    <>
                      <chakraComponents.MenuList {...props}>
                        <Box>{props.children}</Box>
                      </chakraComponents.MenuList>
                      <Button
                        sx={{
                          w: '100%',
                          h: '40px',
                          color: 'trueLink',
                          fontSize: '12px',
                          lineHeight: '14px',
                          p: '8px 16px',
                          bgColor: 'white',
                          borderWidth: '0 1px 1px 1px',
                          borderRadius: '0 0 4px 4px',
                          fontWeight: '500',
                          justifyContent: 'flex-start',
                          _hover: { bgColor: '#c3e6fa' },
                          opacity: '1 !important',
                        }}
                        leftIcon={<Icon as={AddBoxIcon} w="24px" h="24px" />}
                        isLoading={isCreateLoading}
                        onClick={handleCreateNew}
                      >
                        Create New
                      </Button>
                    </>
                  ),
                }}
              />
            </Flex>
            <Spacer />
            <Button
              h="24px"
              w="45px"
              onClick={onEditClick}
              isDisabled={!isEnabled}
              sx={{
                color: 'trueSpace',
                bg: 'trueLighter',
                _disabled: { color: 'trueDim', bg: 'trueLighter' },
              }}
              cursor={isEnabled ? 'pointer' : 'not-allowed'}
            >
              <Text fontSize="12px" lineHeight="14px" fontWeight="500">
                Edit
              </Text>
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
