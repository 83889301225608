import { Flex, Switch, Text } from '@chakra-ui/react';
import { AutopilotStepCard } from '../AutopilotStepCard';
import { ReactComponent as VerifiedUserIcon } from '~/v2/assets/icons/verified-user.svg';
import { TruebaseTooltip } from '~/v2/common/TruebaseTooltip';

export const AutopilotVerifyStep = ({
  contactEnrichmentVerificationOptions,
  setContactEnrichmentVerificationOptions,
  isVerifyEnabled,
  setIsVerifyEnabled,
}) => {
  const handleTripleCheckChange = (isChecked, value) => {
    setContactEnrichmentVerificationOptions((oldArr) => {
      return isChecked
        ? [...oldArr, value]
        : oldArr.filter((item) => item !== value);
    });
  };

  return (
    <AutopilotStepCard
      title={
        <Flex alignItems="center" gap="8px">
          <Switch
            variant="truebaseAutopilot"
            isChecked={isVerifyEnabled}
            onChange={() => {
              setIsVerifyEnabled(!isVerifyEnabled);
            }}
          />
          <Text fontWeight="500" fontSize="14px" lineHeight="16px">
            Verify
          </Text>
        </Flex>
      }
      subtitle="Find & validate emails, ensuring employment status and debouncing to keep your domain’s integrity"
      icon={VerifiedUserIcon}
      content={
        <Flex flexDir="column" gap="12px" w="100%" pb="16px" alignSelf="end">
          <Flex flexDir="column" gap="12px">
            {[
              {
                name: 'employment',
                title: 'Employment verification',
                tooltip:
                  'Confirm title & current employment status on Linkedin',
              },
              {
                name: 'workEmail',
                title: 'Verified work email',
                tooltip:
                  'Ensure only leads with confirmed work emails move to the next step',
              },
            ].map(({ title, tooltip, name }, index) => (
              <TruebaseTooltip key={index} label={tooltip} placement="right">
                <Flex alignItems="center" gap="8px" w="fit-content">
                  <Switch
                    variant="truebaseAutopilot"
                    isChecked={contactEnrichmentVerificationOptions.includes(
                      name
                    )}
                    onChange={({ target: { checked } }) =>
                      handleTripleCheckChange(checked, name)
                    }
                    isDisabled={!isVerifyEnabled}
                  />
                  <Text
                    fontWeight="400"
                    fontSize="13px"
                    lineHeight="16px"
                    color={!isVerifyEnabled ? 'trueDisabled' : 'trueSpace'}
                  >
                    {title}
                  </Text>
                </Flex>
              </TruebaseTooltip>
            ))}
          </Flex>
          <Text
            fontWeight="400"
            fontSize="13px"
            lineHeight="16px"
            color="trueExplain"
          >
            Linkedin profile url will always be included
          </Text>
        </Flex>
      }
      isEnabled={isVerifyEnabled}
    />
  );
};
