import { Flex, Icon, Spacer, Spinner, Text } from '@chakra-ui/react';
import { ReactComponent as Leads1 } from '~/v2/assets/icons/leads/leads-1.svg';
import { ReactComponent as EnrichIcon } from '~/v2/assets/icons/enrich.svg';
import { ReactComponent as MailDeliveredIcon } from '~/v2/assets/icons/mail/mail-delivered.svg';
import { ReactComponent as SearchIcon } from '~/v2/assets/icons/search-global.svg';
import { AnimatePresence, motion } from 'framer-motion';

export const SelectAutopilotMode = ({
  selectedMode,
  setSelectedMode,
  isLoading,
}) => {
  return (
    <Flex flexDir="column" gap="16px" mt="32px" ml="66px">
      <AnimatePresence>
        <motion.div
          initial={{ x: 200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
        >
          <Text
            fontWeight="500"
            fontSize="13px"
            lineHeight="16px"
            color="trueExplain"
          >
            What do you want to do today?
          </Text>
        </motion.div>
      </AnimatePresence>

      <AnimatePresence>
        <motion.div
          initial={{ x: -200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
        >
          <Flex gap="16px">
            {[
              {
                icon: Leads1,
                title: 'Build List',
                subtitle:
                  'Find new leads, qualify them, and get their verified email and phone.',
                value: 'buildList',
              },
              {
                icon: EnrichIcon,
                title: 'Inbound Enrichment',
                subtitle:
                  'Import your own leads and enrich them further with insights and contact information.',
                value: 'inboundEnrichment',
              },
              {
                icon: MailDeliveredIcon,
                title: 'Inbound to Outbound',
                subtitle:
                  'Import your leads, enrich them with new insights and run an email campaign.',
                value: 'inboundToOutbound',
              },
              {
                icon: SearchIcon,
                title: 'Email Outbound',
                subtitle:
                  'Find new qualified leads with verified emails, and run an outreach email campaign.',
                value: 'emailOutbound',
              },
            ].map(({ icon, title, subtitle, value }, i) => {
              return (
                <Flex
                  key={i}
                  onClick={() => {
                    setSelectedMode(value);
                  }}
                  flexDir="column"
                  borderRadius="8px"
                  backgroundColor="white"
                  p="16px"
                  border="1px solid"
                  borderColor="trueLight"
                  cursor="pointer"
                  h="140px"
                  w="260px"
                  justifyContent="center"
                  _hover={{ backgroundColor: 'truePreselect' }}
                  _active={{ borderColor: 'trueBlue' }}
                  transition="all ease 150ms"
                >
                  <Flex flexDir="column" gap="4px">
                    <Icon as={icon} w="24px" h="24px" color="trueDim" />
                    <Text fontWeight="500" fontSize="13px" lineHeight="16px">
                      {title}
                    </Text>
                  </Flex>

                  <Spacer />

                  <Text
                    color="trueMedium"
                    fontWeight="400"
                    fontSize="12px"
                    lineHeight="16px"
                  >
                    {subtitle}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        </motion.div>
      </AnimatePresence>

      {isLoading && selectedMode && (
        <Flex gap="8px" alignItems="center">
          <Spinner color="trueBlue" />
          <Text fontWeight="400" fontSize="12px" lineHeight="14px">
            Setting up your autopilot dashboard
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
