import { Button, Flex, Icon, Spinner, Text, useToast } from '@chakra-ui/react';
import { CompanyFiltersHeader } from '~/v2/features/filters/components/FiltersAccordionHeader';
import { useGetHitsAndMissesQuery } from '~/v2/redux/services/accounts';
import { getIncludedExcludedFilters } from '../../utils';
import { ReactComponent as MinusIcon } from '~/v2/assets/icons/minus/minus-small.svg';
import { ReactComponent as PlusIcon } from '~/v2/assets/icons/add/add-small.svg';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { getParentFromPath } from '~/v2/features/filters/components/utils';
import produce from 'immer';
import { useFilterContext } from '~/v2/features/filters/components/useFilterContext';
import { companyCategoryIconMap } from '~/v2/features/filters/components/filterIcons';
import { TruebaseTooltip } from '../../TruebaseTooltip';
import { flattenDeep, get } from 'lodash';
import { useIsDirtyContext } from '~/v2/contexts/useIsDirtyContext';

export default function HitsAndMisses({ companyId, companyFilters }) {
  const { handleSyncError } = useErrorHandler();
  const { setCompanyFilters } = useFilterContext();
  const { setIsFilterAccordionDirty } = useIsDirtyContext();
  const toast = useToast();

  const { data, isLoading, isFetching } = useGetHitsAndMissesQuery({
    companyId,
    companyFilterStatements: companyFilters,
  });

  if (isLoading || !data) {
    return (
      <Flex justifyContent="center">
        <Spinner />
      </Flex>
    );
  }

  const hitsAndMissesFilters = get(data, 'result.companyFilterStatements', []);
  const flattenedFilters = flattenDeep(hitsAndMissesFilters);

  const suggestedFilters = get(data, 'result.suggestedFilters', []);
  const filtersSuggestedFilters = suggestedFilters.filter(
    (filter) => !flattenedFilters.find((f) => f._id === filter._id)
  );

  const categorizedCompanyFilters =
    getIncludedExcludedFilters(hitsAndMissesFilters);

  const handleSetCompanyFilter = handleSyncError((filter) => {
    setIsFilterAccordionDirty(true);
    setCompanyFilters(
      produce((draft) => {
        if (!filter.include) {
          return [...draft, [filter]];
        }

        const existingFilterGroupIndex = draft.findIndex(
          (f) =>
            getParentFromPath(f[0].path) === getParentFromPath(filter.path) &&
            filter.include &&
            f[0].include
        );
        const existingFilterGroup = draft[existingFilterGroupIndex];
        if (existingFilterGroup) {
          const existingFilter = existingFilterGroup.find(
            (f) => f.path === filter.path
          );
          if (existingFilter) {
            return draft.map((f) =>
              getParentFromPath(f[0].path) === getParentFromPath(filter.path)
                ? f.map((f) =>
                    getParentFromPath(f.path) === getParentFromPath(filter.path)
                      ? {
                          ...f,
                          include: !f.include,
                        }
                      : f
                  )
                : f
            );
          } else {
            draft[existingFilterGroupIndex].push(filter);
          }
        } else {
          return [...draft, [filter]];
        }
      })
    );
  });

  return (
    <Flex flexDir="column" gap="12px">
      {data?.isOk &&
        (hitsAndMissesFilters.length > 0 ? (
          <Flex flexDir="column" gap="12px">
            <Text
              fontWeight="500"
              fontSize="12px"
              lineHeight="14px"
              color="trueDim"
            >
              Account Filters:
            </Text>

            {!!categorizedCompanyFilters.includedFilters.length && (
              <>
                <Text
                  fontWeight="500"
                  fontSize="12px"
                  lineHeight="14px"
                  color="trueGrass"
                >
                  Include:
                </Text>
                <CompanyFiltersHeader
                  filters={categorizedCompanyFilters.includedFilters}
                  isHitsAndMissesView
                />
              </>
            )}

            {!!categorizedCompanyFilters.excludedFilters.length && (
              <>
                <Text
                  fontWeight="500"
                  fontSize="12px"
                  lineHeight="14px"
                  color="trueAttention"
                >
                  Exclude:
                </Text>
                <CompanyFiltersHeader
                  filters={categorizedCompanyFilters.excludedFilters}
                  isHitsAndMissesView
                />
              </>
            )}

            {!!filtersSuggestedFilters.length && (
              <>
                <Text
                  fontWeight="500"
                  fontSize="12px"
                  lineHeight="14px"
                  color="trueDim"
                  mt="4px"
                >
                  Suggested Filters:
                </Text>
                <Flex gap="8px" flexWrap="wrap">
                  {filtersSuggestedFilters.slice(0, 10).map((filter, index) => (
                    <Flex key={index}>
                      <Flex
                        border="1px solid"
                        borderColor="trueLight"
                        borderRadius="24px 0 0 24px"
                        height="24px"
                        w="fit-content"
                        p="4px 12px"
                        alignItems="center"
                        color="trueDim"
                        fontWeight="500"
                        fontSize="12px"
                        lineHeight="14px"
                        gap="4px"
                        sx={{ svg: { width: '16px', height: '16px' } }}
                      >
                        {
                          companyCategoryIconMap?.[
                            filter?.path?.split('|')?.[0]
                          ]
                        }
                        {filter?.publicName}
                      </Flex>
                      <Flex>
                        <TruebaseTooltip label="Include in Account Filters">
                          <Button
                            h="24px"
                            w="24px"
                            minW="unset"
                            borderRadius="0"
                            p="0"
                            onClick={() => {
                              handleSetCompanyFilter({
                                include: true,
                                ...filter,
                              });

                              toast({
                                title: 'Included in Account Filters',
                                status: 'success',
                                duration: 1000,
                              });
                            }}
                            isDisabled={isFetching}
                          >
                            <Icon as={PlusIcon} />
                          </Button>
                        </TruebaseTooltip>

                        <TruebaseTooltip label="Exclude from Account Filters">
                          <Button
                            h="24px"
                            w="24px"
                            minW="unset"
                            borderRadius="0 24px 24px 0"
                            p="0"
                            onClick={() => {
                              handleSetCompanyFilter({
                                include: false,
                                ...filter,
                              });
                              toast({
                                title: 'Excluded from Account Filters',
                                status: 'success',
                                duration: 1000,
                              });
                            }}
                            isDisabled={isFetching}
                          >
                            <Icon as={MinusIcon} />
                          </Button>
                        </TruebaseTooltip>
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </>
            )}
          </Flex>
        ) : (
          <Text fontSize="13px" fontWeight="500" color="trueSpace">
            You&apos;ll see the search evaluation here when you apply company
            filters to your search.
          </Text>
        ))}
    </Flex>
  );
}
