import { Box, Button, Flex, useToast, Text, Skeleton } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { TruebaseTooltip } from '~/v2/common/TruebaseTooltip';
import { useEditSequenceTemplateMutation } from '~/v2/redux/services/sequenceTemplate';
import SequenceStepsForm from './SequenceStepsForm';
import { removeNullValues } from '~/v2/common/utils';
import { useIsDirtyContext } from '~/v2/contexts/useIsDirtyContext';
import { useLocation } from 'react-router';

export default function SequenceTemplateSettings({
  selectedSequenceTemplate,
  setIsPending,
}) {
  const toast = useToast();
  const location = useLocation();

  const [editSequenceTemplate, { isLoading: isSaveLoading }] =
    useEditSequenceTemplateMutation();

  const { setIsSequenceTemplateDirty } = useIsDirtyContext();

  const methods = useForm({
    mode: 'onSubmit',
  });

  const isResetDisabled = isEmpty(methods.formState.dirtyFields);

  const isSaveDisabled =
    isEmpty(methods.formState.dirtyFields) ||
    !isEmpty(methods.formState.errors);

  const resetForm = useCallback(() => {
    methods.reset({
      sequenceSteps: selectedSequenceTemplate?.sequenceSteps || [
        {
          type: 'email',
          subject: null,
          message: null,
          daysSinceLastMessage: 1,
        },
      ],
    });
  }, [methods, selectedSequenceTemplate?.sequenceSteps]);

  const onSubmit = async (formValues, e) => {
    e.preventDefault();
    const cleanedValues = removeNullValues(formValues);
    const { data: responseData } = await editSequenceTemplate({
      _id: selectedSequenceTemplate._id,
      ...cleanedValues,
    });
    toast({
      title: responseData?.response?.data?.isOk
        ? 'Saved Successfully!'
        : 'Something went wrong!',
      status: responseData?.response?.data?.isOk ? 'success' : 'error',
      duration: 1000,
    });
    if (responseData?.response?.data?.isOk) {
      methods.reset({ ...cleanedValues });
      setIsPending(false);
      if (location?.state?.isPending) {
        delete location.state.isPending;
      }
    }
  };

  useEffect(() => {
    if (selectedSequenceTemplate?._id) resetForm();
  }, [selectedSequenceTemplate?._id, resetForm]);

  useEffect(() => {
    setIsSequenceTemplateDirty(!isEmpty(methods.formState.dirtyFields));
  }, [methods.formState.dirtyFields, setIsSequenceTemplateDirty]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box
            sx={{
              border: '1px solid',
              borderColor: 'trueLight',
              borderRadius: '4px',
              bg: 'white',
            }}
          >
            <Box p="16px">
              {!selectedSequenceTemplate?._id ? (
                <Skeleton h="280px" w="100%" speed={0.6} />
              ) : (
                <>
                  <Text
                    fontSize="13px"
                    lineHeight="16px"
                    color="trueExplain"
                    mb="12px"
                  >
                    Sequence Settings
                  </Text>
                  <SequenceStepsForm />
                </>
              )}
            </Box>
            <Flex
              sx={{
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '16px',
                p: '8px 16px',
                borderTop: '1px solid',
                borderColor: 'trueLight',
              }}
            >
              <Box>
                <TruebaseTooltip
                  label={!isResetDisabled && 'Reset to last saved'}
                >
                  <Button
                    size="sm"
                    isDisabled={isResetDisabled}
                    variant="ghost"
                    color="trueDim"
                    _disabled={{
                      color: 'trueDisabled',
                    }}
                    fontSize="13px"
                    lineHeight="15px"
                    fontWeight="medium"
                    onClick={() => {
                      resetForm();
                    }}
                    minW="80px"
                  >
                    Reset
                  </Button>
                </TruebaseTooltip>
              </Box>
              <Box>
                <Button
                  type="submit"
                  size="sm"
                  variant="ghost"
                  color="trueDim"
                  _disabled={{
                    color: 'trueDisabled',
                  }}
                  fontSize="13px"
                  lineHeight="15px"
                  fontWeight="medium"
                  isDisabled={isSaveDisabled}
                  minW="80px"
                  isLoading={isSaveLoading}
                >
                  {isEmpty(methods.formState.dirtyFields)
                    ? 'Settings Saved'
                    : 'Save Settings'}
                </Button>
              </Box>
            </Flex>
          </Box>
        </form>
      </FormProvider>
    </>
  );
}
