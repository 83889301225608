import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import Layout from '~/v2/common/Layout';
import EmailAccountsPreview from './components/EmailAccountsPreview';
import { useGetTeamDetailsQuery } from '~/v2/redux/services/teams';
import { getSelectedTeam } from '~/v2/redux/slices/teamsSlice';

const EmailAccounts = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const selectedTeam = useSelector(getSelectedTeam);
  const { data: teamDetails, isLoading } = useGetTeamDetailsQuery(
    selectedTeam?._id,
    {
      skip: !selectedTeam?._id,
    }
  );
  const team = { ...selectedTeam, ...teamDetails };

  return (
    <Layout
      title="Email accounts"
      subtitle="Add one or more email accounts to run campaigns"
      withBackBtn={location.state?.fromLauchAutopilotPage}
      onBackClick={() => {
        navigate('/autopilot', {
          state: { settings: location.state?.settings },
        });
      }}
    >
      <Box py="24px" w="100%">
        <Helmet title={'Truebase | Email Accounts'} defer={false} />
        <Flex
          p="24px"
          border="1px solid"
          borderColor="trueLight"
          borderRadius="4px"
          direction="column"
          gap="24px"
          bg="white"
        >
          <Text sx={{ fontSize: '16px', lineHeight: '20px', fontWeight: 500 }}>
            Connected accounts
          </Text>
          {!selectedTeam || isLoading ? (
            <Box textAlign="center">
              <Spinner />
            </Box>
          ) : (
            <EmailAccountsPreview
              teamId={team._id}
              emailStats={team.emailStats}
              emailAccounts={team.emailAccounts}
            />
          )}
        </Flex>
      </Box>
    </Layout>
  );
};

export default EmailAccounts;
