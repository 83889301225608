import { useMotionValue, useSpring } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { utils } from './utils';

export default function AnimatedNumber({
  value,
  springOptions = {
    duration: 2500,
    bounce: 0,
  },
  format = false,
}) {
  const ref = useRef(null);
  const motionValue = useMotionValue(0);
  const springValue = useSpring(motionValue, springOptions);
  useEffect(() => {
    motionValue.set(value);
  }, [motionValue, value]);

  useEffect(() => {
    const sub = springValue.on('change', (latest) => {
      if (ref.current) {
        ref.current.textContent = format
          ? utils.formatNumber(latest.toFixed(0))
          : latest.toFixed(0);
      }
    });
    return () => {
      sub();
    };
  }, [springValue, ref, format]);

  return <span ref={ref} />;
}
