import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from '~/v2/common/Errors/ErrorBoundary';
import { Provider } from 'react-redux';
import { store } from './v2/redux/store';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './chakraTheme';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleReCaptchaProvider reCaptchaKey="6LfYjKAkAAAAAHdtIf0PIC11Se8x4KzHNOSGyuqW">
    <Provider store={store}>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <DndProvider backend={HTML5Backend}>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </DndProvider>
        </ChakraProvider>
      </BrowserRouter>
    </Provider>
  </GoogleReCaptchaProvider>
);
serviceWorker.unregister();
