import { TruebaseTooltip } from './TruebaseTooltip';
import { Flex, Text } from '@chakra-ui/react';

const TruebaseBetaTag = ({ isActive, isDisabled = false }) => {
  return (
    <TruebaseTooltip label={!isDisabled && 'Currently in Beta'}>
      <Flex
        sx={{
          fontSize: '11px',
          lineHeight: '14px',
          fontWeight: 500,
          p: '2px 4px',
          h: '18px',
          border: '1px solid',
          borderRadius: '2px',
          ...(isDisabled
            ? { color: 'trueDisabled', borderColor: 'trueDisabled' }
            : isActive
            ? { color: 'trueSpace', borderColor: 'trueSpace' }
            : { color: 'trueDim', borderColor: 'trueDim' }),
        }}
        alignItems="center"
      >
        Beta
      </Flex>
    </TruebaseTooltip>
  );
};

export default TruebaseBetaTag;
