import { Route, Routes } from 'react-router-dom';

import SequencesPage from './components/SequencesPage';

const SequencesRoutes = () => {
  return (
    <Routes>
      <Route path={'*'} element={<SequencesPage />} />
    </Routes>
  );
};

export default SequencesRoutes;
