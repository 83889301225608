import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();

if (
  process.env.REACT_APP_LOGGLY_ENABLED === 'true' &&
  process.env.REACT_APP_LOGGLY_TOKEN
) {
  logger.push({
    logglyKey: process.env.REACT_APP_LOGGLY_TOKEN,
    tag: `client,${process.env.REACT_APP_ENV}`,
  });
}

const logglyLog = ({ error, info, ...args }) => {
  if (
    process.env.REACT_APP_LOGGLY_ENABLED &&
    process.env.REACT_APP_LOGGLY_TOKEN
  ) {
    logger.push({
      tag: `client,${process.env.REACT_APP_ENV}`,
      appVersion: process.env.VERSION_TIMESTAMP,
      ...(error && { error, stackTrace: error.stack }),
      info,
      ...args,
    });
  }
};

export default logglyLog;
