import {
  Box,
  Button,
  Input,
  Stack,
  CheckboxGroup,
  Checkbox,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { ReactComponent as AddBoxIcon } from '../../../assets/icons/add/add-box.svg';

const InvisibleBackdrop = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      backgroundColor="transparent"
      onClick={onClose}
    />
  );
};

const tags = [
  {
    label: 'Scheduled',
    key: 'scheduled',
  },
  {
    label: 'Active',
    key: 'active',
  },
  {
    label: 'Replied',
    key: 'replied',
  },
  {
    label: 'Exited',
    key: 'exited',
  },
  {
    label: 'Bounced',
    key: 'bounced',
  },

  {
    label: 'Unsubscribed',
    key: 'unsubscribed',
  },
  {
    label: 'Exported',
    key: 'exported',
  },
];

const TagFilter = ({
  chosenTags,
  setChosenTags,
  contactsState,
  chosenAutopilot,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [tagSearchValue, setTagSearchValue] = useState('');

  const searchedTags = tags.filter((tag) =>
    new RegExp(tagSearchValue, 'i').test(tag.label)
  );
  const isLoading = contactsState?.isFetching || contactsState?.isLoading;

  return (
    <Box position="relative" zIndex={'1000'}>
      <InvisibleBackdrop
        isOpen={showDropdown}
        onClose={() => setShowDropdown(false)}
      />
      <Button
        cursor="pointer"
        border="1px solid"
        gap="8px"
        borderColor={showDropdown ? 'trueLink' : 'trueLight'}
        h={chosenAutopilot?._id ? '44px' : '42px'}
        color="trueSpace"
        borderRadius="4px"
        fontSize="12px"
        fontWeight="500"
        lineHeight="14px"
        p="13px 16px"
        bg={showDropdown ? 'white' : 'transparent'}
        boxShadow={
          showDropdown
            ? '0px 0px 10px rgba(2, 128, 245, 0.25) !important'
            : 'none'
        }
        _hover={{
          borderColor: showDropdown ? 'trueLink' : '#CBD5E0',
          bg: 'trueLighter',
        }}
        onClick={() => setShowDropdown((s) => !s)}
        isLoading={isLoading}
      >
        <AddBoxIcon />
        Filter by tags
      </Button>
      {showDropdown && (
        <Box bg="transparent" position="absolute" top="42px" w="200px">
          <Input
            variant="truebase"
            h="40px"
            p="13px 18px"
            type="text"
            value={tagSearchValue}
            onChange={(e) => setTagSearchValue(e.target.value)}
            placeholder="Search for tags"
            fontSize="12px"
            fontWeight="400"
            lineHeight="14px"
          />

          {searchedTags && (
            <Stack
              direction="column"
              border="1px solid"
              borderColor="trueLight"
              borderRadius="4px"
              bg="white"
              gap="0"
              mt="-1px"
            >
              <CheckboxGroup>
                {tags
                  .filter((tag) =>
                    new RegExp(tagSearchValue, 'gi').test(tag.label)
                  )
                  .map((tag) => (
                    <Checkbox
                      key={tag.key}
                      value={tag.key}
                      onChange={(e) => {
                        if (chosenTags.includes(e.target.value)) {
                          setChosenTags((s) => s.filter((t) => t !== tag.key));
                        } else {
                          setChosenTags((s) => [...s, tag.key]);
                        }
                        setShowDropdown((s) => !s);
                      }}
                      sx={{ '& > span:first-of-type': { display: 'none' } }}
                      _hover={{
                        bg: 'trueSkylight',
                      }}
                      p="11px 0px 11px 10px"
                    >
                      <Text
                        color={
                          chosenTags.includes(tag.key)
                            ? 'trueDisabled'
                            : 'trueSpace'
                        }
                        fontSize="12px"
                        fontWeight="500"
                        lineHeight="14px"
                      >
                        {tag.label}
                      </Text>
                    </Checkbox>
                  ))}
              </CheckboxGroup>
            </Stack>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TagFilter;
