import React from 'react';
import { Text, Flex, Box } from '@chakra-ui/react';
import OnboardingTitle from './OnboardingTitle';
import EmailSent from '~/v2/common/Animations/EmailSent';

const MobileEndpoint = () => {
  return (
    <Flex direction="column" position="relative">
      <Box>
        <OnboardingTitle>
          <Text>We&apos;re thrilled to welcome you to Truebase.</Text>
        </OnboardingTitle>
        <Text fontSize="12px" color="trueSpace" fontWeight={400} mt="16px">
          Our app performs best on screens larger than a mobile phone&apos;s
          display.
        </Text>
      </Box>
      <Box margin="0 auto" id="lottie-complete-signup" w="134px" h="134px">
        <EmailSent />
      </Box>
      <Text fontSize="12px" color="trueSpace" fontWeight={400}>
        We&apos;ve emailed you a link to proceed once you are on a larger
        display. See you soon!
      </Text>
    </Flex>
  );
};

export default MobileEndpoint;
