import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useForgotPasswordMutation } from '../../redux/services/user';
import { useForm } from 'react-hook-form';
import LoginLayout from './LoginLayout';
import { ReactComponent as MailIcon } from '../../assets/icons/mail/mail-1.svg';
import useErrorHandler from '~/v2/hooks/useErrorHandler';

export default function ForgotPassword() {
  const { handleAsyncError } = useErrorHandler();
  const [forgotPassword] = useForgotPasswordMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  // const validateErrors = () => {
  //   const trimmedEmail = email.trim();
  //   const errors = [
  //     ...Validate.field({ name: 'email', value: trimmedEmail })
  //       .required('Email Address is required')
  //       .email().errors,
  //   ];

  //   if (errors.length > 0) {
  //     setErrors(errors);
  //     return true;
  //   }
  //   return false;
  // };

  // const getErrorMessage = (field) =>
  //   isError(field) ? errors.find((e) => e.field === field).message : '';
  const toast = useToast();
  const onSubmit = handleAsyncError(async ({ email }, e) => {
    e.preventDefault();
    await forgotPassword({
      email: email.trim(),
      authProvider: 'truebase',
    });
    toast({
      title: 'Email sent',
      description: `A recovery link should have been sent if there's an account connected to this email address`,
      status: 'success',
    });
  });
  return (
    <LoginLayout title="Forgot your password?" titleProps={{ mb: '80px' }}>
      <Helmet title={'Truebase | Forgot Your Password?'} defer={false} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.email}>
          <FormLabel variant="truebase">Email Address</FormLabel>
          <InputGroup>
            <Input
              variant="truebase"
              type="email"
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Please enter a valid email',
                },
              })}
              placeholder={'Enter your work email address'}
            />
            <InputRightElement h="48px">
              <MailIcon fill="var(--chakra-colors-trueDim)" />
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage variant="truebase">
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>
        <Button
          width="100%"
          variant="truebase"
          type="submit"
          margin="32px auto"
        >
          Send Reset Email
        </Button>
        <Box display="flex" flexDir="column" gap={2} alignItems="center">
          <Button variant="truebaseText" as={Link} to="/login" color="trueLink">
            Back to login
          </Button>
        </Box>
      </form>
    </LoginLayout>
  );
}
