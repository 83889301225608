import { apiSlice } from './api';

// Define a service using a base URL and expected endpoints
export const titlesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTitles: builder.query({
      query: (search = '') => ({
        url: search ? `titles?search=${search}` : 'titles',
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.result.titles;
      },
    }),
  }),
});

export const { useGetTitlesQuery, useLazyGetTitlesQuery } = titlesApi;
