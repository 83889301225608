import { createAction, createSlice } from '@reduxjs/toolkit';
import { apiSlice } from '../services/api';

export const setSelectedSequenceTemplate = createAction(
  'sequenceTemplates/setSequenceTemplate'
);

export const SEQUENCE_TEMPLATE_LOCAL_STORAGE_KEY = 'truebase-sequenceTemplate';

const slice = createSlice({
  name: 'sequenceTemplates',
  initialState: {
    selectedSequenceTemplateKey: localStorage.getItem(
      SEQUENCE_TEMPLATE_LOCAL_STORAGE_KEY
    ),
    selectedSequenceTemplate: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSelectedSequenceTemplate, (state, action) => {
      state.selectedSequenceTemplate = action.payload;
      state.selectedSequenceTemplateKey = action.payload._id;
      localStorage.setItem(
        SEQUENCE_TEMPLATE_LOCAL_STORAGE_KEY,
        action.payload._id
      );
    });
    builder.addMatcher(
      apiSlice.endpoints.getSequenceTemplates.matchFulfilled,
      (state, { payload }) => {
        const newSelectedSequenceTemplate =
          payload.find(
            (sequenceTemplate) =>
              sequenceTemplate._id === state.selectedSequenceTemplateKey
          ) || payload[0];

        state.selectedSequenceTemplate = newSelectedSequenceTemplate;
        state.selectedSequenceTemplateKey = newSelectedSequenceTemplate._id;
        localStorage.setItem(
          SEQUENCE_TEMPLATE_LOCAL_STORAGE_KEY,
          newSelectedSequenceTemplate._id
        );
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.addSequenceTemplate.matchFulfilled,
      (state, { payload }) => {
        state.selectedSequenceTemplate = payload.result.sequenceTemplate;
        state.selectedSequenceTemplateKey = payload.result.sequenceTemplate._id;
        localStorage.setItem(
          SEQUENCE_TEMPLATE_LOCAL_STORAGE_KEY,
          payload.result.sequenceTemplate._id
        );
      }
    );
  },
});

export const getSelectedSequenceTemplate = (state) =>
  state.sequenceTemplates.selectedSequenceTemplate;

export default slice.reducer;
