import { CircularProgress, Flex, Text } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';

export default function LoadingMessage({ error, loadingMessage }) {
  return (
    <AnimatePresence>
      {(loadingMessage || error) && (
        <Flex
          key="message"
          as={motion.div}
          initial={{
            opacity: 0,
            height: 0,
          }}
          animate={{
            opacity: 1,
            height: 'auto',
            marginBottom: 0,
          }}
          exit={{
            opacity: 0,
            height: 0,
            transition: {
              duration: 0,
            },
          }}
          flexDir="row"
          gap="8px"
          alignItems="center"
        >
          {loadingMessage && (
            <>
              <CircularProgress
                color="trueBlue"
                size="20px"
                trackColor="transparent"
                isIndeterminate
              />
              <Text fontSize="12px" fontWeight={400} color={'trueSpace'}>
                {loadingMessage}
              </Text>
            </>
          )}
          {error && (
            <Text fontSize="12px" fontWeight={400} color={'trueAttention'}>
              {error?.message}
            </Text>
          )}
        </Flex>
      )}
    </AnimatePresence>
  );
}
