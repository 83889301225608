import { Rest } from '../common/services';
import logglyLog from '../common/Errors/Logger';
import { SEQUENCE_TEMPLATE_LOCAL_STORAGE_KEY } from './slices/sequenceTemplateSlice';
import { ICP_LOCAL_STORAGE_KEY } from './slices/icpSlice';
import { ACCOUNT_QUALIFICATION_LOCAL_STORAGE_KEY } from './slices/accountQualificationSlice';

export const customBaseQuery =
  () =>
  async ({ url, method = 'GET', body, files, nonProtected = false }) => {
    try {
      let data;
      switch (method) {
        case 'GET':
          data = await Rest.get(url, nonProtected);
          break;
        case 'POST':
          data = await Rest.post(url, body, nonProtected, files);
          break;
        case 'PUT':
          data = await Rest.put(url, body, nonProtected);
          break;
        case 'PATCH':
          data = await Rest.patch(url, body, nonProtected);
          break;
        case 'DELETE':
          data = await Rest.delete(url, nonProtected);
          break;
        default:
          data = await Rest.get(url, nonProtected);
          break;
      }

      if (typeof data === 'undefined') {
        return { error: 'Something went wrong' };
      }

      if (data.code.toString() === '401' && !nonProtected) {
        localStorage.removeItem('User');
        localStorage.removeItem('AccessToken');
        localStorage.removeItem(ICP_LOCAL_STORAGE_KEY);
        localStorage.removeItem(ACCOUNT_QUALIFICATION_LOCAL_STORAGE_KEY);
        localStorage.removeItem(SEQUENCE_TEMPLATE_LOCAL_STORAGE_KEY);
        const currentLocation = window.location;
        const searchParams = currentLocation.search || '';
        const newURL = `/login${searchParams}`;
        const stateObject = {
          from: `${currentLocation.pathname}${searchParams}`,
        };
        history.pushState(stateObject, '', newURL);
        return window.location.reload();
      }

      if (
        data.code >= 400 &&
        !data.message?.includes('Invalid authorization')
      ) {
        logglyLog({ ...data });
      }

      return { data };
    } catch (err) {
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
