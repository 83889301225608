import { createAction, createSlice } from '@reduxjs/toolkit';
import { teamsApi } from '../services/teams';

export const setSelectedTeam = createAction('teams/setTeam');

export const TEAM_LOCAL_STORAGE_KEY = 'truebase-team';

const slice = createSlice({
  name: 'teams',
  initialState: { selectedTeam: null, teams: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSelectedTeam, (state, action) => {
      state.selectedTeam = action.payload;
      localStorage.setItem(TEAM_LOCAL_STORAGE_KEY, action.payload._id);
    }),
      builder.addMatcher(
        teamsApi.endpoints.getTeams.matchFulfilled,
        (state, { payload }) => {
          const newSelectedTeam =
            payload.find(
              (team) =>
                team._id === localStorage.getItem(TEAM_LOCAL_STORAGE_KEY)
            ) || payload[0];

          state.teams = payload;
          state.selectedTeam = newSelectedTeam;
          localStorage.setItem(TEAM_LOCAL_STORAGE_KEY, newSelectedTeam?._id);
        }
      );
  },
});

export const getSelectedTeam = (state) => state.teams.selectedTeam;
export const getTeams = (state) => state.teams.teams;

export default slice.reducer;
