import { Flex } from '@chakra-ui/react';
import { ReactComponent as EmptyStateGraphic } from '~/v2/assets/icons/Empty state graphic.svg';
import TruebaseAccordionSkeleton from '../../../common/TruebaseAccordion/components/TruebaseAccordionSkeleton';
import TruebaseLeadAccordion from '../../../common/TruebaseAccordion/TruebaseLeadAccordion/TruebaseLeadAccordion';
import EmptyState from './EmptyState';

export default function SequencesList({
  contactsData,
  contactsState,
  chosenTags,
}) {
  return (
    <>
      {!contactsState.isLoading &&
        contactsData?.result?.matches?.map((item) => (
          <TruebaseLeadAccordion
            readonly
            key={item._id}
            accordionKey={item._id}
            account={item.company}
            lead={item}
            isExpandable={false}
            chosenTags={chosenTags}
            isSmall
          />
        ))}

      {!contactsState.isLoading &&
        !contactsData?.result?.matches?.length &&
        !contactsState.uninitialized && (
          <Flex alignItems="center" mt="96px" flexDirection="column" gap="32px">
            <EmptyState
              noAutopilotContacts={contactsData?.result?.matches?.length === 0}
            />
            <EmptyStateGraphic />
          </Flex>
        )}

      {(contactsState.isLoading || contactsState.isFetching) &&
        Array.from({ length: 10 }).map((_, index) => (
          <TruebaseAccordionSkeleton key={index} />
        ))}
    </>
  );
}
