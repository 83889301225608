import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import { id } from '~/utils';
import './app.css';
import useSmartlook from '~/v2/hooks/useSmartlook';
import {
  initIntercom,
  trackCurrentUser,
  updateIntercom,
} from '~/v2/services/helper';
import socket from '~/v2/utility/socket';
import Maintenance from '~/v2/common/Maintenance';
import { useLazyGetTeamsQuery } from '~/v2/redux/services/teams';
import { useLazyGetCurrentUserQuery } from '~/v2/redux/services/user';
import UserRoutes from '~/v2/features/auth/routes';
import { getUser } from '~/v2/redux/slices/userSlice';
import GlobalWarning from '~/v2/common/Old/GlobalWarning';
import Notifications from './v2/features/notifications/Notifications';
const ProfilePage = loadable(() => import('./v2/features/profile/ProfilePage'));
import ReloadPrompt from './v2/common/Reload/ReloadPrompt';
import OnboardingRoutes from './v2/features/onboarding/routes';
import SequencesRoutes from '~/v2/features/sequences/routes';
import useErrorHandler from './v2/hooks/useErrorHandler';
import AutopilotsRoutes from './v2/features/autopilots/routes';
import LaunchAutopilotRoutes from './v2/features/launchAutopilot/routes';
import SettingsRoutes from './v2/features/settings/routes';
import Sidebar from './v2/features/sidebar/Sidebar';
import { Flex } from '@chakra-ui/react';

if (process.env.REACT_APP_MANAGE_GOOGLE_ANALYTICS_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_MANAGE_GOOGLE_ANALYTICS_TRACKING_ID);
}

const App = () => {
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const { handleAsyncError, handleSyncError } = useErrorHandler();
  const [getTeams] = useLazyGetTeamsQuery();
  const hasToken = !!localStorage.getItem('AccessToken');
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const user = useSelector(getUser);
  const userId = user?.id;
  useSmartlook(user);
  const location = useLocation();

  const shouldShowSidebar = useMemo(
    () =>
      !['/login', '/forgot', '/onboarding', '/reset', '/welcome'].some((path) =>
        location.pathname.includes(path)
      ),
    [location]
  );

  const WrapperComponent = useCallback(
    ({ children }) => {
      return shouldShowSidebar ? (
        <Flex h="100vh" overflowX="auto">
          {children}
        </Flex>
      ) : (
        <React.Fragment>{children}</React.Fragment>
      );
    },
    [shouldShowSidebar]
  );

  useEffect(() => {
    const wrappedFn = handleSyncError(() => {
      if (hasToken && !user) {
        getCurrentUser();
      }
    });

    wrappedFn();
  }, [user, getCurrentUser, hasToken, handleSyncError]);

  useEffect(() => {
    const wrappedFn = handleSyncError(() => {
      if (hasToken && user) {
        getTeams();
      }
    });

    wrappedFn();
  }, [user, getTeams, hasToken, handleSyncError]);

  useEffect(() => {
    // Initial value for comparison
    const initialData = localStorage.getItem('AccessToken');

    const handleStorageChange = handleSyncError((e) => {
      // Check if the change is related to the specific key you're interested in
      if (e.key === 'AccessToken' && e.newValue !== initialData) {
        alert('You have been logged out');
        window.location.reload(); // reload the page
      }
    });

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [handleSyncError]);

  useEffect(() => {
    const checkAndInitTrackers = handleAsyncError(async () => {
      if (!isEmpty(user)) {
        await getTeams();
        trackCurrentUser(user);
        updateIntercom(user);
      } else {
        const anonUserId = id.new();
        sessionStorage.setItem('anonUser', JSON.stringify({ id: anonUserId }));
        trackCurrentUser({ id: anonUserId });
      }
    });

    checkAndInitTrackers();
  }, [user, getTeams, handleAsyncError]);

  useEffect(() => {
    socket.on('connect', () => {
      console.info(`Socket Status: Connected`);
      setIsSocketConnected(true);
    });

    socket.on('disconnect', (reason) => {
      console.info(`Socket Status: Not Connected (Reason: ${reason})`);
      setIsSocketConnected(false);
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
    };
  }, []);

  useEffect(() => {
    if (userId && isSocketConnected) socket.emit('join', { userId });
  }, [userId, isSocketConnected]);

  useEffect(() => {
    initIntercom();
  }, []);

  return (
    <React.Fragment>
      <ReloadPrompt />
      <Notifications />
      {userId && process.env.REACT_APP_GLOBAL_WARNING_MESSAGE && (
        <GlobalWarning top={69} />
      )}

      {process.env.REACT_APP_MAINTENANCE_MODE === 'true' ? (
        <Routes>
          <React.Fragment>
            <Route path="/maintenance" element={<Maintenance />} />
            <Route path="*" element={<Navigate to="/maintenance" />} />
          </React.Fragment>
        </Routes>
      ) : (
        <WrapperComponent>
          {shouldShowSidebar && <Sidebar />}
          <Routes>
            <React.Fragment>
              <Route path="/onboarding/*" element={<OnboardingRoutes />} />
              <Route path="/*" element={<UserRoutes />} />
              <Route path={`/`} element={<Navigate to="/autopilot" />} />
              <Route
                path={`/autopilot/*`}
                element={<LaunchAutopilotRoutes />}
              />
              <Route path={`/autopilots/*`} element={<AutopilotsRoutes />} />
              <Route path="/contacts/*" element={<SequencesRoutes />} />
              <Route path="/settings/*" element={<SettingsRoutes />} />
              <Route path="profile" element={<ProfilePage />}></Route>
            </React.Fragment>
          </Routes>
        </WrapperComponent>
      )}

      <Helmet>
        <meta
          name="description"
          content="The fastest way to continuously find and connect with leads that convert."
        />
        <meta property="og:url" content="https://app.truebase.io" />
        <link
          id="favicon"
          rel="shortcut icon"
          type="image/png"
          href={`/static/favicon-manage.ico`}
        />
      </Helmet>
    </React.Fragment>
  );
};

export default App;
