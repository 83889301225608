import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { get } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { AutoResizeTextarea } from '../TruebaseAutoResizeTextArea';

export const StreamingTextArea = ({
  label,
  placeholder,
  name,
  isRequired,
  isDisabled,
  variant = 'truebaseFakeDisabled',
  bg = 'white',
  minH,
  input = 'textarea',
  children,
  formHelperText,
  ...restProps
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { ref, ...rest } = register(
    `${name}.content`,
    isRequired ? { required: 'Required' } : {}
  );
  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={!!get(errors, name)}
      position="relative"
    >
      <FormLabel sx={{ color: 'trueDim', fontWeight: 500 }}>{label}</FormLabel>
      {input === 'textarea' ? (
        <AutoResizeTextarea
          isReadOnly={isDisabled}
          variant={variant}
          whiteSpace="pre-wrap"
          resize="none"
          rows={1}
          overflow="hidden"
          minH={minH}
          {...rest}
          ref={ref}
          onChange={(e) => {
            register(`${name}.content`).onChange(e);
            setValue(`${name}.isOverwrite`, true);
          }}
          placeholder={placeholder}
          bg={bg}
          {...restProps}
        />
      ) : (
        <Input
          isReadOnly={isDisabled}
          variant={variant}
          whiteSpace="pre-wrap"
          resize="none"
          rows={1}
          overflow="hidden"
          minH={minH}
          {...rest}
          ref={ref}
          onChange={(e) => {
            register(`${name}.content`).onChange(e);
            setValue(`${name}.isOverwrite`, true);
          }}
          placeholder={placeholder}
          bg={bg}
          {...restProps}
        />
      )}

      {children}
      <FormErrorMessage>
        {get(errors, `${name}.content.message`)}
      </FormErrorMessage>
    </FormControl>
  );
};
