import { Box, Center } from '@chakra-ui/react';
import React from 'react';

export default function EmptyMatchScore() {
  return (
    <Box position="relative" h="151px">
      <Box
        width="240px"
        position="absolute"
        left="50%"
        top="50%"
        transform="translate(-50%, -50%)"
      >
        <Clouds />
      </Box>
      <Center
        position="absolute"
        left="50%"
        top="50%"
        transform="translate(-50%, -50%)"
        color="trueSpace"
        fontSize="15px"
        fontWeight="500"
        textAlign="center"
      >
        Qualify and disqualify a few accounts <br />
        to see the ICP relevance match score.
      </Center>
    </Box>
  );
}

function Clouds() {
  return (
    <svg
      width="240"
      height="67"
      viewBox="0 0 240 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.75 60.91L95.53 48.83L95.89 34.74L93.52 28.03L67.01 25L47.88 38.43L53.5 56.18L62.31 59.57L78.75 60.91Z"
        fill="#F5F9FA"
      />
      <path
        d="M92.85 65.2702L101.57 50.8402L95.89 34.7402L95.53 48.8302L78.75 60.9102L62.31 59.5702L53.5 56.1802L56.27 64.9402L92.85 65.2702Z"
        fill="#EEF1F9"
      />
      <path
        d="M54.93 61.1596L63.43 58.2696L68.02 53.1096L57.62 37.6796L33.82 37.0996L20.03 50.0896L25.68 57.3596L32.78 60.8296L54.93 61.1596Z"
        fill="#F5F9FA"
      />
      <path
        d="M57.2799 65.1903L63.4299 58.2703L54.9299 61.1603L32.7799 60.8303L25.6799 57.3604L31.7799 65.1903H57.2799Z"
        fill="#EEF1F9"
      />
      <path
        d="M107.29 62.5501L113.27 60.9201L114.67 57.1801L109.97 48.1201L96.55 49.8001L88.16 57.8501L89.94 60.1301L96.55 61.8801L107.29 62.5501Z"
        fill="#F5F9FA"
      />
      <path
        d="M111.65 65.2299L113.27 60.9199L107.29 62.5499L96.5499 61.8799L89.9399 60.1299L94.1999 65.5699L111.65 65.2299Z"
        fill="#EEF1F9"
      />
      <path
        d="M134.76 64.5205C134.76 65.3805 104.6 66.0705 67.38 66.0705C30.16 66.0705 0 65.3805 0 64.5205C0 63.6605 30.17 62.9805 67.38 62.9805C104.59 62.9805 134.76 63.6705 134.76 64.5205Z"
        fill="#EEF1F9"
      />
      <path
        d="M217.83 21.2896L221.99 20.1596L222.97 17.5496L219.33 12.3496L210.35 12.4096L204.51 18.0196L205.75 19.6096L210.35 20.8196L217.83 21.2896Z"
        fill="#F5F9FA"
      />
      <path
        d="M220.86 23.1604L221.99 20.1604L217.83 21.2904L210.35 20.8204L205.75 19.6104L208.72 23.3904L220.86 23.1604Z"
        fill="#EEF1F9"
      />
      <path
        d="M194.59 21.58L184.51 14.32L184.29 5.85001L185.72 1.81001L201.65 0L213.15 8.07L209.78 18.74L204.48 20.77L194.59 21.58Z"
        fill="#F5F9FA"
      />
      <path
        d="M186.12 24.1996L180.88 15.5296L184.29 5.84961L184.51 14.3196L194.59 21.5796L204.48 20.7696L209.78 18.7396L208.11 23.9996L186.12 24.1996Z"
        fill="#EEF1F9"
      />
      <path
        d="M177.44 22.5696L173.84 21.5897L173 19.3397L175.82 13.8896L183.89 14.8997L188.94 19.7397L187.87 21.1096L183.89 22.1597L177.44 22.5696Z"
        fill="#F5F9FA"
      />
      <path
        d="M174.82 24.1804L173.84 21.5904L177.44 22.5704L183.89 22.1604L187.87 21.1104L185.3 24.3804L174.82 24.1804Z"
        fill="#EEF1F9"
      />
      <path
        d="M158 23.7601C158 24.2701 176.14 24.6801 198.51 24.6801C220.88 24.6801 239.02 24.2701 239.02 23.7601C239.02 23.2501 220.89 22.8301 198.51 22.8301C176.13 22.8301 158 23.2401 158 23.7601Z"
        fill="#EEF1F9"
      />
    </svg>
  );
}
