import { HStack, Text } from '@chakra-ui/react';
import React from 'react';

export const ICPOverviewStat = ({
  displayName,
  stat,
  children,
  textProps = {},
  ...rest
}) => {
  return (
    <HStack {...rest}>
      <Text
        minW="104px"
        fontSize="13px"
        lineHeight="16px"
        fontWeight="400"
        color="trueDim"
        {...textProps}
      >
        {displayName}
      </Text>
      {children || (
        <Text fontSize="14px" lineHeight="16px" color="trueMedium">
          {stat}
        </Text>
      )}
    </HStack>
  );
};
