/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLazyGetCurrentUserQuery } from '../redux/services/user';
import { safeJSONParse } from './utils';
import useErrorHandler from '../hooks/useErrorHandler';
import { SEQUENCE_TEMPLATE_LOCAL_STORAGE_KEY } from '../redux/slices/sequenceTemplateSlice';
import { ACCOUNT_QUALIFICATION_LOCAL_STORAGE_KEY } from '../redux/slices/accountQualificationSlice';
import { ICP_LOCAL_STORAGE_KEY } from '../redux/slices/icpSlice';

export const useAuthenticate = () => {
  let userFromStorage = localStorage.getItem('User') || '';
  const authToken = localStorage.getItem('AccessToken');

  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const [user, setUser] = useState(
    authToken && userFromStorage ? safeJSONParse(userFromStorage) : null
  );

  const navigate = useNavigate();
  const location = useLocation();
  const { handleAsyncError } = useErrorHandler();

  useEffect(() => {
    const checkAndRedirect = handleAsyncError(async () => {
      let currentUser;
      const hasToken = !!localStorage.getItem('AccessToken');
      const userFromStorage = localStorage.getItem('User') || '';
      const refreshUser = localStorage.getItem('refreshUser');
      if ((!userFromStorage && hasToken) || refreshUser) {
        const response = await getCurrentUser().unwrap();
        if (response.isOk) {
          currentUser = response.result.user;
          localStorage.setItem('User', JSON.stringify(currentUser));
          localStorage.removeItem('refreshUser');
        } else {
          currentUser = null;
        }
      } else {
        const authToken = localStorage.getItem('AccessToken');
        if (!authToken) {
          localStorage.removeItem('User');
          localStorage.removeItem('AccessToken');
          localStorage.removeItem(ICP_LOCAL_STORAGE_KEY);
          localStorage.removeItem(ACCOUNT_QUALIFICATION_LOCAL_STORAGE_KEY);
          localStorage.removeItem(SEQUENCE_TEMPLATE_LOCAL_STORAGE_KEY);
          localStorage.removeItem('trbs-exportActive');
          navigate(`/login${location.search ?? ''}`, {
            state: { from: `${location.pathname}${location.search ?? ''}` },
          });
          return;
        }
        currentUser = safeJSONParse(userFromStorage);
      }
      if (!currentUser) {
        navigate(`/login${location.search ?? ''}`, {
          state: { from: `${location.pathname}${location.search ?? ''}` },
        });
      } else {
        if (currentUser.isAnonymous) {
          if (
            localStorage.getItem('NoConnections') ||
            localStorage.getItem('NoMatches')
          )
            navigate('/signup');
          else {
            navigate('/autopilot');
          }
        }
        setUser(currentUser);
      }
    });

    checkAndRedirect();
  }, [handleAsyncError]);
};
