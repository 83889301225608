import React from 'react';

export const CloseIcon = ({ width = 24, height = 24, color = '#0F0F4D' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.14 17.73L13.48 12.07L19.14 6.41L17.73 5L12.07 10.66L6.41 5L5 6.41L10.66 12.07L5 17.73L6.41 19.14L12.07 13.48L17.73 19.14L19.14 17.73Z"
        fill={color}
      />
    </svg>
  );
};
