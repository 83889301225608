import {
  Box,
  Button,
  Flex,
  FormControl,
  IconButton,
  Text,
  useToast,
  Spacer,
  Center,
  NumberInput,
  NumberInputField,
  FormErrorMessage,
} from '@chakra-ui/react';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AnimatePresence, motion } from 'framer-motion';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { TruebaseTooltip } from '~/v2/common/TruebaseTooltip';
import { useLazySearchQuery } from '~/v2/redux/services/accounts';
import { ReactComponent as SearchIcon } from '~/v2/assets/icons/search.svg';
import { SpotlightSearchWithContext } from '~/v2/common/SpotlightSearch/SpotlightSearch';
import { ceil, get, has, isEmpty } from 'lodash';
import { useFilterContext } from '~/v2/features/filters/components/useFilterContext';
import { useQualificationContext } from '~/v2/features/filters/components/useQualificationContext';
import { defaultQualificationQuestions, utils } from '~/v2/common/utils';
import { GraphSegment } from './GraphSegment';
import TestResults from '../../TestResults';
import { useEditAccountQualificationMutation } from '~/v2/redux/services/accountQualification';
import { QualificationsForm } from './QualificationsForm';
import { ReactComponent as TooltipArrow } from '~/v2/assets/tooltip arrow.svg';
import { Select, chakraComponents } from 'chakra-react-select';
import { webkitScrollbarConfig } from '~/v2/common/constants/webkitScrollbar';
import { useSelector } from 'react-redux';
import { getSelectedIcp } from '~/v2/redux/slices/icpSlice';
import { useIsDirtyContext } from '~/v2/contexts/useIsDirtyContext';
import { useLocation } from 'react-router';

const SEARCH_LIMIT = 10;

export const TrainQualifications = forwardRef(
  ({ icps, selectedAccountQualification, setIsPending }, ref) => {
    const selectedIcp = useSelector(getSelectedIcp);
    const { setIsQualificationDirty } = useIsDirtyContext();

    const [companySearchOpen, setCompanySearchOpen] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [selectedIcpSetting, setSelectedIcpSetting] = useState(selectedIcp);
    const [latestSearchIcpId, setLatestSearchIcpId] = useState();

    const { handleAsyncError } = useErrorHandler();
    const location = useLocation();

    const {
      testingQualificationMinScore,
      setTestingQualificationMinScore,
      requestId,
      setRequestId,
      searchExclusivity,
    } = useFilterContext();
    const {
      setQualificationQuestions: setContextQualificationQuestions,
      setMinScore: setContextMinScore,
    } = useQualificationContext();

    const [getSearchResults, response] = useLazySearchQuery();
    const [
      editAccountQualification,
      { isLoading: isEditingAccountQualfications },
    ] = useEditAccountQualificationMutation();

    const toast = useToast();

    const methods = useForm({
      mode: 'onChange',
      defaultValues: {
        qualifications:
          utils.formatQualificationsFromServer(
            selectedAccountQualification?.qualificationQuestions
          ) || defaultQualificationQuestions,
        qualificationMinScore:
          selectedAccountQualification?.qualificationMinScore || '',
      },
    });

    const minScore = methods.watch('qualificationMinScore');
    const qualificationsWatch = methods.watch('qualifications');
    const scoreMapSerialized = JSON.stringify(
      qualificationsWatch.map((item) => item.scoreMap)
    );

    const errors = methods.formState.errors;

    const accounts = useMemo(
      () => get(response, 'data.result.accounts', []),
      [response]
    );

    const accountsWithQualificationScore = useMemo(
      () => accounts.filter((a) => has(a, 'qualificationScore')),
      [accounts]
    );

    const isSearchLoading = useMemo(
      () =>
        !response.isUninitialized && // request has been made
        ((typeof response?.data?.isStreamingAccounts === 'undefined' && // request is not streaming yet
          accounts.length === 0) || // no accounts have been returned
          response?.data?.isStreamingAccounts || // request is streaming
          response.isFetching),
      [
        response.isUninitialized,
        response?.data?.isStreamingAccounts,
        accounts,
        response.isFetching,
      ]
    );

    const scoreMapTotal = useMemo(() => {
      return qualificationsWatch.reduce((totalSum, item) => {
        const scoreMap = item.scoreMap;
        const maxScore = Math.max(
          ...Object.values(scoreMap).map((value) => parseFloat(value))
        );
        return totalSum + maxScore;
      }, 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qualificationsWatch, scoreMapSerialized]);

    const passedScoringCount = useMemo(
      () =>
        accountsWithQualificationScore.reduce(
          (accumulator, account) =>
            account.qualificationScore >= testingQualificationMinScore
              ? accumulator + 1
              : accumulator,
          0
        ),
      [accountsWithQualificationScore, testingQualificationMinScore]
    );

    const isResetDisabled = useMemo(() => {
      return !methods.formState.isDirty || qualificationsWatch.length === 0;
    }, [methods.formState.isDirty, qualificationsWatch]);

    const isSaveDisabled = useMemo(() => {
      return (
        !methods.formState.isDirty ||
        !isEmpty(errors?.qualificationMinScore) ||
        !isEmpty(errors?.qualifications)
      );
    }, [
      methods.formState.isDirty,
      errors?.qualificationMinScore,
      errors?.qualifications,
    ]);

    const shouldShowLoadingState = useMemo(
      () => !response.isUninitialized && accounts.length === 0,
      [response.isUninitialized, accounts.length]
    );

    const isTestScoringDisabled =
      !selectedIcpSetting?.personFilterStatements?.length ||
      !!qualificationsWatch?.filter(({ content }) => !content)?.length ||
      !isEmpty(errors?.qualificationMinScore) ||
      !isEmpty(errors?.qualifications);

    const isTestScoringLoading =
      isSearchLoading ||
      shouldShowLoadingState ||
      (accounts?.length > 0 &&
        accounts?.find((acc) => !acc.qualificationAnswers));

    const handleShowCompanySearch = () => {
      setCompanySearchOpen(!companySearchOpen);
    };

    const handleResetQualifications = useCallback(() => {
      methods.reset({
        qualifications:
          utils.formatQualificationsFromServer(
            selectedAccountQualification?.qualificationQuestions
          ) || defaultQualificationQuestions,
        qualificationMinScore:
          selectedAccountQualification?.qualificationMinScore || '',
      });
      setSelectedIcpSetting(icps?.[0] || {});
    }, [
      icps,
      methods,
      selectedAccountQualification?.qualificationQuestions,
      selectedAccountQualification?.qualificationMinScore,
    ]);

    useImperativeHandle(
      ref,
      () => {
        return {
          handleResetQualifications() {
            handleResetQualifications();
          },
        };
      },
      [handleResetQualifications]
    );

    const handleSaveQuestions = handleAsyncError(async () => {
      const isValid = await methods.trigger();
      if (isValid) {
        const res = await editAccountQualification({
          _id: selectedAccountQualification?._id,
          qualificationQuestions: utils.formatQualifications(
            selectedAccountQualification?.qualificationQuestions,
            methods.getValues().qualifications
          ),
          qualificationMinScore: minScore || 0,
        }).unwrap();
        if (!res?.response?.data?.isOk) {
          toast({
            title: 'Error',
            description: 'Questions could not be saved',
            status: 'error',
            duration: 9000,
          });
        } else {
          methods.reset({
            qualifications: utils.formatQualificationsFromServer(
              utils.formatQualifications(
                selectedAccountQualification?.qualificationQuestions,
                methods.getValues().qualifications
              )
            ),
            qualificationMinScore: minScore || '',
          });
          toast({
            title: 'Success',
            description: 'Questions saved',
            status: 'success',
            duration: 3000,
          });
          setIsPending(false);
          if (location?.state?.isPending) {
            delete location.state.isPending;
          }
        }
      }
    });

    const handleSearch = handleAsyncError(
      async ({ refresh, limit = SEARCH_LIMIT }) => {
        let newRequestId;
        if (refresh || !requestId) {
          newRequestId = Math.random();
          setRequestId(newRequestId);
        }
        setLatestSearchIcpId(selectedIcpSetting._id);
        const response = await getSearchResults({
          icpId: selectedIcpSetting._id,
          companyFilters: selectedIcpSetting?.companyFilterStatements || [],
          leadFilters: selectedIcpSetting?.personFilterStatements || [],
          searchExclusivity,
          requestId: refresh ? newRequestId : requestId,
          qualificationQuestions: utils.formatQualifications(
            selectedAccountQualification?.qualificationQuestions,
            methods.getValues().qualifications
          ),
          limit,
          type: 'qualification',
        });

        if (!response.data.data.isOk) {
          return toast({
            title: 'Something went wrong!',
            status: 'error',
            duration: 3000,
          });
        } else {
          setTestingQualificationMinScore(
            (minScore || ceil(scoreMapTotal / 2)) / scoreMapTotal
          );
        }
      }
    );

    const handleTestScoring = handleAsyncError(async () => {
      setContextQualificationQuestions(methods.getValues().qualifications);
      setCompanySearchOpen(false);
      await handleSearch({ refresh: true });
    });

    useEffect(() => {
      const wrappedFn = async () => {
        if (
          selectedAccountQualification?._id &&
          accounts.length > 0 &&
          accountsWithQualificationScore.length === accounts.length &&
          accounts.length % SEARCH_LIMIT === 0 &&
          selectedAccountQualification?.lastTestResult?.passed !==
            passedScoringCount &&
          selectedAccountQualification?.lastTestResult?.failed !==
            accounts.length - passedScoringCount
        ) {
          await editAccountQualification({
            _id: selectedAccountQualification?._id,
            lastTestResult: {
              passed: passedScoringCount,
              failed: accounts.length - passedScoringCount,
            },
          });
        }
      };

      wrappedFn();
    }, [
      accounts,
      passedScoringCount,
      accountsWithQualificationScore,
      selectedAccountQualification,
      editAccountQualification,
    ]);

    useEffect(() => {
      setIsQualificationDirty(methods.formState.isDirty);
    }, [methods.formState.isDirty, setIsQualificationDirty]);

    useEffect(() => {
      setContextQualificationQuestions(qualificationsWatch);
      setContextMinScore(minScore);
    }, [
      minScore,
      qualificationsWatch,
      setContextMinScore,
      setContextQualificationQuestions,
    ]);

    return (
      <AnimatePresence>
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleTestScoring)}>
              <Flex
                flexDir="column"
                gap="16px"
                p="16px"
                bg="white"
                borderWidth="1px"
                borderStyle="solid"
                borderColor="trueLight"
                borderRadius="2px"
                borderBottom="0"
              >
                <Box>
                  <Text
                    color="trueExplain"
                    fontSize="13px"
                    lineHeight="16px"
                    fontWeight="500"
                  >
                    Qualification Questions
                  </Text>
                  <Text
                    color="trueDim"
                    fontSize="12px"
                    lineHeight="14px"
                    fontWeight="400"
                  >
                    Define weighted Qualification Questions for our AI to answer
                    by examining the account’s website
                  </Text>
                </Box>
                <QualificationsForm />
                <Flex gap="24px">
                  <Flex flexDir="column" gap="16px" flex="1">
                    <Box>
                      <Text
                        color="trueExplain"
                        fontSize="13px"
                        lineHeight="16px"
                        fontWeight="500"
                      >
                        Set Minimum Qualification Score
                      </Text>
                      <Text
                        color="trueDim"
                        fontSize="12px"
                        lineHeight="14px"
                        fontWeight="400"
                      >
                        Only accounts above this threshold should pass
                      </Text>
                    </Box>
                    <FormControl
                      variant="truebase"
                      isInvalid={errors?.qualificationMinScore}
                    >
                      <Flex direction="column">
                        <Flex>
                          <NumberInput flex="1" value={minScore}>
                            <NumberInputField
                              borderRadius="4px 0 0 4px"
                              placeholder={ceil(scoreMapTotal / 2)}
                              _placeholder={{ color: 'trueDim' }}
                              color="trueSpace"
                              fontSize="12px"
                              fontWeight="500"
                              lineHeight="14px"
                              {...methods.register(`qualificationMinScore`, {
                                min: {
                                  value: 0,
                                  message:
                                    'Min score must be greater than or equal 0',
                                },
                                max: {
                                  value: scoreMapTotal,
                                  message:
                                    'Max score must be greater than or equal total possible score',
                                },
                              })}
                            />
                          </NumberInput>
                          <Flex
                            p="13px 16px"
                            bg="trueLighter"
                            border="1px solid"
                            borderColor="trueLight"
                            borderRadius="0 4px 4px 0"
                            h="40px"
                            alignItems="center"
                            fontWeight="500"
                            fontSize="12px"
                            lineHeight="14px"
                            ml="-1px"
                            flex="1"
                          >
                            out of {scoreMapTotal}
                          </Flex>
                        </Flex>
                        <FormErrorMessage variant="truebase">
                          {errors?.qualificationMinScore?.message}
                        </FormErrorMessage>
                      </Flex>
                    </FormControl>
                  </Flex>
                  <Flex flexDir="column" gap="16px" flex="1">
                    <Box>
                      <Text
                        color="trueExplain"
                        fontSize="13px"
                        lineHeight="16px"
                        fontWeight="500"
                      >
                        Choose an ICP
                      </Text>
                      <Text
                        color="trueDim"
                        fontSize="12px"
                        lineHeight="14px"
                        fontWeight="400"
                      >
                        To test scoring on your target audience, choose a saved
                        ICP
                      </Text>
                    </Box>
                    <Select
                      defaultValue={{
                        value: selectedIcpSetting?._id,
                        label: selectedIcpSetting?.name,
                      }}
                      value={{
                        value: selectedIcpSetting?._id,
                        label: selectedIcpSetting?.name,
                      }}
                      options={icps?.map((icp) => ({
                        value: icp._id,
                        label: `${icp.name}`,
                      }))}
                      onChange={({ value }) => {
                        setSelectedIcpSetting(
                          icps.find((icp) => icp._id === value)
                        );
                      }}
                      blurInputOnSelect
                      chakraStyles={{
                        control: (baseStyle) => ({
                          ...baseStyle,
                          cursor: 'pointer',
                          bg: 'white',
                          pr: '16px',
                        }),
                        valueContainer: (baseStyle) => ({
                          ...baseStyle,
                          fontSize: '12px',
                          color: 'trueSpace',
                          lineHeight: '14px',
                          fontWeight: '500',
                        }),
                        dropdownIndicator: () => ({ color: 'trueDim' }),
                        menuList: (baseStyle) => ({
                          ...baseStyle,
                          overflowY: 'overlay',
                          p: 0,
                          maxH: '200px',
                          ...webkitScrollbarConfig(),
                        }),
                        menu: (baseStyle) => ({
                          ...baseStyle,
                          mt: '-40px',
                          border: '1px solid',
                          borderColor: 'trueLink',
                          borderRadius: '4px',
                          overflow: 'hidden',
                        }),
                        option: (baseStyle, { isSelected }) => ({
                          ...baseStyle,
                          p: '13px 16px',
                          color: 'trueSpace !important',
                          fontSize: '12px',
                          lineHeight: '14px',
                          fontWeight: '500',
                          ...(isSelected && { bg: 'white !important' }),
                          '&:hover': { backgroundColor: '#c3e6fa !important' },
                        }),
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: (props) => (
                          <chakraComponents.DropdownIndicator {...props}>
                            <TooltipArrow />
                          </chakraComponents.DropdownIndicator>
                        ),
                      }}
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                p="16px"
                bg="white"
                border="1px solid"
                borderColor="trueLight"
                borderRadius="2px"
                alignItems="center"
                gap="16px"
              >
                <TruebaseTooltip
                  label={
                    isSearchLoading
                      ? 'Perform a new search once the current search has finished'
                      : 'Search for a specific account and test scoring'
                  }
                  maxW="280px"
                  textAlign="center"
                >
                  <IconButton
                    size="sm"
                    sx={{
                      bg: companySearchOpen ? 'trueLight' : 'transparent',
                      svg: { path: { fill: 'trueDim' } },
                    }}
                    isDisabled={isSearchLoading}
                    icon={<SearchIcon />}
                    onClick={handleShowCompanySearch}
                  />
                </TruebaseTooltip>
                <Spacer />
                <Box>
                  <TruebaseTooltip
                    label={!isResetDisabled && 'Reset to last saved'}
                  >
                    <Button
                      size="sm"
                      isDisabled={isResetDisabled}
                      variant="ghost"
                      color="trueDim"
                      _disabled={{
                        color: 'trueDisabled',
                      }}
                      fontSize="13px"
                      lineHeight="15px"
                      fontWeight="medium"
                      onClick={handleResetQualifications}
                      minW="80px"
                    >
                      Reset
                    </Button>
                  </TruebaseTooltip>
                </Box>
                <Box>
                  <Button
                    size="sm"
                    variant="ghost"
                    color="trueDim"
                    _disabled={{
                      color: 'trueDisabled',
                    }}
                    fontSize="13px"
                    lineHeight="15px"
                    fontWeight="medium"
                    onClick={handleSaveQuestions}
                    isDisabled={isSaveDisabled}
                    isLoading={isEditingAccountQualfications}
                    minW="80px"
                  >
                    {!methods.formState.isDirty ? 'Saved' : 'Save'}
                  </Button>
                </Box>
                <Box>
                  <TruebaseTooltip
                    label={
                      !selectedIcpSetting?.personFilterStatements?.length
                        ? 'This ICP does not have lead filters'
                        : qualificationsWatch?.filter(({ content }) => !content)
                            ?.length
                        ? 'Add at least one qualification question'
                        : ''
                    }
                  >
                    <Button
                      size="sm"
                      variant="truebaseOutline"
                      fontSize="13px"
                      lineHeight="15px"
                      fontWeight="medium"
                      isDisabled={isTestScoringDisabled}
                      isLoading={isTestScoringLoading}
                      type="submit"
                      minW="106px"
                    >
                      Test Scoring
                    </Button>
                  </TruebaseTooltip>
                </Box>
              </Flex>
            </form>
            <AnimatePresence>
              {companySearchOpen && (
                <motion.div
                  key="companySearch"
                  initial={{
                    opacity: 0,
                    y: -50,
                    height: 0,
                  }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    height: 'auto',
                    transition: { duration: 0.25 },
                  }}
                  exit={{
                    opacity: 0,
                    height: 0,
                    y: -50,
                    transition: { duration: 0.25 },
                  }}
                >
                  <Box
                    pos="absolute"
                    left="0"
                    top="0"
                    h="100%"
                    w="100%"
                    onClick={handleShowCompanySearch}
                    zIndex="1"
                  />
                  <SpotlightSearchWithContext />
                </motion.div>
              )}
            </AnimatePresence>
            <Box zIndex="99" pos="sticky" top="0">
              <AnimatePresence>
                {(!!accounts.length || shouldShowLoadingState) && (
                  <motion.div
                    key="scoringStats"
                    initial={{
                      opacity: 0,
                      y: -50,
                      height: 0,
                    }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      height: 'auto',
                      transition: { duration: 0.25 },
                    }}
                    exit={{
                      opacity: 0,
                      height: 0,
                      y: -50,
                      transition: { duration: 0.25 },
                    }}
                  >
                    <Flex w="100%" my="16px" h="60px">
                      <GraphSegment
                        shouldShowLoadingState={shouldShowLoadingState}
                        containerProps={{
                          bg: '#80e6d6',
                          w: `${
                            shouldShowLoadingState ||
                            !accountsWithQualificationScore.length
                              ? 50
                              : (passedScoringCount /
                                  accountsWithQualificationScore.length) *
                                100
                          }%`,
                          onClick: () => {
                            setSelectedFilter((prev) =>
                              prev === 'passed' ? '' : 'passed'
                            );
                          },
                          border:
                            selectedFilter === 'passed'
                              ? '1px solid #3F21FF'
                              : 'unset',
                          borderRadius:
                            selectedFilter === 'passed' ? '4px' : '4px 0 0 4px',
                          boxShadow:
                            selectedFilter === 'passed'
                              ? '0px 0px 10px 0px #0280F540'
                              : 'unset',
                          minW: '184px',
                        }}
                        title={`${(accountsWithQualificationScore.length
                          ? (passedScoringCount /
                              accountsWithQualificationScore.length) *
                            100
                          : 50
                        ).toFixed(0)}%`}
                        subtitle={`${passedScoringCount} Account${
                          passedScoringCount > 1 || passedScoringCount === 0
                            ? 's'
                            : ''
                        } qualified`}
                      />
                      <GraphSegment
                        shouldShowLoadingState={shouldShowLoadingState}
                        containerProps={{
                          bg: '#dab6c6',
                          w: `${
                            shouldShowLoadingState ||
                            !accountsWithQualificationScore.length
                              ? 50
                              : ((accountsWithQualificationScore.length -
                                  passedScoringCount) /
                                  accountsWithQualificationScore.length) *
                                100
                          }%`,
                          onClick: () => {
                            setSelectedFilter((prev) =>
                              prev === 'failed' ? '' : 'failed'
                            );
                          },
                          border:
                            selectedFilter === 'failed'
                              ? '1px solid #3F21FF'
                              : 'unset',
                          borderRadius:
                            selectedFilter === 'failed' ? '4px' : '0 4px 4px 0',
                          boxShadow:
                            selectedFilter === 'failed'
                              ? '0px 0px 10px 0px #0280F540'
                              : 'unset',
                          minW: '207px',
                        }}
                        title={`${(accountsWithQualificationScore.length
                          ? ((accountsWithQualificationScore.length -
                              passedScoringCount) /
                              accountsWithQualificationScore.length) *
                            100
                          : 50
                        ).toFixed(0)}%`}
                        subtitle={`${
                          accountsWithQualificationScore.length -
                          passedScoringCount
                        } Account${
                          accountsWithQualificationScore.length -
                            passedScoringCount >
                            1 ||
                          accountsWithQualificationScore.length -
                            passedScoringCount ===
                            0
                            ? 's'
                            : ''
                        } not qualified`}
                      />
                    </Flex>
                  </motion.div>
                )}
              </AnimatePresence>
            </Box>

            <TestResults
              response={response}
              filteringFn={(accs) => {
                return accs.filter((acc) => {
                  if (selectedFilter === 'passed') {
                    return (
                      acc.qualificationScore >= testingQualificationMinScore
                    );
                  }
                  if (selectedFilter === 'failed') {
                    return (
                      acc.qualificationScore < testingQualificationMinScore
                    );
                  }
                  return true;
                });
              }}
              page="qualification"
              icpId={latestSearchIcpId}
            />

            {!response.isUninitialized &&
              !response?.data?.isStreamingAccounts &&
              accounts.length > 0 &&
              response?.originalArgs?.icpId === latestSearchIcpId &&
              response?.data?.newAccounts ===
                utils.limitSelectOptions[0].value && (
                <Center my="48px" zIndex="2" pos="relative">
                  <Button
                    h="48px"
                    w="400px"
                    variant="truebase"
                    isLoading={isSearchLoading}
                    onClick={async () => {
                      setCompanySearchOpen(false);
                      await handleSearch({
                        refresh: false,
                        limit: SEARCH_LIMIT,
                      });
                    }}
                  >
                    More
                  </Button>
                </Center>
              )}
          </FormProvider>
        </motion.div>
      </AnimatePresence>
    );
  }
);
