import { Box, chakra, Input } from '@chakra-ui/react';

export const FilterSearchDropdownWrapper = chakra(Box, {
  baseStyle: {
    display: 'flex',
    bg: 'white',
    maxHeight: '450px',
    width: '100%',
    borderColor: 'trueLight',
  },
});

export const MainPanel = chakra(Box, {
  baseStyle: {
    position: 'relative',
    borderBottom: '0',
    width: '100%',
    display: 'flex',
  },
});

export const TwoPanelsWrapper = chakra(Box, {
  baseStyle: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
});

export const LeftPanelWrapper = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '360px',
  },
});

export const RightPanelWrapper = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
  },
});

export const SelectWrapper = chakra(Box, {
  baseStyle: {
    px: 4,
    py: 2,
    borderColor: 'trueLight',
  },
});

export const LeftListWrapper = chakra(Box, {
  baseStyle: {
    flex: 1,
    overflowY: 'auto',
    height: '100%',
    borderColor: 'trueLight',
    width: '360px',
    display: 'flex',
    flexDir: 'column',
    alignItems: 'center',
    p: '16px',
  },
});

export const SearchInputWrapper = chakra(Box, {
  baseStyle: {
    px: 4,
    py: 2,
    borderColor: 'trueLight',
  },
});

export const SearchInput = chakra(Input, {
  baseStyle: {
    bg: 'white',
    size: 'lg',
    fontSize: '14px',
    height: '38px',
    lineHeight: '16px',
    color: 'trueLink',
    borderRadius: '4px',
    _placeholder: {
      color: 'trueDim',
    },
    _focus: {
      borderColor: 'trueLink',
      boxShadow: '0px 0px 10px rgba(2, 128, 245, 0.25) !important',
      borderRadius: '4px',
    },
  },
});

export const RightListWrapper = chakra(Box, {
  baseStyle: {
    flex: '2',
    position: 'relative',
    height: '100%',
    overflowY: 'auto',
    py: '16px',
    pl: '16px',
  },
});
