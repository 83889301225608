import {
  Box,
  Center,
  Skeleton,
  SkeletonCircle,
  Spacer,
} from '@chakra-ui/react';
import React from 'react';

export default function TruebaseAccordionSkeleton(props) {
  return (
    <Box {...props} bg="white" p={4}>
      <Box display="flex" alignItems="center">
        <SkeletonCircle height="48px" width="48px" mr={4} />
        <Box display="flex" flexDirection="column" gap="8px">
          <Skeleton width="108px" height="16px"></Skeleton>
          <Skeleton width="250px" height="8px"></Skeleton>
        </Box>
        <Spacer />
        <Center w="32px" h="32px">
          <SkeletonCircle width="20px" height="20px"></SkeletonCircle>
        </Center>
      </Box>
    </Box>
  );
}
