import { Avatar, Box, SkeletonCircle } from '@chakra-ui/react';
import { ReactComponent as CompanyIcon } from '~/v2/assets/icons/accounts/accounts-2.svg';
import React, { useEffect, useState } from 'react';

const ImageWithLoadingState = ({ alt, timeout = 15000, onError, ...props }) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!hasLoaded) {
        onError?.();
      }
    }, timeout);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [hasLoaded, onError, timeout]);

  return (
    <>
      {!hasLoaded && <SkeletonCircle height="48px" width="48px" />}
      <img
        alt={alt}
        onLoad={() => {
          setHasLoaded(true);
        }}
        style={{ display: hasLoaded ? 'block' : 'none' }}
        {...props}
      />
    </>
  );
};

const AvatarSwitcher = ({ type, src, name, domain, isSmall }) => {
  const [renderAvatarFallback, shouldRenderAvatarFallback] = useState(
    domain ? !process.env.REACT_APP_CLEARBIT_LOGO_URL : !src
  );

  return (
    <Box
      sx={{
        display: 'flex',
        img: {
          maxW: isSmall ? '40px' : '48px',
          maxH: isSmall ? '40px' : '48px',
          borderRadius: '50%',
          alignSelf: 'center',
          objectFit: 'contain',
        },
        svg: {
          fill: 'trueSpace',
        },
      }}
    >
      {domain && !renderAvatarFallback ? (
        <ImageWithLoadingState
          src={`${process.env.REACT_APP_CLEARBIT_LOGO_URL}www.${domain}`}
          alt={name}
          onError={() => shouldRenderAvatarFallback(true)}
        />
      ) : !renderAvatarFallback ? (
        <img
          src={src}
          alt={name}
          onError={() => shouldRenderAvatarFallback(true)}
        />
      ) : type === 'person' ? (
        <Avatar
          height={isSmall ? '40px' : '48px'}
          width={isSmall ? '40px' : '48px'}
          name={name}
        />
      ) : type === 'company' ? (
        <CompanyIcon width={isSmall ? 40 : 48} height={isSmall ? 40 : 48} />
      ) : null}
    </Box>
  );
};

export default AvatarSwitcher;
