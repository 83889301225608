import { Button } from '@chakra-ui/react';
import styled from 'styled-components';

export const ButtonWithGoogle = styled(Button).attrs(() => ({
  variant: 'truebaseOutline',
  w: '100%',
  type: 'button',
  p: 0,
  _hover: {
    background: `#eef1f9 url('/static/img/google.svg') no-repeat 13px 11px`,
    backgroundSize: '24px 24px',
  },
}))`
  background: transparent url('/static/img/google.svg') no-repeat 13px 11px;
  background-size: 24px 24px;
`;
