import { Navigate, Route, Routes } from 'react-router-dom';
import { SequenceTemplateWithContext } from './components/sequenceTemplate/SequenceTemplate';
import { AccountQualificationWithContext } from './components/accountQualification/AccountQualification';
import { ICPPageWithContext } from './components/icp/ICP';
import EmailAccounts from './components/emailAccounts/EmailAccounts';
import ExcludeList from './components/ExcludeList/ExcludeList';
import Team from './components/Team/Team';

const SettingsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="icp" />} />
      <Route path="icp" element={<ICPPageWithContext />} />
      <Route
        path="qualification"
        element={<AccountQualificationWithContext />}
      />
      <Route
        path="sequence-templates"
        element={<SequenceTemplateWithContext />}
      />
      <Route path="email-accounts" element={<EmailAccounts />} />
      <Route path="exclude-list" element={<ExcludeList />} />
      <Route path="team" element={<Team />} />
    </Routes>
  );
};

export default SettingsRoutes;
