import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const label = defineStyle({
  as: 'label',
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: '400',
  color: 'trueGray',
  mb: 2,
});

const lead = defineStyle({
  fontFamily: 'Inter',
  fontWeight: '500',
  fontSize: {
    base: '16px',
    md: '18px',
    xl: '20px',
  },
  lineHeight: {
    base: '26px',
    md: '28px',
    xl: '30px',
  },
});

const body = defineStyle({
  fontFamily: 'Inter',
  fontWeight: '400',
  fontSize: {
    base: '14px',
    md: '14px',
    xl: '16px',
  },
  lineHeight: {
    base: '22px',
    md: '24px',
    xl: '30px',
  },
});

const body2 = defineStyle({
  fontFamily: 'Inter',
  fontWeight: '400',
  fontSize: {
    base: '12px',
    md: '13px',
    xl: '14px',
  },
  lineHeight: {
    base: '20px',
    md: '22px',
    xl: '24px',
  },
});

const stat = defineStyle({
  fontFamily: 'Visby CF',
  fontSize: {
    base: '20px',
    lg: '40px',
    xl: '48px',
  },
  fontWeight: '800',
  lineHeight: { base: '20px', lg: '80px' },
  color: 'white',
});

export const textTheme = defineStyleConfig({
  variants: {
    label,
    lead,
    body,
    body2,
    stat,
  },
});
