import { Route, Routes } from 'react-router-dom';

import LaunchAutopilotWithProviders from './components/LaunchAutopilotPage';

const LaunchAutopilotRoutes = () => {
  return (
    <Routes>
      <Route path={'*'} element={<LaunchAutopilotWithProviders />} />
    </Routes>
  );
};

export default LaunchAutopilotRoutes;
