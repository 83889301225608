import { apiSlice } from './api';
import { omit } from 'lodash';

// Define a service using a base URL and expected endpoints
export const sequenceTemplateApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSequenceTemplates: builder.query({
      query: (args) => ({
        url: `sequenceTemplates/${args.teamId}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.result.sequenceTemplates;
      },
      providesTags: ['SequenceTemplates'],
    }),
    addSequenceTemplate: builder.mutation({
      query: (body) => ({
        url: 'sequenceTemplates',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SequenceTemplates'],
    }),
    duplicateSequenceTemplate: builder.mutation({
      query: (body) => ({
        url: 'sequenceTemplates/duplicate',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SequenceTemplates'],
    }),
    deleteSequenceTemplate: builder.mutation({
      query: ({ _id, teamId }) => ({
        url: `sequenceTemplates/${_id}?teamId=${teamId}`,
        method: 'DELETE',
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const patchGetSequenceTemplate = dispatch(
          apiSlice.util.updateQueryData(
            'getSequenceTemplates',
            { teamId: arg.teamId },
            (draft) => {
              draft = draft.filter(
                (sequenceTemplate) => sequenceTemplate._id !== arg._id
              );
              return draft;
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (e) {
          patchGetSequenceTemplate?.undo();
        }
      },
    }),
    editSequenceTemplate: builder.mutation({
      async queryFn(args, queryApi, extraOptions, baseQuery) {
        const response = await baseQuery({
          url: `sequenceTemplates/${args._id}`,
          method: 'PATCH',
          body: {
            ...omit(args, 'meta'),
            name: args.name || undefined,
          },
        });
        return { data: { response, _id: args._id } };
      },
      invalidatesTags: (_, __, args) => {
        return args.meta?.noRefresh ? [] : ['SequenceTemplates'];
      },
    }),
  }),
});

export const {
  useGetSequenceTemplatesQuery,
  useLazyGetSequenceTemplatesQuery,
  useAddSequenceTemplateMutation,
  useEditSequenceTemplateMutation,
  useDeleteSequenceTemplateMutation,
  useDuplicateSequenceTemplateMutation,
} = sequenceTemplateApi;
