import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Flex, useToast } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { utils } from '../../utils';
import useStreamingForm from '../../../hooks/useStreamingForm';
import TruebaseChatContainer from '../../TruebaseChatContainer';
import TruebaseCenteredClouds from '../../Truebase/TruebaseCenteredClouds';
import PersonalizedSequenceInput from './PersonalizedSequenceInput';
import TabEmptyState from '../components/TabEmptyState';
import { usePersonalizationContext } from '~/v2/features/filters/components/usePersonalizationConext';

const getEmptyValues = (length = 1) => {
  return {
    sequence: {
      messages: Array.from({ length }, () => ({
        content: '',
      })),
      subject: {
        content: '',
      },
    },
  };
};

const LeadPersonalizeSequence = ({ readonly, lead = {} }) => {
  const { sequenceSettings } = usePersonalizationContext();
  const toast = useToast();

  const { methods, isLoading, setIsLoading } = useStreamingForm({
    formOptions: {
      defaultValues: {
        sequence: {
          ...(lead.sequence ||
            getEmptyValues(sequenceSettings?.steps?.length).sequence),
        },
      },
    },
    inProgressListener:
      ({ reset, setValue, getValues }) =>
      ({ path, text, loading, identifier }) => {
        if (identifier !== lead?.personId) return;
        const currentVal = getValues(path);
        if (loading) {
          setGeneratingPath('sequence.messages.0.content');
          setIsLoading(true);
          reset(getEmptyValues(sequenceSettings?.steps?.length));
        } else {
          setIsLoading(false);
          setGeneratingPath(path);
          setValue(path, (!currentVal ? '' : currentVal) + text);
        }
      },
    completeListener:
      () =>
      ({ identifier }) => {
        if (identifier !== lead?.personId) return;
        setGeneratingPath(false);
      },
    failedListener:
      () =>
      ({ identifier }) => {
        if (identifier !== lead?.personId) return;
        if (generatingPath) setGeneratingPath(false);
        if (isLoading) {
          setIsLoading(false);
        }
        if (!toast.isActive(`personalizationUpdated-failed-${identifier}`)) {
          toast({
            id: `personalizationUpdated-failed-${identifier}`,
            title: 'Error',
            description: 'Something went wrong, please try again later',
            status: 'error',
            duration: 5000,
            isClosable: false,
          });
        }
      },
    socketIdentifiers: {
      inProgress: 'sequence-inProgress',
      complete: 'sequence-completed',
      failed: 'personalizationUpdated-failed',
    },
  });

  useEffect(() => {
    methods.reset({
      sequence: {
        ...(lead.sequence ||
          getEmptyValues(sequenceSettings?.steps?.length).sequence),
      },
    });
  }, [lead.sequence, methods, sequenceSettings?.steps?.length]);

  const [isGeneratingSequence, setIsGeneratingSequence] = useState(false);

  const [generatingPath, setGeneratingPath] = useState(false);

  const isGenerateDisabled = !!generatingPath || isLoading;

  useEffect(() => {
    if (!isGeneratingSequence && isGenerateDisabled) {
      setIsGeneratingSequence(true);
    } else if (isGeneratingSequence && !generatingPath && !isLoading) {
      setIsGeneratingSequence(false);
    }
  }, [
    generatingPath,
    isGeneratingSequence,
    isLoading,
    setIsGeneratingSequence,
    isGenerateDisabled,
  ]);

  return (
    <>
      {readonly && !get(lead, 'sequence.messages') ? (
        <TabEmptyState tabName="personalizedSequence" />
      ) : (
        <>
          <FormProvider {...methods}>
            <form>
              <Flex direction="column" gap="16px" px="unset">
                {sequenceSettings?.steps?.length > 0 && (
                  <PersonalizedSequenceInput
                    subjectKey="subject"
                    sequenceFormat="subject"
                    generatingPath={generatingPath}
                    title="Subject"
                  />
                )}
                {sequenceSettings?.steps?.map((_step, index) => (
                  <PersonalizedSequenceInput
                    key={index}
                    subjectKey={index}
                    sequenceFormat="email"
                    generatingPath={generatingPath}
                    title={utils.sequenceStepsReadableArray[index]}
                  />
                ))}
                {get(sequenceSettings, 'steps.length', 0) === 0 && (
                  <TruebaseChatContainer position="relative" minH="120px">
                    <TruebaseCenteredClouds text="Create a tailored email for this lead, including top KPIs and more"></TruebaseCenteredClouds>
                  </TruebaseChatContainer>
                )}
              </Flex>
            </form>
          </FormProvider>
        </>
      )}
    </>
  );
};

export default LeadPersonalizeSequence;
