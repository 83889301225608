import { Link, Text } from '@chakra-ui/react';
import { NavLink as RouterNavLink } from 'react-router-dom';

export default function LinkElement({ to, Icon, text, ...props }) {
  return (
    <Link
      to={to}
      sx={{ color: 'trueDim' }}
      _hover={{ textDecoration: 'none', bg: 'rgba(0, 0, 0, 0.04)' }}
      _activeLink={{
        color: 'trueSpace',
        bg: 'trueLighter',
        borderColor: 'transparent',
        svg: { path: { fill: 'trueSpace' } },
      }}
      as={RouterNavLink}
      h="40px"
      display="flex"
      alignItems="center"
      gap="10px"
      w="100%"
      borderRadius="4px"
      p="8px"
      {...props}
    >
      {Icon && <Icon />}
      <Text
        fontSize="13px"
        lineHeight="16px"
        fontWeight="500"
        ml={Icon ? 'unset' : '32px'}
      >
        {text}
      </Text>
    </Link>
  );
}
