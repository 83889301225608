import { useCallback, useState } from 'react';
import {
  Flex,
  Text,
  Button,
  Box,
  IconButton,
  useToast,
  HStack,
  CloseButton,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as Upload } from '~/v2/assets/icons/upload.svg';
import Papa from 'papaparse';
import { ReactComponent as FileIcon } from '~/v2/assets/icons/file/file-2.svg';
import { CloseIcon } from '~/v2/assets/CloseIcon';
import { validateCSV } from '~/v2/common/utils';
import { tryGetLinkedinHandleFromUrl } from '../../../common/utils';
import schemas from '~/utils/schemas';
import { TruebaseTooltip } from '~/v2/common/TruebaseTooltip';
import { useUpgradeImportFileMutation } from '~/v2/redux/services/user';
import { useSelector } from 'react-redux';
import { getUser } from '~/v2/redux/slices/userSlice';
import { get } from 'lodash';
import { ReactComponent as CheckDone } from '~/v2/assets/check-done.svg';

const createCaseInsensitiveRecord = (record) => {
  const lowerCaseRecord = {};
  for (const key in record) {
    if (Object.hasOwnProperty.call(record, key)) {
      lowerCaseRecord[key.toLowerCase()] = record[key];
    }
  }
  return lowerCaseRecord;
};

export function ImportPanel({ panel, setPanel }) {
  const user = useSelector(getUser);

  const [hasRequestedUpgrade, setHasRequestedUpgrade] = useState();

  const [sendEmail] = useUpgradeImportFileMutation();

  const validateFileUpload = useCallback(
    (file, onValidateSuccess, onValidateError) => {
      Papa.parse(file, {
        trim: true,
        skipEmptyLines: true,
        complete: async (results) => {
          try {
            await validateCSV({
              file,
              results,
              maxLines: 500,
              schemaDefinition: schemas.allowedImportHeaders,
              extraValidationCallback: async (data) => {
                for (const [index, record] of data.entries()) {
                  const modifiedRecord = createCaseInsensitiveRecord(record);

                  const leadFirstName = modifiedRecord['lead first name'];
                  const leadLastName = modifiedRecord['lead last name'];
                  const accountName = modifiedRecord['account name'];
                  const accountDomain = modifiedRecord['account domain 1'];
                  const linkedinHandle = tryGetLinkedinHandleFromUrl(
                    modifiedRecord['lead linkedin url']
                  );
                  const leadWorkEmail = modifiedRecord['lead work email'];
                  const leadPersonalEmail =
                    modifiedRecord['lead personal email'];
                  const leadLocation = modifiedRecord['lead location'];

                  const isValidRecord =
                    linkedinHandle ||
                    leadPersonalEmail ||
                    leadWorkEmail ||
                    (leadFirstName && leadLastName && accountDomain) ||
                    (leadFirstName && leadLastName && accountName) ||
                    (leadFirstName &&
                      leadLastName &&
                      leadLocation &&
                      accountName);

                  if (!isValidRecord) {
                    throw new Error(
                      `The CSV file has an incorrect value on line ${index + 1}`
                    );
                  }
                }
              },
            });

            onValidateSuccess({ leadsCount: results.data.length });
          } catch (error) {
            onValidateError(error.message);
          }
        },
        header: true,
      });
    },
    []
  );

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        setPanel({
          acceptedFiles: [],
          originalFileName: rejectedFiles[0].file.name,
          error: 'Only CSV files are allowed',
          leadsCount: 0,
        });
        return;
      }

      const onValidateSuccess = ({ leadsCount }) => {
        setPanel({
          acceptedFiles,
          originalFileName: acceptedFiles[0].name,
          error: false,
          leadsCount,
        });
      };

      const onValidateError = (errorMessage) => {
        setPanel({
          acceptedFiles,
          originalFileName: acceptedFiles[0].name,
          error: errorMessage,
          leadsCount: 0,
        });
      };

      validateFileUpload(acceptedFiles[0], onValidateSuccess, onValidateError);
    },
    [setPanel, validateFileUpload]
  );

  const { getRootProps: getDropzoneRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'text/csv': ['.csv'],
    },
  });

  const toast = useToast();

  return (
    <Flex
      flexDir="column"
      p="16px"
      gap="10px"
      border="1px solid"
      borderColor={
        panel.error || !panel?.acceptedFiles?.length ? 'trueCorral' : 'trueDim'
      }
      borderStyle="dashed"
      borderRadius="2px"
      cursor="pointer"
      {...getDropzoneRootProps()}
    >
      <input {...getInputProps()} type="file" />
      {panel.originalFileName ? (
        <Box
          flexDir="row"
          alignItems="center"
          gap="16px"
          backgroundColor="trueBg02"
          minH="56px"
          border="1px solid"
          borderColor="trueLight"
          borderRadius="2px"
          p="13px 16px"
          w="100%"
          overflow="hidden"
        >
          <Flex alignItems="center">
            <FileIcon color="trueSpace" width="32" height="32" />

            <Flex ml="16px" direction="column" flex="1" alignItems="flex-start">
              <Text
                textOverflow="ellipsis"
                overflow="hidden"
                noOfLines={1}
                w="80%"
                color="trueSpace"
                fontSize="14px"
                lineHeight="16px"
                fontWeight="500"
              >
                {panel.originalFileName}
              </Text>
              <Box>
                {panel.error ? (
                  <>
                    <Text
                      color="trueAttention"
                      fontSize="13px"
                      lineHeight="16px"
                      display="inline"
                    >
                      {`${panel.error}. Please try another file${
                        panel.error.includes('trial accounts') ? ' or ' : '.'
                      }`}
                    </Text>
                    {panel.error.includes('trial accounts') && (
                      <TruebaseTooltip
                        label={
                          hasRequestedUpgrade
                            ? 'We received your request, we will get back to you shortly'
                            : ''
                        }
                      >
                        <Text
                          color={
                            hasRequestedUpgrade ? 'trueDisabled' : 'trueLink'
                          }
                          fontSize="13px"
                          lineHeight="16px"
                          textDecoration="underline"
                          onClick={async (e) => {
                            e.stopPropagation();
                            setHasRequestedUpgrade(true);
                            const res = await sendEmail({
                              email: get(user, 'email'),
                            });

                            if (get(res, 'data.isOk')) {
                              toast({
                                duration: 5000,
                                position: 'bottom',
                                render: ({ onClose }) => (
                                  <Box
                                    bg="trueAnason"
                                    p="16px 12px"
                                    borderRadius="4px"
                                  >
                                    <HStack spacing="16px" alignItems="center">
                                      <CheckDone
                                        style={{
                                          width: '16px',
                                          height: '16px',
                                        }}
                                      />
                                      <Text
                                        sx={{
                                          fontSize: '14px',
                                          lineHeight: '24px',
                                          fontWeight: '500',
                                          color: 'trueSpace',
                                        }}
                                      >
                                        Request sent. We&apos;ll get back to you
                                        shortly
                                      </Text>
                                      <CloseButton
                                        size="sm"
                                        onClick={onClose}
                                      />
                                    </HStack>
                                  </Box>
                                ),
                              });
                            } else {
                              setHasRequestedUpgrade(false);
                            }
                          }}
                          display="inline"
                        >
                          request upgrade.
                        </Text>
                      </TruebaseTooltip>
                    )}
                  </>
                ) : (
                  <Text color="trueExplain" fontSize="13px" lineHeight="16px">
                    Ready to go
                  </Text>
                )}
              </Box>
            </Flex>
            <IconButton
              justifySelf="flex-end"
              aria-label="Discard file"
              icon={<CloseIcon />}
              h="32px"
              w="32px"
              onClick={(e) => {
                e.stopPropagation();
                setPanel({
                  acceptedFiles: [],
                  originalFileName: '',
                  error: false,
                });
              }}
              variant="truebaseIcon"
            />
          </Flex>
        </Box>
      ) : (
        <>
          <Flex alignItems="center" gap="10px">
            <Button
              w="114px"
              h="32px"
              variant="truebaseOutlineNeutral"
              leftIcon={<Upload />}
            >
              Select file
            </Button>
            <Text
              color="trueDim"
              fontSize="12px"
              lineHeight="14px"
              fontWeight="400"
            >
              Browse for the file or drop it here.
            </Text>
          </Flex>
          <Text
            color="trueDim"
            fontSize="12px"
            lineHeight="14px"
            fontWeight="400"
          >
            The file should be a CSV format, under 500 lines, and 256KB max.
          </Text>
        </>
      )}
    </Flex>
  );
}
