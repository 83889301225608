const accountStatusToReadable = {
  accepted: 'Saved',
  rejected: 'Excluded',
  suggested: 'Suggested',
  skipped: 'Skipped',
};

const leadStatusToReadable = {
  accepted: 'Saved',
  rejected: 'Excluded',
  suggested: 'Suggested',
  skipped: 'Skipped',
};

const backendStatOrder = ['accepted', 'rejected'];
const statusRoutes = {
  accepted: {
    route: '/contacts?status=accepted&',
  },
  rejected: {
    route: '/contacts?status=rejected&',
  },
};
const contactsStatuses = {
  accepted: {
    status: ['accepted'],
    leadStatus: [],
    onlyAcceptedBySystem: false,
    filterSearchValue: '',
  },
  suggested: {
    status: ['accepted'],
    leadStatus: [],
    onlyAcceptedBySystem: true,
    filterSearchValue: '',
  },
  'auto-accepted': {
    status: ['accepted'],
    leadStatus: [],
    onlyAcceptedBySystem: true,
    filterSearchValue: '',
  },
  'pending-leads': {
    status: ['accepted'],
    leadStatus: ['suggested'],
    onlyAcceptedBySystem: false,
    filterSearchValue: '',
  },
  skipped: {
    status: ['skipped'],
    leadStatus: [],
    onlyAcceptedBySystem: false,
    filterSearchValue: '',
  },
  rejected: {
    status: ['rejected'],
    leadStatus: [],
    onlyAcceptedBySystem: false,
    filterSearchValue: '',
  },
  empty: {
    status: [],
    leadStatus: [],
    onlyAcceptedBySystem: false,
    filterSearchValue: '',
  },
};

const createRoute = (status, params) =>
  params ? statusRoutes[status]?.route + params : statusRoutes[status]?.route;

export {
  accountStatusToReadable,
  leadStatusToReadable,
  backendStatOrder,
  statusRoutes,
  contactsStatuses,
  createRoute,
};
