import { Box, HStack, VStack, Avatar, Text, Divider } from '@chakra-ui/react';
import { RequestMembersChange } from './RequestMembersChange';

const MembersPanel = ({ members = [], ...rest }) => {
  return (
    <Box {...rest}>
      <VStack spacing="24px" alignItems="flex-start">
        {members.map(({ firstName, lastName, email }) => (
          <HStack spacing="16px" alignItems="center" key={email}>
            <Avatar size="md" name={`${firstName} ${lastName}`} />
            <VStack spacing="4px" alignItems="start">
              <Text
                fontSize="16px"
                fontWeight="500"
                lineHeight="18px"
                color="trueSpace"
              >
                {firstName} {lastName}
              </Text>
              <Text
                fontSize="14px"
                fontWeight="400"
                lineHeight="16px"
                color="trueDim"
              >
                {email}
              </Text>
            </VStack>
          </HStack>
        ))}
      </VStack>
      <Box py="24px">
        <Divider orientation="horizontal" borderColor="trueLight" opacity="1" />
      </Box>
      <RequestMembersChange />
    </Box>
  );
};

export default MembersPanel;
