import React from 'react';
import { Route, Routes } from 'react-router-dom';

import OnboardingPage from './components/OnboardingPage';

const OnboardingRoutes = () => {
  return (
    <Routes>
      <Route path={'*'} element={<OnboardingPage />} />
    </Routes>
  );
};

export default OnboardingRoutes;
