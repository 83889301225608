import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  OnboardingBackButton,
  OnboardingButton,
} from '../shared/OnboardingButtons';
import OnboardingTitle from '../shared/OnboardingTitle';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
} from '@chakra-ui/react';
import LoadingMessage from '../shared/LoadingMessage';
import { ReactComponent as MailIcon } from '~/v2/assets/icons/mail/mail-1.svg';
import { ButtonWithGoogle } from '../../../auth/ButtonWithGoogle';
import { Separator } from '../../../auth/Separator';
import { useFormContext } from 'react-hook-form';
import {
  useSendVerificationCodeMutation,
  useSignupMutation,
} from '../../../../redux/services/user';
import { sleep } from '../../../../../utils';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import {
  ONBOARDING_LOCALSTORAGE_KEY,
  getRandomInt,
  tryGetLinkedinHandle,
  tryGetLinkedinHandleFromUrl,
} from '../../../../common/utils';
import { useLocation } from 'react-router';

const EmailStep = forwardRef(
  ({ stepInfo, emailLoadingMessage, isLoadingGoogleRes }, ref) => {
    const { handleAsyncError } = useErrorHandler();
    const {
      watch,
      setError,
      formState: { errors },
      clearErrors,
      register,
      getValues,
    } = useFormContext();
    const [isSignupFlowLoading, setIsSignupFlowLoading] = useState(false);
    const [email, temporaryEmail] = watch(['email', 'temporaryEmail']);
    const [sendVerificationEmail, { isLoading: isEmailVerificationLoading }] =
      useSendVerificationCodeMutation();
    const [isFakeGenerating, setIsFakeGenerating] = useState(false);
    const [signUp] = useSignupMutation();
    const { setValue } = useFormContext();
    const isDisabled = isFakeGenerating || isEmailVerificationLoading;

    const location = useLocation();
    const utm_source = new URLSearchParams(location.search).get('utm_source');

    const handleBack = () => {
      setValue('email', '');
      stepInfo.setStep((s) => s - 1);
    };

    const handleClick = useCallback(async () => {
      clearErrors('email');
      if (email) {
        const result = await sendVerificationEmail({
          email,
        }).unwrap();
        if (result.isOk) {
          stepInfo.setStep((s) => s + 1);
        } else {
          if (result.code === 400) {
            setError('email', {
              type: 'customError',
              message: 'Please enter a valid email address.',
            });
          } else {
            setError('email', {
              type: 'customError',
              message: 'Email address already exists.',
            });
          }
        }
      }
    }, [clearErrors, email, setError, sendVerificationEmail, stepInfo]);

    useEffect(() => {
      const wrappedFn = handleAsyncError(async () => {
        setIsFakeGenerating(true);
        //if there is a value saved in temporaryEmail, we copy it since it's the value we found. otherwise we don't show anything
        if (temporaryEmail && (email === '' || !email)) {
          const emailSplit = temporaryEmail.split('');
          //add character by character the temporary email to the email field with a 10ms delay between them
          for (let i = 0; i < emailSplit.length; i++) {
            setValue('email', emailSplit.slice(0, i + 1).join(''));
            await sleep(getRandomInt(10, 100));
          }
        }
        setIsFakeGenerating(false);
      });
      wrappedFn();
      //disable dependencies since we don't want to sync the value of the two, we just want to run it once if it has a value there and the current email is empty (in case the user went back)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleAsyncError]);

    useImperativeHandle(
      ref,
      () => {
        return {
          enterBtnHandler() {
            isDisabled ? null : handleClick();
          },
        };
      },
      [isDisabled, handleClick]
    );

    const signUpWithGoogle = handleAsyncError(async () => {
      setIsSignupFlowLoading(true);
      const response = await signUp({
        authProvider: 'google',
        intercomId: localStorage.getItem('TRUEBASE_LEAD_ID') ?? undefined,
        ...(utm_source && { src: utm_source }),
        personId:
          tryGetLinkedinHandleFromUrl(getValues('personLinkedIn')) ||
          tryGetLinkedinHandle(getValues('personLinkedIn')),
      }).unwrap();
      setIsSignupFlowLoading(false);
      if (response.isOk) {
        localStorage.setItem(
          ONBOARDING_LOCALSTORAGE_KEY,
          JSON.stringify({
            formInfo: getValues(),
            step: stepInfo.step,
          })
        );
        window.location.href = response.result.url;
      }
    });

    return (
      <>
        {isLoadingGoogleRes ? (
          <Spinner
            color="trueBlue"
            w="50px"
            h="50px"
            sx={{ alignSelf: 'center', mt: '80px' }}
            speed="0.65s"
          />
        ) : (
          <>
            <OnboardingTitle id="email-step">
              <Text>Create your account to begin</Text>
              <Text>our collaboration</Text>
            </OnboardingTitle>
            <LoadingMessage
              error={errors.email}
              emailLoadingMessage={emailLoadingMessage}
            />
            <FormControl variant="truebase" isRequired>
              <FormLabel
                variant="truebase"
                color="trueMedium"
                requiredIndicator={' *'}
              >
                What is your professional email address?
              </FormLabel>
              <InputGroup>
                <Input
                  {...register('email', {
                    setValueAs: (v) => v?.toLowerCase(),
                  })}
                  type="email"
                  isDisabled={isDisabled}
                  variant="truebase"
                  placeholder="Your work email"
                  _disabled={{}}
                  sx={{
                    '&:disabled': {
                      bgColor: 'white',
                    },
                  }}
                />
                <InputRightElement top="4px" color="trueMedium">
                  <MailIcon />
                </InputRightElement>
              </InputGroup>
              <FormHelperText
                fontSize="12px"
                fontWeight={400}
                color="trueExplain"
              >
                We will use this email to share the cool AI stuff.
              </FormHelperText>
            </FormControl>
            <OnboardingButton
              noIcon
              isDisabled={isDisabled || isSignupFlowLoading}
              onClick={handleClick}
              isLoading={isEmailVerificationLoading}
            >
              Verify Email
            </OnboardingButton>
            <Separator>&nbsp;&nbsp;OR&nbsp;&nbsp;</Separator>
            <ButtonWithGoogle
              isLoading={isSignupFlowLoading}
              isDisabled={isDisabled}
              onClick={signUpWithGoogle}
            >
              Signup with Google
            </ButtonWithGoogle>
            <OnboardingBackButton
              isDisabled={isDisabled}
              onClick={handleBack}
            />
          </>
        )}
      </>
    );
  }
);
export default EmailStep;
