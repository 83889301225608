import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const sizes = {
  lg: defineStyle({
    maxW: '1152px',
    p: 0,
  }),
  webSection: defineStyle({
    maxW: {
      base: '380px',
      md: '930px',
      xl: '1230px',
    },
    px: {
      base: '16px',
      md: '32px',
    },
  }),
};

export const containerTheme = defineStyleConfig({ sizes });
