import { Box, Flex, Container, Skeleton } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import Layout from '~/v2/common/Layout';
import { useAuthenticate } from '~/v2/common/requiresAuth';
import { TeamAccordion } from '~/v2/features/settings/components/Team/components/TeamAccordion';
import {
  useGetTeamDetailsQuery,
  useGetTeamMembersQuery,
} from '~/v2/redux/services/teams';
import { getSelectedTeam } from '~/v2/redux/slices/teamsSlice';

const Team = () => {
  useAuthenticate();

  const selectedTeam = useSelector(getSelectedTeam);

  const [
    { data: teamDetails, isLoading: teamDetailsIsLoading },
    { data: teamMembers, isLoading: teamMembersIsLoading },
  ] = [
    useGetTeamDetailsQuery(selectedTeam?._id, { skip: !selectedTeam?._id }),
    useGetTeamMembersQuery(selectedTeam?._id, { skip: !selectedTeam?._id }),
  ];

  return (
    <Layout
      title="Team"
      subtitle="Teams are independent, they don’t share ICP details, lead history or exclude list"
    >
      <Box w="100%">
        <Helmet title={'Truebase | Team'} defer={false} />
        <Container size="lg" mx="auto">
          <Flex p="16px 0 76px 0" direction="column" gap="16px">
            {selectedTeam && !teamDetailsIsLoading && !teamMembersIsLoading ? (
              <AnimatePresence>
                <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                >
                  <TeamAccordion
                    team={{
                      ...selectedTeam,
                      ...teamDetails,
                      members: teamMembers,
                    }}
                    defaultIndex={0}
                  />
                </motion.div>
              </AnimatePresence>
            ) : (
              <Skeleton h="411px" borderRadius="4px" />
            )}
          </Flex>
        </Container>
      </Box>
    </Layout>
  );
};

export default Team;
