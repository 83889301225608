/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useState } from 'react';

export const FiltersContext = createContext({
  companyFilters: [],
  leadFilters: [],
  testingQualificationMinScore: 0,
  personalizationCount: 0,
  trainView: 'icp',
  searchExclusivity: 'includeSavedAccounts',
  setSearchExclusivity: () => {},
  setCompanyFilters: () => {},
  setLeadFilters: () => {},
  setTestingQualificationMinScore: () => {},
  setPersonalizationCount: () => {},
  setTrainView: () => {},
  setView: () => {},
});

export const FilterProvider = ({
  children,
  initialCompanyFilters,
  initialLeadFilters,
}) => {
  const [companyFilters, setCompanyFilters] = useState(initialCompanyFilters);
  const [leadFilters, setLeadFilters] = useState(initialLeadFilters);
  const [testingQualificationMinScore, setTestingQualificationMinScore] =
    useState(0);
  const [requestId, setRequestId] = useState(null);
  const [personalizationCount, setPersonalizationCount] = useState(0);
  const [trainView, setTrainView] = useState('icp');
  const [searchExclusivity, setSearchExclusivity] = useState(
    'includeSavedAccounts'
  );
  const handleSetRequestId = (id) => {
    setRequestId(typeof id !== 'string' ? id.toString() : id);
  };
  return (
    <FiltersContext.Provider
      value={{
        companyFilters,
        leadFilters,
        setCompanyFilters,
        setLeadFilters,
        requestId,
        setRequestId: handleSetRequestId,
        trainView,
        setTrainView,
        searchExclusivity,
        setSearchExclusivity,
        personalizationCount,
        setPersonalizationCount,
        testingQualificationMinScore,
        setTestingQualificationMinScore,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};
