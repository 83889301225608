import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const $fg = cssVar('tabs-color');
const $bg = cssVar('tabs-bg');

const truebase = definePartsStyle({
  tablist: {
    borderBottom: '2px solid',
    borderColor: 'trueLighter',
    gap: '32px',
  },
  tab: {
    borderBottom: '2px solid ',
    borderBottomColor: 'transparent',
    marginBottom: '-1px',
    px: 0,
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 500,
    [$fg.variable]: 'colors.trueDim',
    _selected: {
      [$fg.variable]: `colors.trueSpace`,
      borderColor: 'currentColor',
    },
    _active: {
      [$bg.variable]: 'colors.gray.200',
      _dark: {
        [$bg.variable]: 'colors.whiteAlpha.300',
      },
    },
    _disabled: {
      _active: { bg: 'none' },
    },
    color: $fg.reference,
    bg: $bg.reference,
  },
  tabpanel: {
    p: '24px 0 0 0',
  },
});

// export the component theme
export const tabsTheme = defineMultiStyleConfig({ variants: { truebase } });
