import { Route, Routes } from 'react-router-dom';
import AutopilotsPage from './components/AutopilotsPage';

const AutopilotsRoutes = () => {
  return (
    <Routes>
      <Route path={'*'} element={<AutopilotsPage />} />
    </Routes>
  );
};

export default AutopilotsRoutes;
