import { Box } from '@chakra-ui/react';
import React from 'react';

export default function TruebaseChatContainer({ children, ...rest }) {
  return (
    <Box
      __css={{
        bg: 'trueBg02',
        borderRadius: '4px',
        border: '1px solid',
        borderColor: 'trueLight',
        px: '16px',
        py: '10px',
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}
