import { createAction, createSlice } from '@reduxjs/toolkit';
import { apiSlice } from '../services/api';

export const setSelectedIcp = createAction('icps/setIcp');

export const ICP_LOCAL_STORAGE_KEY = 'truebase-icp';

const slice = createSlice({
  name: 'icps',
  initialState: {
    selectedIcpKey: localStorage.getItem(ICP_LOCAL_STORAGE_KEY),
    selectedIcp: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSelectedIcp, (state, action) => {
      state.selectedIcp = action.payload;
      state.selectedIcpKey = action.payload._id;
      localStorage.setItem(ICP_LOCAL_STORAGE_KEY, action.payload._id);
    });
    builder.addMatcher(
      apiSlice.endpoints.getIcps.matchFulfilled,
      (state, { payload }) => {
        const newSelectedIcp =
          payload.find((icp) => icp._id === state.selectedIcpKey) || payload[0];

        state.selectedIcp = newSelectedIcp;
        state.selectedIcpKey = newSelectedIcp._id;
        localStorage.setItem(ICP_LOCAL_STORAGE_KEY, newSelectedIcp._id);
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.addIcp.matchFulfilled,
      (state, { payload }) => {
        state.selectedIcp = payload.result.icp;
        state.selectedIcpKey = payload.result.icp._id;
        localStorage.setItem(ICP_LOCAL_STORAGE_KEY, payload.result.icp._id);
      }
    );
  },
});

export const getSelectedIcp = (state) => state.icps.selectedIcp;

export default slice.reducer;
