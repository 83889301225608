import { Box, useToast } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import React from 'react';
import { TruebaseTooltip } from '../../TruebaseTooltip';
import { ReactComponent as Email } from '~/v2/assets/icons/social_icons/mail/mono.svg';
// import { ReactComponent as Phone } from '~/v2/assets/icons/social_icons/phone/mono.svg';
import { ReactComponent as NoConfig } from '~/v2/assets/icons/social_icons/no conf/mono.svg';

export default function LeadEmailDiscovery({ account, lead }) {
  const toast = useToast();
  return (
    <React.Fragment>
      {!isEmpty(lead.workEmails) &&
        (!lead.emailDiscoveryStatus ||
          ['completed', 'failed'].includes(lead.emailDiscoveryStatus)) && (
          <TruebaseTooltip
            label={
              <React.Fragment>
                {lead.workEmails.map((email, index) => {
                  return <Box key={index}>{email}</Box>;
                })}
              </React.Fragment>
            }
            placement="top"
          >
            <Box
              onClick={() => {
                navigator.clipboard.writeText(lead.workEmails.join('\r\n'));
                toast({
                  title: 'Copied to clipboard',
                  status: 'success',
                  duration: 1000,
                });
              }}
              cursor="pointer"
              color="trueDim"
              sx={{
                svg: {
                  w: '16px',
                  h: '16px',
                },
              }}
              _hover={{
                color: 'trueLink',
              }}
            >
              <Email />
            </Box>
          </TruebaseTooltip>
        )}
      {['completed', 'failed'].includes(lead.emailDiscoveryStatus) &&
        isEmpty(lead.workEmails) && (
          <TruebaseTooltip label="Couldn't find a verified email address">
            <Box
              color="trueDim"
              sx={{
                svg: {
                  w: '16px',
                  h: '16px',
                },
              }}
              _hover={{
                color: 'trueLink',
              }}
            >
              <NoConfig />
            </Box>
          </TruebaseTooltip>
        )}
    </React.Fragment>
  );
}
