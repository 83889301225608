import { chakraComponents, Select } from 'chakra-react-select';
import React, { forwardRef } from 'react';
import { ReactComponent as TooltipArrow } from '../../assets/tooltip arrow.svg';

const TruebaseSelect = forwardRef(
  (
    {
      defaultValue,
      value,
      getOptionLabel,
      onChange,
      options,
      bg,
      additionalSelectComponents = {},
      ...rest
    },
    ref
  ) => {
    return (
      <Select
        ref={ref}
        size="lg"
        chakraStyles={{
          control: (baseStyle, state) => ({
            ...baseStyle,
            width: '100%',
            h: '40px',
            bg: bg || 'white',
            borderColor: state.isFocused ? 'trueLink' : 'trueLight',
            boxShadow: state.isFocused
              ? '0px 0px 10px rgba(2, 128, 245, 0.25) !important'
              : 'none',
            borderRadius: '4px',
            minHeight: '40px',
            '&[aria-invalid=true]': {
              boxShadow: 'none',
            },
            '&[data-invalid]': {
              boxShadow: 'none',
            },
          }),
          valueContainer: (baseStyle) => ({
            ...baseStyle,
            fontSize: '12px',
            color: 'trueSpace',
            lineHeight: '14px',
            fontWeight: '500',
          }),
          singleValue: (baseStyle) => ({
            ...baseStyle,
            color: 'trueSpace',
            fontSize: '12px',
            lineHeight: '14px',
            svg: {
              display: 'none',
            },
          }),
          dropdownIndicator: () => ({
            padding: '8px 16px',
            color: 'trueDim',
          }),
          menuList: (baseStyle) => ({
            ...baseStyle,
            borderRadius: '4px',
          }),
          menu: (baseStyle) => ({
            ...baseStyle,
            mt: 0,
            zIndex: 'var(--chakra-zIndices-popover)',
          }),
          option: (baseStyle, { isSelected }) => ({
            ...baseStyle,
            backgroundColor: 'white',
            color: 'trueSpace',
            '.option-label': {
              color: 'trueSpace',
              fontSize: '12px',
              lineHeight: '14px',
              fontWeight: '500',
            },
            '&:hover': {
              backgroundColor: '#c3e6fa',
              svg: {
                color: 'trueLink',
              },
            },
            ...(isSelected && {
              color: 'trueSpace !important',
            }),
          }),
        }}
        selectedOptionColorScheme="white"
        defaultValue={defaultValue}
        value={value}
        getOptionLabel={getOptionLabel}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: (props) => (
            <chakraComponents.DropdownIndicator {...props}>
              <TooltipArrow />
            </chakraComponents.DropdownIndicator>
          ),
          ...additionalSelectComponents,
        }}
        onChange={onChange}
        options={options}
        {...rest}
      />
    );
  }
);

export default TruebaseSelect;
