import { apiSlice } from './api';
import { createStandaloneToast } from '@chakra-ui/react';
const { toast } = createStandaloneToast();

export const leadsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    startGenerateInsights: builder.mutation({
      query: ({ leadId, ...args }) => ({
        url: `leads/startGenerateInsights`,
        method: 'POST',
        body: args,
      }),
    }),
    engageLead: builder.mutation({
      query: ({ lead, icpId, action }) => ({
        url: `leads/${lead._id}/engage`,
        method: 'PATCH',
        body: { icpId, action },
      }),
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        const actionToSequenceMapper = {
          engage: 'replied',
          unsubscribe: 'exited',
        };
        let removeLeadFromViewDispatch;
        let addLeadToTargetSequenceDispatch;

        if (args.action === 'remove') {
          removeLeadFromViewDispatch = dispatch(
            apiSlice.util.updateQueryData(
              'getAutopilotContacts',
              { teamId: args.teamId },
              (draft) => {
                draft.result.matches = draft.result.matches.filter(
                  (l) => l._id !== args.lead._id
                );
                draft.result.totalCount = draft.result.matches.length;
              }
            )
          );
        } else {
          addLeadToTargetSequenceDispatch = dispatch(
            apiSlice.util.updateQueryData(
              'getAutopilotContacts',
              { teamId: args.teamId },
              (draft) => {
                const leadIndex = draft.result.matches.findIndex(
                  (l) => l._id === args.lead._id
                );
                if (leadIndex !== -1) {
                  // change lead status
                  draft.result.matches[leadIndex].status = [
                    actionToSequenceMapper[args.action],
                  ];

                  // remove lead from view based on chosen tags
                  if (
                    args.chosenTags?.length &&
                    !args.chosenTags.includes(
                      actionToSequenceMapper[args.action]
                    )
                  ) {
                    draft.result.matches = draft.result.matches.filter(
                      (l) => l._id !== args.lead._id
                    );
                    draft.result.totalCount = draft.result.matches.length;
                  }
                }
              }
            )
          );
        }

        try {
          await queryFulfilled;
        } catch (e) {
          toast({
            title: 'Error',
            description: 'Failed to engage lead',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
          removeLeadFromViewDispatch.undo();
          if (addLeadToTargetSequenceDispatch)
            addLeadToTargetSequenceDispatch.undo();
        }
      },
      invalidatesTags: ['autopilotsLeadsStats'],
    }),
  }),
});

export const { useStartGenerateInsightsMutation, useEngageLeadMutation } =
  leadsApi;
