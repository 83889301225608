import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Flex,
  IconButton,
  Spinner,
  Text,
  VStack,
  Divider,
  Center,
  CircularProgress,
  CircularProgressLabel,
  useDisclosure,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import socket from '~/v2/utility/socket';
import { format, formatDistanceToNow } from 'date-fns';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AutopilotCardIcon } from '~/v2/assets/icons/AutopilotCardIcon';
import { TruebaseTooltip } from '~/v2/common/TruebaseTooltip';
import { ReactComponent as DownloadIcon } from '~/v2/assets/icons/download.svg';
import { ReactComponent as EyeIcon } from '~/v2/assets/icons/view/view-large.svg';
import {
  useCancelAutopilotMutation,
  useDeleteAutopilotMutation,
  useGetExportAutopilotCSVMutation,
  useSkipAutopilotMutation,
  useExportAutopilotContactsMutation,
} from '~/v2/redux/services/autopilots';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { capitalize, isUndefined } from 'lodash';
import { useSelector } from 'react-redux';
import { getUser } from '~/v2/redux/slices/userSlice';
import { getAutopilotStats } from '~/v2/common/utils';
import { DropdownToggle } from '~/v2/common/TruebaseAccordion/TruebaseAccountAccordion/DropdownToggle';
import { ReactComponent as RemoveIcon } from '~/v2/assets/icons/clear/clear-large.svg';
import SkipIcon from '~/v2/assets/SkipIcon';

const LazyTruebaseConfirmationAlertDialog = React.lazy(() =>
  import('../../../common/TruebaseConfirmationAlertDialog')
);

const FunnelStep = ({ value, width, bg }) => {
  return (
    <Flex
      borderRadius="2px"
      alignItems="center"
      height="28px"
      width={`${width}px`}
      bg={bg}
      clipPath="polygon(0 0, 100% 0%, 90% 100%, 0% 100%)"
    >
      <Text fontSize="12px" mx="10px" fontWeight="500" color="white">
        {value}
      </Text>
    </Flex>
  );
};

const TimeRemaining = ({
  createdAt,
  autopilotStatus,
  requestedOrImportedCount,
}) => {
  const currentTime = new Date().getTime();
  const elapsedTime = currentTime - new Date(createdAt).getTime();
  const elapsedTimeInSeconds = elapsedTime / 1000;
  const completionRate =
    autopilotStatus.finalStepCompletedCount / elapsedTimeInSeconds;
  const totalTimeInSeconds = requestedOrImportedCount / completionRate;
  const remainingTimeInSeconds = totalTimeInSeconds - elapsedTimeInSeconds;
  const remainingTimeInMinutes = remainingTimeInSeconds / 60;
  const remainingTimeInHours = remainingTimeInMinutes / 60;
  const remainingTimeInDays = remainingTimeInHours / 24;

  let remainingTimeText;
  if (remainingTimeInSeconds < 60) {
    remainingTimeText = `About ${Math.floor(
      remainingTimeInSeconds
    )} seconds remaining...`;
  } else if (remainingTimeInMinutes < 60) {
    remainingTimeText = `About ${Math.floor(
      remainingTimeInMinutes
    )} minutes remaining...`;
  } else if (remainingTimeInHours < 24) {
    remainingTimeText = `About ${Math.floor(
      remainingTimeInHours
    )} hours remaining...`;
  } else {
    remainingTimeText = `About ${Math.floor(
      remainingTimeInDays
    )} days remaining...`;
  }

  return autopilotStatus.finalStepCompletedCount > 0
    ? remainingTimeText
    : 'Calculating estimated time...';
};

const PercentProgress = ({ autopilotStatus, requestedOrImportedCount }) => {
  const percentage =
    (autopilotStatus.finalStepCompletedCount / requestedOrImportedCount) * 100;
  const value = (percentage >= 100 ? 99 : percentage) || 1;

  return (
    <CircularProgress value={value} color="trueBlue">
      <CircularProgressLabel
        color="trueBlue"
        fontWeight="500"
        fontSize="11px"
        lineHeight="14px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {value.toFixed(0)}%
      </CircularProgressLabel>
    </CircularProgress>
  );
};

const calculateWidthsForSteps = (steps, minWidth = 40, maxWidth = 100) => {
  if (!steps.length) return [];

  // Find the maximum value in the steps to use as the scaling factor.
  const maxValue = steps.reduce((max, step) => Math.max(max, step.value), 0);

  let initialWidth = maxWidth;
  if (maxValue > 0) {
    // Scale the first step's width based on its value relative to the maxValue.
    let scale = steps[0].value / maxValue;
    initialWidth = minWidth + (maxWidth - minWidth) * scale;
  }

  let widths = [Math.min(maxWidth, initialWidth)];
  let currentWidth = initialWidth;

  // Dynamically adjust the widths for subsequent steps while maintaining the shape.
  for (let i = 1; i < steps.length; i++) {
    currentWidth *= 0.9; // Reduce width by 10% from the top width of the previous step.
    widths.push(Math.max(minWidth, currentWidth)); // Ensure minWidth is respected.
  }

  // Re-adjust if necessary to ensure the last step is not below minWidth.
  // This part ensures that even if the scaling puts the last step below minWidth due to the 90% reduction,
  // adjustments are made to respect the minWidth.
  let adjustmentFactor = minWidth / widths[widths.length - 1];
  if (adjustmentFactor > 1) {
    for (let i = 0; i < widths.length; i++) {
      widths[i] *= adjustmentFactor;
    }
  }

  // Assign the calculated widths to steps, rounding for cleanliness.
  return steps.map((step, index) => ({
    ...step,
    width: Math.round(widths[index]),
  }));
};

const AutopilotCard = ({
  idx,
  finalStepName,
  firstStepName,
  requestedOrImportedCount,
  autopilotId,
  qualificationMinScore,
  finalStepCompletedCount,
  tripleCheckedCount,
  qualifiedCount,
  totalProcessedCount,
  status,
  createdAt,
  scheduledAt,
  completedAt,
  skippedAt,
  failedAt,
  totalAutopilotCount,
  cancelledAt,
  name,
  steps,
}) => {
  const { handleAsyncError, handleSyncError } = useErrorHandler();

  const user = useSelector(getUser);

  const {
    isOpen: isConfirmationAlertOpen,
    onOpen: onConfirmationAlertOpen,
    onClose: onConfirmationAlertClose,
  } = useDisclosure();

  const [skipAutopilot] = useSkipAutopilotMutation();
  const [deleteAutopilot] = useDeleteAutopilotMutation();
  const [cancelAutopilot] = useCancelAutopilotMutation();
  const [getExportAutopilotCSV, { isLoading, isFetching }] =
    useGetExportAutopilotCSVMutation();
  const [exportContacts, { isLoading: isExportContactsLoading }] =
    useExportAutopilotContactsMutation();
  const [autopilotStatus, setAutopilotStatus] = useState({
    totalProcessedCount,
    qualifiedCount,
    tripleCheckedCount,
    finalStepCompletedCount,
    requestedOrImportedCount,
    steps,
    status,
    scheduledAt,
    completedAt,
    skippedAt,
    failedAt,
    cancelledAt,
  });

  const progressSteps = useMemo(
    () => [
      {
        text: firstStepName === 'search' ? 'Search' : 'Import',
        bg: 'trueGrayLabel',
        label:
          firstStepName === 'import'
            ? 'leads were imported'
            : 'leads found by truebase',
        value: autopilotStatus.totalProcessedCount,
      },
      {
        text: 'Enrich',
        bg: 'trueGrayLabel',
        label: 'leads were enriched',
        value: autopilotStatus.tripleCheckedCount,
        skipped: firstStepName !== 'import',
      },
      {
        text: 'Filter',
        bg: 'trueDim',
        label: 'leads matched your ICP filters',
        value: autopilotStatus.totalProcessedCount,
        skipped:
          firstStepName === 'import' &&
          !autopilotStatus?.steps?.filter?.companyFilterStatements &&
          !autopilotStatus?.steps?.filter?.personFilterStatements,
      },
      {
        text: 'Qualify',
        bg: 'trueMedium',
        label: `leads passed the ${(qualificationMinScore * 100).toFixed(
          0
        )}% minimum qualification score`,
        value: autopilotStatus.qualifiedCount,
        skipped: isUndefined(qualificationMinScore),
      },
      {
        text: 'Waterfall contact verification',
        bg: 'trueDim',
        label: 'leads passed our triple-check contact verification process',
        value: autopilotStatus.tripleCheckedCount,
        skipped: !autopilotStatus?.steps?.enrich?.verification?.length,
      },
      {
        text: finalStepName === 'export' ? 'Export' : 'Engage',
        bg: 'trueGrass',
        label:
          finalStepName === 'export'
            ? 'leads are ready to be exported'
            : 'leads were personalized and scheduled for outreach',
        value: autopilotStatus.finalStepCompletedCount,
      },
    ],
    [autopilotStatus, finalStepName, firstStepName, qualificationMinScore]
  );

  const progressStepsWithWidth = useMemo(
    () =>
      calculateWidthsForSteps(progressSteps.filter((step) => !step.skipped)),
    [progressSteps]
  );

  const getFormattedDate = useCallback(() => {
    const date =
      completedAt || failedAt ? createdAt : cancelledAt || scheduledAt;

    return date ? format(new Date(date), 'EEEE, MMM d, yyyy, h:mm a, zzz') : '';
  }, [cancelledAt, completedAt, createdAt, failedAt, scheduledAt]);

  const getStatusTextColor = useCallback(() => {
    if (
      autopilotStatus.completedAt ||
      autopilotStatus.failedAt ||
      autopilotStatus.cancelledAt
    ) {
      return 'trueExplain';
    }
    return autopilotStatus.status === 'inProgress' ? '#3340C9' : '#FDA522';
  }, [autopilotStatus]);

  const getStatusText = useCallback(() => {
    if (autopilotStatus.status === 'inProgress') {
      return (
        <TimeRemaining
          createdAt={createdAt}
          autopilotStatus={autopilotStatus}
          requestedOrImportedCount={requestedOrImportedCount}
        />
      );
    }
    if (autopilotStatus.completedAt || autopilotStatus.failedAt) {
      return formatDistanceToNow(new Date(createdAt), { addSuffix: true });
    }
    if (autopilotStatus.cancelledAt) {
      return formatDistanceToNow(new Date(cancelledAt), { addSuffix: true });
    }
    if (scheduledAt) {
      return `${skippedAt ? 'Skipped' : 'Scheduled'} for ${format(
        new Date(scheduledAt),
        'EEEE'
      )} next week`;
    }

    return null;
  }, [
    autopilotStatus,
    cancelledAt,
    createdAt,
    requestedOrImportedCount,
    scheduledAt,
    skippedAt,
  ]);

  useEffect(() => {
    const handleAutopilotProgress = handleSyncError(
      ({ steps, autopilotId: _id }) => {
        if (autopilotId === _id) {
          const autopilot = { steps };
          const autopilotStats = getAutopilotStats(autopilot);
          setAutopilotStatus((prev) => ({
            ...prev,
            totalProcessedCount: autopilotStats.totalProcessedCount,
            qualifiedCount: autopilotStats.qualifiedCount,
            tripleCheckedCount: autopilotStats.tripleCheckedCount,
            finalStepCompletedCount: autopilotStats.finalStepCompletedCount,
            status: 'inProgress',
            scheduledAt: false,
            skippedAt: false,
          }));
        }
      }
    );

    const handleAutopilotFinished = handleSyncError(
      ({ steps, autopilotId: _id, status, completedAt, failedAt }) => {
        if (autopilotId === _id) {
          const autopilot = { steps };
          const autopilotStats = getAutopilotStats(autopilot);
          setAutopilotStatus((prev) => ({
            ...prev,
            totalProcessedCount: autopilotStats.totalProcessedCount,
            qualifiedCount: autopilotStats.qualifiedCount,
            tripleCheckedCount: autopilotStats.tripleCheckedCount,
            finalStepCompletedCount: autopilotStats.finalStepCompletedCount,
            status,
            ...(completedAt && { completedAt }),
            ...(failedAt && { failedAt }),
          }));
        }
      }
    );

    const handleAutopilotCompleted = (notificationData) => {
      handleAutopilotFinished({
        ...notificationData,
        status: 'done',
        completedAt: true,
      });
    };

    const handleAutopilotFailed = (notificationData) => {
      handleAutopilotFinished({
        ...notificationData,
        status: 'done',
        failedAt: true,
      });
    };

    socket.on('autopilot-inProgress', handleAutopilotProgress);
    socket.on('autopilot-completed', handleAutopilotCompleted);
    socket.on('autopilot-failed', handleAutopilotFailed);

    return () => {
      socket.off('autopilot-inProgress', handleAutopilotProgress);
      socket.off('autopilot-completed', handleAutopilotCompleted);
      socket.off('autopilot-failed', handleAutopilotFailed);
    };
  }, [
    handleSyncError,
    totalProcessedCount,
    qualifiedCount,
    tripleCheckedCount,
    finalStepCompletedCount,
    autopilotId,
  ]);

  return (
    <AccordionItem border="none" overflow="hidden" py="6px">
      {({ isExpanded }) => (
        <>
          <AccordionButton _hover={{}} _focus={{}} _active={{}} p={0}>
            <Flex
              justifyContent="space-between"
              w="100%"
              bg={
                autopilotStatus.completedAt ||
                autopilotStatus.failedAt ||
                autopilotStatus.cancelledAt
                  ? 'white'
                  : 'trueBg'
              }
              borderWidth="1px"
              borderStyle="solid"
              borderColor="trueLight"
              borderRadius="4px"
              borderBottomRadius={isExpanded ? '1px' : '4px'}
              p="16px"
              cursor={autopilotStatus.cancelledAt ? 'default' : 'pointer'}
            >
              <Flex alignItems="center" gap="16px" maxH={'65px'}>
                {autopilotStatus.status === 'inProgress' ? (
                  <PercentProgress
                    autopilotStatus={autopilotStatus}
                    requestedOrImportedCount={requestedOrImportedCount}
                  />
                ) : (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    width="32px"
                    height="32px"
                  >
                    <AutopilotCardIcon />
                  </Flex>
                )}

                <Box gap="4px" textAlign={'left'}>
                  {name ? (
                    <Text
                      fontSize="14px"
                      lineHeight="16px"
                      fontWeight="500"
                      color="trueSpace"
                    >
                      {name}
                    </Text>
                  ) : (
                    <Flex gap="3px">
                      <Text
                        fontSize="14px"
                        lineHeight="16px"
                        fontWeight="500"
                        color="trueSpace"
                      >
                        #{totalAutopilotCount - idx}
                      </Text>
                      <Text fontSize="14px" lineHeight="16px" color="trueSpace">
                        •
                      </Text>
                      <Text
                        fontSize="14px"
                        lineHeight="16px"
                        fontWeight="500"
                        color="trueSpace"
                      >
                        {capitalize(finalStepName)}
                      </Text>
                      <Text fontSize="14px" lineHeight="16px" color="trueSpace">
                        •
                      </Text>
                      <Text
                        fontSize="14px"
                        lineHeight="16px"
                        fontWeight="500"
                        color="trueSpace"
                      >
                        {requestedOrImportedCount} leads
                      </Text>
                    </Flex>
                  )}

                  <TruebaseTooltip label={getFormattedDate()}>
                    <Text
                      fontSize="14px"
                      lineHeight="26px"
                      color={getStatusTextColor()}
                      cursor="pointer"
                      w="fit-content"
                    >
                      {getStatusText()}
                    </Text>
                  </TruebaseTooltip>
                </Box>
              </Flex>
              <Flex alignItems="center" gap="16px">
                <TruebaseTooltip
                  variant="truebase"
                  label={
                    autopilotStatus.status === 'inProgress'
                      ? `We'll email you once the autopilot is done`
                      : ''
                  }
                >
                  <Box
                    borderRadius="24px"
                    borderColor="trueLight"
                    borderStyle="solid"
                    borderWidth="1px"
                    padding="5px 32px"
                    fontSize="11px"
                    lineHeight="14px"
                    fontWeight={500}
                    color={
                      autopilotStatus.status === 'inProgress'
                        ? 'trueBlue'
                        : autopilotStatus.completedAt ||
                          autopilotStatus.scheduledAt ||
                          autopilotStatus.cancelledAt
                        ? 'trueExplain'
                        : 'trueAttention'
                    }
                  >
                    {autopilotStatus.completedAt
                      ? 'Completed'
                      : autopilotStatus.failedAt
                      ? 'Failed'
                      : autopilotStatus.skippedAt
                      ? 'Skipped'
                      : autopilotStatus.scheduledAt
                      ? 'Scheduled'
                      : autopilotStatus.cancelledAt
                      ? 'Cancelled'
                      : autopilotStatus.status === 'inProgress'
                      ? 'In Progress'
                      : ''}
                  </Box>
                </TruebaseTooltip>
                {autopilotStatus.completedAt && (
                  <TruebaseTooltip
                    label={
                      autopilotStatus.finalStepCompletedCount
                        ? 'View Leads'
                        : 'Autopilot completed but the outcome is 0 leads'
                    }
                  >
                    <IconButton
                      minW={0}
                      w="32px"
                      h="32px"
                      variant="truebaseText"
                      sx={{ svg: { fill: 'trueMedium' } }}
                      as={
                        autopilotStatus.finalStepCompletedCount ? Link : 'span'
                      }
                      to={`/contacts?autopilot=${autopilotId}`}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      isDisabled={!autopilotStatus.finalStepCompletedCount}
                    >
                      <EyeIcon />
                    </IconButton>
                  </TruebaseTooltip>
                )}
                {autopilotStatus.completedAt && (
                  <TruebaseTooltip
                    label={
                      autopilotStatus.finalStepCompletedCount
                        ? 'Download CSV'
                        : 'Autopilot completed but the outcome is 0 leads'
                    }
                  >
                    <IconButton
                      minW={0}
                      w="32px"
                      h="32px"
                      variant="truebaseText"
                      onClick={handleAsyncError(async (e) => {
                        e.stopPropagation();
                        if (autopilotStatus.finalStepCompletedCount) {
                          let res;
                          if (finalStepName === 'export') {
                            res = await getExportAutopilotCSV({
                              autopilotId,
                              teamId: user?.teamId,
                            }).unwrap();
                          } else {
                            res = await exportContacts({
                              teamId: user?.teamId,
                              autopilotId,
                            }).unwrap();
                          }
                          window.open(res.result.url, '_blank');
                        }
                      })}
                      sx={{ svg: { fill: 'trueMedium' } }}
                      as="span"
                      isDisabled={!autopilotStatus.finalStepCompletedCount}
                    >
                      {isLoading || isFetching || isExportContactsLoading ? (
                        <Spinner />
                      ) : (
                        <DownloadIcon />
                      )}
                    </IconButton>
                  </TruebaseTooltip>
                )}
                {(((autopilotStatus.scheduledAt || autopilotStatus.skippedAt) &&
                  autopilotStatus.status === 'notStarted') ||
                  autopilotStatus.status === 'inProgress') && (
                  <DropdownToggle
                    showOnHover={false}
                    options={[
                      ...(autopilotStatus.status === 'notStarted'
                        ? [
                            {
                              label: `${
                                autopilotStatus.skippedAt ? 'Unskip' : 'Skip'
                              } this Autopilot`,
                              onClick: (e) => {
                                e.stopPropagation();
                                skipAutopilot({
                                  autopilotId,
                                  action: autopilotStatus.skippedAt
                                    ? 'unskip'
                                    : 'skip',
                                });
                              },
                              icon: <SkipIcon />,
                            },
                          ]
                        : []),
                      {
                        label:
                          autopilotStatus.status === 'inProgress'
                            ? 'Cancel Autopilot'
                            : 'Cancel repeating Autopilot',
                        onClick: (e) => {
                          e.stopPropagation();
                          autopilotStatus.status === 'inProgress'
                            ? onConfirmationAlertOpen()
                            : deleteAutopilot({
                                autopilotId,
                                teamId: user?.teamId,
                              });
                        },
                        icon: <RemoveIcon />,
                      },
                    ]}
                  />
                )}
                {isConfirmationAlertOpen && (
                  <Suspense fallback={null}>
                    <LazyTruebaseConfirmationAlertDialog
                      isOpen={isConfirmationAlertOpen}
                      onConfirm={() => {
                        cancelAutopilot({ autopilotId });
                      }}
                      onClose={onConfirmationAlertClose}
                      confirmationText={`Cancelling this autopilot will make you lose all leads found by this autopilot.\nAre you sure you want to continue?`}
                    />
                  </Suspense>
                )}
              </Flex>
            </Flex>
          </AccordionButton>
          {!autopilotStatus?.cancelledAt && (
            <AnimatePresence>
              <motion.div
                initial={{ y: 248, height: 0, opacity: 0 }}
                animate={{ y: 0, height: 'auto', opacity: 1 }}
                exit={{ y: 248, height: 0, opacity: 0 }}
                transition={{ duration: 0.5, ease: 'linear' }}
                key={autopilotId}
              >
                <AccordionPanel
                  borderWidth="1px"
                  borderStyle="solid"
                  borderColor="trueLight"
                  borderRadius="4px"
                  borderTop="none"
                  borderTopRadius="1px"
                  bg={
                    autopilotStatus.completedAt ||
                    autopilotStatus.failedAt ||
                    autopilotStatus.cancelledAt
                      ? 'white'
                      : 'trueBg'
                  }
                  p="16px"
                  key={autopilotId}
                >
                  <VStack
                    alignItems="flex-start"
                    spacing="0"
                    divider={
                      <Box
                        sx={{ borderBottomWidth: '0px !important' }}
                        height="12px"
                      >
                        <Divider
                          marginLeft="15.5px"
                          orientation="vertical"
                          borderColor="trueDim"
                          borderStyle="dashed"
                        />
                      </Box>
                    }
                  >
                    {progressStepsWithWidth
                      .filter((step) => !step.skipped)
                      .map((step, index) => (
                        <Flex key={step.text} gap="16px" alignItems="center">
                          <Flex
                            alignItems="center"
                            justifyContent="center"
                            px="3px"
                          >
                            <Center
                              w="26px"
                              h="26px"
                              borderRadius="100px"
                              padding="10px"
                              bgColor="trueSpace"
                              lineHeight="14px"
                              fontSize="11px"
                              fontWeight={500}
                              color="white"
                            >
                              {index + 1}
                            </Center>
                          </Flex>
                          <Text
                            minW="135px"
                            fontWeight={500}
                            fontSize="13px"
                            lineHeight="16px"
                            color="trueSpace"
                            w="168px"
                          >
                            {step.text}
                          </Text>
                          <TruebaseTooltip
                            key={index}
                            label={`${step.value} ${step.label}`}
                            placement="right"
                            hasArrow
                          >
                            <Box>
                              <FunnelStep
                                value={step.value}
                                width={step.width}
                                bg={step.bg}
                              />
                            </Box>
                          </TruebaseTooltip>
                        </Flex>
                      ))}
                  </VStack>
                </AccordionPanel>
              </motion.div>
            </AnimatePresence>
          )}
        </>
      )}
    </AccordionItem>
  );
};

export default AutopilotCard;
