import { useState } from 'react';
import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import LeadPersonalizeSequence from './LeadPersonalizeSequence';
import AccountTab from './AccountTab';
import { useFilterContext } from '~/v2/features/filters/components/useFilterContext';
import LeadInsights from './LeadInsights';
import TruebaseBetaTag from '../../TruebaseBetaTag';
import AccountInsights from '../TruebaseAccountAccordion/AccountInsights';
import { getSelectedIcp } from '~/v2/redux/slices/icpSlice';

export default function LeadTabs({
  readonly,
  companyFilters,
  showHitsAndMisses,
  lead,
  account,
}) {
  const [tabIndex, setTabIndex] = useState(0);
  const selectedIcp = useSelector(getSelectedIcp);
  const { trainView } = useFilterContext();
  const isPersonalizationView = trainView === 'personalization';

  return (
    <Tabs
      position="relative"
      variant="truebase"
      _css={{ '& .chakra-tabs__tab': { borderBottom: 'unset' } }}
      index={tabIndex}
      onChange={(index) => setTabIndex(index)}
    >
      <TabList>
        {isPersonalizationView && (
          <Tab>
            <Flex gap="8px" alignItems="center">
              <span>Personalized Sequence</span>
              <TruebaseBetaTag
                isActive={
                  isPersonalizationView ? tabIndex === 1 : tabIndex === 0
                }
              />
            </Flex>
          </Tab>
        )}
        {isPersonalizationView && <Tab>Account</Tab>}
        {isPersonalizationView && <Tab>Account Insights</Tab>}
        <Tab>
          <Flex gap="8px" alignItems="center">
            <span>Lead Insights</span>
            <TruebaseBetaTag isActive />
          </Flex>
        </Tab>
      </TabList>
      <TabPanels>
        {isPersonalizationView && (
          <TabPanel p="16px 0 0 0">
            <LeadPersonalizeSequence
              readonly={readonly}
              account={account}
              lead={lead}
            />
          </TabPanel>
        )}
        {isPersonalizationView && (
          <TabPanel p="unset">
            <AccountTab
              readonly={readonly}
              icp={selectedIcp}
              lead={lead}
              account={account}
              showHitsAndMisses={showHitsAndMisses}
              companyFilters={companyFilters}
            />
          </TabPanel>
        )}
        {isPersonalizationView && (
          <TabPanel>
            <AccountInsights readonly={readonly} account={account} />
          </TabPanel>
        )}
        <TabPanel p="16px 0 0 0">
          <LeadInsights readonly={readonly} account={account} lead={lead} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
