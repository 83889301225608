import { pick, isEqual, get, groupBy } from 'lodash';
import socket from '~/v2/utility/socket';
import { apiSlice } from './api';
import { createStandaloneToast } from '@chakra-ui/react';
import { sleep } from '~/utils';
import { buildSearchParams } from '~/v2/common/utils';
const { toast } = createStandaloneToast();
import { NotificationQueue } from '~/v2/features/filters/components/utils';

const queue = new NotificationQueue();

const mapFunction = (entityType) => {
  switch (entityType) {
    case 'company':
      return (filterArr) =>
        filterArr.map((filter) => ({
          _id: filter._id.toString(),
          include: filter.include,
        }));
    case 'person':
      return (filterArr) =>
        filterArr.map((filter) => {
          if (filter.freeForm) {
            return {
              ...(filter.includeMongoQuery && {
                includeMongoQuery: filter.includeMongoQuery,
              }),
              ...(filter.excludeMongoQuery && {
                excludeMongoQuery: filter.excludeMongoQuery,
              }),
              include: filter.include,
            };
          } else {
            return {
              _id: filter._id.toString(),
              include: filter.include,
            };
          }
        });

    default:
      throw new Error('Invalid entity type');
  }
};

const prepareFiltersForBackend = (filters, entityType) => {
  return filters ? filters.map(mapFunction(entityType)) : [];
};

export const accountsApi = apiSlice.injectEndpoints({
  injectTags: ['Contacts'],
  endpoints: (builder) => ({
    loadMoreLeads: builder.query({
      query: ({ icpId, companyId }) => ({
        url: `icps/${icpId}/startMatcher`,
        method: 'POST',
        body: { companyId },
      }),
    }),
    getLeadsForAccount: builder.query({
      async queryFn(args, queryApi, extraOptions, baseQuery) {
        const params = [
          { key: 'companyId', value: args.companyId, required: true },
          { key: 'teamId', value: args.teamId, required: true },
          { key: 'status', condition: args.readonly, value: 'accepted' },
        ];

        const retryAttempts = args.readonly ? 0 : 5;
        for (let i = 0; i <= retryAttempts; i++) {
          if (i > 0) await sleep(i * 600);

          let response = await baseQuery({
            url: 'leads?' + buildSearchParams(params).toString(),
            method: 'GET',
          });

          if (!get(response, 'data.result.matches', []).length) {
            return {
              data: {
                ...response.data,
                isLoading: false,
              },
            };
          } else return { data: { ...response.data, isLoading: false } };
        }

        return { error: 'No leads found.' };
      },
      async onCacheEntryAdded(
        args,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) {
        const onPersonalizationUpdated = async ({
          loading,
          insights,
          sequenceInsights,
          sequence,
          identifier,
        }) => {
          await updateCachedData((draft) => {
            if (!loading) {
              draft.result.matches = draft.result.matches.map((lead) => {
                if (lead.personId === identifier) {
                  lead = {
                    ...lead,
                    insights: { ...lead.insights, ...insights },
                    sequenceInsights: {
                      ...lead.sequenceInsights,
                      ...sequenceInsights,
                    },
                    sequence: {
                      ...lead.sequence,
                      ...sequence,
                    },
                  };
                }
                return lead;
              });
            }
          });
        };
        const onMatcherInProgress = ({ leads = [] }) => {
          updateCachedData((draft) => {
            draft.isLoading = true;
            if (leads.length) {
              const companyId = leads.map((lead) => lead.companyId)[0];
              const leadIds = draft.result.matches.map((lead) => lead._id);
              const newLeads = leads.filter(
                (lead) => !leadIds.includes(lead._id)
              );

              if (
                draft.result.matches.find(
                  (lead) => lead.companyId === companyId
                )
              ) {
                draft.result.matches.push(...newLeads);
              }
            }
          });
        };
        const onMatcherCompleted = async ({
          personMatchesCount,
          companyId,
        }) => {
          await updateCachedData((draft) => {
            draft.isLoading = false;
            if (
              draft.result.matches.filter(
                (lead) => lead.companyId === companyId
              ).length > 0
            ) {
              draft.result.noLeadsFound = personMatchesCount === 0;
            }
          });
        };
        const onEmailCompleted = async ({
          leadId,
          workEmails,
          personalEmails,
          workPhones,
          personalPhones,
          contactOutdated,
          outdatedReason,
        }) => {
          dispatch(
            apiSlice.util.updateQueryData('search', { icpId: args.icpId }),

            (draft) => {
              draft.result.matches = draft.result.matches.map((lead) => {
                return lead._id === leadId
                  ? {
                      ...lead,
                      emailDiscoveryStatus: 'completed',
                      workEmails,
                      workPhones,
                      personalEmails,
                      personalPhones,
                      contactOutdated,
                      outdatedReason,
                    }
                  : lead;
              });
            }
          );
          await updateCachedData((draft) => {
            draft.result.matches = draft.result.matches.map((lead) =>
              lead._id === leadId
                ? {
                    ...lead,
                    workEmails,
                    workPhones,
                    personalEmails,
                    personalPhones,
                    contactOutdated,
                    outdatedReason,
                  }
                : lead
            );
          });
        };

        const onEmailInProgress = async ({ leadId, personId, message }) => {
          await updateCachedData((draft) => {
            draft.result.matches = draft.result.matches.map((lead) =>
              lead._id === leadId || lead.personId === personId
                ? {
                    ...lead,
                    emailDiscoveryStatus: message,
                  }
                : lead
            );
          });
        };
        const onEmailFailed = async ({ leadId }) => {
          await updateCachedData((draft) => {
            draft.result.matches = draft.result.matches.map((lead) =>
              lead._id === leadId
                ? {
                    ...lead,
                    emailDiscoveryStatus: 'failed',
                  }
                : lead
            );
          });
        };

        try {
          await cacheDataLoaded;
          socket.on(
            'personalizationUpdated-completed',
            onPersonalizationUpdated
          );
          socket.on('matcher-inProgress', onMatcherInProgress);
          socket.on('matcher-completed', onMatcherCompleted);
          socket.on('email-completed', onEmailCompleted);
          socket.on('email-inProgress', onEmailInProgress);
          socket.on('email-failed', onEmailFailed);
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        await cacheEntryRemoved;
        socket.off(
          'personalizationUpdated-completed',
          onPersonalizationUpdated
        );
        socket.off('matcher-inProgress', onMatcherInProgress);
        socket.off('matcher-completed', onMatcherCompleted);
        socket.off('email-completed', onEmailCompleted);
        // socket.off('email-inProgress', onEmailInProgress);
        socket.off('email-failed', onEmailFailed);
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            apiSlice.util.updateQueryData(
              'search',
              {
                icpId: args.icpId,
                leadFilters: args.leadFilters?.map((lfs) =>
                  lfs.map((lf) => lf._id)
                ),
                companyFilters: args.companyFilters?.map((cfs) =>
                  cfs.map((cf) => cf._id)
                ),
                searchExclusivity: args.searchExclusivity,
                requestId: args.requestId,
              },
              (draft) => {
                draft.result.accounts = draft.result.accounts.map((account) => {
                  if (account.companyId === args.companyId) {
                    const leadsByPersonId = data.result.matches.reduce(
                      (acc, lead) => {
                        acc[lead.personId] = lead;
                        return acc;
                      },
                      {}
                    );
                    return {
                      ...account,
                      leads: account.leads.map((lead) => ({
                        ...lead,
                        leadId: leadsByPersonId[lead.personId]._id,
                        ...leadsByPersonId[lead.personId],
                      })),
                    };
                  }
                  return account;
                });
              }
            )
          );
        } catch (err) {
          const { requestId } = args;
          const { error } = err;
          if (!toast.isActive(`getLeadsForAccount-${requestId}`)) {
            toast({
              id: `getLeadsForAccount-${requestId}`,
              title: 'Error',
              description: error,
              status: 'error',
              duration: 3000,
              isClosable: false,
            });
          }
        }
      },
    }),
    createLead: builder.mutation({
      async queryFn(args, queryApi, extraOptions, baseQuery) {
        const response = await baseQuery({
          url: 'leads',
          method: 'POST',
          body: {
            icpId: args.icpId,
            personCompaniesMap: [
              {
                personIds: [args.personId],
                companyId: args.companyId,
                status: args.status,
              },
            ],
          },
        });
        return response;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        let searchPatchStatusResult,
          searchPatchAccountIdResult,
          getLeadsForAccountResult;
        const onCreateUpdateLeadsInProgress = (batchUpdate) => {
          const batchCompanyUpdateMap = groupBy(batchUpdate, 'companyId');
          const batchPersonUpdateMap = groupBy(batchUpdate, 'personId');
          searchPatchAccountIdResult = dispatch(
            apiSlice.util.updateQueryData(
              'search',
              {
                icpId: args.icpId,
                leadFilters: args.leadFilters?.map((lfs) =>
                  lfs.map((lf) => lf._id)
                ),
                companyFilters: args.companyFilters?.map((cfs) =>
                  cfs.map((cf) => cf._id)
                ),
                searchExclusivity: args.searchExclusivity,
                requestId: args.requestId,
                companyLinkedinUrl: args.companyLinkedinUrl,
              },
              (draft) => {
                draft.result.accounts = draft.result.accounts.map(
                  (resultAccount) => {
                    if (batchCompanyUpdateMap[resultAccount.companyId]) {
                      const [{ accountId, leadId, personId }] =
                        batchCompanyUpdateMap[resultAccount.companyId];
                      return {
                        ...resultAccount,
                        _id: resultAccount._id || accountId || undefined,
                        leads: resultAccount.leads.map((lead) => {
                          if (lead.personId === personId) {
                            return {
                              ...lead,
                              _id: leadId,
                              leadId: leadId,
                              accountId:
                                lead.accountId || accountId || undefined,
                            };
                          }
                          return lead;
                        }),
                      };
                    }
                    return resultAccount;
                  }
                );
              }
            )
          );
          getLeadsForAccountResult = dispatch(
            apiSlice.util.updateQueryData(
              'getLeadsForAccount',
              {
                companyId: args.companyId,
                icpId: args.icpId,
                leadFilters: args.leadFilters,
                companyFilters: args.companyFilters,
                searchExclusivity: args.searchExclusivity,
                requestId: args.requestId,
              },
              (draft) => {
                draft.result.matches = draft.result.matches.map((lead) => {
                  if (batchPersonUpdateMap[lead.personId]) {
                    const [{ leadId, status }] =
                      batchPersonUpdateMap[lead.personId];
                    lead._id = leadId;
                    lead.leadId = leadId;
                    lead.status = status;
                    lead.updatedByFullname = args.updatedByFullname;
                  }
                  return lead;
                });
              }
            )
          );
        };
        try {
          socket.on(
            'createUpdateLeads-inProgress',
            onCreateUpdateLeadsInProgress
          );
          await queryFulfilled;
          searchPatchStatusResult = dispatch(
            apiSlice.util.updateQueryData(
              'search',
              {
                icpId: args.icpId,
                leadFilters: args.leadFilters?.map((lfs) =>
                  lfs.map((lf) => lf._id)
                ),
                companyFilters: args.companyFilters?.map((cfs) =>
                  cfs.map((cf) => cf._id)
                ),
                searchExclusivity: args.searchExclusivity,
                requestId: args.requestId,
                companyLinkedinUrl: args.companyLinkedinUrl,
              },
              (draft) => {
                draft.result.accounts = draft.result.accounts.map(
                  (resultAccount) =>
                    resultAccount.companyId === args.companyId
                      ? {
                          ...resultAccount,
                          status:
                            args.status === 'accepted'
                              ? 'accepted'
                              : resultAccount.status || 'suggested',
                          updatedByFullname: args.updatedByFullname,
                          leads: resultAccount.leads.map((lead) => {
                            if (lead.personId === args.personId) {
                              return {
                                ...lead,
                                status: args.status,
                                updatedByFullname: args.updatedByFullname,
                              };
                            }
                            return lead;
                          }),
                        }
                      : resultAccount
                );
              }
            )
          );
          socket.off(
            'createUpdateLeads-inProgress',
            onCreateUpdateLeadsInProgress
          );
        } catch (e) {
          searchPatchStatusResult.undo();
          searchPatchAccountIdResult?.undo();
          getLeadsForAccountResult?.undo();
        }
      },
      invalidatesTags: ['Contacts'],
    }),
    search: builder.query({
      async queryFn(args, queryApi, extraOptions, baseQuery) {
        if (!args.icpId) {
          return {
            data: {
              ...args,
              result: { accounts: [] },
              newAccounts: 0,
            },
          };
        }
        const stateSlice = accountsApi.endpoints.search.select(args)(
          queryApi.getState()
        );
        const excludedCompanyIds = stateSlice?.data?.result?.accounts?.map(
          (account) => account.companyId
        );
        if (excludedCompanyIds?.length) {
          queryApi.dispatch(
            apiSlice.util.updateQueryData(
              'search',
              {
                icpId: args.icpId,
                leadFilters: args.leadFilters?.map((lfs) =>
                  lfs.map((lf) => lf._id)
                ),
                companyFilters: args.companyFilters?.map((cfs) =>
                  cfs.map((cf) => cf._id)
                ),
                searchExclusivity: args.searchExclusivity,
                requestId: args.requestId,
              },
              (draft) => {
                draft.result.accounts = draft.result.accounts.filter(
                  ({ status }) => !status
                );
              }
            )
          );
        }
        const response = await baseQuery({
          url: `icps/${args.icpId}/startSearch`,
          method: 'POST',
          body: {
            excludedCompanyIds: excludedCompanyIds,
            companyFilterStatements: prepareFiltersForBackend(
              args.companyFilters,
              'company'
            ),
            personFilterStatements: prepareFiltersForBackend(
              args.leadFilters,
              'person'
            ),
            searchExclusivity: args.searchExclusivity,
            limit: args.limit,
            companyLinkedinUrl: args.companyLinkedinUrl?.toLowerCase(),
            qualificationQuestions: args.qualificationQuestions,
            personalization: args.personalization,
            type: args.type,
          },
        });
        return {
          data: {
            ...response,
            previousArgs: args,
            ...(response.data.isOk && {
              result: { accounts: [] },
              newAccounts: 0,
            }),
          },
        };
      },
      serializeQueryArgs: (args) => {
        const qArgs = {
          ...args.queryArgs,
          companyFilters: args.queryArgs.companyFilters?.map((cfs) =>
            cfs.map((cf) => cf._id || cf)
          ),
          leadFilters: args.queryArgs.leadFilters?.map((lfs) =>
            lfs.map((lf) => lf._id || lf)
          ),
          requestId: args.queryArgs.requestId?.toString(),
          searchExclusivity: args.searchExclusivity,
          limit: undefined,
          excludedCompanyIds: undefined,
        };
        const sortedQArgs = Object.keys(qArgs)
          .sort()
          .reduce(
            (acc, key) => ({
              ...acc,
              [key]: qArgs[key],
            }),
            {}
          );
        return JSON.stringify(sortedQArgs);
      },
      merge: (existing, incoming) => {
        return {
          ...existing,
          newAccounts: undefined,
          result: {
            ...existing.result,
            accounts: [
              ...existing.result.accounts,
              ...incoming.result.accounts,
            ],
          },
        };
      },
      async onCacheEntryAdded(
        args,
        {
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          getCacheEntry,
          dispatch,
        }
      ) {
        const onMatcherInProgress = async ({ leads = [], accountId }) => {
          await updateCachedData((draft) => {
            if (accountId) {
              const account = draft.result.accounts.find(
                (acc) => acc._id === accountId
              );
              account.isLoading = true;
            }
            if (leads.length) {
              const firstLead = leads[0];
              const account = draft.result.accounts.find(
                (acc) => acc.companyId === firstLead.companyId
              );
              const leadIds = account.leads.map((lead) => lead._id);
              const newLeads = leads.filter(
                (lead) => !leadIds.includes(lead._id)
              );
              account.leads.push(...newLeads);
              account.isLoading = true;
            }
          });
        };
        const onMatcherCompleted = async ({
          personMatchesCount,
          companyId,
        }) => {
          await updateCachedData((draft) => {
            const account = draft.result.accounts.find(
              (acc) => acc.companyId === companyId
            );
            account.isLoading = false;
            account.noLeadsFound = personMatchesCount === 0;
          });
        };
        const onCompanyOverviewComplete = async ({ message, identifier }) => {
          await updateCachedData((draft) => {
            draft.result.accounts = draft.result.accounts.map((acc) => {
              if (acc.companyId === identifier) {
                acc.companyInsights = {
                  content: message,
                  isOverwrite: false,
                };
              }
              return acc;
            });
          });
        };
        const onAccountScoringCompleted = async ({
          qualificationAnswers,
          qualificationScore,
          identifier,
        }) => {
          await updateCachedData((draft) => {
            draft.result.accounts = draft.result.accounts.map((acc) => {
              if (acc.companyId === identifier) {
                acc.qualificationAnswers = qualificationAnswers;
                acc.qualificationScore = qualificationScore;
              }
              return acc;
            });
          });
        };
        const onPersonalizationUpdated = async ({
          loading,
          insights,
          sequenceInsights,
          sequence,
          identifier,
        }) => {
          await updateCachedData((draft) => {
            if (!loading) {
              draft.result.accounts = draft.result.accounts.map((account) => {
                account.leads = account.leads.map((lead) => {
                  if (lead.personId === identifier) {
                    lead = {
                      ...lead,
                      insights: { ...lead.insights, ...insights },
                      sequenceInsights: {
                        ...lead.sequenceInsights,
                        ...sequenceInsights,
                      },
                      sequence: {
                        ...lead.sequence,
                        ...sequence,
                      },
                    };
                  }
                  return lead;
                });
                return account;
              });
            }
          });
        };
        const onSearchInProgress =
          ({ accounts, leads, type } = { accounts: [], leads: [] }) =>
          async () => {
            await updateCachedData((draft) => {
              const { previousArgs } = getCacheEntry().data;
              if (previousArgs.type !== type) {
                return;
              }
              let merge = false;
              draft.isStreamingAccounts = true;
              if (!draft.previousArgs) {
                draft.previousArgs = previousArgs;
              }
              if (isEqual(draft.previousArgs, args)) {
                merge = true;
              } else {
                merge = false;
                draft.previousArgs = args;
              }
              if (!draft.result) {
                draft.result = { accounts: [] };
              }
              if (accounts?.length === 0) {
                draft.result.accounts = merge ? draft.result.accounts : [];
                return;
              }
              const accountIds = draft.result.accounts.map(
                (account) => account.companyId
              );
              const newAccounts = accounts
                ?.filter((account) => !accountIds.includes(account.companyId))
                .map((account) => ({
                  ...account,
                  leads: leads.filter(
                    (lead) => lead.companyId === account.companyId
                  ),
                }));
              draft.newAccounts =
                (draft.newAccounts ?? 0) + newAccounts?.length;
              if (merge) {
                draft.result.accounts.push(...newAccounts);
              } else {
                draft.result.accounts = newAccounts;
              }
            });
          };
        const onSearchCompleted = () => async () => {
          await updateCachedData((draft) => {
            draft.isStreamingAccounts = false;
          });
        };
        const onSearchFailed =
          ({ error }) =>
          async () => {
            await updateCachedData((draft) => {
              draft.isStreamingAccounts = false;
            });
            !args?.companyLinkedinUrl &&
              !toast.isActive('search-failed') &&
              toast({
                id: `search-failed`,
                title: 'Search failed',
                description: error,
                duration: 3000,
              });
          };
        const onEmailCompleted = async ({
          companyId,
          personId,
          workEmails,
          personalEmails,
          workPhones,
          personalPhones,
          contactOutdated,
          outdatedReason,
        }) => {
          await updateCachedData((draft) => {
            // find account that contains leadId in leads array and update that lead
            draft.result.accounts = draft.result.accounts.map((account) => {
              if (account.companyId === companyId) {
                account.leads = account.leads.map((lead) => {
                  if (lead.personId === personId) {
                    lead.emailDiscoveryStatus = 'completed';
                    lead.workEmails = workEmails;
                    lead.personalEmails = personalEmails;
                    lead.workPhones = workPhones;
                    lead.personalPhones = personalPhones;
                    lead.contactOutdated = contactOutdated;
                    lead.outdatedReason = outdatedReason;
                  }
                  return lead;
                });
              }
              return account;
            });
          });
          dispatch(
            apiSlice.util.updateQueryData(
              'getLeadsForAccount',
              {
                companyId: companyId,
                icpId: args.icpId,
                leadFilters: args.leadFilters,
                companyFilters: args.companyFilters,
                searchExclusivity: args.searchExclusivity,
                requestId: args.requestId,
              },
              (draft) => {
                draft.result.matches = draft.result.matches.map((lead) => {
                  if (lead.personId === personId) {
                    lead.emailDiscoveryStatus = 'completed';
                    lead.workEmails = workEmails;
                    lead.personalEmails = personalEmails;
                    lead.workPhones = workPhones;
                    lead.personalPhones = personalPhones;
                    lead.contactOutdated = contactOutdated;
                    lead.outdatedReason = outdatedReason;
                  }
                  return lead;
                });
              }
            )
          );
        };
        const onEmailFailed = async ({ leadId, companyId, personId }) => {
          await updateCachedData((draft) => {
            // find account that contains leadId in leads array and update that lead
            draft.result.accounts = draft.result.accounts.map((account) => {
              account.leads = account.leads.map((lead) => {
                if (lead.leadId === leadId || lead.personId === personId) {
                  lead.emailDiscoveryStatus = 'failed';
                }
                return lead;
              });
              return account;
            });
          });

          dispatch(
            apiSlice.util.updateQueryData(
              'getLeadsForAccount',
              {
                companyId: companyId,
                icpId: args.icpId,
                leadFilters: args.leadFilters,
                companyFilters: args.companyFilters,
                searchExclusivity: args.searchExclusivity,
                requestId: args.requestId,
              },
              (draft) => {
                draft.result.matches = draft.result.matches.map((lead) => {
                  if (lead.personId === personId) {
                    lead.emailDiscoveryStatus = 'failed';
                  }
                  return lead;
                });
              }
            )
          );
        };
        const onCreateUpdateAccountsInProgress = async (batchUpdate) => {
          const batchCompanyUpdateMap = groupBy(batchUpdate, 'companyId');
          await updateCachedData((draft) => {
            draft.result.accounts = draft.result.accounts.map(
              (resultAccount) => {
                if (batchCompanyUpdateMap[resultAccount.companyId]) {
                  const [{ accountId, leadId, personId, status, leadStatus }] =
                    batchCompanyUpdateMap[resultAccount.companyId];
                  return {
                    ...resultAccount,
                    status,
                    ...(accountId && { _id: accountId }),
                    ...(personId &&
                      leadId && {
                        leads: resultAccount.leads.map((lead) => {
                          if (lead.personId === personId) {
                            return {
                              ...lead,
                              _id: leadId,
                              leadId: leadId,
                              ...(leadStatus && { status: leadStatus }),
                              ...(accountId && { accountId }),
                            };
                          }
                          return lead;
                        }),
                      }),
                  };
                }
                return resultAccount;
              }
            );
          });
        };
        try {
          await cacheDataLoaded;
          socket.on('matcher-inProgress', onMatcherInProgress);
          socket.on('matcher-completed', onMatcherCompleted);
          socket.on(
            'createUpdateAccounts-inProgress',
            onCreateUpdateAccountsInProgress
          );

          socket.on('companyInsights-completed', onCompanyOverviewComplete);
          socket.on('accountScoring-completed', onAccountScoringCompleted);
          socket.on(
            'personalizationUpdated-completed',
            onPersonalizationUpdated
          );
          socket.on('search-inProgress', (data) => {
            queue.enqueue(onSearchInProgress(data));
          });
          socket.on('search-completed', (data) => {
            queue.enqueue(onSearchCompleted(data));
          });
          socket.on('search-failed', (data) => {
            queue.enqueue(onSearchFailed(data || {}));
          });
          socket.on('email-completed', onEmailCompleted);
          socket.on('email-failed', onEmailFailed);
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        await cacheEntryRemoved;
        socket.off('matcher-completed', onMatcherCompleted);
        socket.off('matcher-inProgress', onMatcherInProgress);
        socket.off(
          'createUpdateAccounts-inProgress',
          onCreateUpdateAccountsInProgress
        );

        socket.off('companyInsights-completed', onCompanyOverviewComplete);
        socket.off('accountScoring-completed', onAccountScoringCompleted);
        socket.off(
          'personalizationUpdated-completed',
          onPersonalizationUpdated
        );
        socket.off('search-inProgress', onSearchInProgress());
        socket.off('search-completed', onSearchCompleted());
        socket.off('search-failed', onSearchFailed());
        socket.off('email-completed', onEmailCompleted);
        socket.off('email-failed', onEmailFailed);
      },
    }),
    getHitsAndMisses: builder.query({
      queryFn: async (args, queryApi, extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: `companyFilters/companies/${args.companyId}/profile`,
          method: 'POST',
          body: {
            companyId: args.companyId,
            companyFilterStatements: args.companyFilterStatements.map((cfs) =>
              cfs.map((cfs) => pick(cfs, ['_id', 'include']))
            ),
          },
        });
        return response;
      },
    }),
    startGetCompanyOverview: builder.query({
      query: ({ companyId }) => ({
        url: `companies/${companyId}/startOverview`,
        method: 'POST',
        body: {},
      }),
    }),
  }),
});

export const {
  useLazyStartGetCompanyOverviewQuery,
  useCreateLeadMutation,
  useLazyGetLeadsForAccountQuery,
  useLazyLoadMoreLeadsQuery,
  useLazySearchQuery,
  useGetHitsAndMissesQuery,
} = accountsApi;
