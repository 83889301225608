import { useRef } from 'react';
import {
  Box,
  HStack,
  Button,
  Text,
  useToast,
  CloseButton,
} from '@chakra-ui/react';
import { chakraComponents, Select } from 'chakra-react-select';
import { ReactComponent as TooltipArrow } from '~/v2/assets/tooltip arrow.svg';
import { ReactComponent as CheckDone } from '~/v2/assets/check-done.svg';
import { useRequestMembersChangeMutation } from '~/v2/redux/services/teams';
import useErrorHandler from '~/v2/hooks/useErrorHandler';

export const RequestMembersChange = () => {
  const [requestMembersChange] = useRequestMembersChangeMutation();
  const selectRef = useRef();
  const toast = useToast();
  const { handleAsyncError } = useErrorHandler();

  const handleSendRequest = handleAsyncError(async () => {
    const requestType = selectRef.current.getValue()[0].value;
    const res = await requestMembersChange(requestType).unwrap();
    if (res.isOk) {
      toast({
        duration: 5000,
        position: 'bottom',
        render: ({ onClose }) => (
          <Box bg="trueAnason" p="16px 12px" borderRadius="4px">
            <HStack spacing="16px" alignItems="center">
              <CheckDone style={{ width: '16px', height: '16px' }} />
              <Text
                sx={{
                  fontSize: '14px',
                  lineHeight: '24px',
                  fontWeight: '500',
                  color: 'trueSpace',
                }}
              >
                Message sent. We&apos;ll get back to you as soon as possible
              </Text>
              <CloseButton size="sm" onClick={onClose} />
            </HStack>
          </Box>
        ),
      });
    }
  });

  const options = [
    { value: 'add', label: 'To add a team member' },
    { value: 'remove', label: 'To remove a team member' },
  ];

  return (
    <Box>
      <HStack spacing="16px">
        <Select
          ref={selectRef}
          size="lg"
          chakraStyles={{
            control: (baseStyle, state) => ({
              ...baseStyle,
              width: '222px',
              borderColor: state.isFocused ? 'trueLink' : 'trueLight',
              boxShadow: state.isFocused
                ? '0px 0px 10px rgba(2, 128, 245, 0.25) !important'
                : 'none',
              borderRadius: '4px',
              minHeight: '40px',
            }),
            valueContainer: (baseStyle) => ({
              ...baseStyle,
              fontSize: '12px',
              color: 'trueSpace',
              lineHeight: '14px',
              fontWeight: '500',
              paddingRight: 'unset',
            }),
            singleValue: (baseStyle) => ({
              ...baseStyle,
              color: 'trueSpace',
              fontSize: '12px',
              lineHeight: '14px',
              svg: {
                display: 'none',
              },
            }),
            dropdownIndicator: () => ({
              padding: '8px 16px',
              color: 'trueDim',
            }),
            menuList: (baseStyle) => ({
              ...baseStyle,
              borderRadius: '4px',
            }),
            menu: (baseStyle) => ({
              ...baseStyle,
              mt: 0,
            }),
            option: (baseStyle) => ({
              ...baseStyle,
              backgroundColor: 'white',
              '.option-label': {
                color: 'trueSpace',
                fontSize: '12px',
                lineHeight: '14px',
                fontWeight: '500',
              },
              '&:hover': {
                backgroundColor: '#c3e6fa',
              },
            }),
          }}
          menuPortalTarget={document.body}
          defaultValue={options[0]}
          getOptionLabel={(option) => (
            <Text className="option-label">{option.label}</Text>
          )}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: (props) => (
              <chakraComponents.DropdownIndicator {...props}>
                <TooltipArrow />
              </chakraComponents.DropdownIndicator>
            ),
          }}
          options={options}
          blurInputOnSelect
        />
        <Button
          variant="truebaseOutline"
          w="180px"
          h="40px"
          onClick={handleSendRequest}
        >
          Send Request
        </Button>
      </HStack>
    </Box>
  );
};
