import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
} from '@chakra-ui/react';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as EyeOffIcon } from '~/v2/assets/icons/view/view-large-off.svg';
import { ReactComponent as EyeOnIcon } from '~/v2/assets/icons/view/view-large.svg';
import {
  OnboardingBackButton,
  OnboardingButton,
} from '../shared/OnboardingButtons';
import OnboardingTitle from '../shared/OnboardingTitle';

const PasswordStep = forwardRef(({ stepInfo, isLoading }, ref) => {
  const btnRef = useRef(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        enterBtnHandler() {
          btnRef.current.click();
        },
      };
    },
    []
  );

  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext();
  const [shown, setShown] = useState({
    password: false,
    confirmPassword: false,
  });

  const handleBack = () => {
    stepInfo.setStep((s) => s - 1);
  };

  return (
    <>
      <OnboardingTitle>
        <Text>One last thing</Text>
      </OnboardingTitle>
      <FormControl isInvalid={errors.password} variant="truebase">
        <FormLabel>Password</FormLabel>
        <InputGroup>
          <Input
            variant="truebase"
            type={shown.password ? 'text' : 'password'}
            placeholder="Choose a password"
            {...register('password', {
              required: 'Password is required',
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters',
              },
            })}
          />
          <InputRightElement h="48px">
            <IconButton
              sx={{
                bg: 'transparent',
                _hover: {
                  bg: 'transparent',
                },
                svg: {
                  path: {
                    fill: 'trueDim',
                  },
                },
              }}
              icon={shown.password ? <EyeOnIcon /> : <EyeOffIcon />}
              onClick={() => {
                setShown({ ...shown, password: !shown.password });
              }}
            />
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage variant="truebase">
          {errors?.password?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={errors.confirmPassword} variant="truebase">
        <FormLabel>Confirm password</FormLabel>
        <InputGroup>
          <Input
            variant="truebase"
            placeholder="Confirm your password"
            type={shown.confirmPassword ? 'text' : 'password'}
            {...register('confirmPassword', {
              required: 'Confirm password is required',
              validate: (val) => {
                if (watch('password') !== val) {
                  return 'Your passwords do no match';
                }
              },
            })}
          />
          <InputRightElement h="48px">
            <IconButton
              sx={{
                bg: 'transparent',
                _hover: {
                  bg: 'transparent',
                },
                svg: {
                  path: {
                    fill: 'trueDim',
                  },
                },
              }}
              icon={shown.confirmPassword ? <EyeOnIcon /> : <EyeOffIcon />}
              onClick={() => {
                setShown({ ...shown, confirmPassword: !shown.confirmPassword });
              }}
            />
          </InputRightElement>
        </InputGroup>
        {errors.confirmPassword && (
          <FormErrorMessage variant="truebase">
            {errors.confirmPassword.message}
          </FormErrorMessage>
        )}
      </FormControl>

      <OnboardingButton
        ref={btnRef}
        noIcon
        type="submit"
        variant="truebaseOutline"
        isLoading={isLoading}
      >
        Next
      </OnboardingButton>
      <OnboardingBackButton isDisabled={isLoading} onClick={handleBack} />
    </>
  );
});
export default PasswordStep;
