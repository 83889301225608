import { menuAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const truebase = definePartsStyle({
  item: {
    py: 2,
    px: 4,
    color: 'trueSpace',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    svg: {
      color: 'trueDim',
    },
    _hover: {
      bg: 'trueSkylight',
    },
    _focus: {
      bg: 'trueSkylight',
    },
    display: 'flex',
    gap: '14px',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  list: {
    boxShadow: '10px 20px 40px 0px rgba(126, 139, 173, 0.2)',
    border: 'none',
    borderRadius: '4px',
  },
  divider: {
    borderColor: 'trueLighter',
  },
});

export const menuTheme = defineMultiStyleConfig({
  variants: { truebase },
});
