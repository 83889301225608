import {
  Avatar,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Spacer,
  Text,
  useToast,
  HStack,
  CloseButton,
  Divider,
  Icon,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as ContactsLinkIcon } from '~/v2/assets/icons/leads/leads-1.svg';
import { ReactComponent as ProspectingIcon } from '~/v2/assets/icons/automation/automation-1.svg';
import { ReactComponent as AutopilotNavbarIcon } from '~/v2/assets/icons/autopilot/autopilot-icon.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow/dropdown.svg';
import socket from '~/v2/utility/socket';
import { getUser, logout } from '../../redux/slices/userSlice';
import { ReactComponent as Person } from '../../assets/icons/person.svg';
import { ReactComponent as HelpCircle } from '../../assets/icons/help/help-circle.svg';
import { ReactComponent as Logout } from '../../assets/icons/logout.svg';
import { ReactComponent as DemoIcon } from '../../assets/icons/demo.svg';
import {
  useRequestAddTeamMutation,
  useRequestDemoMutation,
} from '~/v2/redux/services/teams';
import { ReactComponent as CheckDone } from '../../assets/check-done.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings/settings-large.svg';
import { get, isEmpty, isUndefined } from 'lodash';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import LinkElement from './components/LinkElement';
import SidebarAccordionItem from './components/SidebarAccordionItem';
import { ReactComponent as TruebaseLogo } from '../../assets/icons/truebase logo/large/large.svg';
import { useGetIcpsQuery } from '~/v2/redux/services/icp';
import { useGetAccountQualificationsQuery } from '~/v2/redux/services/accountQualification';
import { useGetSequenceTemplatesQuery } from '~/v2/redux/services/sequenceTemplate';
import { setSelectedIcp } from '~/v2/redux/slices/icpSlice';
import { setSelectedAccountQualification } from '~/v2/redux/slices/accountQualificationSlice';
import { setSelectedSequenceTemplate } from '~/v2/redux/slices/sequenceTemplateSlice';
import {
  getSelectedTeam,
  getTeams,
  setSelectedTeam,
} from '~/v2/redux/slices/teamsSlice';
import TeamSelect from '~/v2/common/TeamSelect';
import {
  ONBOARDING_LOCALSTORAGE_KEY,
  safeJSONParse,
  SYSTEM_USER_ID,
} from '~/v2/common/utils';
import ReactConfetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import { ReactComponent as AddBoxIcon } from '~/v2/assets/icons/add/add-box.svg';
import { ReactComponent as Check } from '~/v2/assets/icons/check/check-small.svg';
import { ReactComponent as PartnershipIcon } from '~/v2/assets/icons/partnership.svg';
import { ReactComponent as ChevronRight } from '~/v2/assets/icons/arrow/chevron-right.svg';

export default function Sidebar() {
  const { handleSyncError, handleAsyncError } = useErrorHandler();
  const { width, height } = useWindowSize();

  const user = useSelector(getUser);
  const teams = useSelector(getTeams);
  const selectedTeam = useSelector(getSelectedTeam);

  const [isExploding, setIsExploding] = useState(false);

  const localStorageOnboardingData = localStorage.getItem(
    ONBOARDING_LOCALSTORAGE_KEY
  );

  useGetIcpsQuery(
    { teamId: selectedTeam?._id },
    { skip: isUndefined(selectedTeam?._id), refetchOnMountOrArgChange: true }
  );
  useGetAccountQualificationsQuery(
    { teamId: selectedTeam?._id },
    {
      skip: isUndefined(selectedTeam?._id),
      refetchOnMountOrArgChange: true,
    }
  );
  useGetSequenceTemplatesQuery(
    { teamId: selectedTeam?._id },
    {
      skip: isUndefined(selectedTeam?._id),
      refetchOnMountOrArgChange: true,
    }
  );

  const [requestDemo] = useRequestDemoMutation();
  const [sendEmail] = useRequestAddTeamMutation();

  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const selectOptions = useMemo(() => {
    return teams?.map((team) => ({
      value: team._id,
      label: `${team.name} - ${team.email}`,
      name: team.name,
    }));
  }, [teams]);

  const handleLogout = useCallback(() => {
    socket.emit('leave', { userId: user._id });
    dispatch(logout());
    dispatch(setSelectedIcp({}));
    dispatch(setSelectedAccountQualification({}));
    dispatch(setSelectedSequenceTemplate({}));
    dispatch(setSelectedTeam({}));
    navigate('/login');
  }, [dispatch, navigate, user]);

  const handleDemoRequest = useCallback(async () => {
    const res = await requestDemo({ teamId: user.teamId }).unwrap();
    if (res.isOk) {
      toast({
        duration: 5000,
        position: 'bottom',
        render: ({ onClose }) => (
          <Box bg="trueAnason" p="16px 12px" borderRadius="4px">
            <HStack spacing="16px" alignItems="center">
              <CheckDone style={{ width: '16px', height: '16px' }} />
              <Text
                sx={{
                  fontSize: '14px',
                  lineHeight: '24px',
                  fontWeight: '500',
                  color: 'trueSpace',
                }}
              >
                Request sent. We&apos;ll get back to you shortly
              </Text>
              <CloseButton size="sm" onClick={onClose} />
            </HStack>
          </Box>
        ),
      });
    } else {
      toast({
        title: 'Error',
        description: 'Failed to request a demo',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [requestDemo, toast, user]);

  const handleRequestAddTeam = useCallback(async () => {
    const res = await sendEmail();

    if (get(res, 'data.isOk')) {
      toast({
        duration: 3000,
        position: 'bottom',
        render: ({ onClose }) => (
          <Box bg="trueAnason" p="16px 12px" borderRadius="4px">
            <HStack spacing="16px" alignItems="center">
              <CheckDone style={{ width: '16px', height: '16px' }} />
              <Text
                sx={{
                  fontSize: '14px',
                  lineHeight: '24px',
                  fontWeight: '500',
                  color: 'trueSpace',
                }}
              >
                Request sent. We&apos;ll get back to you shortly
              </Text>
              <CloseButton size="sm" onClick={onClose} />
            </HStack>
          </Box>
        ),
      });
    }
  }, [toast, sendEmail]);

  const userMenu = useCallback(
    () =>
      user && selectedTeam ? (
        <Menu variant="truebase">
          <MenuButton
            p="unset"
            as={Button}
            rightIcon={
              <Icon as={ArrowDown} color="#7E8BAD" w="24px" h="24px" />
            }
            variant="ghost"
            size="sm"
            _hover={{}}
            _focus={{}}
            _active={{}}
          >
            <Flex alignItems="center" gap="8px" p="8px 4px">
              <Avatar
                size="sm"
                name={`${user.firstName} ${user.lastName || ''}`}
                bg="trueSpace"
                color="white"
              />
              <Flex flexDir="column" textAlign="start" gap="2px">
                <Text
                  fontSize="14px"
                  lineHeight="16px"
                  fontWeight="500"
                  color="trueExplain"
                >
                  {user.firstName} {user.lastName}
                </Text>
                <Text
                  fontSize="11px"
                  lineHeight="14px"
                  fontWeight="500"
                  color="trueDim"
                >
                  {selectedTeam.name}
                </Text>
              </Flex>
            </Flex>
          </MenuButton>
          <Portal>
            <MenuList zIndex={10000} minW="250px">
              <Flex flexDir="column" alignItems="center" gap="12px">
                <Avatar
                  size="md"
                  name={`${user.firstName} ${user.lastName}`}
                  bg="trueSpace"
                  color="white"
                />
                <Text fontSize="16px" lineHeight="20px" fontWeight="500">
                  {user.firstName} {user.lastName}
                </Text>
              </Flex>
              {[
                { type: 'divider' },
                { label: 'Profile', href: '/profile', iconSrc: <Person /> },
                ...(user?.id !== SYSTEM_USER_ID
                  ? [{ type: 'team', iconSrc: <PartnershipIcon /> }]
                  : []),
                { type: 'divider' },
                {
                  label: 'Request a Demo',
                  onClick: handleDemoRequest,
                  iconSrc: <DemoIcon />,
                  isDisabled: teams?.find(({ _id }) => _id === user.teamId)
                    ?.hasRequestedDemo,
                },
                { type: 'divider' },
                { label: 'Logout', onClick: handleLogout, iconSrc: <Logout /> },
              ].map((item, index) => (
                <React.Fragment key={index}>
                  {item.type === 'divider' ? (
                    <MenuDivider />
                  ) : item.type === 'team' ? (
                    <Flex
                      py={2}
                      px={4}
                      alignItems="center"
                      sx={{ _hover: { bg: '#F5F5F5' } }}
                    >
                      <Box minW="24px" color="trueDim">
                        {item.iconSrc}
                      </Box>
                      <Menu variant="truebase" placement="right">
                        <MenuButton
                          p="unset"
                          as={Button}
                          rightIcon={
                            <Icon as={ChevronRight} w="24px" h="24px" />
                          }
                          variant="ghost"
                          size="sm"
                          _hover={{}}
                          _focus={{}}
                          _active={{}}
                          w="100%"
                          ml="14px"
                        >
                          <Flex alignItems="center" gap="4px">
                            <Text
                              fontSize="14px"
                              lineHeight="16px"
                              fontWeight="500"
                            >
                              Team:
                            </Text>
                            <Text
                              fontSize="11px"
                              lineHeight="14px"
                              fontWeight="500"
                              color="trueDim"
                            >
                              {selectedTeam.name}
                            </Text>
                          </Flex>
                        </MenuButton>
                        <MenuList
                          zIndex={10000}
                          minW="250px"
                          maxH="300px"
                          ml="18px"
                        >
                          {selectOptions.map(({ name, value }, index) => (
                            <MenuItem
                              onClick={() => {
                                dispatch(
                                  setSelectedTeam(
                                    teams.find(({ _id }) => value === _id)
                                  )
                                );
                              }}
                              py={2}
                              px={4}
                              isDisabled={item.isDisabled}
                              key={index}
                            >
                              <Flex alignItems="center" gap="10px">
                                <Avatar
                                  size="xs"
                                  name={`${user.firstName}`}
                                  bg={
                                    selectedTeam?._id === value
                                      ? 'trueSpace'
                                      : 'trueDim'
                                  }
                                  color="white"
                                />
                                <Text
                                  color={
                                    selectedTeam?._id === value
                                      ? 'trueSpace'
                                      : 'trueDim'
                                  }
                                >
                                  {name}
                                </Text>
                              </Flex>
                              <Spacer />
                              {selectedTeam?._id === value && (
                                <Icon
                                  as={Check}
                                  w="24px"
                                  h="24px"
                                  fill="trueSpace"
                                />
                              )}
                            </MenuItem>
                          ))}

                          <MenuDivider />

                          <MenuItem
                            sx={{ _hover: { bg: 'transparent' } }}
                            disabled
                          >
                            <Flex gap="8px" alignItems="center">
                              <Icon
                                as={AddBoxIcon}
                                w="24px"
                                h="24px"
                                fill="trueDisabled"
                              />
                              <Text color="trueDisabled">Create New</Text>
                            </Flex>
                            <Spacer />
                            <Flex
                              h="20px"
                              fontSize="11px"
                              lineHeight="14px"
                              w="fit-content"
                              p="3px 8px"
                              color="trueLink"
                              bg="trueLighter"
                              borderRadius="2px"
                              onClick={handleRequestAddTeam}
                            >
                              Request Upgrade
                            </Flex>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Flex>
                  ) : item.externalHref ? (
                    <MenuItem
                      as="a"
                      href={item.externalHref}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Box>{item.iconSrc}</Box>
                      <Text>{item.label}</Text>
                    </MenuItem>
                  ) : item.href ? (
                    <MenuItem
                      as={item.href ? RouterLink : undefined}
                      href={item.href}
                      to={item.href}
                    >
                      <Box minW="24px">{item.iconSrc}</Box>
                      <Text>{item.label}</Text>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      onClick={item.onClick}
                      py={2}
                      px={4}
                      isDisabled={item.isDisabled}
                    >
                      <Box minW="24px">{item.iconSrc}</Box>
                      <Text>{item.label}</Text>
                    </MenuItem>
                  )}
                </React.Fragment>
              ))}
            </MenuList>
          </Portal>
        </Menu>
      ) : (
        <></>
      ),
    [
      handleDemoRequest,
      handleLogout,
      teams,
      user,
      selectedTeam,
      selectOptions,
      dispatch,
      handleRequestAddTeam,
    ]
  );

  useEffect(() => {
    const wrappedFn = handleSyncError(() => {
      const userIntercomSegments = get(user, 'segments', []);
      if (userIntercomSegments.find(({ name }) => name === '_Churned')) {
        handleLogout();
        toast({
          description: 'Trial expired.',
          status: 'error',
        });
      }
    });

    wrappedFn();
  }, [handleLogout, toast, user, handleSyncError]);

  useEffect(() => {
    const saveData = handleAsyncError(async () => {
      const data = safeJSONParse(localStorageOnboardingData);
      if (!isEmpty(data)) {
        localStorage.removeItem(ONBOARDING_LOCALSTORAGE_KEY);
        localStorage.removeItem('TRUEBASE_LEAD_ID');
      }
    });

    saveData();
  }, [localStorageOnboardingData, handleAsyncError]);

  useEffect(() => {
    const saveData = handleAsyncError(async () => {
      const data = safeJSONParse(localStorageOnboardingData);
      if (!isEmpty(data)) {
        setIsExploding(true);
        localStorage.removeItem(ONBOARDING_LOCALSTORAGE_KEY);
        localStorage.removeItem('TRUEBASE_LEAD_ID');
      }
    });

    saveData();
  }, [localStorageOnboardingData, user?.id, handleAsyncError]);

  return (
    <Flex
      flexDir="column"
      zIndex="999"
      p="24px 32px"
      w="319px"
      maxH="100vh"
      borderRight="1px solid"
      borderColor="trueLighter"
      overflow="auto"
    >
      {isExploding && (
        <ReactConfetti
          numberOfPieces={250}
          recycle={false}
          friction={0.98}
          onConfettiComplete={() => setIsExploding(false)}
          width={width}
          height={height}
          confettiSource={{ x: 0, y: height }}
          gravity={0.05}
          initialVelocityX={20}
          initialVelocityY={25}
        />
      )}
      {isExploding && (
        <ReactConfetti
          numberOfPieces={250}
          recycle={false}
          friction={0.98}
          onConfettiComplete={() => setIsExploding(false)}
          width={width}
          height={height}
          confettiSource={{ x: width, y: height }}
          gravity={0.05}
          initialVelocityX={-20}
          initialVelocityY={25}
        />
      )}
      <Flex flexDir="column" gap="32px">
        <TruebaseLogo />
        <LinkElement
          to="/autopilot"
          Icon={ProspectingIcon}
          text="Launch Autopilot"
          border="1px solid"
          borderColor="trueLight"
          sx={
            get(location, 'pathname', '').includes('settings') &&
            get(location, 'state.settings.selectedMode', '')
              ? {
                  color: 'trueSpace',
                  bg: 'trueLighter',
                  borderColor: 'transparent',
                  svg: { path: { fill: 'trueSpace' } },
                }
              : {}
          }
        />
        <Divider />
        <Flex
          gap="12px"
          alignItems="center"
          justifyContent="center"
          fontSize="13px"
          lineHeight="15px"
          flexDir="column"
        >
          <LinkElement
            sx={{ color: 'trueDim', svg: { path: { fill: 'trueDim' } } }}
            to="/autopilots"
            Icon={AutopilotNavbarIcon}
            text="Autopilots"
          />
          <LinkElement
            to={`/contacts`}
            Icon={ContactsLinkIcon}
            text="Contacts"
          />
        </Flex>
        <Divider />
        <SidebarAccordionItem icon={<SettingsIcon />} title="Settings" />
        {user?.id === SYSTEM_USER_ID && (
          <TeamSelect
            selectOptions={selectOptions}
            selectedOption={
              selectedTeam
                ? {
                    value: selectedTeam._id,
                    label: `${selectedTeam.name} - ${selectedTeam.email}`,
                  }
                : {}
            }
            onChange={(option) => {
              dispatch(
                setSelectedTeam(teams.find(({ _id }) => option.value === _id))
              );
            }}
          />
        )}
      </Flex>
      <Spacer />
      <Flex flexDir="column" gap="12px">
        <Flex
          as="a"
          alignItems="center"
          href="https://knowledge.truebase.io"
          target="_blank"
          rel="noopener noreferrer"
          gap="10px"
          color="trueDim"
          p="8px"
        >
          <HelpCircle />
          <Text fontSize="13px" lineHeight="16px" fontWeight="500">
            Help
          </Text>
        </Flex>
        {userMenu()}
      </Flex>
    </Flex>
  );
}
