import React from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { ReactComponent as ProspectingIcon } from '~/v2/assets/icons/prospecting.svg';
import { NavLink } from 'react-router-dom';

const EmptyState = ({ noAutopilotContacts }) => {
  return (
    <Flex direction="column" alignItems="center" gap="32px">
      {noAutopilotContacts ? (
        <Box
          sx={{
            textAlign: 'center',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 500,
            color: 'trueSpace',
          }}
        >
          <Text>
            No contacts found for this autopilot. Try a different autopilot.
          </Text>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              textAlign: 'center',
              fontSize: '14px',
              lineHeight: '20px',
              fontWeight: 500,
              color: 'trueSpace',
            }}
          >
            <Text>
              Kick things off with some training first. When you&apos;re set,
              launch autopilot and choose to engage with Truebase.
            </Text>
          </Box>
          <Flex gap="16px" alignItems="center">
            <Button
              variant="truebaseOutline"
              h="32px"
              w="86px"
              p="unset"
              gap="4px"
              sx={{ svg: { w: '16px', h: '16px' } }}
              as={NavLink}
              to="/settings/icp"
            >
              <ProspectingIcon />
              <Text>Train</Text>
            </Button>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default EmptyState;
