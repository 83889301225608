import {
  Box,
  Button,
  Flex,
  Icon,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';
import { AutopilotStepCard } from '../AutopilotStepCard';
import { ReactComponent as ExportedIcon } from '~/v2/assets/icons/exported.svg';
import ContactsLinkIcon from '~/v2/assets/ContactsLinkIcon';
import { LeadCountSelector } from '../LeadCountSelector';
import { Select, chakraComponents } from 'chakra-react-select';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedTeam } from '~/v2/redux/slices/teamsSlice';
import { get } from 'lodash';
import { webkitScrollbarConfig } from '~/v2/common/constants/webkitScrollbar';
import { ReactComponent as TooltipArrow } from '~/v2/assets/tooltip arrow.svg';
import { useNavigate } from 'react-router';
import { useAddSequenceTemplateMutation } from '~/v2/redux/services/sequenceTemplate';
import { ReactComponent as NoEmail } from '~/v2/assets/icons/no-email-account.svg';
import {
  getSelectedSequenceTemplate,
  setSelectedSequenceTemplate,
} from '~/v2/redux/slices/sequenceTemplateSlice';
import { ReactComponent as SequenceTemplateIcon } from '~/v2/assets/icons/sequence-template.svg';
import { ReactComponent as AddBoxIcon } from '~/v2/assets/icons/add/add-box.svg';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { generateUniqueName } from '~/v2/common/utils';

export const AutopilotEngageStep = ({
  selectedMode,
  leadsCount,
  setLeadsCount,
  selectedSequenceTemplateSettings,
  setSelectedSequenceTemplateSettings,
  navigationState,
  sequenceTemplates,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleAsyncError } = useErrorHandler();
  const toast = useToast();

  const team = useSelector(getSelectedTeam);
  const selectedSequenceTemplate = useSelector(getSelectedSequenceTemplate);

  const [addSequenceTemplate, { isLoading: isSavingSequenceTemplate }] =
    useAddSequenceTemplateMutation();

  const handleCreateNew = handleAsyncError(async () => {
    const response = await addSequenceTemplate({
      name: generateUniqueName('Sequence Template', sequenceTemplates),
      teamId: team._id,
    }).unwrap();

    dispatch(setSelectedSequenceTemplate(response.result.sequenceTemplate));
    navigate('/settings/sequence-templates', {
      state: { ...navigationState, isPending: true },
    });
    toast({
      title: response?.isOk ? 'Added Successfully!' : 'Something went wrong!',
      status: response?.isOk ? 'success' : 'error',
      duration: 1000,
    });
  });

  return (
    <>
      <AutopilotStepCard
        title={
          <Text fontWeight="500" fontSize="14px" lineHeight="16px">
            Engage
          </Text>
        }
        subtitle="Scheduling & dispatching based on your email preferences letting you focus on replies"
        icon={ExportedIcon}
        content={
          <Flex
            flexDir="column"
            gap="16px"
            w="100%"
            p="16px 16px 16px 0"
            alignSelf="end"
          >
            {selectedMode === 'emailOutbound' && (
              <>
                <Text
                  sx={{
                    fontWeight: 400,
                    fontSize: '13px',
                    lineHeight: '16px',
                    color: 'trueDim',
                  }}
                >
                  Choose how many leads you need that passed all checkpoints of
                  the automation
                </Text>
                <Flex alignItems="center" gap="16px">
                  <Flex alignItems="center" gap="8px">
                    <Icon
                      as={ContactsLinkIcon}
                      color="trueDim"
                      w="24px"
                      h="24px"
                    />
                    <LeadCountSelector
                      leadsCount={leadsCount}
                      setLeadsCount={setLeadsCount}
                    />
                  </Flex>
                  <Text
                    sx={{
                      fontWeight: 500,
                      fontSize: '12px',
                      lineHeight: '14px',
                      color: 'trueDim',
                    }}
                  >
                    Leads
                  </Text>
                </Flex>
              </>
            )}
            <Flex alignItems="end" w="100%">
              <Flex direction="column" gap="16px">
                <Text
                  sx={{
                    fontSize: '13px',
                    lineHeight: '16px',
                    color: 'trueDim',
                  }}
                >
                  Choose an email sequence template for this campaign
                </Text>
                <Flex alignItems="center" gap="8px">
                  <Select
                    defaultValue={{
                      value: selectedSequenceTemplate?._id,
                      label: selectedSequenceTemplate?.name,
                    }}
                    onChange={({ value }) => {
                      setSelectedSequenceTemplateSettings(
                        sequenceTemplates.find((st) => st._id === value)
                      );
                    }}
                    options={sequenceTemplates?.map((st) => ({
                      value: st._id,
                      label: `${st.name}`,
                    }))}
                    blurInputOnSelect
                    getOptionLabel={(option) => (
                      <Flex alignItems="center" gap="8px">
                        <Box
                          sx={{
                            svg: {
                              w: '24px',
                              h: '24px',
                              path: {
                                fill: !sequenceTemplates?.length
                                  ? 'trueCorral'
                                  : 'trueDim',
                              },
                            },
                          }}
                        >
                          <SequenceTemplateIcon />
                        </Box>
                        <span className="option-label">{option.label}</span>
                      </Flex>
                    )}
                    chakraStyles={{
                      control: (baseStyle) => ({
                        ...baseStyle,
                        cursor: 'pointer',
                        border: 'none',
                        w: '260px',
                      }),
                      valueContainer: (baseStyle) => ({
                        ...baseStyle,
                        fontSize: '12px',
                        color: 'trueSpace',
                        lineHeight: '14px',
                        fontWeight: '500',
                        p: 0,
                      }),
                      dropdownIndicator: () => ({ color: 'trueDim' }),
                      menuList: (baseStyle) => ({
                        ...baseStyle,
                        overflowY: 'overlay',
                        p: 0,
                        maxH: '60px',
                        borderRadius: '4px 4px 0 0',
                        boxShadow: 'none',
                        borderBottom: 0,
                        zIndex: '999',
                        ...webkitScrollbarConfig(),
                      }),
                      menu: (baseStyle) => ({
                        ...baseStyle,
                        mt: '-30px',
                        overflow: 'hidden',
                        zIndex: '999',
                      }),
                      option: (baseStyle, { isSelected }) => ({
                        ...baseStyle,
                        p: '8px 16px',
                        color: 'trueSpace !important',
                        fontSize: '12px',
                        lineHeight: '14px',
                        fontWeight: '500',
                        ...(isSelected && { bg: 'white !important' }),
                        '&:hover': { backgroundColor: '#c3e6fa !important' },
                      }),
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: (props) => (
                        <chakraComponents.DropdownIndicator {...props}>
                          <TooltipArrow />
                        </chakraComponents.DropdownIndicator>
                      ),
                      MenuList: (props) => (
                        <>
                          <chakraComponents.MenuList {...props}>
                            <Box>{props.children}</Box>
                          </chakraComponents.MenuList>
                          <Button
                            sx={{
                              w: '100%',
                              h: '40px',
                              color: 'trueLink',
                              fontSize: '12px',
                              lineHeight: '14px',
                              p: '8px 16px',
                              bgColor: 'white',
                              borderWidth: '0 1px 1px 1px',
                              borderRadius: '0 0 4px 4px',
                              fontWeight: '500',
                              justifyContent: 'flex-start',
                              _hover: { bgColor: '#c3e6fa' },
                              opacity: '1 !important',
                            }}
                            leftIcon={
                              <Icon as={AddBoxIcon} w="24px" h="24px" />
                            }
                            isLoading={isSavingSequenceTemplate}
                            onClick={handleCreateNew}
                          >
                            Create New
                          </Button>
                        </>
                      ),
                    }}
                  />
                </Flex>
              </Flex>
              <Spacer />
              <Button
                h="24px"
                w="45px"
                fontSize="12px"
                lineHeight="14px"
                fontWeight="500"
                isLoading={isSavingSequenceTemplate}
                onClick={() => {
                  dispatch(
                    setSelectedSequenceTemplate(
                      sequenceTemplates.find(
                        ({ _id }) =>
                          selectedSequenceTemplateSettings._id === _id
                      )
                    )
                  );
                  navigate('/settings/sequence-templates', {
                    state: navigationState,
                  });
                }}
              >
                Edit
              </Button>
            </Flex>
            {!get(team, 'emailAccounts', []).length && (
              <Flex direction="column" gap="16px">
                <Text
                  sx={{
                    fontSize: '13px',
                    lineHeight: '16px',
                    color: 'trueDim',
                  }}
                >
                  Choose email account(s) you want us to use when reaching out
                  to these leads
                </Text>
                <Flex justifyContent="space-between" alignItems="center">
                  <Flex gap="8px" alignItems="center">
                    <NoEmail />
                    <Text
                      sx={{
                        fontSize: '12px',
                        lineHeight: '14px',
                        fontWeight: 500,
                      }}
                    >
                      Didn’t add an account yet?
                    </Text>
                  </Flex>
                  <Button
                    h="24px"
                    w="94px"
                    fontSize="12px"
                    lineHeight="14px"
                    fontWeight="500"
                    onClick={() => {
                      navigate('/settings/email-accounts', {
                        state: navigationState,
                      });
                    }}
                  >
                    Connect one
                  </Button>
                </Flex>
              </Flex>
            )}
          </Flex>
        }
      />
    </>
  );
};
