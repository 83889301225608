import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const truebase = defineStyle({
  fontSize: '14px',
  bgColor: '#3F21FF',
  color: 'white',
  boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
  transition: 'none',
  _hover: {
    background: 'linear-gradient(90deg, #3F21FF 0%, #8A3DFF 100%)',
    _disabled: {
      bgColor: 'trueDisabled',
    },
  },
  _active: {
    boxShadow: 'inset 2px 2px 4px rgba(0, 0, 0, 0.1)',
  },
  _disabled: {
    bgColor: 'trueDisabled',
    _active: {
      boxShadow: 'none',
      bgColor: 'trueDisabled',
    },
  },
});

const truebaseOutline = defineStyle({
  fontSize: '14px',
  lineHeight: '16px',
  borderColor: 'trueBlue',
  borderWidth: '1px',
  borderRadius: '4px',
  color: 'trueBlue',
  _hover: {
    boxShadow: '2px 2px 4px 0px #0000001A',
    bg: 'trueLighter',
  },
  _active: {
    boxShadow: 'inset 2px 2px 4px 0px #0000001A',
    bg: 'trueLight',
  },
  _disabled: {
    borderColor: 'trueDisabled',
    color: 'trueDisabled',
  },
});

const truebaseDark = defineStyle({
  fontSize: '14px',
  bgColor: 'trueSpace',
  color: 'white',
  boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
  transition: 'none',
  _hover: { background: 'trueGray' },
  _active: { bgColor: 'trueDark' },
});

const truebaseOutlineDark = defineStyle({
  fontSize: '14px',
  lineHeight: '16px',
  borderColor: 'trueSpace',
  borderWidth: '1px',
  borderRadius: '4px',
  color: 'trueSpace',
  _hover: { bg: 'trueLighter' },
  _active: { bg: 'trueLight' },
});

const truebaseOutlineNeutral = defineStyle({
  fontSize: '13px',
  lineHeight: '16px',
  borderColor: 'trueDim',
  borderWidth: '1px',
  borderRadius: '4px',
  color: 'trueDim',
  _hover: {
    boxShadow: '2px 2px 4px 0px #0000001A',
    bg: 'trueLighter',
  },
  _active: {
    boxShadow: 'inset 2px 2px 4px 0px #0000001A',
    bg: 'trueLight',
  },
  _disabled: {
    borderColor: 'trueDisabled',
    color: 'trueDisabled',
  },
});

const truebaseLink = defineStyle({
  fontSize: '13px',
  lineheight: '16px',
  fontWeight: 500,
  minW: 0,
  h: 'fit-content',
  color: 'trueMedium',
  '&.is-active': {
    color: 'trueBlue',
    backgroundColor: 'trueLight',
    borderRadius: '4px',
  },
  py: '8px',
  px: '16px',
});

const truebaseText = defineStyle({
  fontSize: '13px',
  lineHeight: '16px',
  bgColor: 'transparent',
  color: 'trueMedium',
  fontWeight: 500,
  px: '16px',
  py: '8px',
  _hover: {
    bg: 'trueLighter',
    borderRadius: '4px',
  },
  _active: {
    bg: 'trueLight',
    borderRadius: '4px',
  },
  _disabled: {
    color: 'trueDisabled',
  },
});

const truebaseIcon = defineStyle({
  fontSize: '13px',
  lineHeight: '16px',
  bgColor: 'transparent',
  color: 'trueMedium',
  fontWeight: 500,
  px: '8px',
  py: '8px',
  minW: 0,
  _hover: {
    bg: 'trueLighter',
    borderRadius: '4px',
  },
  _active: {
    bg: 'trueLight',
    borderRadius: '4px',
  },
  _disabled: {
    color: 'trueDisabled',
  },
});

const truebaseTextHighlight = defineStyle({
  fontSize: '13px',
  lineHeight: '16px',
  bgColor: 'transparent',
  color: 'trueLink',
  fontWeight: 500,
  px: '16px',
  py: '8px',
  _hover: {
    bg: 'trueLighter',
    borderRadius: '4px',
  },
  _active: {
    bg: 'trueLight',
    borderRadius: '4px',
  },
  _disabled: {
    color: 'trueDisabled',
  },
});

const truebaseTextNeutral = defineStyle({
  fontSize: '13px',
  lineHeight: '16px',
  fontWeight: 500,
  border: 'none',
  color: 'trueDim',
  px: '16px',
  py: '8px',
  _hover: {
    bg: 'trueLighter',
    borderRadius: '4px',
  },
  _active: {
    bg: 'trueLight',
    borderRadius: '4px',
  },
  _disabled: {
    color: 'trueDisabled',
  },
});

const truebaseGradient = defineStyle({
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: '500',
  w: { base: '200px', xl: '261px' },
  h: { base: '40px', xl: '56px' },
  borderRadius: '4px',
  color: 'white',
  background: 'trueBlue',
  transition: 'none',
  _hover: {
    background: 'linear-gradient(90deg, #3F21FF 0%, #8A3DFF 100%)',
    _disabled: {
      bgColor: 'trueDisabled',
    },
  },
  _active: {
    boxShadow: 'inset 2px 2px 4px rgba(0, 0, 0, 0.1)',
  },
  _disabled: {
    bgColor: 'trueDisabled',
  },
});

export const buttonTheme = defineStyleConfig({
  variants: {
    truebase,
    truebaseOutline,
    truebaseDark,
    truebaseOutlineDark,
    truebaseText,
    truebaseTextHighlight,
    truebaseTextNeutral,
    truebaseIcon,
    truebaseLink,
    truebaseOutlineNeutral,
    truebaseGradient,
  },
  defaultProps: {
    size: 'lg',
  },
});
