import { Button, Flex, Icon, Spacer, Text } from '@chakra-ui/react';
import { get } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ReactComponent as InfoIcon } from '~/v2/assets/icons/info-mono.svg';
import { TruebaseTooltip } from '~/v2/common/TruebaseTooltip';
import { getSelectedTeam } from '~/v2/redux/slices/teamsSlice';

export const LaunchAutopilotFooter = ({
  selectedMode,
  setSelectedMode,
  handleLaunchAutopilot,
  isCreateAutopilotLoading,
  steps,
  autopilotName,
  panel,
}) => {
  const location = useLocation();

  const team = useSelector(getSelectedTeam);

  const disabledReason = useMemo(() => {
    return {
      filter: 'Please add lead filters to ICP',
      qualify: 'Please add qualification questions to account qualification',
      engage: !get(team, 'emailAccounts', []).length
        ? 'Please make sure you have an email account connected'
        : 'Please add steps to email template',
    };
  }, [team]);

  const disabledStep = useMemo(
    () => steps.find((step) => step.isDisabled),
    [steps]
  );

  const isDisabled = useMemo(() => {
    const isNameInvalid = !autopilotName || autopilotName.length > 60;
    const isModeInvalid =
      ['inboundEnrichment', 'inboundToOutbound'].includes(selectedMode) &&
      !panel.acceptedFiles.length;
    return disabledStep || isNameInvalid || panel.error || isModeInvalid;
  }, [
    autopilotName,
    panel.acceptedFiles.length,
    panel.error,
    selectedMode,
    disabledStep,
  ]);

  const getDisabledReason = useCallback(() => {
    if (isDisabled) {
      if (disabledReason[get(disabledStep, 'name')]) {
        return disabledReason[get(disabledStep, 'name')];
      }
      if (!autopilotName) {
        return 'Please add autopilot name';
      }
      if (autopilotName.length > 60) {
        return 'Autopilot name can be more than 60 characters';
      }
      if (panel.error) {
        return 'Please check imported file';
      }
      if (
        ['inboundEnrichment', 'inboundToOutbound'].includes(selectedMode) &&
        !panel.acceptedFiles.length
      ) {
        return 'Please import a file';
      }
    }
    return '';
  }, [
    autopilotName,
    disabledReason,
    disabledStep,
    isDisabled,
    panel.acceptedFiles.length,
    panel.error,
    selectedMode,
  ]);

  return (
    <Flex
      w="100%"
      pos="absolute"
      bottom="0"
      left="0"
      py="16px"
      borderTop="1px solid"
      borderColor="trueLighter"
      zIndex="998"
      bg="white"
      justifyContent="center"
    >
      <Flex maxW="1152px" w="100%">
        <Flex alignItems="center" gap="16px">
          <Icon as={InfoIcon} w="24px" h="24px" />
          <Text
            color="trueExplain"
            sx={{
              fontSize: '13px',
              fontWeight: '400',
              lineHeight: '16px',
            }}
          >
            We&apos;ll notify you by email once Autopilot has finished the
            quest.
          </Text>
        </Flex>
        <Spacer />
        <Flex gap="16px">
          <Button
            type="button"
            w="200px"
            h="40px"
            variant="truebaseOutline"
            onClick={() => {
              location.state = {};
              setSelectedMode('');
            }}
          >
            Cancel
          </Button>
          <TruebaseTooltip label={getDisabledReason()}>
            <Button
              h="40px"
              w="200px"
              variant="truebase"
              borderRadius="4px"
              onClick={handleLaunchAutopilot}
              isLoading={isCreateAutopilotLoading}
              isDisabled={isDisabled}
            >
              Launch Autopilot
            </Button>
          </TruebaseTooltip>
        </Flex>
      </Flex>
    </Flex>
  );
};
