import { Box } from '@chakra-ui/react';
import React from 'react';
import AngelList from '~/v2/assets/icons/social_icons/angellist/mono.svg';
import Crunchbase from '~/v2/assets/icons/social_icons/crunchbase/mono.svg';
import Github from '~/v2/assets/icons/social_icons/github/mono.svg';
import Linkedin from '~/v2/assets/icons/social_icons/linkedin/mono.svg';
import Medium from '~/v2/assets/icons/social_icons/medium/mono.svg';
import Quora from '~/v2/assets/icons/social_icons/quora/mono.svg';
import Reddit from '~/v2/assets/icons/social_icons/reddit/mono.svg';
import Salesnavigator from '~/v2/assets/icons/social_icons/salesnav/mono.svg';
import Twitter from '~/v2/assets/icons/social_icons/twitter/mono.svg';
import Youtube from '~/v2/assets/icons/social_icons/youtube/mono.svg';
import Link from '~/v2/assets/icons/social_icons/web/mono.svg';
import { TruebaseTooltip } from '../../TruebaseTooltip';

const SocialProfile = ({ service, url, type }) => {
  if (
    ![
      'linkedin',
      'angellist',
      'twitter',
      'github',
      'reddit',
      'youtube',
      'quora',
      'medium',
      'crunchbase',
      'salesnavigator',
      'website',
    ].includes(service.toLowerCase())
  )
    return '';
  return (
    <TruebaseTooltip
      closeOnPointerDown
      placement="top"
      label={url}
      aria-label={url}
      type="link"
    >
      <Box
        width="16px"
        height="16px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        className="show-on-hover"
        sx={{
          color: 'trueDim',
          img: {
            w: '16px',
            h: '16px',
          },
        }}
        _hover={{
          color: 'trueLink',
          img: {
            transition: 'all 0.2s ease',
          },
        }}
        as="a"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        <ServiceSelector service={service} type={type} />
      </Box>
    </TruebaseTooltip>
  );
};

const ServiceSelector = ({ service, type }) => {
  let src;
  switch (service) {
    case 'linkedin':
      src = Linkedin;
      break;
    case 'salesnavigator':
      src = Salesnavigator;
      break;
    case 'twitter':
      src = Twitter;
      break;
    case 'angellist':
      src = AngelList;
      break;
    case 'github':
      src = Github;
      break;
    case 'youtube':
      src = Youtube;
      break;
    case 'reddit':
      src = Reddit;
      break;
    case 'quora':
      src = Quora;
      break;
    case 'crunchbase':
      src = Crunchbase;
      break;
    case 'medium':
      src = Medium;
      break;
    case 'website':
      src = Link;
      break;
    default:
      break;
  }

  return <img src={src} alt={service} />;
};

export default SocialProfile;
