import styled from '@emotion/styled';

export const Separator = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  color: #0f0f4d;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #7e8bad;
  }

  &::before {
    margin-right: 0.25em;
  }
  &::after {
    margin-left: 0.25em;
  }
`;
