import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import EmailSent from '../../../../common/Animations/EmailSent';
import { useFormContext } from 'react-hook-form';
import {
  useSendVerificationCodeMutation,
  useVerifyUserMutation,
} from '../../../../redux/services/user';
import { ReactComponent as MailIcon } from '~/v2/assets/icons/mail/mail-1.svg';
import OnboardingTitle from '../shared/OnboardingTitle';
import {
  OnboardingBackButton,
  OnboardingButton,
} from '../shared/OnboardingButtons';
import useErrorHandler from '~/v2/hooks/useErrorHandler';

const VerifyEmailStep = forwardRef(({ stepInfo }, ref) => {
  const { handleAsyncError } = useErrorHandler();
  const {
    register,
    formState: { errors },
    watch,
    clearErrors,
    setError,
  } = useFormContext();
  const [email, verificationCode] = watch(['email', 'verificationCode']);
  const toast = useToast();
  const [verifyUser, { isLoading: isVerifyUserLoading }] =
    useVerifyUserMutation();
  const [sendVerificationEmail, { isLoading: isResendingVerificationEmail }] =
    useSendVerificationCodeMutation();

  const handleClick = useCallback(async () => {
    if (verificationCode) {
      clearErrors('verificationCode');
      const result = await verifyUser({
        email,
        token: verificationCode,
      }).unwrap();
      if (result.isOk) {
        stepInfo.setStep((s) => s + 1);
      } else {
        setError('verificationCode', {
          type: 'manual',
          message: 'Invalid verification code',
        });
      }
    }
  }, [clearErrors, setError, stepInfo, verificationCode, email, verifyUser]);

  useImperativeHandle(
    ref,
    () => {
      return {
        enterBtnHandler() {
          !verificationCode ? null : handleClick();
        },
      };
    },
    [verificationCode, handleClick]
  );

  const resendEmail = handleAsyncError(async () => {
    const result = await sendVerificationEmail({
      email: email,
    }).unwrap();
    if (result.isOk) {
      toast({
        description: 'A verification code has been sent to your email',
        status: 'success',
        duration: 9000,
        isClosable: true,
        containerStyle: {
          minW: '500px',
          p: '0.5rem',
          '.chakra-alert': {
            bgColor: 'trueHighlight01',
          },
          button: {
            top: '0.75rem',
            '.chakra-icon': {
              color: 'trueMedium',
            },
          },

          '.chakra-alert__icon': {
            color: 'trueMedium',
          },
          '.chakra-alert__desc': {
            color: 'trueSpace',
          },
        },
      });
    } else {
      toast({
        title: 'Error',
        description: 'Could not resend the email! Try again later.',
        status: 'error',
        duration: undefined,
        isClosable: true,
      });
    }
  });

  return (
    <>
      <OnboardingTitle>
        <Text>Create your account to begin</Text>
        <Text>our collaboration</Text>
      </OnboardingTitle>
      <Text fontSize="12px" color="trueSpace" fontWeight={400}>
        Hop over to your email to proceed!
      </Text>
      <FormControl variant="truebase">
        <InputGroup>
          <Input
            variant="truebase"
            type="email"
            placeholder="Enter your work email address"
            _disabled={{
              bg: 'white',
            }}
            value={email}
            isDisabled
          />
          <InputRightElement h="48px">
            <MailIcon fill="var(--chakra-colors-trueDim)" />
          </InputRightElement>
        </InputGroup>
        <FormHelperText mt="16px" color="trueExplain">
          We will use this email to share the cool AI stuff.
        </FormHelperText>
      </FormControl>
      <Box margin="0 auto" id="lottie-complete-signup" w="86px" h="86px">
        <EmailSent />
      </Box>
      <FormControl variant="truebase" isInvalid={errors.verificationCode}>
        <Input
          variant="truebase"
          type="text"
          placeholder="Enter verification code"
          {...register('verificationCode', {
            required: 'Verification code is required',
          })}
        />
        <FormErrorMessage variant="truebase">
          {errors.verificationCode && errors.verificationCode.message}
        </FormErrorMessage>
      </FormControl>
      <OnboardingButton isLoading={isVerifyUserLoading} onClick={handleClick}>
        Next
      </OnboardingButton>
      <OnboardingBackButton
        isDisabled={isVerifyUserLoading}
        onClick={() => stepInfo.setStep((s) => s - 1)}
      >
        Back
      </OnboardingBackButton>
      <Box mt="80px">
        <Text textAlign="center" fontSize="13px">
          Didn&apos;t get the email?
        </Text>
        <Flex mt="24px" justifyContent="center" alignItems="center">
          <Button
            h="32px"
            type="button"
            variant="truebaseTextHighlight"
            onClick={() => resendEmail()}
            isLoading={isResendingVerificationEmail}
          >
            Resend
          </Button>
        </Flex>
      </Box>
    </>
  );
});
export default VerifyEmailStep;
