import { apiSlice } from './api';
import {
  companyFilterCategories,
  personFilterCategories,
} from '@truebase/common/config/schemas.json';

// Define a service using a base URL and expected endpoints
export const filtersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFilters: builder.query({
      query: (args = {}) => {
        let searchParams = new URLSearchParams();
        let hasParams = false;
        const actualArgs = {
          ...args,
          skip: args.pageNumber * args.limit,
          pageNumber: undefined,
        };
        const keys = Object.keys(actualArgs);
        for (let i = 0; i < keys.length; i++) {
          // if we pass in all as a value, we don't want to add it to the query
          if (actualArgs[keys[i]] && actualArgs[keys[i]] !== 'all') {
            hasParams = true;
            if (keys[i] === 'searchText') {
              searchParams.append(
                keys[i],
                JSON.stringify(actualArgs[keys[i]].toString())
              );
            } else {
              searchParams.append(keys[i], actualArgs[keys[i]]);
            }
          }
        }
        return {
          url:
            'companyFilters' + (hasParams ? `?${searchParams.toString()}` : ''),
          method: 'GET',
        };
      },
      transformResponse: (response, meta, args) => {
        return {
          ...response,
          pageNumber: args.pageNumber,
        };
      },
    }),
    getStats: builder.query({
      query: (args = {}) => {
        let searchParams = new URLSearchParams();
        let hasParams = false;
        const keys = Object.keys(args);
        for (let i = 0; i < keys.length; i++) {
          if (args[keys[i]]) {
            hasParams = true;
            if (keys[i] === 'searchText') {
              searchParams.append(
                keys[i],
                JSON.stringify(args[keys[i]].toString())
              );
            } else {
              searchParams.append(keys[i], args[keys[i]]);
            }
          }
        }
        return {
          url:
            'companyFilters/stats' +
            (hasParams ? `?${searchParams.toString()}` : ''),
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        if (response && response.result) {
          return response.result?.stats.length > 0
            ? response.result.stats.map((stat) => {
                return {
                  icon: companyFilterCategories.find(
                    (category) =>
                      category._id.toLocaleLowerCase() ===
                      stat._id.toLocaleLowerCase()
                  )?.icon,
                  ...stat,
                };
              })
            : companyFilterCategories;
        } else {
          return companyFilterCategories.map((c) => ({
            ...c,
            count: 0,
          }));
        }
      },
    }),
    getPersonFilters: builder.query({
      query: (args = {}) => {
        let searchParams = new URLSearchParams();
        let hasParams = false;
        const actualArgs = {
          ...args,
          skip: args.pageNumber * args.limit,
          pageNumber: undefined,
        };
        const keys = Object.keys(actualArgs);
        for (let i = 0; i < keys.length; i++) {
          // if we pass in all as a value, we don't want to add it to the query
          if (actualArgs[keys[i]] && actualArgs[keys[i]] !== 'all') {
            hasParams = true;
            if (keys[i] === 'searchText') {
              searchParams.append(
                keys[i],
                JSON.stringify(actualArgs[keys[i]].toString())
              );
            } else {
              searchParams.append(keys[i], actualArgs[keys[i]]);
            }
          }
        }
        return {
          url:
            'personFilters' + (hasParams ? `?${searchParams.toString()}` : ''),
          method: 'GET',
        };
      },
      transformResponse: (response, meta, args) => {
        return {
          ...response,
          pageNumber: args.pageNumber,
        };
      },
    }),
    getPersonStats: builder.query({
      query: (args = {}) => {
        let searchParams = new URLSearchParams();
        let hasParams = false;
        const keys = Object.keys(args);
        for (let i = 0; i < keys.length; i++) {
          if (args[keys[i]]) {
            hasParams = true;
            searchParams.append(
              keys[i],
              JSON.stringify(args[keys[i]].toString())
            );
          }
        }
        return {
          url:
            'personFilters/stats' +
            (hasParams ? `?${searchParams.toString()}` : ''),
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        if (response && response.result) {
          return response.result?.stats.length > 0
            ? response.result.stats.map((stat) => {
                return {
                  icon: companyFilterCategories.find(
                    (category) =>
                      category._id.toLocaleLowerCase() ===
                      stat._id.toLocaleLowerCase()
                  )?.icon,
                  ...stat,
                };
              })
            : personFilterCategories;
        } else {
          return personFilterCategories.map((c) => ({
            ...c,
            count: 0,
          }));
        }
      },
    }),
    generatePersonTitles: builder.query({
      query: (args = {}) => {
        let searchParams = new URLSearchParams();
        let hasParams = false;
        const keys = Object.keys(args);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const value = args[key];
          if (value) {
            hasParams = true;
            if (Array.isArray(value)) {
              const arrayKey = `${key}[]`;
              value.forEach((item) => {
                searchParams.append(arrayKey, item.toString());
              });
            } else {
              searchParams.append(key, value.toString());
            }
          }
        }
        return {
          url:
            'personFilters/generatePersonTitles' +
            (hasParams ? `?${searchParams.toString()}` : ''),
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetStatsQuery,
  useGetFiltersQuery,
  useLazyGetFiltersQuery,
  useLazyGetStatsQuery,
  useGetPersonFiltersQuery,
  useLazyGetPersonFiltersQuery,
  useGetPersonStatsQuery,
  useLazyGetPersonStatsQuery,
  useLazyGeneratePersonTitlesQuery,
} = filtersApi;
