import { Accordion, Flex, Box } from '@chakra-ui/react';
import AutopilotCard from './AutopilotCard';
import { get } from 'lodash';
import AutopilotCardSkeleton from './AutopilotCardSkeleton';
import { useEffect, useRef } from 'react';
import { getAutopilotStats } from '~/v2/common/utils';
import { useIsVisible } from '~/v2/hooks/useIsVisible';
import { useLocation } from 'react-router-dom';

const AutopilotCards = ({
  autopilotsResponse,
  isLoading,
  isFetching,
  isUninitialized,
  setPage,
}) => {
  const loadMoreRef = useRef(null);
  const isLoadMoreVisible = useIsVisible(loadMoreRef);

  const autopilots = autopilotsResponse?.result?.autopilots;
  const totalAutopilotCount = autopilotsResponse?.result?.totalCount;

  const location = useLocation();

  useEffect(() => {
    if (
      isLoadMoreVisible &&
      !isLoading &&
      !isFetching &&
      autopilots?.length !== totalAutopilotCount
    ) {
      setPage((p) => p + 1);
    }
  }, [
    isLoadMoreVisible,
    isLoading,
    isFetching,
    autopilots?.length,
    totalAutopilotCount,
    setPage,
  ]);

  const AutopilotCardWithStats = ({ autopilot, ...rest }) => {
    const autopilotStats = getAutopilotStats(autopilot);
    return (
      <AutopilotCard
        key={autopilot._id}
        autopilotId={autopilot._id}
        firstStepName={autopilotStats.firstStepName}
        finalStepName={autopilotStats.finalStepName}
        requestedOrImportedCount={autopilotStats.requestedOrImportedCount}
        qualificationMinScore={autopilotStats.qualificationMinScore}
        totalProcessedCount={autopilotStats.totalProcessedCount}
        qualifiedCount={autopilotStats.qualifiedCount}
        tripleCheckedCount={autopilotStats.tripleCheckedCount}
        finalStepCompletedCount={autopilotStats.finalStepCompletedCount}
        createdAt={autopilot.createdAt}
        status={autopilot.status}
        scheduledAt={autopilot?.scheduledAt}
        completedAt={autopilot?.completedAt}
        cancelledAt={autopilot?.cancelledAt}
        skippedAt={autopilot?.skippedAt}
        failedAt={autopilot?.failedAt}
        totalAutopilotCount={totalAutopilotCount}
        name={get(autopilot, 'name')}
        steps={autopilot?.steps}
        {...rest}
      />
    );
  };
  return (
    <>
      {autopilots?.length > 0 && (
        <Flex direction="column" pb="56px">
          <Accordion
            allowToggle
            defaultIndex={get(location, 'state.defaultAutopilotIndex', null)}
          >
            {autopilots.map((autopilot, idx) => (
              <AutopilotCardWithStats
                idx={idx}
                key={autopilot._id}
                autopilot={autopilot}
              />
            ))}
          </Accordion>
        </Flex>
      )}
      <Box
        ref={loadMoreRef}
        display={autopilots?.length === totalAutopilotCount ? 'none' : 'block'}
      />
      {(isFetching || isLoading || isUninitialized) &&
        Array.from({ length: 10 }).map((_, index) => (
          <AutopilotCardSkeleton key={index} />
        ))}
    </>
  );
};

export default AutopilotCards;
