import { omit } from 'lodash';
import { apiSlice } from './api';

export const teamsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTeams: builder.query({
      query: () => ({
        url: 'teams',
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.result.teams;
      },
      providesTags: ['Teams'],
    }),
    getTeamDetails: builder.query({
      query: (teamId) => ({
        url: `teams/${teamId}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.result.stats;
      },
      providesTags: ['Teams'],
    }),
    getTeamMembers: builder.query({
      query: (teamId) => ({
        url: `members/${teamId}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.result.members;
      },
    }),
    requestMembersChange: builder.mutation({
      query: (requestType) => ({
        url: 'teams/request-members-change',
        method: 'POST',
        body: { requestType },
      }),
    }),
    requestUpgrade: builder.mutation({
      query: ({ teamId, requestType }) => ({
        url: `teams/${teamId}/request-upgrade`,
        method: 'POST',
        body: { requestType },
      }),
    }),
    addEditRole: builder.mutation({
      query: (args) => ({
        url: `teams/${args.teamId}/roles`,
        method: 'PATCH',
        body: { ...args },
      }),
      invalidatesTags: ['Teams'],
    }),
    createTeam: builder.mutation({
      query: (payload) => ({
        url: 'teams',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response) => {
        return { isOk: true, teamId: response.result.teamId };
      },
      transformErrorResponse: (error) => {
        if (error) {
          return { isOk: false };
        }
      },
      invalidatesTags: ['User'],
    }),
    addToTeam: builder.mutation({
      query: (inviteId) => ({
        url: 'members',
        method: 'POST',
        body: { inviteId },
      }),
      transformResponse: (response) => {
        return {
          isOk: true,
          referrerId: response.result.referrerId,
          inviteId: response.result.inviteId,
        };
      },
      transformErrorResponse: (error) => {
        if (error) {
          return { isOk: false };
        }
      },
    }),
    requestQuotaIncrease: builder.mutation({
      query: (args) => ({
        url: 'teams/request-quota-increase',
        method: 'POST',
        body: { ...args },
      }),
    }),
    getInvite: builder.query({
      query: (inviteId) => ({
        url: `invites/${inviteId}`,
        method: 'GET',
        nonProtected: true,
      }),
      transformResponse: (response) => {
        return response.result.invite;
      },
    }),
    updateTeam: builder.mutation({
      query: (args) => ({
        url: `teams/${args.teamId}`,
        method: 'PATCH',
        body: { ...args },
      }),
      invalidatesTags: ['Teams'],
    }),
    requestDemo: builder.mutation({
      query: (args) => ({
        url: `teams/${args.teamId}/request-demo`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Teams'],
    }),
    testEmail: builder.mutation({
      query: ({ teamId, ...args }) => ({
        url: `teams/${teamId}/test-email`,
        method: 'POST',
        body: { ...args },
      }),
    }),
    addEmail: builder.mutation({
      query: (args) => ({
        url: `teams/${args.teamId}/add-email`,
        method: 'POST',
        body: { provider: 'googleOAuth' },
      }),
      transformResponse: (response) => {
        return response.result.url;
      },
    }),
    sendPreviewEmail: builder.mutation({
      query: (payload) => ({
        url: 'teams/send-preview-email',
        method: 'POST',
        body: payload,
      }),
    }),
    setup: builder.mutation({
      query: ({ teamId, ...args }) => ({
        url: `teams/${teamId}/setup`,
        method: 'PATCH',
        body: args,
      }),
      invalidatesTags: ['Icps', 'AccountQualifications', 'SequenceTemplates'],
    }),
    actOnPerson: builder.mutation({
      query: ({ teamId, personId, action, companyId }) => ({
        url: `teams/${teamId}/persons/${personId}/${action}`,
        method: 'POST',
        body: { companyId },
      }),
      invalidatesTags: ['Teams'],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        let dispatchFunction;

        if (args.action === 'undo') {
          dispatchFunction = dispatch(
            apiSlice.util.updateQueryData(
              'search',
              {
                icpId: args.icpId,
                leadFilters: args.leadFilters?.map((lfs) =>
                  lfs.map((lf) => lf._id)
                ),
                companyFilters: args.companyFilters?.map((cfs) =>
                  cfs.map((cf) => cf._id)
                ),
                searchExclusivity: args.searchExclusivity,
                requestId: args.requestId,
                companyLinkedinUrl: args.companyLinkedinUrl,
              },
              (draft) => {
                draft.result.accounts = draft.result.accounts.map(
                  (resultAccount) => {
                    if (resultAccount.companyId === args.companyId) {
                      return {
                        ...resultAccount,
                        leads: resultAccount.leads.map((lead) => {
                          if (lead.personId === args.personId) {
                            return {
                              ...omit(lead, ['state', 'enqueuedAt']),
                            };
                          }
                          return lead;
                        }),
                      };
                    }

                    return resultAccount;
                  }
                );
              }
            )
          );
        }
        try {
          await queryFulfilled;
        } catch (e) {
          dispatchFunction.undo();
        }
      },
    }),
    actOnCompany: builder.mutation({
      query: ({ teamId, companyId, action }) => ({
        url: `teams/${teamId}/companies/${companyId}/${action}`,
        method: 'POST',
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        let dispatchFunction;

        if (args.action === 'undo') {
          dispatchFunction = dispatch(
            apiSlice.util.updateQueryData(
              'getExcludedAccounts',
              { teamId: args.teamId },
              (draft) => {
                draft.result.excludedAccounts =
                  draft.result.excludedAccounts.filter(
                    (acc) => acc.companyId !== args.companyId
                  );
              }
            )
          );
        } else {
          dispatchFunction = dispatch(
            apiSlice.util.updateQueryData(
              'getExcludedAccounts',
              { teamId: args.teamId },
              (draft) => {
                draft.result.excludedAccounts = [
                  args.account,
                  ...draft.result.excludedAccounts.filter(
                    (acc) => acc.companyId !== args.companyId
                  ),
                ];
              }
            )
          );
        }
        try {
          await queryFulfilled;
        } catch (e) {
          if (dispatchFunction) dispatchFunction.undo();
        }
      },
      invalidatesTags: ['Teams'],
    }),
    getExcludedAccounts: builder.query({
      query: ({ teamId }) => ({
        url: `teams/${teamId}/excluded-accounts`,
        method: 'GET',
      }),
    }),
    requestAddTeam: builder.mutation({
      query: (payload) => ({
        url: 'teams/request-add-team',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetTeamsQuery,
  useLazyGetTeamsQuery,
  useLazyGetTeamDetailsQuery,
  useGetTeamDetailsQuery,
  useGetTeamMembersQuery,
  useRequestMembersChangeMutation,
  useAddEditRoleMutation,
  useAddToTeamMutation,
  useCreateTeamMutation,
  useGetInviteQuery,
  useLazyGetInviteQuery,
  useRequestQuotaIncreaseMutation,
  useRequestUpgradeMutation,
  useUpdateTeamMutation,
  useRequestDemoMutation,
  useAddEmailMutation,
  useTestEmailMutation,
  useSendPreviewEmailMutation,
  useSetupMutation,
  useActOnPersonMutation,
  useActOnCompanyMutation,
  useGetExcludedAccountsQuery,
  useRequestAddTeamMutation,
} = teamsApi;
