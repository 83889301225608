import { useCallback, useState } from 'react';
import {
  Divider,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
} from '@chakra-ui/react';

import { ReactComponent as SearchIcon } from '~/v2/assets/icons/search.svg';
import { ReactComponent as ClearBack } from '~/v2/assets/icons/clear/clear-back.svg';
import TruebaseAccordion from '../TruebaseAccordion/TruebaseAccountAccordion/TruebaseAccordion';
import { useLazySearchQuery } from '~/v2/redux/services/accounts';
import { useSelector } from 'react-redux';
import { SpotlightSearchProvider } from './SpotlightSearchProvider';
import { useSpotlightSearchContext } from './useSpotlightSearchContext';
import schemas from '~/utils/schemas';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { getSelectedIcp } from '~/v2/redux/slices/icpSlice';

export default function SpotlightSearch() {
  const { handleAsyncError } = useErrorHandler();
  const [validationError, setValidationError] = useState(false);
  const [open, setOpen] = useState({});
  const [isFocused, setIsFocused] = useState(false);
  const [getSearchResults, response] = useLazySearchQuery();
  const selectedIcp = useSelector(getSelectedIcp);
  const {
    spotlightSearchSubmitted,
    setSpotlightSearchSubmitted,
    spotlightSearchValue,
    setSpotlightSearchValue,
    setSpotlightSearchReqId,
    setIsSpotlightSearchMode,
  } = useSpotlightSearchContext();

  let newRequestId;

  const setOpenSpecific = useCallback(
    (key) => {
      setOpen({
        ...Object.keys(open).reduce((acc, cv) => {
          return {
            ...acc,
            [cv]: false,
          };
        }, {}),
        [key]: !open[key],
      });
    },
    [open, setOpen]
  );

  const onChange = async ({ target: { value } }) => {
    setSpotlightSearchValue(value);
  };

  const onClear = () => {
    setSpotlightSearchSubmitted(false);
    setSpotlightSearchValue('');
  };

  const handleSearch = handleAsyncError(async ({ key }) => {
    if (key === 'Enter' && spotlightSearchValue) {
      try {
        await schemas.companyLinkedinHandleOrUrlSchema.validate(
          spotlightSearchValue
        );
        setValidationError(false);
      } catch {
        setValidationError(true);
        return;
      }

      newRequestId = Math.random();
      setSpotlightSearchReqId(newRequestId);
      setIsSpotlightSearchMode(true);
      getSearchResults({
        icpId: selectedIcp._id,
        requestId: newRequestId,
        companyFilters: [],
        leadFilters: [],
        limit: 1,
        companyLinkedinUrl: spotlightSearchValue,
        type: 'spotlight',
      });
      setSpotlightSearchSubmitted(true);
    }
  });

  const isLoadingState =
    !response.isUninitialized &&
    ((typeof response?.data?.isStreamingAccounts === 'undefined' &&
      response?.data?.result?.accounts.length === 0) ||
      response?.data?.isStreamingAccounts ||
      response.isLoading ||
      response.isFetching);
  const result =
    spotlightSearchSubmitted && response?.data?.result?.accounts?.[0];
  const hasError =
    (spotlightSearchSubmitted &&
      response.status === 'fulfilled' &&
      !result &&
      !isLoadingState) ||
    validationError;

  const showModalBody =
    (spotlightSearchSubmitted && (isLoadingState || result || hasError)) ||
    validationError;
  const showModalData = spotlightSearchSubmitted && result && !isLoadingState;

  return (
    <Flex
      p={showModalData ? '16px 16px 0' : '16px'}
      bg="white"
      mt="16px"
      borderRadius="4px"
      boxShadow={isFocused ? '10px 20px 40px 0px #7E8BAD33' : 'unset'}
      border="1px solid"
      borderColor={isFocused ? 'transparent' : 'trueLight'}
      flexDir="column"
      gap="16px"
      pos="relative"
      zIndex="2"
    >
      <InputGroup>
        <Input
          variant="truebase"
          h="40px"
          placeholder="Enter a company Linkedin handle or URL"
          value={spotlightSearchValue}
          onChange={onChange}
          onKeyUpCapture={handleSearch}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
        />
        <InputRightElement
          sx={{ svg: { fill: 'trueDim' }, cursor: 'pointer' }}
          mr="8px"
          onClick={onClear}
        >
          {spotlightSearchValue ? <ClearBack /> : <SearchIcon />}
        </InputRightElement>
      </InputGroup>

      {showModalBody && (
        <Flex flexDir="column">
          <Divider />
          {isLoadingState && (
            <Flex gap="8px" mt="16px">
              <Spinner size="sm" color="trueBlue" />
              <Text
                fontWeight="400"
                fontSize="12px"
                lineHeight="14px"
                color="trueSpace"
              >
                Locating the requested profile..
              </Text>
            </Flex>
          )}
          {hasError && (
            <Text
              fontWeight="400"
              fontSize="12px"
              lineHeight="14px"
              color="trueAttention"
              mt="16px"
            >
              {validationError
                ? 'Please enter a valid company Linkedin handle or URL!'
                : 'Uh-oh! The profile wasn`t found. Spelling error?'}
            </Text>
          )}
          {showModalData && (
            <TruebaseAccordion
              key={result.companyId}
              showHitsAndMisses
              setOpen={setOpenSpecific}
              isOpen={open[result.companyId]}
              accordionKey={result.companyId}
              account={result}
              accordionItemProps={{
                px: 'unset',
                border: 'unset',
                overflow: 'hidden',
              }}
            />
          )}
        </Flex>
      )}
    </Flex>
  );
}

export const SpotlightSearchWithContext = () => {
  return (
    <SpotlightSearchProvider>
      <SpotlightSearch />
    </SpotlightSearchProvider>
  );
};
