import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';
import { AnimatePresence, motion, useInView } from 'framer-motion';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as CheckOff } from '~/v2/assets/icons/clear/clear-large.svg';
import { ReactComponent as CheckOffSmall } from '~/v2/assets/icons/clear/clear-small.svg';
import { useLazyGetLeadsForAccountQuery } from '../../../redux/services/accounts';
import AvatarSwitcher from '../components/AvatarSwitcher';
import MatchScoresPanel from './MatchScoresPanel';
import { CompanyInfo, CompanySocialInfo } from '../components/CompanyInfo';
import { useFilterContext } from '~/v2/features/filters/components/useFilterContext';
import AccountTabs from './AccountTabs';
import GeneratingStatus from '../components/GeneratingStatus';
import { isEqual } from 'lodash';
import { getStatus } from '~/v2/redux/slices/statusSlice';
import { ReactComponent as Skip } from '~/v2/assets/icons/restore.svg';
import { ReactComponent as ThreeDots } from '~/v2/assets/icons/more/more-vertical.svg';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { useQualificationContext } from '~/v2/features/filters/components/useQualificationContext';
import { utils } from '../../utils';
import { useSpotlightSearchContext } from '../../SpotlightSearch/useSpotlightSearchContext';
import { TruebaseTooltip } from '../../TruebaseTooltip';
import { getSelectedIcp } from '~/v2/redux/slices/icpSlice';
import { getSelectedAccountQualification } from '~/v2/redux/slices/accountQualificationSlice';
import { useTestQualificationMutation } from '~/v2/redux/services/accountQualification';
import { getSelectedTeam } from '~/v2/redux/slices/teamsSlice';
import { useActOnCompanyMutation } from '~/v2/redux/services/teams';
import { useLocation } from 'react-router';

const socialProfilesOrder = [
  'website',
  'linkedin',
  'crunchbase',
  'salesnavigator',
  'facebook',
  'twitter',
  'instagram',
  'angellist',
  'github',
  'medium',
  'youtube',
  'quora',
  'reddit',
];

const TruebaseAccordion = ({
  accordionKey,
  setOpen,
  isOpen,
  account,
  showHitsAndMisses,
  readonly,
  accountIndex,
  showOnHover = true,
  accordionItemProps = {
    borderWidth: '1px',
    borderColor: 'trueLight',
    borderRadius: '4px',
    overflow: 'hidden',
    bgColor: 'white',
    px: '16px',
  },
}) => {
  const { handleAsyncError, handleSyncError } = useErrorHandler();
  const [internalIndex, setInternalIndex] = useState(0);
  const [accordionOpenedBefore, setAccordionOpenedBefore] = useState(false);
  const [accountTabsIndex, setAccountsTabIndex] = useState(0);
  const isStatusDirty = useRef(false);
  const [getLeadsForAccount, leadsResponse] = useLazyGetLeadsForAccountQuery();
  const selectedIcp = useSelector(getSelectedIcp);
  const selectedTeam = useSelector(getSelectedTeam);
  const selectedAccountQualification = useSelector(
    getSelectedAccountQualification
  );
  const { testingQualificationMinScore, trainView } = useFilterContext();
  const { companyFilters, leadFilters, searchExclusivity, requestId } =
    useFilterContext();
  const {
    isSpotlightSearchMode,
    setSpotlightSearchSubmitted,
    setSpotlightSearchValue,
  } = useSpotlightSearchContext();

  const toast = useToast();
  const itemRef = useRef();
  const isInView = useInView(itemRef);
  const location = useLocation();

  const [actOnCompany] = useActOnCompanyMutation();

  const [isAccountExcluded, setIsAccountExcluded] = useState(
    Object.keys(selectedTeam?.excludedCompanies || {}).includes(
      account.companyId
    )
  );

  const isGeneratingAccountScore = useSelector(getStatus)(
    account.companyId
  )?.find(({ type }) => type === 'accountScoring');

  useEffect(() => {
    const wrappedFn = handleSyncError(() => {
      if (
        isOpen &&
        account.status === 'accepted' &&
        !account.leads &&
        readonly
      ) {
        getLeadsForAccount(
          {
            icpId: selectedIcp._id,
            companyId: account.companyId,
            teamId: selectedIcp.teamId,
            companyFilters: companyFilters,
            leadFilters: leadFilters,
            searchExclusivity,
            requestId,
            readonly,
          },
          true
        );
      }
    });
    wrappedFn();
  }, [
    handleSyncError,
    readonly,
    account.status,
    account.leads,
    account.companyId,
    isOpen,
    selectedIcp._id,
    selectedIcp.teamId,
    getLeadsForAccount,
    companyFilters,
    leadFilters,
    searchExclusivity,
    requestId,
  ]);

  const socialProfiles = useMemo(() => {
    if (!account.socialProfiles) return [];

    return account.socialProfiles.slice().sort((a, b) => {
      return (
        socialProfilesOrder.indexOf(a.service) -
        socialProfilesOrder.indexOf(b.service)
      );
    });
  }, [account.socialProfiles]);

  useEffect(() => {
    if (!isStatusDirty.current) {
      isStatusDirty.current = true;
    }
  }, [account.status]);

  const handleOpen = useCallback(
    (isExpanded, currentKey) => {
      if (isExpanded) {
        if (internalIndex !== currentKey) {
          setInternalIndex(currentKey);
        } else if (internalIndex === currentKey) {
          setOpen(accordionKey);
        }
      } else {
        setOpen(accordionKey);
        setInternalIndex(currentKey);
      }
    },
    [accordionKey, internalIndex, setOpen]
  );

  useEffect(() => {
    if (
      isGeneratingAccountScore &&
      !isOpen &&
      !accordionOpenedBefore &&
      accountIndex === 0 &&
      trainView === 'qualification'
    ) {
      setAccordionOpenedBefore(true);
      handleOpen(false, 2);
    }
  }, [
    isGeneratingAccountScore,
    handleOpen,
    isOpen,
    accordionOpenedBefore,
    accountIndex,
    trainView,
  ]);

  useEffect(() => {
    setIsAccountExcluded(
      Object.keys(selectedTeam?.excludedCompanies || {}).includes(
        account.companyId
      )
    );
  }, [selectedTeam?.excludedCompanies, account.companyId]);

  const [testQualification] = useTestQualificationMutation();
  const { qualificationQuestions, minScore } = useQualificationContext();

  const isTestScoringDisabled =
    qualificationQuestions.findIndex((q) => !q.content) > -1;

  const preventAccordionOpen = location.pathname.includes(
    '/settings/exclude-list'
  );

  const handleAccountScoring = async (e) => {
    e.stopPropagation();
    !isOpen && handleOpen(false, 2);
    const res = await testQualification({
      icpId: selectedIcp._id,
      companyIds: [account.companyId],
      ...(minScore && { qualificationMinScore: minScore / 100 }),
      qualificationQuestions: utils.formatQualifications(
        selectedAccountQualification.qualificationQuestions,
        qualificationQuestions
      ),
    }).unwrap();
    if (!res?.isOk) {
      toast({
        title: 'Error',
        description: 'An error occurred! Try again later',
        status: 'error',
        duration: 9000,
      });
    }
  };

  const handleExcludeAccount = handleAsyncError(async (e) => {
    e.stopPropagation();
    setIsAccountExcluded(!isAccountExcluded);
    setSpotlightSearchSubmitted(false);
    setSpotlightSearchValue('');
    await actOnCompany({
      companyId: account.companyId,
      action: isAccountExcluded ? 'undo' : 'exclude',
      icpId: selectedIcp._id,
      teamId: selectedTeam._id,
      account,
    });
  });

  const action = {
    '/settings/qualification': (
      <TruebaseTooltip
        label={
          isTestScoringDisabled ? 'Add at least one qualification question' : ''
        }
      >
        <Button
          size="sm"
          variant="truebaseOutline"
          fontSize="13px"
          lineHeight="15px"
          fontWeight="medium"
          minW="106px"
          isDisabled={isTestScoringDisabled}
          onClick={handleAccountScoring}
        >
          Test Scoring
        </Button>
      </TruebaseTooltip>
    ),
    '/settings/exclude-list': (
      <Button
        size="sm"
        variant="truebaseOutline"
        fontSize="13px"
        lineHeight="15px"
        fontWeight="medium"
        minW="106px"
        onClick={handleExcludeAccount}
      >
        {isAccountExcluded ? 'Remove from exclude list' : 'Exclude'}
      </Button>
    ),
  };

  return (
    <Box ref={itemRef} sx={!isInView && { bg: 'white', w: '100%', h: '82px' }}>
      <AnimatePresence>
        {isInView && (
          <motion.div
            key="truebaseAccourdion"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.25 } }}
            exit={{ opacity: 0, transition: { duration: 0.25 } }}
          >
            <Accordion
              sx={{
                '& .match-score-icon': { opacity: isOpen ? 1 : 0 },
                '.show-on-hover': {
                  transition: 'opacity 0.2s ease',
                  opacity: isOpen ? 1 : 0,
                  '&:hover': { opacity: 1 },
                },
              }}
              _hover={{
                '& .match-score-icon': { opacity: 1 },
                '.show-on-hover': { opacity: 1 },
              }}
              allowToggle
              onChange={() => setOpen(accordionKey)}
              index={isOpen && !preventAccordionOpen ? 0 : -1}
            >
              <AccordionItem {...accordionItemProps}>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton
                      _hover={{}}
                      _focus={{}}
                      _active={{}}
                      py={0}
                      px="unset"
                      onClick={() => handleOpen(isExpanded, 2)}
                      as="div"
                      cursor="pointer"
                    >
                      <Flex
                        alignItems="center"
                        w="100%"
                        bg="white"
                        p={4}
                        borderColor="trueLight"
                        px="0"
                      >
                        <Button
                          width="fit-content"
                          _hover={{}}
                          _focus={{}}
                          _active={{}}
                          variant="ghost"
                          p={0}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpen(isExpanded, 2);
                          }}
                        >
                          <AvatarSwitcher
                            type="company"
                            src={account.avatar}
                            name={account.name}
                            domain={account.domains?.[0]}
                          />
                        </Button>
                        <Flex
                          color="trueMedium"
                          flexDirection="column"
                          ml={4}
                          gap={1}
                        >
                          <Flex alignItems="center" gap="8px">
                            <CompanySocialInfo
                              socialProfiles={socialProfiles}
                              account={account}
                            />
                            <GeneratingStatus
                              account={
                                isOpen
                                  ? { ...account, leads: undefined }
                                  : account
                              }
                              priority="accounts"
                            />
                          </Flex>
                          <CompanyInfo account={account} />
                        </Flex>
                        <Spacer />
                        <Box display="flex" alignItems="center" columnGap={8}>
                          <Flex gap={4}>
                            {!readonly && (
                              <>
                                {account?.qualificationScore !== undefined &&
                                  account.status !== 'rejected' &&
                                  trainView === 'qualification' &&
                                  !isGeneratingAccountScore && (
                                    <Flex
                                      borderWidth="1px"
                                      borderColor={
                                        account?.qualificationScore >=
                                        testingQualificationMinScore
                                          ? 'trueAnason'
                                          : 'trueCorral'
                                      }
                                      borderRadius="24px"
                                      width="110px"
                                      alignItems="center"
                                      justifyContent="center"
                                      mr="32px"
                                    >
                                      <Text
                                        py="5px"
                                        sx={{
                                          fontSize: '12px',
                                          lineHeight: '14px',
                                          fontWeight: 500,
                                          color: 'trueExplain',
                                        }}
                                      >
                                        Score{' '}
                                        {(
                                          account.qualificationScore * 100
                                        ).toFixed(0)}
                                        %
                                      </Text>
                                    </Flex>
                                  )}

                                {!isSpotlightSearchMode &&
                                  trainView !== 'qualification' && (
                                    <Flex
                                      alignItems="center"
                                      className={
                                        showOnHover ? 'show-on-hover' : ''
                                      }
                                    >
                                      {isAccountExcluded && (
                                        <Flex
                                          alignItems="center"
                                          justifyContent="center"
                                          gap="6px"
                                          border="1px solid"
                                          borderColor="trueLight"
                                          borderRadius="4px"
                                          padding="5px 0"
                                          w="118px"
                                        >
                                          <Icon
                                            as={CheckOffSmall}
                                            color="trueDim"
                                            w="16px"
                                            h="16px"
                                          />
                                          <Text
                                            fontWeight="500"
                                            fontSize="12px"
                                            lineHeight="14px"
                                            color="trueDim"
                                          >
                                            Excluded
                                          </Text>
                                        </Flex>
                                      )}

                                      <Menu variant="truebase">
                                        <MenuButton
                                          onClick={(e) => e.stopPropagation()}
                                          as={IconButton}
                                          ml="16px"
                                          w="32px"
                                          h="32px"
                                          minW={0}
                                          className="showOnHover"
                                          sx={{
                                            svg: { fill: 'trueDim' },
                                          }}
                                          variant="ghost"
                                          icon={<ThreeDots />}
                                        />
                                        {isAccountExcluded ? (
                                          <MenuList>
                                            <MenuItem
                                              onClick={handleAsyncError(
                                                async (e) => {
                                                  e.stopPropagation();
                                                  setIsAccountExcluded(false);
                                                  await actOnCompany({
                                                    companyId:
                                                      account.companyId,
                                                    action: 'undo',
                                                    icpId: selectedIcp._id,
                                                    teamId: selectedTeam._id,
                                                    account,
                                                  });
                                                }
                                              )}
                                            >
                                              <Skip />
                                              <Text>
                                                Remove from exclude list
                                              </Text>
                                            </MenuItem>
                                          </MenuList>
                                        ) : (
                                          <MenuList>
                                            <MenuItem
                                              onClick={handleAsyncError(
                                                async (e) => {
                                                  e.stopPropagation();
                                                  setIsAccountExcluded(true);
                                                  await actOnCompany({
                                                    companyId:
                                                      account.companyId,
                                                    action: 'exclude',
                                                    icpId: selectedIcp._id,
                                                    teamId: selectedTeam._id,
                                                    account,
                                                  });
                                                }
                                              )}
                                            >
                                              <CheckOff />
                                              <Text>Add to exclude list</Text>
                                            </MenuItem>
                                          </MenuList>
                                        )}
                                      </Menu>
                                    </Flex>
                                  )}

                                {isSpotlightSearchMode &&
                                  action[location.pathname]}
                              </>
                            )}
                          </Flex>
                        </Box>
                      </Flex>
                    </AccordionButton>
                    {isInView && (
                      <AnimatePresence>
                        {(() => {
                          switch (internalIndex) {
                            case 1:
                              return (
                                <motion.div
                                  initial={{ y: 248, height: 0, opacity: 0 }}
                                  animate={{ y: 0, height: 'auto', opacity: 1 }}
                                  exit={{ y: 248, height: 0, opacity: 0 }}
                                  transition="linear"
                                  key="two"
                                >
                                  <AccordionPanel
                                    py={6}
                                    px={0}
                                    borderTopWidth="1px"
                                    borderColor="trueLight"
                                    key="two"
                                    bg="white"
                                  >
                                    <MatchScoresPanel
                                      subScores={account?.score?.subScores}
                                    />
                                  </AccordionPanel>
                                </motion.div>
                              );
                            case 2:
                              return (
                                <motion.div
                                  initial={{ y: 248, height: 0, opacity: 0 }}
                                  animate={{ y: 0, height: 'auto', opacity: 1 }}
                                  exit={{ y: 248, height: 0, opacity: 0 }}
                                  transition="linear"
                                  key="three"
                                >
                                  <AccordionPanel
                                    pb="16px"
                                    pt={0}
                                    px={0}
                                    borderTopWidth={
                                      internalIndex !== 2 ? '1px' : '0px'
                                    }
                                    borderColor="trueLight"
                                    key="two"
                                    bg="white"
                                  >
                                    <AccountTabs
                                      tabIndex={accountTabsIndex}
                                      setTabIndex={setAccountsTabIndex}
                                      readonly={readonly}
                                      leadsResponse={leadsResponse}
                                      showHitsAndMisses={showHitsAndMisses}
                                      account={account}
                                      companyFilters={companyFilters}
                                      accountIndex={accountIndex}
                                    />
                                  </AccordionPanel>
                                </motion.div>
                              );
                          }
                        })()}
                      </AnimatePresence>
                    )}
                  </>
                )}
              </AccordionItem>
            </Accordion>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

const MemoizedTruebaseAccordion = memo(
  TruebaseAccordion,
  (prevProps, nextProps) => {
    const { accordionKey, isOpen, account } = prevProps;
    const {
      accordionKey: nextAccordionKey,
      isOpen: nextIsOpen,
      account: nextAccount,
    } = nextProps;
    return (
      nextAccordionKey === accordionKey &&
      isOpen === nextIsOpen &&
      isEqual(account, nextAccount)
    );
  }
);

export default MemoizedTruebaseAccordion;
