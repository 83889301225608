import { Flex, Switch, Text } from '@chakra-ui/react';
import { AutopilotStepCard } from '../AutopilotStepCard';
import { ReactComponent as QualficationsIcon } from '~/v2/assets/icons/qualifications.svg';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedAccountQualification,
  setSelectedAccountQualification,
} from '~/v2/redux/slices/accountQualificationSlice';
import { useAddAccountQualificationMutation } from '~/v2/redux/services/accountQualification';
import { useNavigate } from 'react-router';

export const AutopilotQualifyStep = ({
  isQualifyEnabled,
  setIsQualifyEnabled,
  selectedQualificationSetting,
  setSelectedQualificationSetting,
  navigationState,
  accountQualifications,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedAccountQualification = useSelector(
    getSelectedAccountQualification
  );

  const [addAccountQualification, { isLoading: isSavingAccountQualification }] =
    useAddAccountQualificationMutation();

  return (
    <AutopilotStepCard
      title={
        <Flex alignItems="center" gap="8px">
          <Switch
            variant="truebaseAutopilot"
            isChecked={isQualifyEnabled}
            onChange={() => {
              setIsQualifyEnabled(!isQualifyEnabled);
            }}
          />
          <Text fontWeight="500" fontSize="14px" lineHeight="16px">
            Qualify
          </Text>
        </Flex>
      }
      subtitle="Ensure only accounts above your minimum qualification score get through"
      extraContent={
        get(selectedQualificationSetting, 'lastTestResult') &&
        isQualifyEnabled ? (
          <Text
            fontWeight="400"
            fontSize="13px"
            lineHeight="16px"
            color="trueExplain"
          >
            {(
              (selectedQualificationSetting.lastTestResult.passed /
                (selectedQualificationSetting.lastTestResult.passed +
                  selectedQualificationSetting.lastTestResult.failed)) *
                100 || 0
            ).toFixed(0)}
            % estimated to pass
          </Text>
        ) : (
          <></>
        )
      }
      icon={QualficationsIcon}
      selectLogo={QualficationsIcon}
      defaultValue={{
        value: selectedAccountQualification?._id,
        label: selectedAccountQualification?.name,
      }}
      onChange={(value) => {
        setSelectedQualificationSetting(
          accountQualifications.find(
            (accountQualification) => accountQualification._id === value
          )
        );
      }}
      options={accountQualifications?.map((accountQualification) => ({
        value: accountQualification._id,
        label: `${accountQualification.name}`,
      }))}
      onEditClick={() => {
        dispatch(
          setSelectedAccountQualification(
            accountQualifications.find(
              ({ _id }) => selectedQualificationSetting._id === _id
            )
          )
        );
        navigate('/settings/qualification', {
          state: navigationState,
        });
      }}
      isEnabled={isQualifyEnabled}
      createFn={addAccountQualification}
      isCreateLoading={isSavingAccountQualification}
      onCreate={({ accountQualification }) => {
        dispatch(setSelectedAccountQualification(accountQualification));
        navigate('/settings/qualification', {
          state: { ...navigationState, isPending: true },
        });
      }}
      name="Account Qualification"
      data={accountQualifications}
    />
  );
};
