import { Box, Spacer } from '@chakra-ui/react';
import { ReactComponent as FilterSmart } from '../../../assets/icons/filter/filter-smart.svg';
import { useEffect, useState } from 'react';
import { TruebaseTooltip } from '../../../common/TruebaseTooltip';
import { companyCategoryIconMap, leadCategoryIconMap } from './filterIcons';

export default function ListItem(props) {
  const [type, setType] = useState('all');
  const [pathSplit, setPathSplit] = useState([]);
  useEffect(() => {
    const pathSplit = props.path.split('|');
    setPathSplit(pathSplit);
    if (props.publicName === 'All') {
      setType('all');
      return;
    }
    if (pathSplit.length === 1) {
      setType('category');
    } else {
      setType('subcategory');
    }
  }, [props.path, props.publicName]);
  const color =
    props.count === -1 || props.count === 0
      ? 'trueDisabled'
      : props.selected
      ? 'trueSpace'
      : 'trueDim';
  if (!props.selectedStat?.includes(pathSplit[0]) && type === 'subcategory') {
    return null;
  }
  return (
    <Box
      onClick={
        props.count !== -1 || props.count === 0 ? props.onClick : undefined
      }
      display="flex"
      padding="12px 16px"
      alignItems="center"
      cursor={props.count === -1 || props.count === 0 ? 'inherit' : 'pointer'}
      userSelect="none"
      w="100%"
      borderRadius="4px"
      border={props.selected ? '1px solid' : 'unset'}
      borderColor="trueLight"
      backgroundColor={props.selected ? 'trueBg02' : 'unset'}
    >
      <Box
        mr="16px"
        textAlign="center"
        w="24px"
        h="24px"
        sx={{
          svg: {
            width: '24px',
            height: '24px',
            path: {
              fill:
                props.count === -1 || props.count === 0
                  ? 'trueDisabled'
                  : props.selected
                  ? 'trueSpace'
                  : 'trueDim',
            },
          },
        }}
      >
        {props.type === 'company'
          ? companyCategoryIconMap[props._id]
          : leadCategoryIconMap[props._id]}
      </Box>
      <Box
        sx={{
          fontSize: '14px',
          lineHeight: '16px',
          fontWeight: 500,
        }}
        as="span"
        color={color}
      >
        {props.publicName === 'All' ? 'All filters' : props.publicName}
      </Box>
      <Spacer />
      {props.smart && (
        <TruebaseTooltip
          maxW="275px"
          label="Smart filters are inferred by AI - beyond basic demographics and firmo-graphics in profiles."
        >
          <Box mr="8px" sx={{ svg: { fill: 'trueBlue' } }}>
            <FilterSmart />
          </Box>
        </TruebaseTooltip>
      )}
      {props.count !== -1 && (
        <TruebaseTooltip label="Number of filters in this category">
          <Box
            as="span"
            fontSize="12px"
            lineHeight="14px"
            fontWeight="500"
            color={
              props.count === 0
                ? 'trueDisabled'
                : props.selected
                ? 'trueSpace'
                : 'trueDim'
            }
            textAlign="right"
          >
            {props.count}
          </Box>
        </TruebaseTooltip>
      )}
    </Box>
  );
}
