/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useState } from 'react';

export const PersonalizationContext = createContext({
  sequenceSettings: {},
  setSequenceSettings: () => {},
});

export const PersonalizationProvider = ({
  children,
  initialSequenceSettings,
}) => {
  const [sequenceSettings, setSequenceSettings] = useState(
    initialSequenceSettings
  );

  return (
    <PersonalizationContext.Provider
      value={{
        sequenceSettings,
        setSequenceSettings,
      }}
    >
      {children}
    </PersonalizationContext.Provider>
  );
};
