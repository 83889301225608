import React, { Component, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Center, Text } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import logglyLog from './Logger';
import Layout from '../Layout';
import { ReactComponent as ErrorImg } from '../../assets/icons/broken-pen.svg';
import { ReactComponent as Refresh } from '../../assets/icons/sync.svg';

function RouterListener({ clearError }) {
  const location = useLocation();
  const currentPath = useRef(location.pathname);

  useEffect(() => {
    if (currentPath.current !== location.pathname) {
      clearError();
      currentPath.current = location.pathname;
    }
  }, [location.pathname, clearError]);

  return null;
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, info) {
    logglyLog({ error, info });
  }

  clearError = () => {
    this.setState({ error: null });
  };

  render() {
    if (this.state.error) {
      return (
        <Layout>
          <Center flexDir="column" gap="16px" h="100%">
            <ErrorImg />
            <Text
              sx={{
                fontSize: '22px',
                fontWeight: '700',
                lineHeight: '26px',
                color: 'trueSpace',
                mt: '16px',
              }}
            >
              Oops, something went wrong.
            </Text>
            <Button
              variant="truebaseOutlineNeutral"
              h="32px"
              p="0 16px 0 8px"
              iconSpacing="8px"
              leftIcon={<Refresh />}
              onClick={() => {
                window.location.replace('/autopilot');
              }}
            >
              Try again
            </Button>
            <Text
              sx={{ fontSize: '14px', lineHeight: '16px', color: 'trueMedium' }}
            >
              We&apos;ve flagged the issue for resolution, but if it lingers,
              we&apos;d appreciate your input via the chat in the lower right
              corner.
            </Text>
          </Center>
          <RouterListener clearError={this.clearError} />
        </Layout>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
