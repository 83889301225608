import { useCallback, useMemo, useRef, useEffect } from 'react';
import { Button, Flex, IconButton, Spacer } from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './styles.css';
import 'quill-mention';
import { emailData } from '@truebase/common/config/schemas.json';
import { ReactComponent as AddSequenceVariable } from '~/v2/assets/icons/sequence-variable.svg';
import { ReactComponent as SendIcon } from '~/v2/assets/icons/mail/send.svg';
import { useIsDirtyContext } from '~/v2/contexts/useIsDirtyContext';

const emailVariables = emailData.map((v) => ({
  label: `{{{${v.publicName}}}} - ${v.publicName}`,
  value: `{{${v.publicName}}}}`,
}));

const CustomToolbar = ({ index, handleTestEmail, isTestEmailDisabled }) => (
  <div id={`ql-toolbar-message-${index}`}>
    <Flex alignItems="center" gap="8px" direction="row">
      <IconButton
        className="ql-insertVariable toolbar-icon-btn"
        variant="ghost"
        sx={{ _hover: { path: { fill: '#0280F5' } } }}
      >
        <AddSequenceVariable />
      </IconButton>
      <IconButton className="ql-link toolbar-icon-btn" variant="ghost" />
      <select className="ql-size" defaultValue="normal">
        <option value="small">Small</option>
        <option value="normal">Normal</option>
        <option value="large">Large</option>
        <option value="huge">Huge</option>
      </select>
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <select className="ql-color"></select>
      <select className="ql-background"></select>
      <Spacer />
      <Button
        className="toolbar-btn"
        variant="truebaseTextHighlight"
        onClick={(e) => {
          e.stopPropagation();
          handleTestEmail?.();
        }}
        leftIcon={<SendIcon />}
        iconSpacing="8px"
        isDisabled={isTestEmailDisabled}
      >
        Send test email
      </Button>
    </Flex>
  </div>
);

const SequenceMessageTextEditor = ({
  value,
  onChange,
  isError,
  index,
  handleTestEmail,
}) => {
  const quillRef = useRef(null);
  const { setIsSequenceTemplateDirty } = useIsDirtyContext();

  const insertVariable = useCallback(() => {
    const quill = quillRef.current.getEditor();
    quill.theme.modules.mention.openMenu('{');
  }, []);

  useEffect(() => {
    const quill = quillRef.current.getEditor();
    const toolbar = quill.getModule('toolbar');
    toolbar.addHandler('insertVariable', insertVariable);
  }, [insertVariable]);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: `#ql-toolbar-message-${index}`,
        handlers: {
          insertVariable: insertVariable,
        },
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['{'],
        dataAttributes: [],
        renderItem: (item) => item.label,
        source: (searchTerm, renderList) => {
          if (searchTerm.length === 0) {
            renderList(emailVariables, searchTerm);
          } else {
            const matches = emailVariables.filter((value) =>
              value.value.toLowerCase().includes(searchTerm.toLowerCase())
            );
            renderList(matches, searchTerm);
          }
        },
      },
    }),
    [index, insertVariable]
  );

  const handleChange = (_, __, ___, editor) => {
    const html = editor.getHTML();
    const cleanedHtml = html === '<p><br></p>' ? null : html;
    onChange(cleanedHtml);
    setIsSequenceTemplateDirty(true);
  };

  return (
    <div
      id="truebase-quill-container"
      className={`truebase-quill ${isError ? 'with-error' : ''}`}
    >
      <CustomToolbar
        index={index}
        handleTestEmail={handleTestEmail}
        isTestEmailDisabled={!value}
      />
      <ReactQuill
        bounds="#truebase-quill-container"
        ref={quillRef}
        modules={modules}
        placeholder="Start writing your email template"
        value={value}
        onChange={handleChange}
        preserveWhitespace
      />
    </div>
  );
};

export default SequenceMessageTextEditor;
