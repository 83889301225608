import _, { startCase, camelCase } from 'lodash';
import { ObjectId } from 'bson';

const isEncoded = (url) => {
  return url !== decodeURIComponent(url);
};

export const utils = {
  formatNumber: (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
  },
  formatSelectValue: (value) => {
    return {
      label: startCase(camelCase(value)),
      value,
    };
  },
  pluralize: (count, singular, plural) => {
    const form = count === 1 ? singular : plural;
    return `${count} ${form}`;
  },
  sequenceSteps: ['opener', 'followUp', 'finalPitch'],
  sequenceStepsWithSubject: ['opener', 'followUp', 'finalPitch', 'subject'],
  sequenceStepsReadableArray: ['Opener', 'Follow Up', 'Final Pitch', 'Subject'],
  sequenceStepsReadable: {
    opener: 'Opener',
    followUp: 'Follow Up',
    finalPitch: 'Final Pitch',
  },
  addOverwriteToFormData: (data) => {
    //check every field, if it is an object and has a content property, then check if it has isOverwrite property, if not, set it to false
    //make it work if the field you encounter is an array with nested objects
    const traverse = (data) => {
      Object.keys(data).forEach((key) => {
        if (typeof data[key] === 'object') {
          if (Array.isArray(data[key])) {
            data[key].forEach((item) => {
              traverse(item);
            });
          } else {
            traverse(data[key]);
          }
        }
      });
      if (
        typeof data === 'object' &&
        data.content &&
        _.isUndefined(data.isOverwrite)
      ) {
        data.isOverwrite = false;
      }
    };
    traverse(data);
    return data;
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  noop: () => {},
  senderCompanyInsightsEmpty: {
    offering: { content: '' },
    uniqueValueProposition: { content: '' },
    metricsToImprove: { content: '' },
    targetMarket: { content: '' },
    offeringProof: { content: '' },
    stage: { content: '' },
  },
  senderCompanyDetailsEmpty: {
    _id: '',
    name: '',
  },
  senderPersonDetailsEmpty: {
    _id: '',
    name: '',
    title: '',
  },
  formatQualifications(serverQualifications, qualifications) {
    return qualifications?.map((q) => {
      return serverQualifications?.find((svQ) => svQ.content === q.content)
        ? {
            ...serverQualifications?.find((svQ) => svQ.content === q.content),
            scoreMap: [
              { name: 'y', label: 'Yes' },
              { name: 'i', label: "I don't know" },
              { name: 'n', label: 'No' },
            ].reduce((acc, answer) => {
              acc[answer.label] = parseInt(q.scoreMap[answer.name]);
              return acc;
            }, {}),
          }
        : {
            id: q.id || ObjectId().toString(),
            content: q.content,
            scoreMap: [
              { name: 'y', label: 'Yes' },
              { name: 'i', label: "I don't know" },
              { name: 'n', label: 'No' },
            ].reduce((acc, answer) => {
              acc[answer.label] = parseInt(q.scoreMap[answer.name]);
              return acc;
            }, {}),
          };
    });
  },
  formatQualificationsFromServer(qualifications) {
    return (
      qualifications?.map((q) => ({
        ...q,
        scoreMap: [
          { name: 'y', label: 'Yes' },
          { name: 'i', label: "I don't know" },
          { name: 'n', label: 'No' },
        ].reduce((acc, answer) => {
          acc[answer.name] = q.scoreMap[answer.label];
          return acc;
        }, {}),
      })) || undefined
    );
  },
  decodeURIComponentRecursively(url) {
    while (isEncoded(url)) url = decodeURIComponent(url);
    return url;
  },
  accountLeadViewSelectOptions: [
    { label: 'Accounts', value: 'accounts' },
    { label: 'Leads', value: 'leads' },
  ],
  limitSelectOptions: [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ],
  qualificationAnswers: [
    { name: 'y', label: 'Yes' },
    { name: 'i', label: "I don't know" },
    { name: 'n', label: 'No' },
  ],
};
export const ONBOARDING_LOCALSTORAGE_KEY = 'trbs-onboarding';
export const LEAD_IMPORT_SAMPLE_URL =
  'https://s3.amazonaws.com/app.truebase.io/assets/Truebase%2B-%2BLead%2BImport%2B-%2Bsample.csv';
export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
export const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const SYSTEM_USER_ID = '000000000000000000000000';

export const safeJSONParse = (value) => {
  try {
    const parsedValue = JSON.parse(value);
    return parsedValue;
  } catch {
    return null;
  }
};

export const buildSearchParams = (params) => {
  const searchParams = new URLSearchParams();
  params.forEach((param) => {
    // handle if condition is different from value
    if (param.condition) {
      searchParams.append(param.key, JSON.stringify(param.value));
    } else if (param.value) {
      searchParams.append(param.key, JSON.stringify(param.value));
    } else if (param.required) {
      throw new Error(`Missing required parameter: ${param.key}`);
    }
  });
  return searchParams;
};

export const removeEmptyStringsFromObject = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      removeEmptyStringsFromObject(obj[key]);
    } else if (obj[key] === '') {
      delete obj[key];
    }
  });
  return obj;
};

export const removeNullValues = (obj) => {
  if (_.isArray(obj)) {
    return obj.map(removeNullValues);
  }

  if (_.isObject(obj)) {
    return _.reduce(
      obj,
      (result, value, key) => {
        const cleanedValue = removeNullValues(value);
        if (
          cleanedValue !== null &&
          (!_.isObject(cleanedValue) || !_.isEmpty(cleanedValue))
        ) {
          result[key] = cleanedValue;
        }
        return result;
      },
      {}
    );
  }

  return obj;
};

export const removeHTMLMargins = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  div.querySelectorAll('p').forEach((p) => {
    p.style.margin = 'unset';
  });
  return div.innerHTML;
};

export const urlPattern =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;

export const tryGetLinkedinHandleFromUrl = (url) => {
  if (!url) return;
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = `https://${url}`;
  }

  if (url === decodeURI(url)) url = encodeURI(url);

  const regex = new RegExp(
    '((?:https?:)?\\/\\/(?:[\\w]+\\.)?(in.)?(?:linkedin)\\.com\\/(showcase|company)\\/(?<linkedin_company_profile_handle>[^(\\/)?]+)\\/?)|' +
      '((?:https?:)?\\/\\/(?:[\\w]+\\.)?linkedin\\.com\\/(in|showcase)\\/(?<linkedin_person_profile_handle>[^(\\/)?]{3,})\\/?)|' +
      '((?:https?:)?\\/\\/(?:[\\w]+\\.)?linkedin\\.com\\/pub\\/(?<linkedin_pub_profile_handle>[A-z0-9_-]+)(?:\\/[A-z0-9]+){3}\\/?)'
  );

  const match = regex.exec(url);
  if (!match || !match.groups) return undefined;

  const handles = Object.entries(match.groups)
    .filter(([_, value]) => value !== undefined)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  let handleToDecode = handles[Object.keys(handles)[0]];
  if (!handleToDecode) return undefined;

  do {
    try {
      handleToDecode = decodeURIComponent(handleToDecode);
    } catch (err) {
      break;
    }
  } while (decodeURIComponent(handleToDecode) !== handleToDecode);

  return handleToDecode;
};

export const tryGetLinkedinHandle = (value) => {
  if (!value) return;
  const regex = /[(\\/)?]/;
  const match = regex.test(value);
  if (match) return;
  let handleToDecode = value;

  do {
    try {
      handleToDecode = decodeURIComponent(handleToDecode);
    } catch (err) {
      break;
    }
  } while (decodeURIComponent(handleToDecode) !== handleToDecode);

  return handleToDecode;
};

export const validateCSV = async ({
  file,
  results,
  maxLines = Infinity,
  schemaDefinition,
  extraValidationCallback,
}) => {
  const columns = results.meta.fields; // Get the list of column names
  const data = results.data; // Get the data rows

  try {
    await schemaDefinition.validate(columns);
  } catch (e) {
    throw new Error(e.message);
  }

  if (data.length === 0 || columns.length === 0)
    throw new Error(`The CSV file is empty`);

  // Check if the CSV file has more than maxLines rows
  if (data.length > maxLines)
    throw new Error(`Max number of rows for trial accounts is ${maxLines}`);

  // File size is more than 256KB
  if (file.size > 256000)
    throw new Error(`Max file size for trial accounts is 256KB`);

  if (extraValidationCallback) {
    await extraValidationCallback(data);
  }

  return;
};

export const getAutopilotStats = (autopilot) => {
  let lowestOrder = +Infinity;
  let highestOrder = -Infinity;
  let firstStepName;
  let finalStepName;

  for (const stepName in _.get(autopilot, 'steps', {})) {
    if (autopilot.steps[stepName].order < lowestOrder) {
      lowestOrder = _.get(autopilot, `steps.${stepName}.order`, 0);
      firstStepName = stepName;
    }

    if (autopilot.steps[stepName].order > highestOrder) {
      highestOrder = _.get(autopilot, `steps.${stepName}.order`, 0);
      finalStepName = stepName;
    }
  }

  const requestedOrImportedCount =
    _.get(autopilot, `steps.${firstStepName}.requestedCount`) ||
    _.get(autopilot, `steps.${firstStepName}.importedCount`) ||
    0;
  const qualifiedCount = _.get(autopilot, `steps.qualify.completedCount`, 0);
  const tripleCheckedCount = _.get(autopilot, `steps.enrich.completedCount`, 0);
  const finalStepCompletedCount =
    finalStepName === 'export'
      ? autopilot.status === 'done'
        ? _.get(autopilot, `steps.export.completedCount`, 0)
        : _.get(autopilot, `steps.enrich.completedCount`, 0)
      : _.get(autopilot, `steps.${finalStepName}.completedCount`, 0);
  const totalProcessedCount = _.get(
    autopilot,
    `steps.${firstStepName}.completedCount`,
    0
  );

  const qualificationMinScore = _.get(
    autopilot,
    `steps.qualify.qualificationMinScore`
  );

  return {
    _id: autopilot._id,
    createdAt: autopilot.createdAt,
    firstStepName,
    finalStepName,
    requestedOrImportedCount,
    qualifiedCount,
    tripleCheckedCount,
    finalStepCompletedCount,
    totalProcessedCount,
    qualificationMinScore,
    steps: autopilot.steps,
  };
};

export const generateUniqueName = (name, data) => {
  let newName;
  let i = 1;

  do {
    newName = `${name} ${i}`;
    i++;
  } while (data.find((item) => item.name === newName));

  return newName;
};

export const getIncludedExcludedFilters = (filters) => {
  const includedFilters = [];
  const excludedFilters = [];

  filters.forEach((subarr) => {
    if (subarr[0].include) {
      includedFilters.push(subarr);
    } else {
      excludedFilters.push(subarr);
    }
  });

  return { includedFilters, excludedFilters };
};

export const defaultQualificationQuestions = [
  { id: ObjectId().toString(), content: '', scoreMap: { y: 2, i: 1, n: 0 } },
];
