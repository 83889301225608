import { useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../sequenceTemplate/components/styles.css';
import 'quill-mention';

const toolbarOptions = [
  [{ size: [] }],
  ['bold', 'italic', 'underline'],
  [{ color: [] }, { background: [] }],
  ['link'],
];

const modules = {
  toolbar: toolbarOptions,
};

const SignatureTextEditor = ({ value, onChange, isError }) => {
  const handleChange = (_, __, ___, editor) => {
    const html = editor.getHTML();
    const cleanedHtml = html === '<p><br></p>' ? null : html;
    onChange(cleanedHtml);
  };

  return (
    <div
      id="truebase-quill-container"
      className={`truebase-quill ${
        isError ? 'with-error' : ''
      } truebase-signature`}
    >
      <ReactQuill
        bounds="#truebase-quill-container"
        modules={modules}
        placeholder="Create your email signature"
        value={value}
        onChange={handleChange}
        preserveWhitespace
      />
    </div>
  );
};

export default SignatureTextEditor;
