import { createAction, createSlice } from '@reduxjs/toolkit';
import { apiSlice } from '../services/api';

export const setSelectedAccountQualification = createAction(
  'accountQualifications/setAccountQualification'
);

export const ACCOUNT_QUALIFICATION_LOCAL_STORAGE_KEY =
  'truebase-accountQualification';

const slice = createSlice({
  name: 'accountQualifications',
  initialState: {
    selectedAccountQualificationKey: localStorage.getItem(
      ACCOUNT_QUALIFICATION_LOCAL_STORAGE_KEY
    ),
    selectedAccountQualification: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSelectedAccountQualification, (state, action) => {
      state.selectedAccountQualification = action.payload;
      state.selectedAccountQualificationKey = action.payload._id;
      localStorage.setItem(
        ACCOUNT_QUALIFICATION_LOCAL_STORAGE_KEY,
        action.payload._id
      );
    });
    builder.addMatcher(
      apiSlice.endpoints.getAccountQualifications.matchFulfilled,
      (state, { payload }) => {
        const newSelectedAccountQualification =
          payload.find(
            (accountQualification) =>
              accountQualification._id === state.selectedAccountQualificationKey
          ) || payload[0];

        state.selectedAccountQualification = newSelectedAccountQualification;
        state.selectedAccountQualificationKey =
          newSelectedAccountQualification._id;
        localStorage.setItem(
          ACCOUNT_QUALIFICATION_LOCAL_STORAGE_KEY,
          newSelectedAccountQualification._id
        );
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.addAccountQualification.matchFulfilled,
      (state, { payload }) => {
        state.selectedAccountQualification =
          payload.result.accountQualification;
        state.selectedAccountQualificationKey =
          payload.result.accountQualification._id;
        localStorage.setItem(
          ACCOUNT_QUALIFICATION_LOCAL_STORAGE_KEY,
          payload.result.accountQualification._id
        );
      }
    );
  },
});

export const getAccountQualifications = (state) => state.accountQualifications;
export const getSelectedAccountQualification = (state) =>
  state.accountQualifications.selectedAccountQualification;

export default slice.reducer;
