import { createContext, useState } from 'react';

export const SpotlightSearchContext = createContext({});

export const SpotlightSearchProvider = ({ children }) => {
  const [spotlightSearchSubmitted, setSpotlightSearchSubmitted] =
    useState(false);
  const [spotlightSearchReqId, setSpotlightSearchReqId] = useState(null);
  const [spotlightSearchValue, setSpotlightSearchValue] = useState('');
  const [isSpotlightSearchMode, setIsSpotlightSearchMode] = useState(false);

  const handleSetRequestId = (id) => {
    setSpotlightSearchReqId(typeof id !== 'string' ? id.toString() : id);
  };

  return (
    <SpotlightSearchContext.Provider
      value={{
        spotlightSearchReqId,
        setSpotlightSearchReqId: handleSetRequestId,
        spotlightSearchValue,
        setSpotlightSearchValue,
        isSpotlightSearchMode,
        setIsSpotlightSearchMode,
        spotlightSearchSubmitted,
        setSpotlightSearchSubmitted,
      }}
    >
      {children}
    </SpotlightSearchContext.Provider>
  );
};
