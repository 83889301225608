import * as yup from 'yup';

const linkedinUrlPattern = /^(https?:\/\/)?(www\.)?linkedin\.com/;

// TODO: @Peter change LI handle regex to be more restrictive
const personLinkedinHandleRegex = /[^(\\/)?]{2,}/;

const personLinkedinHandleSchema = yup
  .string()
  .required('Person LinkedIn Handle is required')
  .test(
    'is-valid-person-linkedin-handle',
    'Must be a valid LinkedIn Handle',
    (value) => value.match(personLinkedinHandleRegex)
  );

const personLinkedinUrlRegex =
  /(?:https?:\/\/)?(?:[\w]+\.)?linkedin\.com\/(in)\/([\w\-_À-ÿ%]+)\/?/;

const personLinkedinUrlSchema = yup
  .string()
  .required('Person LinkedIn URL is required')
  .test(
    'is-valid-person-linkedin-url',
    'Must be a valid Person LinkedIn URL',
    (value) => value.match(personLinkedinUrlRegex)
  );

const personLinkedinHandleOrUrlSchema = yup
  .mixed()
  .test(
    'test-person-linkedin-handle-or-url',
    'Field must be a valid Person Linkedin handle or URL',
    (value) => {
      if (linkedinUrlPattern.test(value)) {
        return personLinkedinUrlSchema.isValidSync(value);
      } else {
        return personLinkedinHandleSchema.isValidSync(value);
      }
    }
  );

const companyLinkedinHandleRegex = /([\w\-_À-ÿ%]+)/;

const companyLinkedinHandleSchema = yup
  .string()
  .required('Company LinkedIn Handle is required')
  .test(
    'is-valid-company-linkedin-handle',
    'Must be a valid LinkedIn Handle',
    (value) => value.match(companyLinkedinHandleRegex)
  );

const companyLinkedinUrlRegex =
  /(?:https?:\/\/)?(?:[\w]+\.)?linkedin\.com\/(company)\/([\w\-_À-ÿ%]+)\/?/;

const companyLinkedinUrlSchema = yup
  .string()
  .required('Company LinkedIn URL is required')
  .test(
    'is-valid-company-linkedin-url',
    'Must be a valid Company LinkedIn URL',
    (value) => value.match(companyLinkedinUrlRegex)
  );

const companyLinkedinHandleOrUrlSchema = yup
  .mixed()
  .test(
    'test-company-linkedin-handle-or-url',
    'Field must be a valid Company Linkedin handle or URL',
    (value) => {
      if (linkedinUrlPattern.test(value)) {
        return companyLinkedinUrlSchema.isValidSync(value);
      } else {
        return companyLinkedinHandleSchema.isValidSync(value);
      }
    }
  );

const linkedinHandleOrUrlSchema = yup
  .mixed()
  .test(
    'test-company-or-person-linkedin-handle-or-url',
    'Field must be a valid Linkedin handle or URL',
    (value) => {
      if (linkedinUrlPattern.test(value)) {
        return (
          companyLinkedinUrlSchema.isValidSync(value) ||
          personLinkedinUrlSchema.isValidSync(value)
        );
      } else {
        return (
          companyLinkedinHandleSchema.isValidSync(value) ||
          personLinkedinHandleSchema.isValidSync(value)
        );
      }
    }
  );

const allowedImportHeaders = yup.mixed().test({
  test: (value) =>
    [
      'lead first name',
      'lead last name',
      'account name',
      'account domain 1',
      'lead linkedin url',
      'lead work email',
      'lead personal email',
      'lead location',
    ].some((header) => value.map((str) => str.toLowerCase()).includes(header)),
  message: 'The CSV file does not have any of the required columns',
});

export default {
  personLinkedinHandleSchema,
  personLinkedinUrlSchema,
  personLinkedinHandleOrUrlSchema,
  companyLinkedinHandleSchema,
  companyLinkedinUrlSchema,
  companyLinkedinHandleOrUrlSchema,
  linkedinHandleOrUrlSchema,
  allowedImportHeaders,
};
