export const matchScoreExplanations = {
  People: {
    desc: 'Employee count, departments formation, key executive changes',
  },
  Industry: { desc: 'Various industry categorisation' },
  Signal: { desc: 'Company ranks, growth, web presence' },
  Financial: { desc: 'Funding, revenue, spending' },
  Technology: { desc: 'Product used and released' },
  Company: { desc: 'Formation, recruiting, culture' },
};
