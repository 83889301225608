import { Box, Flex } from '@chakra-ui/react';
import { get, isUndefined } from 'lodash';
import { useSelector } from 'react-redux';
import Layout from '~/v2/common/Layout';
import { SpotlightSearchWithContext } from '~/v2/common/SpotlightSearch/SpotlightSearch';
import { defaultQualificationQuestions, utils } from '~/v2/common/utils';
import { QualificationProvider } from '~/v2/features/filters/components/QualificationProvider';
import { useGetExcludedAccountsQuery } from '~/v2/redux/services/teams';
import { getSelectedAccountQualification } from '~/v2/redux/slices/accountQualificationSlice';
import { getSelectedTeam } from '~/v2/redux/slices/teamsSlice';
import TruebaseAccordion from '~/v2/common/TruebaseAccordion/TruebaseAccountAccordion/TruebaseAccordion';
import TruebaseAccordionSkeleton from '~/v2/common/TruebaseAccordion/components/TruebaseAccordionSkeleton';
import { useCallback, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const ExcludeList = () => {
  const selectedAccountQualification = useSelector(
    getSelectedAccountQualification
  );
  const selectedTeam = useSelector(getSelectedTeam);

  const { data } = useGetExcludedAccountsQuery(
    { teamId: selectedTeam?._id },
    { skip: isUndefined(selectedTeam?._id), refetchOnMountOrArgChange: true }
  );

  const [open, setOpen] = useState({});

  const setOpenSpecific = useCallback(
    (key) => {
      setOpen({
        ...Object.keys(open).reduce((acc, cv) => {
          return {
            ...acc,
            [cv]: false,
          };
        }, {}),
        [key]: !open[key],
      });
    },
    [open, setOpen]
  );

  return (
    <Layout
      title="Exclude List"
      subtitle="Suppress accounts to be excluded from Search"
    >
      <Box pb="56px" pt={4} w="100%">
        <Helmet title={'Truebase | Exclude List'} defer={false} />
        <QualificationProvider
          initialQualificationQuestions={
            utils.formatQualificationsFromServer(
              selectedAccountQualification?.qualificationQuestions
            ) || defaultQualificationQuestions
          }
        >
          <AnimatePresence>
            <motion.div
              key="exclude-list-spotlight"
              initial={{ x: 250, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
            >
              <SpotlightSearchWithContext />
            </motion.div>
          </AnimatePresence>

          <Flex mt="24px" gap="12px" flexDir="column">
            {isUndefined(data)
              ? Array.from({ length: 10 }).map((_, index) => (
                  <TruebaseAccordionSkeleton key={index} mb="8px" />
                ))
              : get(data, 'result.excludedAccounts', []).map(
                  (account, index) => (
                    <TruebaseAccordion
                      key={account.companyId}
                      showHitsAndMisses
                      setOpen={setOpenSpecific}
                      isOpen={open[account.companyId]}
                      accordionKey={account.companyId}
                      account={account}
                      accountIndex={index}
                      showOnHover={false}
                    />
                  )
                )}
          </Flex>
        </QualificationProvider>
      </Box>
    </Layout>
  );
};

export default ExcludeList;
