import { ReactComponent as HubspotIcon } from '~/v2/assets/icons/provider_icons/hubspot.svg';
import { ReactComponent as ZohoIcon } from '~/v2/assets/icons/provider_icons/zoho.svg';
import { ReactComponent as SalesforceIcon } from '~/v2/assets/icons/provider_icons/salesforce.svg';
import { ReactComponent as CopperIcon } from '~/v2/assets/icons/provider_icons/copper.svg';
import { ReactComponent as ActiveCampaignIcon } from '~/v2/assets/icons/provider_icons/active_campaign.svg';
import { ReactComponent as NimbleIcon } from '~/v2/assets/icons/provider_icons/nimble.svg';
import { ReactComponent as LinkedinConnectIcon } from '~/v2/assets/icons/provider_icons/linkedinConnect.svg';
import { ReactComponent as DuxSoupIcon } from '~/v2/assets/icons/provider_icons/duxSoup.svg';
import { ReactComponent as OverDarkIcon } from '~/v2/assets/icons/provider_icons/Truebase Icon/Over Light.svg';
import { ReactComponent as KlentyIcon } from '~/v2/assets/icons/provider_icons/klenty.svg';
import { ReactComponent as SalesHandyIcon } from '~/v2/assets/icons/provider_icons/saleshandy.svg';
import { ReactComponent as SalesBlinkIcon } from '~/v2/assets/icons/provider_icons/salesblink.svg';
import { ReactComponent as SmartLeadIcon } from '~/v2/assets/icons/provider_icons/smartlead.svg';
import { ReactComponent as InstantlyIcon } from '~/v2/assets/icons/provider_icons/instantly.svg';
import { ReactComponent as LemlistIcon } from '~/v2/assets/icons/provider_icons/lemlist.svg';
import { ReactComponent as HeyReachIcon } from '~/v2/assets/icons/provider_icons/heyreach.svg';

export const TOOLTIP_DELAY = 1200;

export const AUTOPILOT_FREQUENCY_OPTIONS = [
  { label: 'Once', value: 'once' },
  { label: 'Weekly', value: 'weekly' },
];

export const FORMATTED_EXPORT_OPTIONS = [
  { icon: OverDarkIcon, label: 'Export all data as a CSV', value: 'truebase' },
  { icon: HubspotIcon, label: 'Leads to Hubspot Contacts', value: 'hubspot' },
  {
    icon: ZohoIcon,
    label: 'Export Leads to Zoho CRM Leads',
    value: 'zohoLeads',
  },
  {
    icon: SalesforceIcon,
    label: 'Export Leads to Salesforce Leads',
    value: 'salesforceLeads',
  },
  {
    icon: CopperIcon,
    label: 'Export Leads to Copper CRM People',
    value: 'copperCrmPeople',
  },
  {
    icon: ActiveCampaignIcon,
    label: 'Export Leads to Active Campaign Contacts',
    value: 'activeCampaignContacts',
  },
  {
    icon: NimbleIcon,
    label: 'Export Leads to Nimble Contacts as People',
    value: 'nimbleContacts',
  },
  {
    icon: LinkedinConnectIcon,
    label: 'Export leads to Linkedin Connect',
    value: 'linkedinConnect',
  },
  { icon: DuxSoupIcon, label: 'Export leads to Dux-Soup', value: 'duxSoup' },
  {
    icon: KlentyIcon,
    label: 'Export Leads to Klenty Prospects List',
    value: 'klentyProspects',
  },
  {
    icon: KlentyIcon,
    label: 'Export Leads to Klenty Accounts List',
    value: 'klentyAccounts',
  },
  {
    icon: SalesHandyIcon,
    label: 'Export leads to add as prospects in Saleshandy Sequence',
    value: 'salesHandy',
  },
  {
    icon: SalesBlinkIcon,
    label: 'Export Leads to SalesBlink Outreach List',
    value: 'salesBlink',
  },
  {
    icon: SmartLeadIcon,
    label: 'Export Leads to Smartlead Email Campaign',
    value: 'smartLead',
  },
  {
    icon: InstantlyIcon,
    label: 'Export Leads to Instantly Campaign',
    value: 'instantly',
  },
  {
    icon: LemlistIcon,
    label: 'Export Leads to Lemlist Campaign’s Lead List',
    value: 'lemlist',
  },
  {
    icon: HeyReachIcon,
    label: 'Export leads to the Heyreach prospects list',
    value: 'heyreach',
  },
];
