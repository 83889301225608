import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const truebase = definePartsStyle({
  track: defineStyle({
    _checked: {
      bg: 'trueBlue04',
    },
    height: '8px',
  }),
  thumb: defineStyle({
    position: 'absolute',
    top: '-3px',
    width: '16px',
    height: '16px',
    boxShadow: '0px 3px 6px 0px #00000026',
    left: '-2px',
    _checked: {
      opacity: 1,
      bg: 'trueBlue',
      left: '6px',
    },
  }),
});

const truebaseLarge = definePartsStyle({
  track: defineStyle({
    bg: 'trueDim',
    _disabled: {
      bg: 'trueDisabled',
    },
    _checked: {
      bg: 'trueBlue04',
      _disabled: {
        bg: 'trueDisabled',
      },
    },
    height: '16px',
    width: '48px',
    padding: '0px',
  }),
  thumb: defineStyle({
    position: 'absolute',
    top: '-4px',
    width: '24px',
    height: '24px',
    boxShadow: '0px 3px 6px 0px #00000026',
    left: '-1px',
    _checked: {
      opacity: 1,
      bg: 'trueBlue',
      left: '12px',
    },
  }),
});

const truebaseAutopilot = definePartsStyle({
  track: defineStyle({
    h: '16px',
    w: '30px',
    p: 0,
    bg: 'trueDim',
    _checked: { bg: 'trueExplain' },
    _disabled: {
      opacity: 1,
      bg: 'trueDisabled',
      _checked: { bg: 'trueDisabled' },
    },
  }),
  thumb: defineStyle({
    pos: 'absolute',
    top: '2px',
    left: '2px',
    w: '12px',
    h: '12px',
    _disabled: { bg: '#FFFFFF !important' },
  }),
});

export const switchTheme = defineMultiStyleConfig({
  variants: { truebase, truebaseLarge, truebaseAutopilot },
});
