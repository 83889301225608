import { buildSearchParams } from '~/v2/common/utils';
import { apiSlice } from './api';
import { pick } from 'lodash';

export const autopilotApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAutopilots: builder.query({
      queryFn: async (args, queryApi, extraOptions, baseQuery) => {
        const params = [
          { key: 'teamId', value: args.teamId, required: true },
          { key: 'limit', value: args.limit },
          { key: 'page', value: args.page },
        ];
        const response = await baseQuery({
          url: `autopilots?${buildSearchParams(params).toString()}`,
          method: 'GET',
        });
        return response;
      },
      providesTags: ['Autopilots'],
    }),
    createAutopilot: builder.mutation({
      query: ({
        name,
        icpId,
        accountQualificationId,
        sequenceTemplateId,
        teamId,
        finalStepName,
        firstStepName,
        exportProvider,
        file,
        requestedCount,
        qualificationMinScore,
        contactEnrichmentVerificationOptions,
        frequency,
        filterStepEnabled,
      }) => ({
        url: 'autopilots',
        method: 'POST',
        body: {
          name,
          icpId,
          accountQualificationId,
          sequenceTemplateId,
          teamId,
          finalStepName,
          firstStepName,
          ...(requestedCount && { requestedCount }),
          ...(qualificationMinScore && { qualificationMinScore }),
          ...(finalStepName === 'export' && { exportProvider: exportProvider }),
          contactEnrichmentVerificationOptions,
          frequency,
          filterStepEnabled,
        },
        ...(firstStepName === 'import' && { files: [file] }),
      }),
      invalidatesTags: ['Autopilots'],
    }),
    getExportAutopilotCSV: builder.mutation({
      query: ({ autopilotId, teamId }) => ({
        url: `autopilots/${autopilotId}/export-csv`,
        body: { teamId },
        method: 'POST',
      }),
    }),
    skipAutopilot: builder.mutation({
      query: ({ autopilotId, action }) => ({
        url: `autopilots/${autopilotId}/skip`,
        body: { action },
        method: 'PATCH',
      }),
      invalidatesTags: ['Autopilots'],
    }),
    deleteAutopilot: builder.mutation({
      query: ({ autopilotId, teamId }) => ({
        url: `autopilots/${autopilotId}?teamId=${teamId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, args) => ['Autopilots'],
    }),
    getAutopilotContacts: builder.query({
      async queryFn(args = {}, queryApi, extraOptions, baseQuery) {
        const params = [
          { key: 'teamId', value: args.teamId, required: true },
          { key: 'autopilotId', value: args.autopilotId },
          { key: 'tags', value: args.tags, required: true },
          { key: 'page', value: args.page },
          { key: 'limit', value: args.limit },
        ];

        const response = await baseQuery({
          url: `autopilots/contacts?` + buildSearchParams(params).toString(),
          method: 'GET',
        });

        return {
          data: {
            ...pick(response, ['data.code', 'data.isOk']),
            result: response.data.result,
          },
        };
      },
      serializeQueryArgs: (args) => ({ teamId: args.teamId }),
      providesTags: ['autopilotsContacts'],
    }),
    cancelAutopilot: builder.mutation({
      query: ({ autopilotId }) => ({
        url: `autopilots/${autopilotId}/cancel`,
        method: 'PATCH',
      }),
      invalidatesTags: (_result, _error, args) => ['Autopilots'],
    }),
    exportAutopilotContacts: builder.mutation({
      query: (args) => ({
        url: `autopilots/contacts/export`,
        body: args,
        method: 'POST',
      }),
    }),
    getAutopilotTemplates: builder.query({
      queryFn: async (args, queryApi, extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: `autopilots/templates`,
          method: 'GET',
        });
        return response;
      },
    }),
  }),
});

export const {
  useGetAutopilotContactsQuery,
  useGetAutopilotsQuery,
  useCreateAutopilotMutation,
  useGetExportAutopilotCSVMutation,
  useSkipAutopilotMutation,
  useDeleteAutopilotMutation,
  useCancelAutopilotMutation,
  useExportAutopilotContactsMutation,
  useGetAutopilotTemplatesQuery,
} = autopilotApi;
