import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import lottie from 'lottie-web';
import EmailSentAnimation from './static/completeSignupAnimation.json';

const EmailSent = () => {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById('lottie-complete-signup'),
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: EmailSentAnimation,
    });
  }, []);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '-1px',
        left: '8px',
        display: 'inline-block',
      }}
      id="lottie-complete-signup"
    ></Box>
  );
};

export default EmailSent;
