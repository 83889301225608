/* eslint-disable react-hooks/exhaustive-deps */
import { ObjectId } from 'bson';
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  NumberInput,
  NumberInputField,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ReactComponent as MoreIcon } from '~/v2/assets/icons/more/more-vertical.svg';
import { ReactComponent as AddBox } from '~/v2/assets/icons/add/add-box.svg';
import { AnimatePresence, motion } from 'framer-motion';
import {
  defaultQualificationQuestions,
  utils,
} from '../../../../../common/utils';

export const QualificationsForm = ({ qualifications }) => {
  const {
    control,
    formState: { errors },
    watch,
    register,
    reset,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'qualifications',
  });
  const watchFields = watch('qualifications');

  useEffect(() => {
    if (qualifications)
      reset({
        qualifications:
          utils.formatQualificationsFromServer(qualifications) ||
          defaultQualificationQuestions,
      });
  }, [qualifications, reset]);

  return (
    <Flex flexDir="column" gap="16px" bg="white">
      <AnimatePresence initial={false}>
        {fields.map((field, index) => (
          <motion.div
            key={field.id}
            initial={{ opacity: 0, height: 0 }}
            animate={{
              opacity: 1,
              height: 'auto',
            }}
            exit={{ opacity: 0, height: 0, marginBottom: '-14px' }}
          >
            <Flex
              p={4}
              direction="column"
              gap="16px"
              w="100%"
              bgColor="trueBg02"
              borderColor="trueLight"
              borderWidth="1px"
              borderRadius="2px"
            >
              <Flex alignItems="center" gap={4}>
                <FormControl
                  variant="truebase"
                  isInvalid={errors?.qualifications?.[index]?.content?.message}
                >
                  <Input
                    variant="truebaseSmall"
                    {...register(`qualifications.${index}.content`, {
                      required: 'This field is required',
                      //validate that the question ends on a question mark
                      pattern: {
                        value: /.*\?$/,
                        message: 'Question must end on a question mark',
                      },
                    })}
                    placeholder="Is it a B2B Company?"
                  />
                  <FormErrorMessage variant="truebase">
                    {errors?.qualifications?.[index]?.content?.message}
                  </FormErrorMessage>
                </FormControl>
                <Menu variant="truebase" isLazy>
                  <MenuButton
                    as={IconButton}
                    type="button"
                    w="32px"
                    h="32px"
                    minW={0}
                    variant="truebaseText"
                    isDisabled={fields.length === 1}
                    icon={<MoreIcon />}
                  />
                  <MenuList>
                    <MenuItem onClick={() => remove(index)}>Remove</MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
              <Flex alignItems="center">
                <Text
                  sx={{
                    fontSize: '12px',
                    color: 'trueDim',
                    lineHeight: '14px',
                    fontWeight: '500',
                  }}
                >
                  Answer Weights
                </Text>
                <Divider
                  opacity="1"
                  borderColor="trueLight"
                  orientation="vertical"
                  h="40px"
                  mx="24px"
                />
                <Flex gap="32px" alignItems="center">
                  {utils.qualificationAnswers.map(({ name, label }) => (
                    <Flex gap="16px" alignItems="center" key={index + label}>
                      <Text
                        sx={{
                          fontSize: '12px',
                          lineHeight: '14px',
                          fontWeight: '500',
                          whiteSpace: 'pre',
                        }}
                      >
                        {label}
                      </Text>
                      <FormControl
                        variant="truebase"
                        isInvalid={
                          errors?.qualifications?.[index]?.scoreMap?.[name]
                        }
                      >
                        <NumberInput
                          variant="truebaseSmall"
                          w="90px"
                          isDisabled={
                            errors?.qualifications?.[index]?.content?.message ||
                            !watchFields?.[index]?.content
                          }
                        >
                          <NumberInputField
                            {...register(
                              `qualifications.${index}.scoreMap.${name}`,
                              {
                                required: 'This field is required',
                                min: {
                                  value: 0,
                                  message:
                                    'Weight must be greater than or equal 0',
                                },
                              }
                            )}
                          />
                        </NumberInput>
                        <FormErrorMessage variant="truebase">
                          {
                            errors?.qualifications?.[index]?.scoreMap?.[name]
                              ?.message
                          }
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </Flex>
          </motion.div>
        ))}
      </AnimatePresence>
      {fields.length < 5 && (
        <Button
          type="button"
          p={4}
          w="100%"
          h="56px"
          bgColor="white"
          borderColor="trueSky"
          borderStyle="dashed"
          borderWidth="1px"
          borderRadius="2px"
          color="trueDim"
          justifyContent="flex-start"
          fontWeight={400}
          fontSize="14px"
          onClick={() =>
            append({
              content: '',
              scoreMap: { y: 2, i: 1, n: 0 },
              id: new ObjectId().toString(),
            })
          }
          leftIcon={<AddBox />}
          iconSpacing="11px"
          alignItems="center"
          display="flex"
          isDisabled={watchFields?.[watchFields?.length - 1]?.content === ''}
        >
          Add question
        </Button>
      )}
    </Flex>
  );
};
