import { Box, Link, Spacer, Text } from '@chakra-ui/react';
import React from 'react';
import { TruebaseTooltip } from './TruebaseTooltip';

const Footer = () => {
  return (
    <Box
      sx={{
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        bottom: 0,
        right: 0,
        w: '100%',
        position: 'absolute',
        textAlign: 'left !important',
        padding: '20px 100px 20px 30px',
        fontSize: '13px',
        background: '#f1f2f5',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '20px',
        color: '#9dabb1',
        a: {
          '&:hover': {
            color: '#23527c',
          },
        },
      }}
    >
      <Box as="span"></Box>
      <Spacer />
      <TruebaseTooltip label={process.env.VERSION_TIMESTAMP}>
        <Text cursor="pointer">Version</Text>
      </TruebaseTooltip>
      <Link
        href="https://truebase.io/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </Link>
      <Link
        href="https://truebase.io/terms-of-use"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Use
      </Link>
    </Box>
  );
};

Footer.displayName = 'Footer';

export default Footer;
