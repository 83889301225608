import {
  Spacer,
  Text,
  AccordionPanel,
  AccordionButton,
  AccordionItem,
  Accordion,
  Icon,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import LinkElement from './LinkElement';

import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow/dropdown.svg';
import { get } from 'lodash';

export default function SidebarAccordionItem({ icon, title }) {
  const [accordionOpen, setAccordionOpen] = useState(0);

  const location = useLocation();

  return (
    <Accordion index={accordionOpen}>
      <AccordionItem border={0}>
        <AccordionButton
          as="div"
          p="8px"
          cursor="pointer"
          h="40px"
          color="trueDim"
          onClick={() => {
            setAccordionOpen(accordionOpen === 0 ? -1 : 0);
          }}
          borderRadius="4px"
          gap="10px"
        >
          {icon}
          <Text fontSize="13px" lineHeight="16px" fontWeight="500">
            {title}
          </Text>
          <Spacer />
          <Icon
            as={ArrowDown}
            w="24px"
            h="24px"
            color="#7E8BAD"
            rotate={accordionOpen ? '0' : '180deg'}
            transform="auto"
            transition="all ease 250ms"
          />
        </AccordionButton>
        <AccordionPanel
          p={0}
          mt="8px"
          display="flex"
          flexDir="column"
          gap="8px"
        >
          <LinkElement
            to="/settings/icp"
            text="ICP"
            _activeLink={
              get(location, 'state.settings.selectedMode', '')
                ? {}
                : {
                    color: 'trueSpace',
                    bg: 'trueLighter',
                    borderColor: 'transparent',
                    svg: { path: { fill: 'trueSpace' } },
                  }
            }
          />
          <LinkElement
            to="/settings/qualification"
            text="Account Qualification"
            _activeLink={
              get(location, 'state.settings.selectedMode', '')
                ? {}
                : {
                    color: 'trueSpace',
                    bg: 'trueLighter',
                    borderColor: 'transparent',
                    svg: { path: { fill: 'trueSpace' } },
                  }
            }
          />
          <LinkElement
            to="/settings/sequence-templates"
            text="Email Sequence Templates"
            _activeLink={
              get(location, 'state.settings.selectedMode', '')
                ? {}
                : {
                    color: 'trueSpace',
                    bg: 'trueLighter',
                    borderColor: 'transparent',
                    svg: { path: { fill: 'trueSpace' } },
                  }
            }
          />
          <LinkElement to="/settings/email-accounts" text="Email Accounts" />
          <LinkElement to="/settings/exclude-list" text="Exclude List" />
          <LinkElement to="/settings/team" text="Team" />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
