import React from 'react';

export const TagIcon = ({ width = 24, height = 24, color = '##7E8BAD' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="current"
    >
      <path
        fill={color}
        d="m13.273 6.72-6-6a1.325 1.325 0 0 0-.94-.387H1.666A1.337 1.337 0 0 0 .333 1.667v4.666a1.333 1.333 0 0 0 .393.947l6 6a1.323 1.323 0 0 0 .94.387 1.303 1.303 0 0 0 .94-.394l4.667-4.666a1.303 1.303 0 0 0 .393-.94 1.346 1.346 0 0 0-.393-.947ZM2.666 3.667a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
      />
    </svg>
  );
};
