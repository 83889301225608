import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react';
import React from 'react';
import { ReactComponent as ThreeDots } from '~/v2/assets/icons/more/more-vertical.svg';

export const DropdownToggle = ({ options, showOnHover = true }) => {
  return (
    <Menu variant="truebase" isLazy>
      <MenuButton
        sx={{
          svg: {
            width: '24px',
            height: '24px',
            fill: 'trueDim',
          },
        }}
        size="sm"
        as={IconButton}
        icon={<ThreeDots className={showOnHover ? 'show-on-hover' : ''} />}
        variant="ghost"
        onClick={(e) => e.stopPropagation()}
      ></MenuButton>
      <Portal>
        <MenuList>
          {options.map((option) => (
            <MenuItem
              isDisabled={option.disabled}
              key={option.label}
              onClick={option.onClick}
            >
              {option.icon} {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
};
