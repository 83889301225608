import React from 'react';

export const AutopilotCardIcon = ({
  width = 28,
  height = 28,
  color = '#0F0F4D',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.83984 8.50667L3.89317 10.56C3.53317 11.64 3.33317 12.8 3.33317 14C3.33317 19.88 8.11984 24.6667 13.9998 24.6667C19.8798 24.6667 24.6665 19.88 24.6665 14C24.6665 8.12 19.8798 3.33333 13.9998 3.33333C12.7998 3.33333 11.6398 3.53333 10.5598 3.89333L8.51984 1.85333C10.1998 1.09333 12.0532 0.666666 13.9998 0.666666C21.3598 0.666666 27.3332 6.64 27.3332 14C27.3332 21.36 21.3598 27.3333 13.9998 27.3333C6.63984 27.3333 0.666504 21.36 0.666504 14C0.666504 12.04 1.09317 10.1867 1.83984 8.50667ZM7.33317 5.33333C7.33317 6.44 6.43984 7.33333 5.33317 7.33333C4.2265 7.33333 3.33317 6.44 3.33317 5.33333C3.33317 4.22667 4.2265 3.33333 5.33317 3.33333C6.43984 3.33333 7.33317 4.22667 7.33317 5.33333ZM14.0398 9.98667H13.9465L12.3998 14.4H15.5865L14.0398 9.98667ZM13.9998 22C18.4132 22 21.9998 18.4133 21.9998 14C21.9998 9.58667 18.4132 6 13.9998 6C9.5865 6 5.99984 9.58667 5.99984 14C5.99984 18.4133 9.5865 22 13.9998 22ZM13.0532 8H14.9598L18.9732 18.6667H17.1198L16.1598 15.9467H11.8532L10.8798 18.6667H9.03984L13.0532 8Z"
        fill={color}
      />
    </svg>
  );
};
