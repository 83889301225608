import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Spacer,
  Flex,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Portal,
} from '@chakra-ui/react';
import React, { useMemo, useState, useEffect, useRef, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as CheckOff } from '~/v2/assets/icons/clear/clear-large.svg';
import AvatarSwitcher from '../components/AvatarSwitcher';
import SocialProfile from '../components/SocialProfile';
import LeadEmailDiscovery from './LeadEmailDiscovery';
import { useFilterContext } from '~/v2/features/filters/components/useFilterContext';
import LeadTabs from '../TruebaseLeadAccordion/LeadTabs';
import GeneratingStatus from '../components/GeneratingStatus';
import { ReactComponent as Skip } from '~/v2/assets/icons/restore.svg';
import { ReactComponent as ThreeDots } from '~/v2/assets/icons/more/more-vertical.svg';
import { ReactComponent as CheckOn } from '~/v2/assets/icons/done/done-large.svg';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { getSelectedIcp } from '~/v2/redux/slices/icpSlice';
import { getSelectedTeam } from '~/v2/redux/slices/teamsSlice';
import { useActOnPersonMutation } from '~/v2/redux/services/teams';

const socialProfilesOrder = [
  'website',
  'linkedin',
  'crunchbase',
  'salesnavigator',
  'facebook',
  'twitter',
  'instagram',
  'angellist',
  'github',
  'medium',
  'youtube',
  'quora',
  'reddit',
];

export const LeadRow = ({ readonly, lead, account }) => {
  const { handleAsyncError } = useErrorHandler();
  const isStatusDirty = useRef(false);
  const selectedIcp = useSelector(getSelectedIcp);
  const selectedTeam = useSelector(getSelectedTeam);
  const { companyFilters, leadFilters, requestId, trainView } =
    useFilterContext();

  const socialProfiles = useMemo(() => {
    if (!lead.socialProfiles) return [];

    return lead.socialProfiles.slice().sort((a, b) => {
      return (
        socialProfilesOrder.indexOf(a.service) -
        socialProfilesOrder.indexOf(b.service)
      );
    });
  }, [lead.socialProfiles]);

  const [actOnPerson] = useActOnPersonMutation();

  const [isLeadQueued, setIsLeadQueued] = useState(
    lead.state === 'pending' && lead.steps?.search?.enqueuedAt
  );
  const [isLeadExcluded, setIsLeadExcluded] = useState(
    Object.keys(selectedTeam?.excludedPersons || {}).includes(lead.personId)
  );

  const [isAccountExcluded, setIsAccountExcluded] = useState(
    Object.keys(selectedTeam?.excludedCompanies || {}).includes(
      account.companyId
    )
  );

  useEffect(() => {
    setIsAccountExcluded(
      Object.keys(selectedTeam?.excludedCompanies || {}).includes(
        account.companyId
      )
    );
  }, [selectedTeam?.excludedCompanies, account.companyId]);

  useEffect(() => {
    if (!isStatusDirty.current) {
      isStatusDirty.current = true;
    }
  }, [lead.status]);
  return (
    <>
      <Flex flexDir="column" w="100%">
        <Flex flexDir="row" w="100%">
          <Flex w="100%">
            <Flex w="100%" key={lead._id} gap="16px" pt="24px">
              <Center>
                <AvatarSwitcher
                  type="person"
                  src={lead.avatar}
                  name={lead.name}
                />
              </Center>
              <Flex
                color="trueMedium"
                flexDirection="column"
                justifyContent="center"
              >
                <Flex mb={1} alignItems="center" gap="8px">
                  <Box
                    fontSize="16px"
                    lineHeight="20px"
                    fontWeight="medium"
                    color="trueSpace"
                  >
                    {lead.name}
                  </Box>
                  {socialProfiles.map(({ service = '', url = '' }) => {
                    return (
                      <SocialProfile
                        id={lead._id}
                        key={lead._id + service}
                        service={service}
                        url={url}
                        type="person"
                      />
                    );
                  })}
                  <Flex className="show-on-hover" gap="8px">
                    <LeadEmailDiscovery account={account} lead={lead} />
                    <GeneratingStatus lead={lead} priority="leads" />
                  </Flex>
                </Flex>
                <Text
                  as="span"
                  fontSize="14px"
                  lineHeight="16px"
                  color="trueDim"
                >
                  {lead.title} {lead.address ? ` • ${lead.address}` : ''}
                </Text>
              </Flex>
              <Spacer />
              {!readonly && trainView !== 'qualification' && (
                <Flex
                  gap={'16px'}
                  alignItems="center"
                  onClick={(e) => e.stopPropagation()}
                >
                  {isLeadQueued && !isAccountExcluded && (
                    <Box sx={{ svg: { fill: 'trueGrass' } }}>
                      <CheckOn />
                    </Box>
                  )}
                  {(isLeadExcluded || isAccountExcluded) && (
                    <Box sx={{ svg: { fill: 'trueCorral' } }}>
                      <CheckOff />
                    </Box>
                  )}
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export const LeadRowWithTabs = ({
  readonly,
  lead,
  account,
  isOpen,
  handleExpandRow,
}) => {
  return (
    <>
      <Accordion
        overflow="hidden"
        onChange={handleExpandRow}
        index={isOpen ? 0 : -1}
        allowToggle
      >
        <AccordionItem border={0}>
          <AccordionButton
            _hover={{}}
            _focus={{}}
            _active={{}}
            as="div"
            p={0}
            cursor="pointer"
          >
            <LeadRow readonly={readonly} lead={lead} account={account} />
          </AccordionButton>
          <AccordionPanel pt="16px" pl="32px" ml="32px" pr="unset">
            <LeadTabs readonly={readonly} lead={lead} account={account} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};
