import {
  Button,
  Container,
  Flex,
  Progress,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { FormProvider } from 'react-hook-form';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { v4 } from 'uuid';
import { ReactComponent as OverDarkIcon } from '~/v2/assets/icons/provider_icons/Truebase Icon/Over Light.svg';
import { ReactComponent as TruebaseLogo } from '~/v2/assets/icons/truebase logo/small/dark.svg';
import useStreamingForm from '../../../hooks/useStreamingForm';
import socket from '../../../utility/socket';
import CompanyLinkedInStep from './steps/CompanyLinkedInStep';
import EmailStep from './steps/EmailStep';
import OfferingStep from './steps/OfferingStep';
import PasswordStep from './steps/PasswordStep';
import PersonLinkedInStep from './steps/PersonLinkedInStep';
import VerifyEmailStep from './steps/VerifyEmailStep';
import MobileEndpoint from './shared/MobileEndpoint';
import {
  useLazyGetCurrentUserQuery,
  useSignupMutation,
  useSendMobileOnboardingEmailMutation,
  useEditMutation,
} from '../../../redux/services/user';
import {
  useCreateTeamMutation,
  useSetupMutation,
} from '../../../redux/services/teams';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { createOrAddToTeam } from '../../auth/signupUtils';
import {
  ONBOARDING_LOCALSTORAGE_KEY,
  safeJSONParse,
  tryGetLinkedinHandle,
  tryGetLinkedinHandleFromUrl,
} from '../../../common/utils';
import useScrollTop from '~/v2/hooks/useScrollTop';
import { get } from 'lodash';
import AutoConifgureStep from './steps/AutoConifgureStep';
import { useAddIcpMutation } from '~/v2/redux/services/icp';
import { useAddSequenceTemplateMutation } from '~/v2/redux/services/sequenceTemplate';
import { useAddAccountQualificationMutation } from '~/v2/redux/services/accountQualification';
import { setSelectedIcp } from '~/v2/redux/slices/icpSlice';
import { setSelectedSequenceTemplate } from '~/v2/redux/slices/sequenceTemplateSlice';
import { setSelectedAccountQualification } from '~/v2/redux/slices/accountQualificationSlice';

const NUMBER_OF_STEPS = 7;

export default function OnboardingPage() {
  const { handleAsyncError, handleSyncError } = useErrorHandler();
  const formRef = useRef(null);
  const toast = useToast();
  const [signUp] = useSignupMutation();
  const [createTeam] = useCreateTeamMutation();
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const [sendMobileOnboardingEmail] = useSendMobileOnboardingEmailMutation();
  const dispatch = useDispatch();
  const localStorageData = safeJSONParse(
    localStorage.getItem(ONBOARDING_LOCALSTORAGE_KEY)
  );
  const [notificationId] = useState(
    localStorageData?.formInfo.notificationId || v4()
  );
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [isErrorStep, setIsErrorStep] = useState(false);
  const [isLoadingGoogleRes, setIsLoadingGoogleRes] = useState(false);
  const [step, setStep] = useState(localStorageData?.step || 0);
  const [isSocketConnected, setIsSocketConnected] = useState(socket.connected);
  const [showMobileEndpoint, setShowMobileEndpoint] = useState(false);
  const [isFromChromeExtension, setIsFromChromeExtension] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { methods, isGenerating } = useStreamingForm({
    formOptions: {
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: { notificationId, ...localStorageData?.formInfo },
    },
    localIdentifier: notificationId,
    socketIdentifiers: {
      inProgress: 'onboardingStreaming-inProgress',
      complete: 'onboardingStreaming-completed',
      failed: 'onboardingStreaming-failed',
    },
  });
  const [addIcp] = useAddIcpMutation();
  const [addSequenceTemplate] = useAddSequenceTemplateMutation();
  const [addAccountQualification] = useAddAccountQualificationMutation();
  const [setupTeam] = useSetupMutation();
  const [editUser] = useEditMutation();

  const { getValues, setValue } = methods;

  useScrollTop(step);

  useEffect(() => {
    const onbeforeunloadFn = handleSyncError(() => {
      if (!isErrorStep) {
        localStorage.setItem(
          ONBOARDING_LOCALSTORAGE_KEY,
          JSON.stringify({ formInfo: methods.getValues(), step })
        );
      }
    });
    window.addEventListener('beforeunload', onbeforeunloadFn);
    return () => {
      window.removeEventListener('beforeunload', onbeforeunloadFn);
    };
  }, [methods, step, isErrorStep, handleSyncError]);

  useEffect(() => {
    const keyDownHandler = (e) => {
      if (e.key === 'Enter') {
        const isSafari = () => {
          return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        };
        e.preventDefault();
        if (!isSafari()) formRef.current.enterBtnHandler();
      }
    };
    window.addEventListener('keydown', keyDownHandler);
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    socket.on('connect', () => {
      console.info(`Socket Status: Connected`);
      setIsSocketConnected(true);
    });

    socket.on('disconnect', (reason) => {
      console.info(`Socket Status: Not Connected (Reason: ${reason})`);
      setIsSocketConnected(false);
    });

    const handleInProgressNotif = (data) => {
      setLoadingMessage(data.message);
    };
    const handleCompleteNotif = () => {
      setLoadingMessage(null);
    };
    const handleEmailCompleted = ({ workEmails = [] }) => {
      const workEmail = get(workEmails, '[0]');
      if (workEmail) setValue('temporaryEmail', workEmail);
    };

    socket.on('onboarding-inProgress', handleInProgressNotif);
    socket.on('onboarding-completed', handleCompleteNotif);
    socket.on('email-completed', handleEmailCompleted);

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('onboarding-inProgress', handleInProgressNotif);
      socket.off('onboarding-completed', handleCompleteNotif);
      socket.off('email-completed', handleEmailCompleted);
    };
  }, [setValue]);

  const createTeamAndRedirect = handleAsyncError(async (currentUser = {}) => {
    const teamResult = await createOrAddToTeam(
      { createTeam },
      null,
      `${get(getValues('company'), 'companyName')} Team`,
      false,
      tryGetLinkedinHandleFromUrl(getValues('companyLinkedIn')) ||
        tryGetLinkedinHandle(getValues('companyLinkedIn')),
      getValues('senderCompanyInsights'),
      localStorage.getItem('TRUEBASE_LEAD_ID'),
      get(getValues('company'), 'companyName')
    );

    const {
      result: { icp },
    } = await addIcp({
      name: 'My First ICP',
      teamId: teamResult.teamId,
    }).unwrap();
    dispatch(setSelectedIcp(icp));

    const {
      result: { accountQualification },
    } = await addAccountQualification({
      name: 'My First Account Qualification',
      teamId: teamResult.teamId,
    }).unwrap();
    dispatch(setSelectedAccountQualification(accountQualification));

    const {
      result: { sequenceTemplate },
    } = await addSequenceTemplate({
      name: 'My First Email Sequence Template',
      teamId: teamResult.teamId,
    }).unwrap();
    dispatch(setSelectedSequenceTemplate(sequenceTemplate));

    await editUser({
      company: get(getValues('company'), 'companyName'),
      title: getValues('personTitle'),
    });

    localStorage.setItem('refreshUser', 'true');
    localStorage.removeItem('connections');

    if (isMobile) {
      setShowMobileEndpoint(true);
      setStep(5);
      sendMobileOnboardingEmail({
        email: currentUser.email || getValues('email'),
        firstName: currentUser.firstName || getValues('personFirstName'),
      });

      await setupTeam({
        icpId: icp._id,
        accountQualificationId: accountQualification._id,
        sequenceTemplateId: sequenceTemplate._id,
        teamId: teamResult.teamId,
      });
      localStorage.removeItem('TRUEBASE_LEAD_ID');
    } else {
      setStep(6);
    }
    if (isLoadingGoogleRes) {
      setIsLoadingGoogleRes(false);
    }
  });

  useEffect(() => {
    if (notificationId && isSocketConnected) {
      socket.emit('join', { userId: notificationId });
    }
  }, [notificationId, isSocketConnected]);

  useEffect(() => {
    const src = new URLSearchParams(location.search).get('utm_source');
    if (src?.includes('chrome')) setIsFromChromeExtension(true);
    const fetchData = handleAsyncError(async () => {
      const shouldShowMobileOnboarding = new URLSearchParams(
        location.search
      ).get('showMobileEndpoint');
      if (shouldShowMobileOnboarding) {
        const email = new URLSearchParams(location.search).get('email');
        setShowMobileEndpoint(true);
        setStep(5);
        if (email) {
          sendMobileOnboardingEmail({
            email,
            firstName: getValues('personFirstName'),
          });
        }
        return;
      }
      const tokenString = new URLSearchParams(location.search).get('t');
      const error = new URLSearchParams(location.search).get('e');
      if (error) {
        toast({
          title: 'Signup failed',
          ...(['dup', 'ad'].includes(error) && {
            description:
              error === 'dup'
                ? 'Account already exists. Please use a different email or login'
                : 'Access denied',
          }),
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        localStorage.removeItem('AccessToken');
        window.history.replaceState(
          {},
          '',
          `${location.origin}/onboarding?utm_source=${src}`
        );
        return;
      } else if (tokenString) {
        setIsLoadingGoogleRes(true);
        const token = safeJSONParse(
          decodeURIComponent(tokenString.replace('#', ''))
        );
        localStorage.setItem('AccessToken', token.authToken);
        const userResponse = await getCurrentUser().unwrap();
        const currentUser = userResponse.result.user;
        if (currentUser)
          localStorage.setItem('User', JSON.stringify(currentUser));
        await createTeamAndRedirect(currentUser);
        window.history.replaceState(
          {},
          '',
          `${location.origin}/onboarding?utm_source=${src}`
        );
        return;
      }
    });

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = handleAsyncError(async () => {
    setIsSubmitLoading(true);
    const payload = {
      firstName: getValues('personFirstName'),
      lastName: getValues('personLastName'),
      password: getValues('password'),
      email: getValues('email'),
      authProvider: 'truebase',
      token: getValues('verificationCode'),
      intercomId: localStorage.getItem('TRUEBASE_LEAD_ID') ?? undefined,
      src: isFromChromeExtension ? 'chromeExtension' : 'app',
      personId:
        tryGetLinkedinHandleFromUrl(getValues('personLinkedIn')) ||
        tryGetLinkedinHandle(getValues('personLinkedIn')),
    };
    const signUpResponse = await signUp({ ...payload }).unwrap();
    if (!signUpResponse.isOk) {
      setIsSubmitLoading(false);
      return toast({
        title: 'Signup failed',
        description: signUpResponse.error?.message ?? 'Something went wrong!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    localStorage.setItem(
      ONBOARDING_LOCALSTORAGE_KEY,
      JSON.stringify({
        formInfo: methods.getValues(),
        step,
      })
    );
    localStorage.setItem('AccessToken', signUpResponse.result.authToken);

    const userResponse = await getCurrentUser().unwrap();
    const currentUser = userResponse.result.user;
    if (currentUser) localStorage.setItem('User', JSON.stringify(currentUser));

    await createTeamAndRedirect();
    setIsSubmitLoading(false);
  });

  return (
    <Container maxW="536px" w="100%" margin="0 auto" pb="180px">
      <Helmet title={'Truebase | Onboarding'} defer={false} />
      <Flex flexDir="column" gap={{ base: '24px', md: '48px' }}>
        <Flex py="32px" flexDir="column" gap="16px">
          <Flex flexDir="column" gap="8px">
            <OverDarkIcon />
            <TruebaseLogo />
          </Flex>
          <Progress
            value={(100 / NUMBER_OF_STEPS) * (step + 1)}
            variant="truebase"
            sx={{
              '& > div': {
                transitionProperty: 'all',
                transitionDuration: 'transition.duration.slow',
              },
            }}
          />
        </Flex>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Flex pb={{ base: '48px', md: '80px' }} gap="24px" flexDir="column">
              {showMobileEndpoint ? (
                <MobileEndpoint />
              ) : (
                (() => {
                  switch (step) {
                    case 0:
                      return (
                        <PersonLinkedInStep
                          ref={formRef}
                          loadingMessage={loadingMessage}
                          setLoadingMessage={setLoadingMessage}
                          stepInfo={{
                            step,
                            setStep,
                          }}
                          isFromChromeExtension={isFromChromeExtension}
                        />
                      );
                    case 1:
                      return (
                        <CompanyLinkedInStep
                          ref={formRef}
                          loadingMessage={loadingMessage}
                          setLoadingMessage={setLoadingMessage}
                          stepInfo={{
                            step,
                            setStep,
                          }}
                        />
                      );
                    case 2:
                      return (
                        <OfferingStep
                          ref={formRef}
                          loadingMessage={loadingMessage}
                          setLoadingMessage={setLoadingMessage}
                          isGenerating={isGenerating}
                          stepInfo={{
                            step,
                            setStep,
                          }}
                        />
                      );
                    case 3:
                      return (
                        <EmailStep
                          ref={formRef}
                          stepInfo={{
                            step,
                            setStep,
                          }}
                          setShowMobileEndpoint={setShowMobileEndpoint}
                          isFromChromeExtension={isFromChromeExtension}
                          isLoadingGoogleRes={isLoadingGoogleRes}
                        />
                      );
                    case 4:
                      return (
                        <VerifyEmailStep
                          ref={formRef}
                          stepInfo={{ step, setStep }}
                        />
                      );
                    case 5:
                      return (
                        <PasswordStep
                          ref={formRef}
                          stepInfo={{ step, setStep }}
                          isLoading={isSubmitLoading}
                        />
                      );
                    case 6:
                      return (
                        <AutoConifgureStep
                          ref={formRef}
                          stepInfo={{ step, setStep }}
                          isLoading={isSubmitLoading}
                          isFromChromeExtension={isFromChromeExtension}
                          getValues={getValues}
                        />
                      );
                    default:
                      setIsErrorStep(true);
                      return <Text>Something went wrong</Text>;
                  }
                })()
              )}
            </Flex>
          </form>
        </FormProvider>
        {step <= 3 && !showMobileEndpoint && !isLoadingGoogleRes && (
          <Flex gap="24px" flexDir="column" alignItems="center">
            <Text fontSize="13px" fontWeight={400} color="trueSpace">
              Already have an account?
            </Text>
            <Button
              w="64px"
              h="32px"
              variant="truebaseTextHighlight"
              as={Link}
              to={`/login${
                isFromChromeExtension ? '?utm_source=chrome-extension' : ''
              }`}
            >
              Login
            </Button>
          </Flex>
        )}
      </Flex>
    </Container>
  );
}
