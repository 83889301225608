import { Button, Center } from '@chakra-ui/react';
import React, { forwardRef } from 'react';
import { ReactComponent as AIMagic } from '~/v2/assets/icons/AI magic.svg';

export const OnboardingButton = forwardRef(
  ({ children, noIcon, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        type="button"
        variant="truebaseOutline"
        leftIcon={noIcon ? null : <AIMagic />}
        {...props}
      >
        {children}
      </Button>
    );
  }
);

export const OnboardingBackButton = ({ children, ...props }) => {
  return (
    <Center>
      <Button type="button" w="62px" h="32px" variant="truebaseText" {...props}>
        Back
      </Button>
    </Center>
  );
};
