import React from 'react';
import PropTypes from 'prop-types';

const CloudsGray = ({ width = 366, height = 89 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 366 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.75 74.71L95.53 62.6301L95.89 48.5401L93.52 41.8301L67.01 38.8L47.88 52.23L53.5 69.9801L62.31 73.3701L78.75 74.71Z"
        fill="#F5F9FA"
      />
      <path
        d="M92.85 79.07L101.57 64.64L95.89 48.54L95.53 62.63L78.75 74.71L62.31 73.37L53.5 69.98L56.27 78.74L92.85 79.07Z"
        fill="#EEF1F9"
      />
      <path
        d="M54.93 74.96L63.43 72.07L68.02 66.91L57.62 51.48L33.82 50.9L20.03 63.89L25.68 71.16L32.78 74.63L54.93 74.96Z"
        fill="#F5F9FA"
      />
      <path
        d="M57.28 78.99L63.43 72.07L54.93 74.96L32.78 74.63L25.68 71.16L31.78 78.99H57.28Z"
        fill="#EEF1F9"
      />
      <path
        d="M107.29 76.35L113.27 74.72L114.67 70.98L109.97 61.92L96.55 63.6L88.16 71.65L89.94 73.93L96.55 75.68L107.29 76.35Z"
        fill="#F5F9FA"
      />
      <path
        d="M111.65 79.0299L113.27 74.7199L107.29 76.3499L96.55 75.6799L89.94 73.9299L94.2 79.3699L111.65 79.0299Z"
        fill="#EEF1F9"
      />
      <path
        d="M134.76 78.32C134.76 79.18 104.6 79.87 67.38 79.87C30.16 79.87 0 79.18 0 78.32C0 77.46 30.17 76.78 67.38 76.78C104.59 76.78 134.76 77.47 134.76 78.32Z"
        fill="#EEF1F9"
      />
      <path
        d="M194.61 35.42L187.69 33.53L186.06 29.2001L192.12 20.55L207.05 20.66L216.76 29.98L214.7 32.62L207.05 34.6501L194.61 35.42Z"
        fill="#F5F9FA"
      />
      <path
        d="M189.56 38.53L187.69 33.53L194.61 35.42L207.05 34.65L214.7 32.62L209.76 38.92L189.56 38.53Z"
        fill="#F5F9FA"
      />
      <path
        d="M235.02 35.9L251.8 23.82L252.16 9.73L249.79 3.02L223.28 0L204.15 13.42L209.77 31.17L218.58 34.56L235.02 35.9Z"
        fill="#F5F9FA"
      />
      <path
        d="M249.11 40.27L257.84 25.84L252.16 9.72998L251.8 23.82L235.02 35.9L218.58 34.56L209.77 31.17L212.54 39.93L249.11 40.27Z"
        fill="#EEF1F9"
      />
      <path
        d="M263.55 37.55L269.54 35.91L270.94 32.18L266.24 23.12L252.82 24.8L244.43 32.85L246.21 35.13L252.82 36.88L263.55 37.55Z"
        fill="#F5F9FA"
      />
      <path
        d="M267.92 40.23L269.54 35.91L263.55 37.55L252.82 36.88L246.21 35.13L250.47 40.57L267.92 40.23Z"
        fill="#F5F9FA"
      />
      <path
        d="M295.88 39.52C295.88 40.37 265.71 41.06 228.5 41.06C191.29 41.06 161.12 40.37 161.12 39.52C161.12 38.67 191.29 37.98 228.5 37.98C265.71 37.98 295.88 38.67 295.88 39.52Z"
        fill="#EEF1F9"
      />
      <path
        d="M344.29 85.3499L348.45 84.2199L349.43 81.6099L345.79 76.4099L336.81 76.4699L330.97 82.0799L332.21 83.6699L336.81 84.8799L344.29 85.3499Z"
        fill="#F5F9FA"
      />
      <path
        d="M347.32 87.2199L348.45 84.2199L344.29 85.3499L336.81 84.8799L332.21 83.6699L335.18 87.4499L347.32 87.2199Z"
        fill="#EEF1F9"
      />
      <path
        d="M321.05 85.6401L310.97 78.3801L310.75 69.9101L312.18 65.8701L328.11 64.0601L339.61 72.1301L336.24 82.8001L330.94 84.8301L321.05 85.6401Z"
        fill="#F5F9FA"
      />
      <path
        d="M312.58 88.26L307.34 79.59L310.75 69.91L310.97 78.38L321.05 85.64L330.94 84.83L336.24 82.8L334.57 88.06L312.58 88.26Z"
        fill="#EEF1F9"
      />
      <path
        d="M303.9 86.63L300.3 85.65L299.46 83.4L302.28 77.95L310.35 78.96L315.4 83.8L314.33 85.17L310.35 86.22L303.9 86.63Z"
        fill="#F5F9FA"
      />
      <path
        d="M301.28 88.24L300.3 85.65L303.9 86.63L310.35 86.22L314.33 85.17L311.76 88.44L301.28 88.24Z"
        fill="#EEF1F9"
      />
      <path
        d="M284.46 87.82C284.46 88.33 302.6 88.74 324.97 88.74C347.34 88.74 365.48 88.33 365.48 87.82C365.48 87.31 347.35 86.89 324.97 86.89C302.59 86.89 284.46 87.3 284.46 87.82Z"
        fill="#EEF1F9"
      />
    </svg>
  );
};

CloudsGray.propTypes = {
  width: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
};

export default CloudsGray;
