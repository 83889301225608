import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useEffect } from 'react';
import HitsAndMisses from '../components/HitsAndMisses';
import { useSelector } from 'react-redux';
import { getStatus } from '~/v2/redux/slices/statusSlice';
import { useSpotlightSearchContext } from '../../SpotlightSearch/useSpotlightSearchContext';
import AccountInsights from '../TruebaseAccountAccordion/AccountInsights';

export default function AccountInLeadViewTabs({
  readonly,
  showHitsAndMisses,
  companyFilters,
  account,
  tabIndex,
  setTabIndex,
}) {
  const { isSpotlightSearchMode } = useSpotlightSearchContext();

  const hideAccountTabs =
    location.pathname.includes('contacts') && !isSpotlightSearchMode;

  const isGeneratingAccountScore = useSelector(getStatus)(
    account.companyId
  )?.find(({ type }) => type === 'accountScoring');

  useEffect(() => {
    if (isGeneratingAccountScore && !hideAccountTabs) {
      setTabIndex(1);
    }
  }, [isGeneratingAccountScore, hideAccountTabs, setTabIndex]);

  return (
    hideAccountTabs || (
      <Tabs
        position="relative"
        variant="truebase"
        w="100%"
        index={tabIndex}
        onChange={(i) => setTabIndex(i)}
      >
        <TabList>
          <Tab>Insights</Tab>
          {showHitsAndMisses && <Tab>Search Evaluation</Tab>}
        </TabList>
        <TabPanels>
          <TabPanel>
            <AccountInsights account={account} readonly={readonly} />
          </TabPanel>
          {showHitsAndMisses && (
            <TabPanel>
              <HitsAndMisses
                companyFilters={companyFilters}
                companyId={account.companyId}
              />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    )
  );
}
