import { Divider, Flex, Icon, Text } from '@chakra-ui/react';
import { ReactComponent as ArrowLeft } from '../assets/icons/arrow/chevron-left.svg';

const Layout = ({
  children,
  icon,
  title,
  subtitle,
  withBackBtn,
  onBackClick,
  extraHeaderContent,
  maxWidth = '1152px',
  footer,
}) => {
  return (
    <Flex
      bg="#FAFAFA"
      flexGrow="1"
      h="100%"
      flexDir="column"
      pos="relative"
      pb={footer ? '48px' : 'unset'}
    >
      {(title || extraHeaderContent) && (
        <>
          <Flex justifyContent="center">
            <Flex
              maxW={maxWidth}
              w="100%"
              alignItems="center"
              py="24px"
              gap="12px"
            >
              {withBackBtn && (
                <Icon
                  as={ArrowLeft}
                  w="40px"
                  h="40px"
                  cursor="pointer"
                  onClick={onBackClick}
                />
              )}
              {icon}
              <Flex flexDir="column">
                <Text fontWeight="700" fontSize="22px" lineHeight="26px">
                  {title}
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="13px"
                  lineHeight="16px"
                  color="trueExplain"
                >
                  {subtitle}
                </Text>
              </Flex>
              {extraHeaderContent}
            </Flex>
          </Flex>

          <Divider color="black" />
        </>
      )}

      <Flex flexDir="column" overflow="auto" alignItems="center" h="100%">
        <Flex maxW={maxWidth} w="100%" flexDir="column" h="100%">
          {children}
        </Flex>
        {footer}
      </Flex>
    </Flex>
  );
};

export default Layout;
