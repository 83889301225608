import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, {
  forwardRef,
  useImperativeHandle,
  useCallback,
  useEffect,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { LayoutGroup, motion } from 'framer-motion';
import { OnboardingButton } from '../shared/OnboardingButtons';
import LoadingMessage from '../shared/LoadingMessage';
import OnboardingTitle from '../shared/OnboardingTitle';
import {
  useGetEmailMutation,
  useGetSignupDetailsMutation,
} from '../../../../redux/services/person';
import { sendChromeExtensionMessage } from '~/v2/utility/chrome';
import schemas from '~/utils/schemas';
import {
  tryGetLinkedinHandle,
  tryGetLinkedinHandleFromUrl,
} from '~/v2/common/utils';
import socket from '~/v2/utility/socket';

const PersonLinkedInStep = forwardRef(
  (
    { stepInfo, loadingMessage, setLoadingMessage, isFromChromeExtension },
    ref
  ) => {
    const {
      register,
      watch,
      setValue,
      setError,
      clearErrors,
      formState: { errors },
    } = useFormContext();
    const intercomId = localStorage.getItem('TRUEBASE_LEAD_ID');
    const intercomVisitorId = localStorage.getItem('INTERCOM_VISITOR_ID');
    const srcApp = new URLSearchParams(location.search).get('utm_source');
    const [getSignupDetails, { isLoading }] = useGetSignupDetailsMutation();
    const [getEmail] = useGetEmailMutation();
    const [personLinkedIn, notificationId] = watch([
      'personLinkedIn',
      'notificationId',
    ]);
    const isButtonDisabled =
      !personLinkedIn || errors?.personLinkedIn || isLoading;
    const toast = useToast();

    const handleClick = useCallback(async () => {
      clearErrors('personLinkedIn');
      const personId =
        tryGetLinkedinHandleFromUrl(personLinkedIn) ||
        tryGetLinkedinHandle(personLinkedIn);
      try {
        const res = await getSignupDetails({
          personId,
          notificationId,
          ...(intercomId && { intercomId }),
          ...(intercomVisitorId && { intercomVisitorId }),
          ...(srcApp && { srcApp }),
        }).unwrap();
        if (res.code === 504) {
          throw new Error('timeout');
        } else if (!res.isOk) {
          setLoadingMessage(null);
          setError('personLinkedIn', {
            type: 'manual',
            message: `Can't find your LinkedIn profile; check spelling.`,
          });
        }
      } catch (e) {
        setLoadingMessage(null);
        toast({
          title: 'An error occurred. Please try again.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    }, [
      clearErrors,
      setError,
      personLinkedIn,
      getSignupDetails,
      toast,
      notificationId,
      setLoadingMessage,
      intercomId,
      intercomVisitorId,
      srcApp,
    ]);

    useImperativeHandle(
      ref,
      () => {
        return {
          enterBtnHandler() {
            isButtonDisabled ? null : handleClick();
          },
        };
      },
      [isButtonDisabled, handleClick]
    );

    useEffect(() => {
      const handleCompleteNotif = (result) => {
        setValue('temporaryCompanyId', result.companyId);
        setValue('companyDomains', result.companyDomains);
        setValue('personFirstName', result.personFirstName);
        setValue('personLastName', result.personLastName);
        setValue('personTitle', result.personTitle);

        if (result.intercomId) {
          localStorage.setItem('TRUEBASE_LEAD_ID', result.intercomId);
          if (isFromChromeExtension)
            sendChromeExtensionMessage({
              newFields: {
                intercomId: result.intercomId,
              },
              destorySenderTab: false,
            });
        }

        if (personLinkedIn && result?.companyId) {
          const personId =
            tryGetLinkedinHandleFromUrl(personLinkedIn) ||
            tryGetLinkedinHandle(personLinkedIn);

          getEmail({
            personId,
            personLinkedinUrl: result.personLinkedinUrl,
            personName: result.personName,
            companyId: result.companyId,
            companyName: result.companyName,
            companyDomains: result.companyDomains,
            notificationId,
          });
        }

        stepInfo.setStep((s) => s + 1);
      };

      socket.on('personRecord-completed', handleCompleteNotif);

      return () => {
        socket.off('personRecord-completed', handleCompleteNotif);
      };
    }, [
      notificationId,
      personLinkedIn,
      getEmail,
      stepInfo,
      isFromChromeExtension,
      setValue,
    ]);

    return (
      <LayoutGroup>
        <OnboardingTitle id="person-step">
          <Text>Ready to dive into AI prospecting?</Text>
          <Text>Let&apos;s get started!</Text>
        </OnboardingTitle>
        <LoadingMessage
          loadingMessage={loadingMessage}
          error={errors.personLinkedIn}
        />
        <motion.div
          style={{ display: 'flex', gap: '24px', flexDirection: 'column' }}
          layout="preserve-aspect"
          transition={{
            type: 'spring',
            stiffness: 75,
            duration: 1,
          }}
        >
          <FormControl variant="truebase" isRequired>
            <FormLabel
              variant="truebase"
              color="trueMedium"
              requiredIndicator={' *'}
            >
              Can we know your LinkedIn?
            </FormLabel>
            <Input
              {...register('personLinkedIn', {
                setValueAs: (v) => v?.toLowerCase(),
                validate: async (value) => {
                  try {
                    await schemas.personLinkedinHandleOrUrlSchema.validate(
                      value.trim()
                    );
                  } catch {
                    try {
                      await schemas.companyLinkedinUrlSchema.validate(
                        value.trim()
                      );
                      return "Oops! That's a company’s LinkedIn; we need a person’s handle or URL.";
                    } catch {
                      return 'Please enter a valid Linkedin URL or handle!';
                    }
                  }
                },
              })}
              variant="truebaseFakeDisabled"
              placeholder="LinkedIn handle or URL"
              isDisabled={isLoading}
              h="48px"
            />
            <FormHelperText color="trueExplain">
              Your LinkedIn will help us set up your account efficiently.
            </FormHelperText>
          </FormControl>
          <OnboardingButton isDisabled={isButtonDisabled} onClick={handleClick}>
            Next
          </OnboardingButton>
        </motion.div>
      </LayoutGroup>
    );
  }
);

export default PersonLinkedInStep;
