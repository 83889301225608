import React from 'react';
import styled from 'styled-components';

const GlobalWarningWrapper = styled.div`
  width: 100%;
  background-color: var(--chakra-colors-trueWarning);
  position: sticky;
  min-height: 40px;
  top: ${({ top }) => (top ? `${top}px` : 0)};
  z-index: 1000;
  left: 0;
  transition: top 750ms ease;
  text-align: center;

  div:first-of-type {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 8px 32px;
    position: relative;
  }

  p {
    color: var(--chakra-colors-trueSpace);
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    position: relative;
    top: 1px;
  }

  p:last-of-type {
    display: inline;
  }
`;

const WarningIcon = styled.img`
  color: black;
  display: inline;
  margin-right: 14px;
`;

const GlobalWarning = ({ top }) => {
  return (
    <GlobalWarningWrapper top={top}>
      <div>
        <WarningIcon alt="warning" src="static/img/warning.svg" />
        <p>{process.env.REACT_APP_GLOBAL_WARNING_MESSAGE}</p>
      </div>
    </GlobalWarningWrapper>
  );
};

export default GlobalWarning;
