import { Box, Flex, useRadio } from '@chakra-ui/react';
import { ReactComponent as RadioOn } from '~/v2/assets/icons/radio/radio-large-on.svg';
import { ReactComponent as RadioOff } from '~/v2/assets/icons/radio/radio-large-off.svg';
import { TruebaseTooltip } from '../TruebaseTooltip';

export function CustomRadio(props) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  const { isDisabled } = props;

  return (
    <TruebaseTooltip variant="truebase" label={isDisabled ? 'Coming soon' : ''}>
      <Flex
        as="label"
        gap="8px"
        alignItems="center"
        sx={{
          color: input.checked ? 'trueSpace' : 'trueDim',
          cursor: isDisabled ? 'not-allowed' : 'pointer',
        }}
      >
        <input {...input} />
        <Box
          {...checkbox}
          sx={{
            svg: {
              fill: isDisabled
                ? 'trueDisabled'
                : input.checked
                ? 'trueLink'
                : 'trueDim',
            },
          }}
        >
          {input.checked ? <RadioOn /> : <RadioOff />}
        </Box>
        {props.children}
      </Flex>
    </TruebaseTooltip>
  );
}
