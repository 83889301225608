import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const truebase = definePartsStyle({
  icon: defineStyle({}),
  control: defineStyle({
    w: '18px',
    h: '18px',
    m: '3px',
    borderColor: 'trueDim',
    _checked: {
      color: 'white',
      background: 'trueLink',
    },
  }),
  label: defineStyle({
    fontSize: '14px',
    lineHeight: '16px',
    color: 'trueMedium',
    ml: '16px',
  }),
});

const truebaseNew = definePartsStyle({
  icon: defineStyle({}),
  control: defineStyle({
    w: '18px',
    h: '18px',
    m: '3px',
    borderColor: 'trueDim',
    color: 'trueDim',
    _checked: {
      color: 'white',
      background: 'trueLink',
      _disabled: {
        background: 'trueDisabled',
        color: 'white',
        borderColor: 'trueDisabled',
      },
    },
    _disabled: {
      borderColor: 'trueDisabled',
      background: 'trueDisabled',
      color: 'white',
    },
  }),
  label: defineStyle({
    fontSize: '14px',
    lineHeight: '16px',
    color: 'trueMedium',
    ml: '16px',
    fontWeight: 500,
    _checked: {
      color: 'trueSpace',
    },
    _disabled: {
      color: 'trueStrong',
    },
  }),
});

export const checkboxTheme = defineMultiStyleConfig({
  variants: { truebase, truebaseNew },
});
