import { Tooltip, useStyleConfig } from '@chakra-ui/react';
import React from 'react';

export const TruebaseTooltip = (props) => {
  const { children, hasArrow = true, type, sx = {}, ...rest } = props;
  const styles = useStyleConfig('TruebaseTooltip', props);
  return (
    <Tooltip
      sx={{ ...styles, ...sx }}
      hasArrow={hasArrow}
      maxW={type === 'link' ? '300px' : props.maxW || 'unset'}
      textOverflow="ellipsis"
      whiteSpace={type === 'link' ? 'nowrap' : 'normal'}
      overflow={type === 'link' ? 'hidden' : 'visible'}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};
