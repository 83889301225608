import React, { useCallback, useEffect } from 'react';
import { defaultTo } from 'lodash';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  Box,
  Flex,
  IconButton,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepTitle,
  StepDescription,
  StepNumber,
  StepSeparator,
  useSteps,
  Text,
  FormControl,
  Spacer,
  Icon,
  useToast,
} from '@chakra-ui/react';
import { EmailPreview } from './EmailAccountsPreview';
import { Controller, useFormContext } from 'react-hook-form';
import { ReactComponent as Close } from '~/v2/assets/icons/close/close-large.svg';
import { ReactComponent as CheckOn } from '~/v2/assets/icons/done/done-small.svg';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { ReactComponent as SendIcon } from '~/v2/assets/icons/mail/send.svg';
import { useSendPreviewEmailMutation } from '~/v2/redux/services/teams';
import SignatureTextEditor from './SignatureTextEditor';
import { removeHTMLMargins } from '~/v2/common/utils';

const steps = [
  {
    title: 'Email Account',
    description: 'Daily limit & more',
  },
  {
    title: 'Signature',
    description: 'Add your personal touch',
  },
];

const stepWidth = [138, 174, 126];

const EmailSetupModal = ({
  selectedEmailIndex,
  emailSentCounts,
  isOpen,
  onClose,
  isUpdateTeamLoading,
}) => {
  const { watch, setValue, control } = useFormContext();

  const [sendPreviewEmail, { isLoading }] = useSendPreviewEmailMutation();

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const selectedEmail = defaultTo(
    watch()?.emailAccounts?.[selectedEmailIndex],
    {}
  );

  useEffect(() => {
    if (!isOpen && activeStep) {
      setActiveStep(0);
    }
  }, [isOpen, setActiveStep, activeStep]);

  const DailyLimitStep = useCallback(() => {
    return (
      <Flex direction="column" gap="24px">
        <EmailPreview
          field={selectedEmail}
          showMenu={false}
          emailSentCounts={emailSentCounts}
        />
        <Text
          sx={{ fontSize: '13px', lineHeight: '16px', color: 'trueExplain' }}
        >
          The maximum number of emails that could be sent via this email account
          per day.
        </Text>
        <Box>
          <Flex
            justifyContent="space-between"
            sx={{ fontSize: '13px', lineHeight: '16px', fontWeight: 500 }}
          >
            <Text>Daily Limit</Text>
            <Text>{selectedEmail.dailyLimit}/500</Text>
          </Flex>
          <Slider
            value={selectedEmail.dailyLimit}
            min={0}
            max={500}
            step={10}
            variant="truebase"
            onChange={(value) => {
              setValue(`emailAccounts.${selectedEmailIndex}.dailyLimit`, value);
            }}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </Box>
        {selectedEmail.dailyLimit == 0 && (
          <Text
            sx={{
              fontSize: '12px',
              lineHeight: '14px',
              color: 'trueAttention',
            }}
          >
            Daily limit should be higher than 0!
          </Text>
        )}
      </Flex>
    );
  }, [selectedEmail, selectedEmailIndex, setValue, emailSentCounts]);

  const SignatureStep = useCallback(() => {
    return (
      <FormControl>
        <Controller
          name={`emailAccounts.${selectedEmailIndex}.signature`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <SignatureTextEditor value={value} onChange={onChange} />
          )}
        />
      </FormControl>
    );
  }, [control, selectedEmailIndex]);

  const { handleAsyncError } = useErrorHandler();

  const handleNext = handleAsyncError(async (e) => {
    if (activeStep < steps.length - 1) {
      e.preventDefault();
      setActiveStep(activeStep + 1);
    }
  });
  const toast = useToast();

  const handleSendPreviewEmail = async () => {
    const res = await sendPreviewEmail({
      email: selectedEmail._id,
      signature: removeHTMLMargins(selectedEmail.signature),
    });
    toast({
      title: res?.data?.isOk
        ? 'Preview email sent successfully!'
        : 'Something went wrong!',
      status: res?.data?.isOk ? 'success' : 'error',
      duration: 1000,
    });
  };

  return (
    <Modal
      motionPreset="slideInRight"
      size="6xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          borderBottomWidth="1px"
          borderBottomColor="trueLight"
          p="18px 24px"
          fontWeight="500"
          fontSize="16px"
          lineHeight="20px"
        >
          <Stepper index={activeStep} variant="truebase" w="fit-content">
            {steps.map((step, index) => (
              <Step key={index} minW={stepWidth[index] + 64}>
                <StepIndicator
                  borderColor={index <= activeStep ? 'trueBlue' : 'trueLight'}
                >
                  <StepStatus
                    complete={<CheckOn />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>
                <Flex direction="column" shrink="0" gap="4px">
                  <StepTitle>{step.title}</StepTitle>
                  <StepDescription>{step.description}</StepDescription>
                </Flex>
                <StepSeparator
                  sx={{
                    bg:
                      index < activeStep
                        ? 'trueBlue !important'
                        : 'trueLight !important',
                  }}
                />
              </Step>
            ))}
          </Stepper>
          <Box
            sx={{
              w: `${stepWidth[activeStep]}px`,
              h: '2px',
              bg: 'trueBlue',
              position: 'absolute',
              top: '70px',
              left: `${
                24 +
                stepWidth.reduce(
                  (acc, curr, index) =>
                    acc + (index < activeStep ? curr + 82 : 0),
                  0
                )
              }px`,
            }}
          />
        </ModalHeader>
        <Box position="absolute" right="24px" top="20px">
          <IconButton
            onClick={onClose}
            variant="ghost"
            sx={{ w: '32px', h: '32px', minW: 0, svg: { fill: 'trueDim' } }}
          >
            <Close />
          </IconButton>
        </Box>
        <ModalBody
          p="24px"
          borderBottomWidth="1px"
          borderBottomColor="trueLight"
        >
          {activeStep === 0 ? <DailyLimitStep /> : <SignatureStep />}
        </ModalBody>
        <ModalFooter p="16px 24px" gap="16px">
          {activeStep === 1 && (
            <>
              <Button
                variant="truebaseOutlineNeutral"
                gap="8px"
                onClick={handleSendPreviewEmail}
                isLoading={isLoading}
                isDisabled={!selectedEmail?.signature}
              >
                <Icon as={SendIcon} fill="trueDim" w="24px" h="24px" />
                Send me a preview
              </Button>
              <Spacer />
            </>
          )}
          <Button
            type="button"
            w="200px"
            h="40px"
            variant="truebaseOutlineNeutral"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            h="40px"
            w="200px"
            variant="truebase"
            fontSize="13px"
            form="emailAccountsForm"
            type={activeStep === steps.length - 1 ? 'submit' : 'button'}
            isLoading={isUpdateTeamLoading}
            onClick={handleNext}
            isDisabled={activeStep === 0 && selectedEmail.dailyLimit === 0}
          >
            {activeStep === 1 ? 'Save' : 'Next'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EmailSetupModal;
