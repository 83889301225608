import React from 'react';
import { Center, Text, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as Cloud } from '~/v2/assets/icons/cloud.svg';

const SearchLink = () => (
  <Link
    color="trueLink"
    to="/settings/icp"
    textDecoration="underline"
    fontWeight={500}
    as={RouterLink}
  >
    Search
  </Link>
);

const textStyleProps = {
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 400,
  color: 'trueExplain',
};

const messages = {
  leads: (
    <Text {...textStyleProps}>
      No leads saved yet! Use the <SearchLink /> to find and save leads for this
      account!
    </Text>
  ),
  insights: (
    <Text {...textStyleProps}>
      Use <SearchLink /> to discover insights about this account.
    </Text>
  ),
  score: (
    <Text {...textStyleProps}>
      Need a score for this account? Hop into <SearchLink />.
    </Text>
  ),
  leadInsights: (
    <Text {...textStyleProps}>
      Use <SearchLink /> to discover insights about this lead.
    </Text>
  ),
  outreachStrategy: (
    <Text {...textStyleProps}>
      Get started on your lead&apos;s Outreach Strategy in the <SearchLink />{' '}
      section!
    </Text>
  ),
  personalizedSequence: (
    <Text {...textStyleProps}>
      Head over to <SearchLink /> to generate a personalized sequence.
    </Text>
  ),
};

const TabEmptyState = ({ tabName = 'leads', ...props }) => {
  return (
    <Center flexDir="column" gap="16px" mb="8px" {...props}>
      <Cloud />
      {messages[tabName]}
    </Center>
  );
};

export default TabEmptyState;
