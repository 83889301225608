import { Route, Routes } from 'react-router-dom';

import ForgotPassword from '~/v2/features/auth/ForgotPassword';
import Login from '~/v2/features/auth/Login';
import ResetPassword from '~/v2/features/auth/ResetPassword';

const UserRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="forgot" element={<ForgotPassword />}></Route>
      <Route path={'reset/:token'} element={<ResetPassword />}></Route>
      <Route path={'welcome/:token'} element={<ResetPassword />}></Route>
    </Routes>
  );
};

export default UserRoutes;
