import { Flex, Spinner, Text } from '@chakra-ui/react';

export const GraphSegment = ({
  shouldShowLoadingState,
  containerProps,
  title,
  subtitle,
}) => {
  return (
    <Flex
      transition="width 0.5s ease-in-out"
      p="8px 24px"
      color="trueSpace"
      flexDir="column"
      justifyContent="center"
      cursor="pointer"
      gap="2px"
      {...containerProps}
    >
      {shouldShowLoadingState ? (
        <Spinner size="sm" color="trueBlue" />
      ) : (
        <Text fontSize="22px" lineHeight="26px" fontWeight="700">
          {title}
        </Text>
      )}
      <Text fontSize="13px" lineHeight="16px" fontWeight="500">
        {subtitle}
      </Text>
    </Flex>
  );
};
