import { createAction, createSlice, createSelector } from '@reduxjs/toolkit';

export const setNotificationStatus = createAction(
  'status/setNotificationStatus'
);
export const removeNotificationStatus = createAction(
  'status/removeNotificationStatus'
);

const slice = createSlice({
  name: 'status',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setNotificationStatus, (state, action) => {
      const { identifier, ...payload } = action.payload;
      if (!state[identifier]) state[identifier] = [];
      if (state[identifier].find((m) => m.type === payload.type)) {
        state[identifier] = state[identifier].map((m) =>
          m.type !== payload.type ? m : payload
        );
      } else {
        state[identifier].push(payload);
      }
    });
    builder.addCase(removeNotificationStatus, (state, action) => {
      const { identifier, ...payload } = action.payload;
      if (state[identifier]) {
        state[identifier] = state[identifier]?.filter(
          (t) => t.type !== payload.type
        );
        if (state[identifier].length === 0) {
          // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
          const { [identifier]: _, ...rest } = state;
          state = { ...rest };
        }
      }
    });
  },
});

const getStatusObject = (state) => state.status;

export const getStatus = createSelector(
  [getStatusObject],
  (status) => (identifier) => status[identifier]
  // This will now only recompute if `state.status` has changed, not on every call
);

export default slice.reducer;
