/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useState } from 'react';

export const IsDirtyContext = createContext({
  isFilterAccordionDirty: false,
  setIsFilterAccordionDirty: () => {},
  isQualificationDirty: false,
  setIsQualificationDirty: () => {},
  isSequenceTemplateDirty: false,
  setIsSequenceTemplateDirty: () => {},
});

export const IsDirtyProvider = ({ children }) => {
  const [isFilterAccordionDirty, setIsFilterAccordionDirty] = useState(false);
  const [isQualificationDirty, setIsQualificationDirty] = useState(false);
  const [isSequenceTemplateDirty, setIsSequenceTemplateDirty] = useState(false);

  return (
    <IsDirtyContext.Provider
      value={{
        isFilterAccordionDirty,
        setIsFilterAccordionDirty,
        isQualificationDirty,
        setIsQualificationDirty,
        isSequenceTemplateDirty,
        setIsSequenceTemplateDirty,
      }}
    >
      {children}
    </IsDirtyContext.Provider>
  );
};
