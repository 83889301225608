import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import OnboardingTitle from '../shared/OnboardingTitle';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import LoadingMessage from '../shared/LoadingMessage';
import {
  OnboardingBackButton,
  OnboardingButton,
} from '../shared/OnboardingButtons';
import {
  useGenerateOfferingMutation,
  useGetCompanySignupDetailsMutation,
} from '../../../../redux/services/company';
import { EMPTY_INSIGHTS_VALUE } from '../shared/utils';
import { LayoutGroup, motion } from 'framer-motion';
import { sleep } from '../../../../../utils';
import { getRandomInt } from '../../../../common/utils';
import schemas from '~/utils/schemas';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import socket from '~/v2/utility/socket';

const CompanyLinkedInStep = forwardRef(
  ({ stepInfo, loadingMessage, setLoadingMessage }, ref) => {
    const toast = useToast();
    const { handleAsyncError } = useErrorHandler();
    const [isFakeGenerating, setIsFakeGenerating] = useState(false);
    const {
      watch,
      register,
      setValue,
      setError,
      clearErrors,
      formState: { errors },
    } = useFormContext();
    const [getCompanySignupDetails, { isLoading }] =
      useGetCompanySignupDetailsMutation();
    const [startGenerateOffering] = useGenerateOfferingMutation();
    const [
      personFirstName,
      companyLinkedIn,
      notificationId,
      temporaryCompanyId,
      companyId,
    ] = watch([
      'personFirstName',
      'companyLinkedIn',
      'notificationId',
      'temporaryCompanyId',
      'companyId',
    ]);

    const isButtonDisabled = useMemo(
      () =>
        !companyLinkedIn ||
        errors?.companyLinkedIn ||
        isLoading ||
        isFakeGenerating,
      [companyLinkedIn, errors?.companyLinkedIn, isLoading, isFakeGenerating]
    );

    const handleClick = useCallback(async () => {
      clearErrors('companyLinkedIn');
      try {
        const res = await getCompanySignupDetails({
          companyId: companyLinkedIn,
          notificationId,
        }).unwrap();
        if (res.code === 504) {
          throw new Error('timeout');
        } else if (!res.isOk) {
          setLoadingMessage(null);
          setError('companyLinkedIn', {
            type: 'manual',
            message:
              "Uh-oh! Your company’s LinkedIn profile wasn't found. Spelling error?",
          });
        }
      } catch (e) {
        setLoadingMessage(null);
        toast({
          title: 'An error occurred. Please try again.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    }, [
      clearErrors,
      companyLinkedIn,
      getCompanySignupDetails,
      notificationId,
      setLoadingMessage,
      setError,
      toast,
    ]);

    useImperativeHandle(
      ref,
      () => {
        return {
          enterBtnHandler() {
            isButtonDisabled ? null : handleClick();
          },
        };
      },
      [isButtonDisabled, handleClick]
    );

    useEffect(() => {
      const handleCompleteNotif = (result) => {
        stepInfo.setStep((s) => s + 1);
        setValue('company', result);
        setValue('senderCompanyInsights', EMPTY_INSIGHTS_VALUE);
        startGenerateOffering({
          companyId: companyLinkedIn,
          companyRecord: result,
          notificationId,
        });
      };

      socket.on('companyRecord-completed', handleCompleteNotif);

      return () => {
        socket.off('companyRecord-completed', handleCompleteNotif);
      };
    }, [
      notificationId,
      companyLinkedIn,
      startGenerateOffering,
      stepInfo,
      setValue,
    ]);

    useEffect(() => {
      const wrappedFn = handleAsyncError(async () => {
        setIsFakeGenerating(true);
        //if there is a value saved in temporaryCompanyId, we copy it since it's the value we found. otherwise we don't show anything
        if (temporaryCompanyId && (companyId === '' || !companyId)) {
          const companyIdSplit = temporaryCompanyId.split('');
          // add character by character the temporaryCompanyId field with a 10ms delay between them
          for (let i = 0; i < companyIdSplit.length; i++) {
            setValue(
              'companyLinkedIn',
              companyIdSplit.slice(0, i + 1).join('')
            );
            await sleep(getRandomInt(10, 100));
          }
        }
        setIsFakeGenerating(false);
      });
      wrappedFn();
      //disable dependencies since we don't want to sync the value of the two, we just want to run it once if it has a value there and the current companyId is empty (in case the user went back)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleAsyncError]);

    return (
      <LayoutGroup>
        <OnboardingTitle>
          <Text>Delighted to see you onboard,</Text>
          <Text>{personFirstName}</Text>
        </OnboardingTitle>
        <LoadingMessage
          loadingMessage={loadingMessage}
          error={errors.companyLinkedIn}
        />
        <motion.div
          style={{ display: 'flex', gap: '24px', flexDirection: 'column' }}
          layout="preserve-aspect"
          transition={{
            type: 'spring',
            stiffness: 75,
            duration: 1,
          }}
        >
          <FormControl variant="truebase" isRequired>
            <FormLabel
              variant="truebase"
              color="trueMedium"
              requiredIndicator={' *'}
            >
              What&apos;s your company&apos;s Linkedin handle
            </FormLabel>
            <Input
              {...register('companyLinkedIn', {
                setValueAs: (v) => v?.toLowerCase(),
                validate: async (value) => {
                  try {
                    await schemas.companyLinkedinHandleOrUrlSchema.validate(
                      value.trim()
                    );
                  } catch {
                    try {
                      await schemas.personLinkedinUrlSchema.validate(
                        value.trim()
                      );
                      return "Oops! That's a person LinkedIn; we need the company's.";
                    } catch {
                      return 'Please enter a valid Linkedin URL or handle!';
                    }
                  }
                },
              })}
              variant="truebaseFakeDisabled"
              placeholder="LinkedIn handle or URL"
              isDisabled={isLoading}
              h="48px"
            />
            <FormHelperText color="trueExplain">
              This lets us get to know your business better.
            </FormHelperText>
          </FormControl>
          <OnboardingButton isDisabled={isButtonDisabled} onClick={handleClick}>
            Next
          </OnboardingButton>
          <OnboardingBackButton
            isDisabled={isButtonDisabled}
            onClick={() => stepInfo.setStep((s) => s - 1)}
          />
        </motion.div>
      </LayoutGroup>
    );
  }
);
export default CompanyLinkedInStep;
