import {
  Box,
  Button,
  Flex,
  IconButton,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../../../common/Layout';
import { useAuthenticate } from '../../../common/requiresAuth';
import { useSelector } from 'react-redux';
import SequencesListWrapper from './SequencesListWrapper';
import { capitalize, defaultTo, isUndefined } from 'lodash';
import { chakraComponents, Select } from 'chakra-react-select';
import { useFilterContext } from '../../filters/components/useFilterContext';
import { FilterProvider } from '../../filters/components/FilterProvider';
import { ReactComponent as ContactsLinkIcon } from '~/v2/assets/icons/leads/leads-1.svg';
import { ReactComponent as TooltipArrow } from '~/v2/assets/tooltip arrow.svg';
import { webkitScrollbarConfig } from '../../../common/constants/webkitScrollbar';
import { AutopilotCardIcon } from '~/v2/assets/icons/AutopilotCardIcon';
import {
  useGetAutopilotContactsQuery,
  useGetAutopilotsQuery,
  useExportAutopilotContactsMutation,
} from '~/v2/redux/services/autopilots';
import { getAutopilotStats } from '~/v2/common/utils';
import { format } from 'date-fns';
import { ReactComponent as DownloadIcon } from '~/v2/assets/icons/download.svg';
import { ReactComponent as ClearBack } from '~/v2/assets/icons/clear/clear-back.svg';
import AnimatedNumber from '~/v2/common/AnimatedNumber';
import TagCapsule from './TagCapsule';
import TagFilter from './TagFilter';
import { getSelectedIcp } from '~/v2/redux/slices/icpSlice';
import { TruebaseTooltip } from '~/v2/common/TruebaseTooltip';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { getSelectedTeam } from '~/v2/redux/slices/teamsSlice';

const SequencesPageWithoutProviders = ({ selectedIcp = {} }) => {
  useAuthenticate();

  const navigate = useNavigate();
  const location = useLocation();

  const selectedTeam = useSelector(getSelectedTeam);

  const { handleAsyncError } = useErrorHandler();
  const [exportContacts, { isLoading: isExportContactsLoading }] =
    useExportAutopilotContactsMutation();

  const { data: autopilotsResponse, isFetching: isAutopilotsFetching } =
    useGetAutopilotsQuery(
      { teamId: selectedTeam?._id, page: 0 },
      { skip: isUndefined(selectedTeam?._id) }
    );

  const autopilots = autopilotsResponse?.result?.autopilots;
  const autopilotOptions = defaultTo(
    autopilots?.map((autopilot, index) => {
      const autopilotStats = getAutopilotStats(autopilot);
      return {
        _id: autopilot._id,
        value: `#${autopilotsResponse?.result?.totalCount - index}${capitalize(
          autopilotStats.finalStepName
        )}${autopilotStats.requestedOrImportedCount} leads${format(
          new Date(autopilotStats.createdAt),
          `MMM dd, yyyy, HH:mm`
        )}`,
        label: (
          <Flex gap="2px" justifyContent="space-between">
            <Text
              fontSize="12px"
              lineHeight="14px"
              fontWeight="500"
              color="trueSpace"
            >
              #{autopilotsResponse?.result?.totalCount - index}
            </Text>
            <Text fontSize="14px" lineHeight="16px" color="trueDim">
              •
            </Text>
            <Text
              fontSize="12px"
              lineHeight="14px"
              fontWeight="500"
              color="trueSpace"
            >
              {capitalize(autopilotStats?.finalStepName)}
            </Text>
            <Text fontSize="14px" lineHeight="16px" color="trueDim">
              •
            </Text>
            <Text
              fontSize="12px"
              lineHeight="14px"
              fontWeight="500"
              color="trueSpace"
            >
              {autopilotStats?.requestedOrImportedCount} leads
            </Text>
            <Text mx="6px" fontSize="14px" lineHeight="16px" color="trueDim">
              •
            </Text>
            {autopilotStats?.createdAt && (
              <Text fontSize="12px" lineHeight="14px" color="trueDim">
                {format(
                  new Date(autopilotStats.createdAt),
                  `MMM dd, yyyy, HH:mm`
                )}
              </Text>
            )}
            <Text ml="8px">{autopilotStats.label}</Text>
          </Flex>
        ),
      };
    }),
    []
  );
  autopilotOptions?.unshift({
    _id: undefined,
    value: 'All autopilots',
    label: <Text>All autopilots</Text>,
  });
  const autopilotIdString = new URLSearchParams(location.search).get(
    'autopilot'
  );
  const chosenAutopilot =
    autopilotOptions?.find(({ _id }) => _id === autopilotIdString) ||
    autopilotOptions?.[0];

  const [controlShouldRenderValue, setControlShouldRenderValue] =
    useState(true);
  const [chosenTags, setChosenTags] = useState([]);
  const [page, setPage] = useState(0);

  const {
    isLoading: isContactsLoading,
    data: contactsData,
    isFetching: isContactsFetching,
    isUninitialized: isContactsUninitialized,
  } = useGetAutopilotContactsQuery(
    {
      teamId: selectedTeam?._id,
      autopilotId: chosenAutopilot?._id,
      tags: chosenTags,
      page,
      limit: 10,
    },
    {
      skip: isUndefined(selectedTeam?._id) || isAutopilotsFetching,
      refetchOnMountOrArgChange: true,
    }
  );

  const { setCompanyFilters, setLeadFilters } = useFilterContext();

  useEffect(() => {
    if (selectedIcp) {
      setCompanyFilters(selectedIcp.companyFilterStatements || []);
      setLeadFilters(selectedIcp.personFilterStatements || []);
    }
  }, [selectedIcp, setCompanyFilters, setLeadFilters]);

  return (
    <Layout
      icon={
        <Box
          mr="16px"
          sx={{
            svg: { fill: 'trueSpace', width: '48px', height: '48px' },
          }}
        >
          <ContactsLinkIcon />
        </Box>
      }
      extraHeaderContent={
        <Flex alignItems="center" w="100%">
          <VStack
            spacing="2px"
            pr="16px"
            borderRight="1px solid"
            borderRightColor="trueLighter"
            alignItems="flex-start"
          >
            <Text
              fontSize="16px"
              lineHeight="20px"
              fontWeight="500"
              color="trueSpace"
            >
              <AnimatedNumber
                value={contactsData?.result?.totalCount || 0}
                springOptions={{ duration: 1500, bounce: 0 }}
                format
              />
            </Text>
            <Text fontSize="12px" lineHeight="14px" color="trueDim">
              Contacts
            </Text>
          </VStack>
          <Box ml="16px">
            <Select
              placeholder="Search for a specific autopilot"
              size="md"
              chakraStyles={{
                control: (baseStyle, state) => ({
                  ...baseStyle,
                  borderColor: state.isFocused ? 'trueLink' : 'trueLight',
                  boxShadow: state.isFocused
                    ? '0px 0px 10px rgba(2, 128, 245, 0.25) !important'
                    : 'none',
                  borderRadius: '4px',
                  width: '380px',
                  cursor: 'pointer',
                }),
                valueContainer: (baseStyle) => ({
                  ...baseStyle,
                  fontSize: '12px',
                  color: 'trueSpace',
                  lineHeight: '14px',
                  fontWeight: '500',
                }),
                singleValue: (baseStyle) => ({
                  ...baseStyle,
                  color: 'trueSpace',
                  fontSize: '12px',
                  lineHeight: '14px',
                  svg: {
                    display: 'none',
                  },
                }),
                dropdownIndicator: () => ({
                  padding: '8px 16px 8px 0px',
                  color: 'trueDim',
                }),
                menuList: (baseStyle) => ({
                  ...baseStyle,
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: '4px',
                  overflowY: 'overlay',
                  p: 0,
                  borderRadius: 0,
                  boxShadow: 'none',
                  maxH: '346px',
                  ...webkitScrollbarConfig(),
                }),
                menu: (baseStyle) => ({
                  ...baseStyle,
                  mt: 0,
                  overflow: 'hidden',
                }),
                option: (baseStyle, { isSelected }) => ({
                  ...baseStyle,
                  backgroundColor: 'white',
                  p: '0',
                  color: 'trueSpace',
                  fontSize: '12px',
                  lineHeight: '14px',
                  fontWeight: '500',
                  svg: {
                    color: 'trueDim',
                  },
                  '&:hover': {
                    backgroundColor: '#c3e6fa',
                    svg: {
                      color: 'trueLink',
                    },
                  },
                  ...(isSelected && {
                    color: 'trueSpace !important',
                  }),
                }),
                placeholder: (baseStyle) => ({
                  ...baseStyle,
                  color: 'trueMedium',
                  fontSize: '12px',
                  lineHeight: '14px',
                  fontWeight: '400',
                  pos: 'absolute',
                }),
              }}
              defaultValue={autopilotOptions?.[0]}
              value={chosenAutopilot}
              selectedOptionColorScheme="white"
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: (props) => (
                  <chakraComponents.DropdownIndicator {...props}>
                    <TooltipArrow />
                  </chakraComponents.DropdownIndicator>
                ),
                ValueContainer: (props) => (
                  <chakraComponents.ValueContainer {...props}>
                    <Flex alignItems="center">
                      {chosenAutopilot && controlShouldRenderValue && (
                        <Box mr={'-16px'}>
                          <AutopilotCardIcon width="20px" height="20px" />
                        </Box>
                      )}
                      {props.children}
                    </Flex>
                  </chakraComponents.ValueContainer>
                ),
                MenuList: (props) => (
                  <>
                    <chakraComponents.MenuList {...props}>
                      <Box>{props.children}</Box>
                    </chakraComponents.MenuList>
                  </>
                ),
              }}
              getOptionLabel={(option) => (
                <Flex alignItems="center" p="11px 16px" w="100%">
                  <Box mr="8px">
                    <AutopilotCardIcon width="20px" height="20px" />
                  </Box>
                  {option.label}
                </Flex>
              )}
              onChange={(s) =>
                navigate(s._id ? `/contacts?autopilot=${s._id}` : '/contacts')
              }
              options={autopilotOptions}
              controlShouldRenderValue={controlShouldRenderValue}
              onMenuOpen={() => setControlShouldRenderValue(false)}
              onMenuClose={() => setControlShouldRenderValue(true)}
              isDisabled={isContactsLoading || isContactsFetching}
              blurInputOnSelect
            />
          </Box>
          <Box ml="16px">
            <TagFilter
              chosenTags={chosenTags}
              setChosenTags={setChosenTags}
              contactsState={{
                isLoading: isContactsLoading,
                isFetching: isContactsFetching,
                uninitialized: isContactsUninitialized,
              }}
              chosenAutopilot={chosenAutopilot}
            />
          </Box>
          <Spacer />
          <TruebaseTooltip
            label={
              isExportContactsLoading
                ? 'Exporting...'
                : !chosenAutopilot?._id
                ? 'Please select an autopilot'
                : !isContactsFetching && !isAutopilotsFetching
                ? 'Download CSV'
                : false
            }
          >
            <IconButton
              variant="ghost"
              w="32px"
              h="32px"
              isDisabled={
                isContactsFetching ||
                isAutopilotsFetching ||
                !chosenAutopilot?._id
              }
              isLoading={isExportContactsLoading}
              onClick={handleAsyncError(async (e) => {
                e.stopPropagation();
                const res = await exportContacts({
                  teamId: selectedIcp?.teamId,
                  autopilotId: chosenAutopilot?._id,
                  tags: chosenTags,
                }).unwrap();
                window.open(res.result.url, '_blank');
              })}
              sx={{ svg: { fill: 'trueMedium' } }}
            >
              <DownloadIcon />
            </IconButton>
          </TruebaseTooltip>
        </Flex>
      }
    >
      <Box w="100%" h="100%" pb="56px">
        <Helmet title={'Truebase | Contacts'} defer={false} />
        <Flex gap="8px" m="16px 0">
          {chosenTags.length > 0 && (
            <>
              {chosenTags.map((tag) => (
                <TagCapsule
                  key={tag}
                  label={capitalize(tag)}
                  isDisabled={isContactsLoading || isContactsFetching}
                  onClick={() => {
                    if (!(isContactsLoading || isContactsFetching)) {
                      setChosenTags((s) => s.filter((t) => t !== tag));
                    }
                  }}
                />
              ))}
              <Button
                ml="auto"
                variant="truebaseText"
                sx={{
                  h: '24px',
                  p: '0',
                  color: 'trueDim',
                  fontSize: '12px',
                  lineHeight: '14px',
                  fontWeight: '500',
                }}
                rightIcon={<ClearBack />}
                isDisabled={isContactsLoading || isContactsFetching}
                onClick={() => setChosenTags([])}
              >
                Clear tags
              </Button>
            </>
          )}
        </Flex>
        <Flex
          maxW="1152px"
          margin="0 auto"
          flexDir="column"
          gap="8px"
          pb="56px"
        >
          <SequencesListWrapper
            chosenAutopilot={chosenAutopilot}
            chosenTags={chosenTags}
            contactsData={contactsData}
            contactsState={{
              isLoading: isContactsLoading,
              isFetching: isContactsFetching,
              uninitialized: isContactsUninitialized,
            }}
            setPage={setPage}
          />
        </Flex>
      </Box>
    </Layout>
  );
};

const SequencesPageWithProviders = () => {
  useAuthenticate();
  const selectedIcp = useSelector(getSelectedIcp);

  return (
    <FilterProvider
      initialCompanyFilters={selectedIcp?.companyFilterStatements || []}
      initialLeadFilters={selectedIcp?.personFilterStatements || []}
    >
      <SequencesPageWithoutProviders selectedIcp={selectedIcp} />
    </FilterProvider>
  );
};

export default SequencesPageWithProviders;
