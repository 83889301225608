import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import CloudsGray from '~/v2/assets/JsIcons/CloudsGray';
import CloudsWhite from '~/v2/assets/JsIcons/CloudsWhite';

export default function TruebaseCenteredClouds({
  variant = 'gray',
  text,
  children,
}) {
  return (
    <>
      <Box
        position="absolute"
        left="50%"
        top="50%"
        transform="translate(-50%, -50%)"
      >
        {(() => {
          switch (variant) {
            case 'gray':
              return <CloudsGray />;
            case 'white':
              return <CloudsWhite />;
            default:
              return <CloudsGray />;
          }
        })()}
      </Box>
      {text && (
        <Box
          position="absolute"
          left="50%"
          top="50%"
          transform="translate(-50%, -50%)"
          zIndex={2}
        >
          <Text fontSize="14px" fontWeight="500" color="trueSpace">
            {text}
          </Text>
          {children}
        </Box>
      )}
    </>
  );
}
