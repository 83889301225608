import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import socket from '../../utility/socket';
import {
  removeNotificationStatus,
  setNotificationStatus,
} from '../../redux/slices/statusSlice';
import { isArray } from 'lodash';
import { getSelectedIcp } from '~/v2/redux/slices/icpSlice';

//we send one main identifier and one additional account identifier (if main is lead identifier) or lead identifier (if main is account identifier)
//in order to show notifications on both the account card and the lead card
const genericInProgressHandler = ({
  dispatch,
  identifiers,
  type,
  loadingMessage,
}) => {
  for (const identifier of identifiers) {
    dispatch(
      setNotificationStatus({
        identifier,
        type,
        message: loadingMessage,
      })
    );
  }
};

const genericCompleteHandler = ({ dispatch, identifiers, type }) => {
  for (const identifier of identifiers) {
    dispatch(
      removeNotificationStatus({
        identifier,
        type,
      })
    );
  }
};

export default function Notifications() {
  const selectedIcp = useSelector(getSelectedIcp);
  const dispatch = useDispatch();
  useEffect(() => {
    //#region company insights
    socket.on('companyInsights-inProgress', ({ identifier, loadingMessage }) =>
      genericInProgressHandler({
        dispatch,
        identifiers: [identifier],
        loadingMessage,
        type: 'companyInsights',
      })
    );
    socket.on('companyInsights-completed', ({ identifier }) =>
      genericCompleteHandler({
        dispatch,
        identifiers: [identifier],
        type: 'companyInsights',
      })
    );
    socket.on('companyInsights-failed', ({ identifier }) =>
      genericCompleteHandler({
        dispatch,
        identifiers: [identifier],
        type: 'companyInsights',
      })
    );
    //#endregion company insights
    //#region account scoring
    socket.on('accountScoring-inProgress', ({ identifier, loadingMessage }) =>
      genericInProgressHandler({
        dispatch,
        identifiers: [identifier],
        type: 'accountScoring',
        loadingMessage,
      })
    );
    socket.on('accountScoring-completed', ({ identifier }) =>
      genericCompleteHandler({
        dispatch,
        identifiers: [identifier],
        type: 'accountScoring',
      })
    );
    socket.on('accountScoring-failed', ({ identifier }) =>
      genericCompleteHandler({
        dispatch,
        identifiers: [identifier],
        type: 'accountScoring',
      })
    );
    //#endregion account scoring
    //#region lead sequence
    socket.on('sequence-inProgress', ({ identifier, loadingMessage }) =>
      genericInProgressHandler({
        dispatch,
        identifiers: [identifier],
        loadingMessage,
        type: 'sequence',
      })
    );
    socket.on('sequence-completed', ({ identifier }) =>
      genericCompleteHandler({
        dispatch,
        identifiers: [identifier],
        type: 'sequence',
      })
    );
    socket.on('sequence-failed', ({ identifier }) =>
      genericCompleteHandler({
        dispatch,
        identifiers: [identifier],
        type: 'sequence',
      })
    );
    socket.on('personInsights-inProgress', ({ identifier, loadingMessage }) =>
      genericInProgressHandler({
        dispatch,
        identifiers: [identifier],
        loadingMessage,
        type: 'personInsights',
      })
    );
    socket.on('personInsights-completed', ({ identifier }) =>
      genericCompleteHandler({
        dispatch,
        identifiers: [identifier],
        type: 'personInsights',
      })
    );
    socket.on('personInsights-failed', ({ identifier }) =>
      genericCompleteHandler({
        dispatch,
        identifiers: [identifier],
        type: 'personInsights',
      })
    );
    //#endregion lead sequence
    //#region email
    socket.on('email-inProgress', (notificationData) => {
      if (isArray(notificationData)) {
        for (const { personId, message } of notificationData) {
          genericInProgressHandler({
            dispatch,
            identifiers: [personId],
            type: 'email',
            loadingMessage: message,
          });
        }
      } else {
        const { personId, message } = notificationData;
        genericInProgressHandler({
          dispatch,
          identifiers: [personId],
          type: 'email',
          loadingMessage: message,
        });
      }
    });

    socket.on('email-completed', ({ personId }) =>
      genericCompleteHandler({
        dispatch,
        identifiers: [personId],
        type: 'email',
      })
    );
    socket.on('email-failed', ({ personId }) =>
      genericCompleteHandler({
        dispatch,
        identifiers: [personId],
        type: 'email',
      })
    );
    //#endregion email
    return () => {
      //#region company insights
      socket.off('companyInsights-inProgress');
      socket.off('companyInsights-completed');
      socket.off('companyInsights-failed');
      //#endregion company insights
      //#region account scoring
      socket.off('accountScoring-inProgress');
      socket.off('accountScoring-completed');
      socket.off('accountScoring-failed');
      //#endregion account scoring
      //#region lead sequence
      socket.off('sequence-inProgress');
      socket.off('sequence-completed');
      socket.off('sequence-failed');
      socket.off('personInsights-inProgress');
      socket.off('personInsights-completed');
      socket.off('personInsights-failed');
      //#endregion lead sequence
      //#region email
      socket.off('email-inProgress');
      socket.off('email-completed');
      socket.off('email-failed');
      //#endregion email
    };
  }, [selectedIcp?._id, dispatch]);
  return null;
}
