import { Box, Flex } from '@chakra-ui/react';
import { TruebaseTooltip } from '../../TruebaseTooltip';
import SocialProfile from './SocialProfile';
import { tryGetRevenueRange, tryGetUrl } from '~/utils';
import { isEmpty, startCase } from 'lodash';
import InfoWithTooltip from './InfoWithTooltip';

const industriesMapper = {
  b2BSales: 'B2B Sales',
  saaS: 'SaaS',
  generativeAi: 'Generative AI',
  artificialIntelligenceAi: 'Artificial Intelligence AI',
  default: startCase,
};

export const CompanySocialInfo = ({
  account,
  socialProfiles = [],
  type = 'company',
  isSmall,
}) => {
  return (
    <Flex gap="8px" alignItems="center">
      <TruebaseTooltip
        hasArrow
        placement="top"
        label={type === 'company' ? account.name : ''}
        aria-label="Company name"
      >
        <Box
          color="trueSpace"
          lineHeight={isSmall ? '16px' : '19px'}
          fontSize={isSmall ? '14px' : '16px'}
          fontWeight="500"
        >
          {account.name}
        </Box>
      </TruebaseTooltip>
      {!isEmpty(account.domains) && (
        <SocialProfile
          id={account._id}
          service="website"
          url={tryGetUrl(account.domains[0])}
        />
      )}

      {socialProfiles.map(({ service = '', url = '' }) => {
        return (
          <SocialProfile
            id={account._id}
            key={account._id + service}
            service={service}
            url={url}
            type="company"
          />
        );
      })}
    </Flex>
  );
};

export const CompanyInfo = ({ account }) => {
  return (
    <Box
      display="flex"
      color="trueDim"
      fontSize="14px"
      lineHeight="16px"
      gap={1}
    >
      {account.employee?.range && (
        <InfoWithTooltip label={`Employees: ${account.employee.range}`}>
          {account.employee.range}
        </InfoWithTooltip>
      )}
      {account.revenue?.range && (
        <>
          <Box>•</Box>
          <InfoWithTooltip label={`Revenue: ${account.revenue.range}`}>
            ${account.revenue.range}
          </InfoWithTooltip>
        </>
      )}
      {account.funding?.amount && (
        <>
          <Box>•</Box>
          <InfoWithTooltip
            label={`Total funding: $${tryGetRevenueRange(
              account.funding.amount
            )}`}
          >
            ${tryGetRevenueRange(account.funding.amount)}
          </InfoWithTooltip>
        </>
      )}
      {!isEmpty(account.location) && (
        <>
          <Box>•</Box>
          <InfoWithTooltip label={`Headquarters: ${account.location}`}>
            {account.location}
          </InfoWithTooltip>
        </>
      )}

      {account?.industries && (
        <>
          <Box>•</Box>
          <InfoWithTooltip
            label={`${account.industries.map(
              (i) => ` ${industriesMapper?.[i] || industriesMapper.default(i)}`
            )}`}
          >
            {startCase(account.industries[0])}{' '}
            {!!(account.industries.length - 1) &&
              `(+${account.industries.length - 1})`}
          </InfoWithTooltip>
        </>
      )}
    </Box>
  );
};
