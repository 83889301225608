import smartlookClient from 'smartlook-client';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import useErrorHandler from './useErrorHandler';

const SMART_LOOK_ID = process.env.REACT_APP_SMART_LOOK_ID;

function useSmartlook(user) {
  const { handleSyncError } = useErrorHandler();
  useEffect(() => {
    const wrappedFn = handleSyncError(() => {
      if (!smartlookClient.initialized() && SMART_LOOK_ID) {
        smartlookClient.init(SMART_LOOK_ID, {
          advancedNetwork: {
            websockets: true,
          },
        });
      }
    });
    wrappedFn();
  }, [handleSyncError]);

  useEffect(() => {
    const wrappedFn = handleSyncError(() => {
      if (!isEmpty(user)) {
        const { id, email, firstName = '', lastName = '' } = user;
        const name = `${firstName} ${lastName}`;

        if (smartlookClient.initialized()) {
          smartlookClient.identify(id, {
            name,
            email,
          });
        }
      }
    });
    wrappedFn();
  }, [user, handleSyncError]);

  return smartlookClient;
}

export default useSmartlook;
