export const formatOptions = [
  { label: 'Email', value: 'email' },
  { label: 'Linkedin Connect', value: 'linkedinConnect' },
  { label: 'Linkedin InMail', value: 'linkedinInmail' },
  { label: 'SMS Text', value: 'smsText' },
  { label: 'Phone Script', value: 'phoneScript' },
];

export const daysOptions = Array.from(Array(30)).map((_, index) => {
  return {
    label: `Day ${index + 1}`,
    value: index + 1,
  };
});

export const voiceOptions = [
  { label: 'Professional', value: 'professional' },
  { label: 'Casual', value: 'casual' },
  { label: 'Authoritative', value: 'authoritative' },
  { label: 'Friendly', value: 'friendly' },
  { label: 'Conversational', value: 'conversational' },
  { label: 'Informative', value: 'informative' },
  { label: 'Playful', value: 'playful' },
  { label: 'Innovative', value: 'innovative' },
  { label: 'Sophisticated', value: 'sophisticated' },
  { label: 'Accessible', value: 'accessible' },
  { label: 'Humorous', value: 'humorous' },
  { label: 'Serious', value: 'serious' },
  { label: 'Educational', value: 'educational' },
  { label: 'Thoughtful', value: 'thoughtful' },
  { label: 'Inspiring', value: 'inspiring' },
  { label: 'Engaging', value: 'engaging' },
  { label: 'Lighthearted', value: 'lighthearted' },
  { label: 'Direct', value: 'direct' },
  { label: 'Empowering', value: 'empowering' },
  { label: 'Relatable', value: 'relatable' },
];

export const toneOptions = [
  { label: 'Polite', value: 'polite' },
  { label: 'Enthusiastic', value: 'enthusiastic' },
  { label: 'Formal', value: 'formal' },
  { label: 'Warm', value: 'warm' },
  { label: 'Empathetic', value: 'empathetic' },
  { label: 'Direct', value: 'direct' },
  { label: 'Persuasive', value: 'persuasive' },
  { label: 'Confident', value: 'confident' },
  { label: 'Respectful', value: 'respectful' },
  { label: 'Helpful', value: 'helpful' },
  { label: 'Urgent', value: 'urgent' },
  { label: 'Sincere', value: 'sincere' },
  { label: 'Appreciative', value: 'appreciative' },
  { label: 'Encouraging', value: 'encouraging' },
  { label: 'Informal', value: 'informal' },
  { label: 'Caring', value: 'caring' },
  { label: 'Diplomatic', value: 'diplomatic' },
  { label: 'Assertive', value: 'assertive' },
  { label: 'Reassuring', value: 'reassuring' },
  { label: 'Authoritative', value: 'authoritative' },
];
export const lengthOptions = [
  { label: 'Short', value: 'short' },
  { label: 'Medium', value: 'medium' },
  { label: 'Long', value: 'long' },
];

export const messageOutreachFramework = {
  problemAgitateSolve: {
    abbreviation: 'PAS',
    label: 'Problem - Agitate - Solve',
    description:
      'Identifies a problem, agitates it by highlighting potential fallout, and then offers a solution.',
  },
  attentionInterestDesireAction: {
    abbreviation: 'AIDA',
    label: 'Attention, Interest, Desire, Action',
    description:
      "Grabs reader's attention, generates interest, creates a desire for your product/service, and incites action.",
  },
  beforeAfterBridge: {
    abbreviation: 'BAB',
    label: 'Before - After - Bridge',
    description:
      "Paints a picture of the reader's world before your solution, after your solution, and how to get there.",
  },
  featuresAdvantagesBenefits: {
    abbreviation: 'FAB',
    label: 'Features - Advantages - Benefits',
    description:
      "Describes product features, explains advantages, and shows benefits related to the reader's situation.",
  },
  questionQualifyCallToAction: {
    abbreviation: 'QQC',
    label: 'Question - Qualify - Call to Action',
    description:
      'Opens with a question, qualifies the prospect, and ends with a call to action.',
  },
  starChainHook: {
    abbreviation: 'SCH',
    label: 'Star - Chain - Hook',
    description:
      "Starts with an attention-grabbing statement or 'star', links benefits in a 'chain', ends with a 'hook'.",
  },
  youUsBenefit: {
    abbreviation: 'YUB',
    label: 'You - Us - Benefit',
    description:
      "Starts by addressing the prospect's need, introduces your company, and shows the benefits.",
  },
  gainLogicFear: {
    abbreviation: 'GLF',
    label: 'Gain - Logic - Fear',
    description:
      "Highlights gains if the recipient acts, reasons to act now, and potential losses if they don't.",
  },
  challengerSales: {
    abbreviation: 'CS',
    label: 'Challenger Sales',
    description:
      'Teaches the recipient, tailors for their needs, and takes control of the conversation.',
  },
  elevatorPitch: {
    abbreviation: 'EP',
    label: 'Elevator Pitch',
    description:
      'Briefly states who you are, what you do, and the unique value you provide.',
  },
  provocationBasedSelling: {
    abbreviation: 'PBS',
    label: 'Provocation-Based Selling',
    description:
      'Provokes thought, outlines a new perspective, and provides a solution.',
  },
};
