export const createOrAddToTeam = async (
  { addToTeam, createTeam },
  inviteId,
  teamName = 'truebase',
  premiumEnrichment = false,
  companyId,
  companyInsights,
  intercomId,
  companyName
) => {
  if (inviteId) {
    const response = await addToTeam(inviteId).unwrap();
    if (response.isOk) {
      return response;
    } else {
      // error
    }
  } else {
    const response = await createTeam({
      name: teamName,
      premiumEnrichment,
      companyId,
      companyInsights,
      ...(intercomId && { intercomId }),
      companyName,
    }).unwrap();
    if (response.isOk) {
      return { teamId: response.teamId };
    } else {
      // error
    }
  }
};
