import { useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Text,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Flex,
  Spacer,
  Menu,
  MenuItem,
  MenuList,
  IconButton,
  MenuButton,
  Icon,
  Button,
  useDisclosure,
  ModalFooter,
  ModalBody,
  Box,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  Modal,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
} from '@chakra-ui/react';
import { format } from 'date-fns';

import OverviewPanel from './OverviewPanel';
import MembersPanel from './MembersPanel';
import ThreeDots from '~/v2/assets/ThreeDots';
import { ReactComponent as CloseIcon } from '~/v2/assets/icons/close/close-large.svg';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';
import { useUpdateTeamMutation } from '~/v2/redux/services/teams';
import { ReactComponent as RenameIcon } from '~/v2/assets/icons/edit/edit-2.svg';

export const TeamAccordion = ({ team = {}, ...rest }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [updateTeam, { isLoading: isUpdateTeamLoading }] =
    useUpdateTeamMutation();

  const { register, handleSubmit, reset, watch } = useForm({
    defaultValues: { name: get(team, 'name') },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const teamName = watch('name');

  const handleSaveTeamName = async ({ name }) => {
    if (name === get(team, 'name')) {
      onClose();
    } else {
      const res = await updateTeam({ teamId: team._id, name }).unwrap();
      toast({
        title: res?.isOk
          ? 'Team name changed successfully!'
          : 'Something went wrong!',
        status: res?.isOk ? 'success' : 'error',
        duration: 3000,
      });
      onClose();
    }
  };

  return (
    <Accordion {...rest}>
      <AccordionItem
        borderWidth="1px"
        borderColor="trueLight"
        borderRadius="4px"
        overflow="hidden"
        px="6"
        backgroundColor="white"
      >
        {({ isExpanded }) => (
          <>
            <AccordionButton
              background="white"
              cursor="default"
              _hover={{}}
              _focus={{}}
              _active={{}}
              variant="ghost"
              p="16px 0 0"
            >
              <Flex
                alignItems="start"
                w="100%"
                bg="white"
                borderColor="trueLight"
                gap="4px"
                direction="column"
              >
                <Text
                  fontSize="16px"
                  fontWeight="500"
                  lineHeight="18px"
                  color="trueSpace"
                  textAlign="left"
                >
                  {team.name}
                </Text>
                <Text
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="16px"
                  color="trueDim"
                >
                  Active since {format(new Date(team.createdAt), 'dd MMM y')}
                </Text>
              </Flex>
              <Spacer />
              <Menu variant="truebase">
                <MenuButton
                  onClick={(e) => e.stopPropagation()}
                  as={IconButton}
                  sx={{ svg: { fill: 'trueDim' } }}
                  variant="ghost"
                  icon={<Icon as={ThreeDots} />}
                  h="32px"
                  w="32px"
                  minW="unset"
                />
                <MenuList>
                  <MenuItem onClick={onOpen}>
                    <Icon as={RenameIcon} w="24px" h="24px" />
                    <Text>Rename</Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            </AccordionButton>
            <AccordionPanel py={4} px="0" bg="white">
              {isExpanded && (
                <>
                  <Tabs
                    position="relative"
                    variant="truebase"
                    index={tabIndex}
                    onChange={(i) => setTabIndex(i)}
                  >
                    <TabList>
                      <Tab>Overview</Tab>
                      <Tab>Members</Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <OverviewPanel team={team} />
                      </TabPanel>
                      <TabPanel>
                        <MembersPanel members={team.members} />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </>
              )}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>

      <Modal
        motionPreset="slideInRight"
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <form onSubmit={handleSubmit(handleSaveTeamName)}>
          <ModalContent maxW="600px">
            <ModalHeader
              borderBottomWidth="1px"
              borderBottomColor="trueLight"
              p="18px 24px"
              fontWeight="500"
              fontSize="16px"
              lineHeight="20px"
              display="flex"
            >
              <Flex alignItems="center" gap="12px">
                <Icon as={RenameIcon} w="24px" h="24px" />
                <Text fontSize="14px" fontWeight={500} color="trueSpace">
                  Rename Team
                </Text>
              </Flex>
              <Spacer />
              <Box>
                <IconButton
                  onClick={onClose}
                  variant="ghost"
                  sx={{
                    w: '32px',
                    h: '32px',
                    minW: 0,
                    svg: { fill: 'trueDim' },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </ModalHeader>

            <ModalBody
              p="24px"
              borderBottomWidth="1px"
              borderBottomColor="trueLight"
            >
              <InputGroup>
                <InputLeftElement
                  color="trueDim"
                  fontSize="12px"
                  fontWeight="500"
                  lineHeight="14px"
                  w="fit-content"
                  p="13px 16px"
                >
                  Team name *
                </InputLeftElement>
                <Input
                  type="tel"
                  pl="94px"
                  color="trueSpace"
                  fontSize="12px"
                  fontWeight="500"
                  lineHeight="14px"
                  {...register('name', { required: 'You must enter a name' })}
                />
              </InputGroup>
            </ModalBody>
            <ModalFooter p="16px 32px" gap="16px">
              <Spacer />
              <Button
                type="button"
                w="120px"
                h="40px"
                variant="truebaseOutline"
                onClick={() => {
                  reset();
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                h="40px"
                w="120px"
                variant="truebase"
                fontSize="13px"
                type="submit"
                isDisabled={!teamName}
                isLoading={isUpdateTeamLoading}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </Accordion>
  );
};
