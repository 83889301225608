import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Flex,
  IconButton,
  Spacer,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close/close-large.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import { useCallbackPrompt } from '../../../hooks/useCallbackPrompt';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

export default function NavigationBlocker({
  shouldBlock,
  type = 'train',
  additionalCancelNavigationAction,
  onConfirm,
}) {
  const [notShowNavigationConfirm, setNotShowNavigationConfirm] =
    useLocalStorage(`notShowNavigationConfirm-${type}`, false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    !notShowNavigationConfirm && shouldBlock,
    additionalCancelNavigationAction
  );
  const [checked, setChecked] = useState(false);
  const handleConfirmChange = (e) => {
    setChecked(e.target.checked);
  };
  const cancelRef = React.useRef();

  const typeMap = {
    train: {
      promptText: 'Do you want to navigate away without saving your changes?',
      showThisAgain: true,
      actions: (
        <Flex justifyContent="space-between" alignItems="center" w="100%">
          <Checkbox
            variant="truebase"
            checked={notShowNavigationConfirm}
            onChange={handleConfirmChange}
          >
            Don&apos;t show this again
          </Checkbox>
          <Button
            variant="truebaseTextHighlight"
            ref={cancelRef}
            w="54px"
            h="32px"
            onClick={() => {
              setNotShowNavigationConfirm(checked);
              confirmNavigation();
              onConfirm?.();
            }}
          >
            Yes
          </Button>
        </Flex>
      ),
    },
    icp: {
      promptText: 'Navigating away will discard unsaved changes.',
      showThisAgain: false,
      actions: (
        // a button to conttinu editing and a button to discard changes
        <Flex justifyContent="flex-end" alignItems="center" w="100%">
          <Button
            variant="truebaseText"
            ref={cancelRef}
            sx={{
              width: '77px',
              height: '32px',
              padding: '8px 16px 8px 16px',
              borderRadius: '4px',
              gap: '8px',
            }}
            marginInline={2}
            onClick={confirmNavigation}
          >
            Discard
          </Button>

          <Button
            variant="truebaseTextHighlight"
            ref={cancelRef}
            marginInline={2}
            onClick={cancelNavigation}
            sx={{
              width: '105px',
              height: '32px',
              padding: '8px 16px 8px 16px',
              borderRadius: '4px',
              gap: '8px',
            }}
          >
            Keep editing
          </Button>
        </Flex>
      ),
    },
  };

  return (
    <AlertDialog
      size="xl"
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={cancelNavigation}
      isOpen={showPrompt}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogBody p={4} paddingInlineStart={0}>
          <Flex alignItems="center" gap="16px">
            <Box sx={{ svg: { fill: 'trueWarning' } }}>
              <InfoIcon />
            </Box>
            <Text
              color="trueSpace"
              fontSize="14px"
              fontWeight={500}
              lineHeight="20px"
            >
              {typeMap[type].promptText}
            </Text>
            <Spacer />
            <IconButton
              h="32px"
              w="32px"
              onClick={cancelNavigation}
              variant="truebaseIcon"
            >
              <CloseIcon />
            </IconButton>
          </Flex>
        </AlertDialogBody>
        <AlertDialogFooter
          px="16px"
          py="8px"
          sx={{
            borderTopWidth: '1px',
            borderTopColor: 'trueLight',
          }}
        >
          {typeMap[type].actions}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
