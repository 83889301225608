import { useCallback } from 'react';
import logglyLog from '../common/Errors/Logger';

function useErrorHandler() {
  // Handles errors for asynchronous functions
  const handleAsyncError = useCallback((asyncFn) => {
    return async (...args) => {
      try {
        return await asyncFn(...args);
      } catch (error) {
        logglyLog({ error });
      }
    };
  }, []);

  // Handles errors for synchronous functions
  const handleSyncError = useCallback((syncFn) => {
    return (...args) => {
      try {
        return syncFn(...args);
      } catch (error) {
        logglyLog({ error });
      }
    };
  }, []);

  return { handleAsyncError, handleSyncError };
}

export default useErrorHandler;
