import { Select, chakraComponents } from 'chakra-react-select';
import { webkitScrollbarConfig } from '~/v2/common/constants/webkitScrollbar';
import { ReactComponent as TooltipArrow } from '~/v2/assets/tooltip arrow.svg';
import { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CloseButton,
  Flex,
  HStack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { getUser } from '~/v2/redux/slices/userSlice';
import { ReactComponent as CheckDone } from '~/v2/assets/check-done.svg';
import { useUpgradeLeadsCountMutation } from '~/v2/redux/services/user';

export const LeadCountSelector = ({ leadsCount, setLeadsCount }) => {
  const toast = useToast();

  const user = useSelector(getUser);

  const [sendEmail] = useUpgradeLeadsCountMutation();

  const userIntercomTags = get(user, 'tags', []);

  const [requestedValues, setRequestedValues] = useState([]);

  const onRequestUpgrade = useCallback(
    async ({ label }) => {
      const res = await sendEmail({ email: get(user, 'email'), label });

      if (get(res, 'data.isOk')) {
        setRequestedValues([...requestedValues, label]);
        toast({
          duration: 5000,
          position: 'bottom',
          render: ({ onClose }) => (
            <Box bg="trueAnason" p="16px 12px" borderRadius="4px">
              <HStack spacing="16px" alignItems="center">
                <CheckDone style={{ width: '16px', height: '16px' }} />
                <Text
                  sx={{
                    fontSize: '14px',
                    lineHeight: '24px',
                    fontWeight: '500',
                    color: 'trueSpace',
                  }}
                >
                  Request sent. We&apos;ll get back to you shortly
                </Text>
                <CloseButton size="sm" onClick={onClose} />
              </HStack>
            </Box>
          ),
        });
      }
    },
    [toast, user, sendEmail, requestedValues]
  );

  const getOption = useCallback(
    ({ label, userIntercomTags }) => {
      const isDisabled = !userIntercomTags.find(
        (tag) => get(tag, 'name', '') === `feature-autopilot-${label}`
      );

      return {
        label: (
          <Flex justifyContent="space-between" w="100%" alignItems="center">
            <Text opacity={isDisabled ? '0.4' : '1'}>{label}</Text>
            {isDisabled && (
              <Button
                variant="truebaseLink"
                color="trueLink"
                textDecor="underline"
                isDisabled={requestedValues.includes(label)}
                onClick={() => onRequestUpgrade({ label })}
              >
                Request Upgrade
              </Button>
            )}
          </Flex>
        ),
        value: label,
        isDisabled,
      };
    },
    [onRequestUpgrade, requestedValues]
  );

  const selectOptions = useMemo(
    () => [
      { label: '10', value: '10' },
      { label: '50', value: '50' },
      getOption({ label: '100', userIntercomTags }),
      getOption({ label: '200', userIntercomTags }),
      getOption({ label: '300', userIntercomTags }),
      getOption({ label: '500', userIntercomTags }),
    ],
    [getOption, userIntercomTags]
  );

  return (
    <Select
      value={leadsCount}
      options={selectOptions}
      onChange={(option) => {
        setLeadsCount(option);
      }}
      blurInputOnSelect
      chakraStyles={{
        control: (baseStyle) => ({
          ...baseStyle,
          border: 'none',
          borderRadius: '0',
          minHeight: '24px',
          maxHeight: '24px',
          width: '110px',
          cursor: 'pointer',
          bg: 'white',
        }),
        valueContainer: (baseStyle) => ({
          ...baseStyle,
          fontSize: '12px',
          color: 'trueSpace',
          lineHeight: '14px',
          fontWeight: '500',
          p: 0,
        }),
        dropdownIndicator: () => ({ color: 'trueDim' }),
        menuList: (baseStyle) => ({
          ...baseStyle,
          borderRadius: '4px',
          overflowY: 'overlay',
          p: 0,
          boxShadow: 'none',
          maxH: '200px',
          ...webkitScrollbarConfig(),
        }),
        menu: (baseStyle) => ({
          ...baseStyle,
          overflow: 'hidden',
          w: '260px',
          zIndex: '999',
        }),
        option: (baseStyle, { isSelected }) => ({
          ...baseStyle,
          h: '40px',
          backgroundColor: 'white',
          p: '8px 16px',
          color: 'trueSpace',
          fontSize: '12px',
          lineHeight: '14px',
          fontWeight: '500',
          svg: { color: 'trueDim' },
          '&:hover': { backgroundColor: '#c3e6fa', svg: { color: 'trueLink' } },
          ...(isSelected && { color: 'trueSpace !important' }),
          opacity: '1 !important',
        }),
      }}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: (props) => (
          <chakraComponents.DropdownIndicator {...props}>
            <TooltipArrow />
          </chakraComponents.DropdownIndicator>
        ),
      }}
    />
  );
};
