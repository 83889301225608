import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const truebase = definePartsStyle({
  footer: {
    borderTopWidth: '1px',
    borderTopColor: 'trueLighter',
  },
  header: {
    borderBottomWidth: '1px',
    borderBottomColor: 'trueLighter',
    color: 'trueSpace',
    fontSize: '14px',
    lineHeight: '16px',
    py: '20px',
    px: '16px',
  },
  closeButton: {
    top: '20px',
    right: '16px',
    svg: {
      height: '9px',
      width: '9px',
    },
  },
  body: {
    py: '32px',
    px: '16px',
  },
});

export const modalTheme = defineMultiStyleConfig({
  variants: { truebase },
});
