import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import useErrorHandler from './useErrorHandler';
import { useGetAccountsLeadsCountMutation } from '../redux/services/icp';

const defaultCounts = {
  accounts: 0,
  leads: 0,
};

export const useFilterResultsCount = ({
  companyFilters,
  leadFilters,
  selectedIcp,
}) => {
  const { handleAsyncError } = useErrorHandler();
  const [filterResultsCount, setFilterResultsCount] = useState(defaultCounts);
  const [getAccountsLeadsCount, data] = useGetAccountsLeadsCountMutation();

  useEffect(() => {
    const getFilterResultsCount = handleAsyncError(async () => {
      try {
        const { data } = await getAccountsLeadsCount({
          companyFilterStatements: companyFilters.length
            ? companyFilters
            : undefined,
          personFilterStatements: leadFilters.length ? leadFilters : undefined,
          teamId: selectedIcp.teamId,
        });
        setFilterResultsCount({
          accounts: data.accountsCount,
          leads: data.leadsCount,
        });
      } catch {
        setFilterResultsCount(defaultCounts);
      }
    });

    const debouncedGetFilterResultsCount = debounce(
      getFilterResultsCount,
      1000
    );

    if (leadFilters.length) {
      debouncedGetFilterResultsCount();
    } else {
      setFilterResultsCount(defaultCounts);
    }

    return () => {
      debouncedGetFilterResultsCount.cancel();
    };
  }, [
    handleAsyncError,
    leadFilters,
    companyFilters,
    selectedIcp,
    getAccountsLeadsCount,
  ]);
  return { filterResultsCount, requestData: data };
};
