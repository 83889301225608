import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const truebaseOutline = defineStyle({
  bgColor: 'white',
  borderColor: 'trueBlue',
  borderWidth: '1px',
  borderRadius: '4px',
  color: 'trueBlue',
  py: '20px',
  px: '46px',
  svg: {
    fill: 'trueBlue',
  },
  _hover: {
    bg: 'trueLighter',
  },
  _active: {
    boxShadow: 'inset 2px 2px 4px rgba(0, 0, 0, 0.1)',
  },
  _disabled: {
    borderColor: 'trueDisabled',
    color: 'trueDisabled',
  },
});

export const iconButtonTheme = defineStyleConfig({
  variants: { truebaseOutline },
});
