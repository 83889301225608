import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Flex, Switch, Text } from '@chakra-ui/react';
import { AutopilotStepCard } from '../AutopilotStepCard';
import { ReactComponent as FilterIcon } from '~/v2/assets/icons/filter/filter-list-on.svg';
import { ReactComponent as AutopilotSelectLogo } from '~/v2/assets/icons/autopilot-select.svg';
import { useAddIcpMutation } from '~/v2/redux/services/icp';
import { get } from 'lodash';
import { getSelectedIcp } from '~/v2/redux/slices/icpSlice';

export const AutopilotFilterStep = ({
  setSelectedIcpSetting,
  setSelectedIcp,
  selectedIcpSetting,
  navigationState,
  isFilterStepEnabled,
  setIsFilterStepEnabled,
  templatesResponse,
  selectedMode,
  icps,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedIcp = useSelector(getSelectedIcp);

  const [addIcp, { isLoading: isCreateLoading }] = useAddIcpMutation();

  const isRequired = get(
    get(templatesResponse, 'result.templates', []).find(
      ({ name }) => name === selectedMode
    ),
    'steps',
    []
  ).find(({ name }) => name === 'filter').isRequired;

  return (
    <AutopilotStepCard
      title={
        <Flex alignItems="center" gap="8px">
          <Switch
            variant="truebaseAutopilot"
            isChecked={isFilterStepEnabled}
            onChange={() => {
              setIsFilterStepEnabled(!isFilterStepEnabled);
            }}
            isDisabled={isRequired}
          />
          <Text fontWeight="500" fontSize="14px" lineHeight="16px">
            Filter
          </Text>
        </Flex>
      }
      subtitle="Select a saved ICP to filter your list by"
      icon={FilterIcon}
      defaultValue={{ value: selectedIcp?._id, label: selectedIcp?.name }}
      onChange={(value) => {
        setSelectedIcpSetting(icps.find((icp) => icp._id === value));
      }}
      options={icps?.map((icp) => ({
        value: icp._id,
        label: `${icp.name}`,
      }))}
      onEditClick={() => {
        dispatch(
          setSelectedIcp(icps.find(({ _id }) => selectedIcpSetting._id === _id))
        );
        navigate('/settings/icp', { state: navigationState });
      }}
      selectLogo={AutopilotSelectLogo}
      isEnabled={isRequired || isFilterStepEnabled}
      createFn={addIcp}
      isCreateLoading={isCreateLoading}
      onCreate={({ icp }) => {
        dispatch(
          setSelectedIcp({
            ...icp,
            personFilterStatements: [],
            companyFilterStatements: [],
          })
        );
        navigate('/settings/icp', {
          state: { ...navigationState, isPending: true },
        });
      }}
      name="ICP"
      data={icps}
    />
  );
};
