import { useEffect, useRef, useState, useCallback } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';

import useDebounce from '../hooks/useDebounce';
import { usePrevious } from '../hooks/usePrevious';
import useErrorHandler from './useErrorHandler';
const LIMIT = 25;

// const addCategoriesToResults = (results) => {
//   let prevCategory = '';
//   const data = results.reduce((acc, cv) => {
//     if (cv.path === 'category') {
//       prevCategory = cv.publicName;
//       return [...acc, cv];
//     }
//     if (cv.path !== prevCategory) {
//       prevCategory = cv.path;
//       return [
//         ...acc,
//         {
//           publicName: prevCategory,
//           path: 'category',
//           count: 0,
//           _id: Math.random(),
//         },
//         cv,
//       ];
//     } else {
//       return [...acc, cv];
//     }
//   }, []);
//   return data;
// };

export function useVirtualScrollWithSearchTerm(
  useLazyGetQuery,
  useLazyGetStatsQuery
) {
  const mainInputRef = useRef();
  const [getFilters, filterResponse] = useLazyGetQuery();
  const [getStats, statsResponse] = useLazyGetStatsQuery();
  const { handleSyncError } = useErrorHandler();
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const lastSearchTerm = useRef('');
  const debouncedSearchTerm = useDebounce(search, 500);
  const parentRef = useRef();
  const [selectedStatId, setSelectedStatId] = useState('all');
  const prevSelectedStatId = usePrevious(selectedStatId);
  const previousPage = useRef(-1);

  const hasNextPage = filterResponse.data?.isOk
    ? filterResponse.data?.result?.filters?.length !== 0 &&
      LIMIT <= data?.length
    : false;

  const rowVirtualizer = useVirtualizer({
    count: data?.length + 1,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 60,
    overscan: 1,
  });

  const fetchData = useCallback(
    async (searchText, limit, pageNumber, path, reset = false) => {
      previousPage.current = pageNumber;

      const response = await getFilters({
        searchText,
        limit,
        pageNumber,
        path,
      }).unwrap();
      if (response?.result) {
        setPageNumber(response.pageNumber);
        if (reset) {
          setData(response?.result?.filters);
          if (
            response?.result?.filters &&
            response?.result?.filters?.length !== 0
          ) {
            rowVirtualizer?.scrollToIndex(0);
          }
        } else {
          if (response?.result?.filters?.length !== 0)
            setData((d) => [...d, ...response.result.filters]);
        }
      }
    },
    [getFilters, rowVirtualizer]
  );

  useEffect(() => {
    const wrappedFn = handleSyncError(() => {
      fetchData('', 25, 0, undefined, true);
      getStats();
    });
    wrappedFn();
  }, [fetchData, getStats, handleSyncError]);

  useEffect(() => {
    const wrappedFn = handleSyncError(() => {
      let searchText = '';
      if (debouncedSearchTerm.replace(/['"]/g, '').trim().length >= 2) {
        searchText = debouncedSearchTerm;
      }

      if (searchText?.length > 100) {
        return;
      }

      if (prevSelectedStatId !== selectedStatId) {
        fetchData(searchText, 25, 0, selectedStatId, true);
      }

      if (searchText !== lastSearchTerm.current && !filterResponse.isFetching) {
        fetchData(searchText, 25, 0, selectedStatId, true);
        getStats({ searchText });
        lastSearchTerm.current = searchText;
      }
    });
    wrappedFn();
  }, [
    handleSyncError,
    debouncedSearchTerm,
    filterResponse.isFetching,
    selectedStatId,
    prevSelectedStatId,
    fetchData,
    getStats,
  ]);

  const virtualItems = rowVirtualizer.getVirtualItems();

  useEffect(() => {
    const wrappedFn = handleSyncError(() => {
      const [lastItem] = [...virtualItems].reverse();
      let searchText = '';
      if (debouncedSearchTerm.replace(/['"]/g, '').trim().length >= 2) {
        searchText = debouncedSearchTerm;
      }

      if (searchText?.length > 100) {
        return;
      }

      if (!lastItem) {
        return;
      }

      if (
        lastItem.index >= data.length - 1 &&
        !filterResponse.isFetching &&
        data.length !== 0 &&
        hasNextPage &&
        previousPage.current !== pageNumber + 1
      ) {
        fetchData(searchText, LIMIT, pageNumber + 1, selectedStatId);
      }
    });
    wrappedFn();
  }, [
    handleSyncError,
    fetchData,
    pageNumber,
    previousPage,
    selectedStatId,
    data?.length,
    filterResponse.isFetching,
    virtualItems,
    hasNextPage,
    debouncedSearchTerm,
  ]);

  return {
    mainInputRef,
    parentRef,
    rowVirtualizer,
    virtualItems,
    data,
    search,
    setSearch,
    selectedStatId,
    setSelectedStatId,
    filterResponse,
    statsResponse,
    hasNextPage,
  };
}
