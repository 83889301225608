import { sliderAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const truebase = definePartsStyle({
  track: { bg: 'trueLight' },
  filledTrack: { bg: 'trueBlue' },
  thumb: {
    bg: 'trueBlue',
    w: '24px',
    h: '24px',
    filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.15))',
  },
});

export const sliderTheme = defineMultiStyleConfig({
  variants: {
    truebase,
  },
});
