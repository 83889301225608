import React from 'react';

const ContactsLinkIcon = ({ width = 24, height = 24, ...rest }) => {
  return (
    <svg
      {...rest}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_320_8685)">
        <path
          d="M16 11C16.5933 11 17.1734 10.8241 17.6667 10.4944C18.1601 10.1648 18.5446 9.69623 18.7716 9.14805C18.9987 8.59987 19.0581 7.99667 18.9424 7.41473C18.8266 6.83279 18.5409 6.29824 18.1213 5.87868C17.7018 5.45912 17.1672 5.1734 16.5853 5.05765C16.0033 4.94189 15.4001 5.0013 14.8519 5.22836C14.3038 5.45543 13.8352 5.83994 13.5056 6.33329C13.1759 6.82664 13 7.40666 13 8C12.9983 8.39445 13.0747 8.78533 13.2249 9.15008C13.375 9.51484 13.5959 9.84624 13.8748 10.1252C14.1538 10.4041 14.4852 10.625 14.8499 10.7751C15.2147 10.9253 15.6056 11.0017 16 11ZM8 11C8.59334 11 9.17336 10.8241 9.66671 10.4944C10.1601 10.1648 10.5446 9.69623 10.7716 9.14805C10.9987 8.59987 11.0581 7.99667 10.9424 7.41473C10.8266 6.83279 10.5409 6.29824 10.1213 5.87868C9.70176 5.45912 9.16721 5.1734 8.58527 5.05765C8.00333 4.94189 7.40013 5.0013 6.85195 5.22836C6.30377 5.45543 5.83524 5.83994 5.50559 6.33329C5.17595 6.82664 5 7.40666 5 8C4.99828 8.39445 5.0747 8.78533 5.22486 9.15008C5.37501 9.51484 5.59592 9.84624 5.87484 10.1252C6.15376 10.4041 6.48516 10.625 6.84992 10.7751C7.21467 10.9253 7.60555 11.0017 8 11ZM8 13C5.67 13 1 14.17 1 16.5V19H15V16.5C15 14.17 10.33 13 8 13ZM16 13C15.71 13 15.38 13.02 15.03 13.05C15.6164 13.4191 16.1028 13.927 16.4464 14.5287C16.79 15.1304 16.9801 15.8074 17 16.5V19H23V16.5C23 14.17 18.33 13 16 13Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_320_8685">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ContactsLinkIcon;
