import { cssVar, extendTheme } from '@chakra-ui/react';
import { buttonTheme } from './v2/common/TruebaseButton';
import { formLabelTheme } from './v2/common/TruebaseFormLabel';
import { formErrorTheme } from './v2/common/TruebaseFormError';
import { iconButtonTheme } from './v2/common/TruebaseIconButton';
import { inputTheme } from './v2/common/TruebaseInput';
import { modalTheme } from './v2/common/TruebaseModal';
import { TruebaseTag } from './v2/common/TruebaseTag';
import { formTheme } from './v2/common/TruebaseForm';
import { menuTheme } from './v2/common/TruebaseContextMenu';
import { checkboxTheme } from './v2/common/TruebaseCheckbox';
import { containerTheme } from './v2/common/Truebase/TruebaseContainer';
import { textTheme } from './v2/common/Truebase/TruebaseText';
import { tabsTheme } from './v2/common/Truebase/TruebaseTabs';
import { textareaTheme } from './v2/common/TruebaseTextArea';
import { switchTheme } from './v2/common/TruebaseSwitch';
import { stepperTheme } from './v2/common/TruebaseStepper';
import { sliderTheme } from './v2/common/TruebaseSlider';
import { progressTheme } from './v2/common/TruebaseProgressBar';
const $arrowBg = cssVar('popper-arrow-bg');

export const theme = extendTheme({
  colors: {
    // text & interface colors
    trueDisabled: '#DADADA',
    trueMedium: '#5E6B8C',
    trueLight: '#E1E6F3',
    trueSpace: '#0F0F4D',
    trueGray: '#424448',
    trueGrayLabel: '#424468',
    trueDim: '#7E8BAD',
    trueInfo: '#AFB2BB',
    trueLighter: '#EEF1F9',
    truePreselect: '#EBF5FA',
    trueBg: '#F5F9FA',
    trueBg02: '#F8FAFC',
    // accent colors
    trueBlue: '#3F21FF',
    trueBlue04: '#3f21ff66',
    trueExplain: '#3975AC',
    truePurple: '#8a3dff',
    trueLink: '#0280F5',
    trueAttention: '#FF0370',
    trueGrass: '#0FD9B7',
    trueSky: '#91D7FF',
    trueSkylight: '#C3E6FA',
    trueReef: '#4CEFF9',
    trueWarning: '#F7B84B',
    trueCorral: '#D4A6BA',
    trueAnason: '#B9E1DA',
    trueHighlight01: '#A3E4D7',
    trueAutomations: 'rgba(255,255,255,0.5)',
    trueHover: 'linear-gradient(90deg, #3F21FF 0%, #8A3DFF 100%)',
    trueProgress: 'linear-gradient(90deg, #3F21FF 0%, #8A3DFF 171.57%)',
  },
  breakPoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1152px',
    '2xl': '1440px',
  },
  fonts: {
    heading: `Roboto, sans-serif`,
    body: `Roboto, sans-serif`,
  },
  components: {
    Modal: modalTheme,
    // Alert: {
    //   variants: {
    //     solid: (props) => {
    //       // only applies to `solid` variant
    //       const { colorScheme: c } = props;
    //       if (c !== 'blue') {
    //         // use original definition for all color schemes except "blue"
    //         return theme.components.Alert.variants.solid(props);
    //       }
    //       return {
    //         title: {
    //           paddingTop: '6px',
    //         },
    //         container: {
    //           p: '16px',
    //           h: '56px',
    //           w: '440px',
    //           boxShadow: '10px 20px 40px rgb(126 139 173 / 20%)',
    //           background: 'white',
    //           fontSize: '14px',
    //           lineHeight: '16px',
    //           color: 'trueSpace',
    //           alignItems: 'center',
    //           button: {
    //             w: '24px',
    //             h: '24px',
    //             top: '16px',
    //             right: '16px',
    //           },
    //         },
    //         icon: {
    //           h: '24px',
    //           w: '24px',
    //         },
    //       };
    //     },
    //   },
    // },
    TruebaseBadge: {
      baseStyle: {
        color: 'trueMedium',
        display: 'flex',
        fontSize: '11px',
        lineHeight: '13px',
        borderRadius: '10px',
        padding: '2px 3.5px 1px 3.5px',
        justifyContent: 'center',
      },
      variants: {
        green: {
          bg: 'trueAnason',
        },
        red: {
          bg: 'trueCorral',
        },
      },
      defaultProps: {
        variant: 'red',
      },
    },
    TruebaseTooltip: {
      baseStyle: {
        bg: 'trueSpace',
        borderRadius: '4px',
        color: 'white',
        fontSize: '13px',
        lineHeight: '15px',
        py: '4px',
        px: '16px',
        // https://github.com/chakra-ui/chakra-ui/issues/4695
        [$arrowBg.variable]: 'colors.trueSpace',
        arrowSize: '12px',
      },
    },
    Tag: TruebaseTag,
    Button: buttonTheme,
    IconButton: iconButtonTheme,
    Input: inputTheme,
    NumberInput: inputTheme,
    FormError: formErrorTheme,
    FormLabel: formLabelTheme,
    Form: formTheme,
    Menu: menuTheme,
    Checkbox: checkboxTheme,
    Container: containerTheme,
    Text: textTheme,
    Tabs: tabsTheme,
    Textarea: textareaTheme,
    Switch: switchTheme,
    Stepper: stepperTheme,
    Slider: sliderTheme,
    Progress: progressTheme,
  },
});
