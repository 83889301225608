import React from 'react';
import PropTypes from 'prop-types';

const CloudsGray = ({ width = 240, height = 67 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 240 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.9951 61.015L95.7751 48.935L96.1351 34.845L93.7651 28.135L67.2551 25.105L48.1251 38.535L53.7451 56.285L62.5551 59.675L78.9951 61.015Z"
        fill="white"
      />
      <path
        d="M93.0951 65.375L101.815 50.945L96.1351 34.845L95.7751 48.935L78.9951 61.015L62.5551 59.675L53.7451 56.285L56.5151 65.045L93.0951 65.375Z"
        fill="#EEF1F9"
      />
      <path
        d="M55.1751 61.2651L63.6751 58.3751L68.2652 53.2151L57.8652 37.7851L34.0651 37.2051L20.2751 50.1951L25.9251 57.4651L33.0251 60.9351L55.1751 61.2651Z"
        fill="white"
      />
      <path
        d="M57.5251 65.2948L63.675 58.3748L55.175 61.2648L33.0251 60.9348L25.925 57.4648L32.025 65.2948H57.5251Z"
        fill="#EEF1F9"
      />
      <path
        d="M107.535 62.6551L113.515 61.0251L114.915 57.2851L110.215 48.2251L96.7952 49.9051L88.4052 57.9551L90.1852 60.2351L96.7952 61.9851L107.535 62.6551Z"
        fill="white"
      />
      <path
        d="M111.895 65.3349L113.515 61.0249L107.535 62.6549L96.7951 61.9849L90.1851 60.2349L94.4451 65.6749L111.895 65.3349Z"
        fill="#EEF1F9"
      />
      <path
        d="M135.005 64.625C135.005 65.485 104.845 66.175 67.6251 66.175C30.4051 66.175 0.245117 65.485 0.245117 64.625C0.245117 63.765 30.4151 63.085 67.6251 63.085C104.835 63.085 135.005 63.775 135.005 64.625Z"
        fill="#EEF1F9"
      />
      <path
        d="M218.075 21.3948L222.235 20.2648L223.215 17.6548L219.575 12.4548L210.595 12.5148L204.755 18.1248L205.995 19.7148L210.595 20.9248L218.075 21.3948Z"
        fill="white"
      />
      <path
        d="M221.105 23.2648L222.235 20.2648L218.075 21.3948L210.595 20.9248L205.995 19.7148L208.965 23.4948L221.105 23.2648Z"
        fill="#EEF1F9"
      />
      <path
        d="M194.835 21.685L184.755 14.425L184.535 5.95499L185.965 1.91499L201.895 0.10498L213.395 8.17498L210.025 18.845L204.725 20.875L194.835 21.685Z"
        fill="white"
      />
      <path
        d="M186.365 24.3051L181.125 15.6351L184.535 5.95508L184.755 14.4251L194.835 21.6851L204.725 20.8751L210.025 18.8451L208.355 24.1051L186.365 24.3051Z"
        fill="#EEF1F9"
      />
      <path
        d="M177.685 22.6749L174.085 21.6949L173.245 19.4449L176.065 13.9949L184.135 15.0049L189.185 19.8449L188.115 21.2149L184.135 22.2649L177.685 22.6749Z"
        fill="white"
      />
      <path
        d="M175.065 24.2848L174.085 21.6948L177.685 22.6748L184.135 22.2648L188.115 21.2148L185.545 24.4848L175.065 24.2848Z"
        fill="#EEF1F9"
      />
      <path
        d="M158.245 23.8651C158.245 24.3751 176.385 24.7851 198.755 24.7851C221.125 24.7851 239.265 24.3751 239.265 23.8651C239.265 23.3551 221.135 22.9351 198.755 22.9351C176.375 22.9351 158.245 23.3451 158.245 23.8651Z"
        fill="#EEF1F9"
      />
    </svg>
  );
};

CloudsGray.propTypes = {
  width: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
};

export default CloudsGray;
