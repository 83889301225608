import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const filterVariant = definePartsStyle({
  container: {
    borderColor: 'trueLight',
    borderWidth: '1px',
    bg: 'white',
    fontSize: '12px',
    lineHeight: '14px',
    borderRadius: '24px',
    px: '4px',
  },
  label: {
    color: 'trueMedium',
    lineHeight: '14px',
    fontSize: '12px',
    fontWeight: '500',
  },
});

const icpVariant = definePartsStyle({
  container: {
    borderRadius: '24px',
    color: 'trueDim',
    backgroundColor: 'white',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 500,
    py: '4px',
    px: '16px',
    borderWidth: '1px',
    borderColor: 'trueLight',
  },
});

const icpNotClickableVariant = definePartsStyle({
  container: {
    borderRadius: '1px 1px 8px 1px',
    bg: 'trueLighter',
    color: 'trueDim',
    fontSize: '11px',
    lineHeight: '12px',
    py: 0,
    px: 0,
    minH: '16px',
    minW: '140px',
    justifyContent: 'center',
    textAlign: 'left',
    alignItems: 'center',
  },
});

export const TruebaseTag = defineMultiStyleConfig({
  variants: {
    filter: filterVariant,
    icp: icpVariant,
    icpNotClickable: icpNotClickableVariant,
  },
});
