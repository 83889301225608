import { Box, Skeleton, Spacer } from '@chakra-ui/react';
import { get, isUndefined } from 'lodash';
import { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { useAuthenticate } from '~/v2/common/requiresAuth';
import { getSelectedTeam } from '~/v2/redux/slices/teamsSlice';
import { useFilterContext } from '~/v2/features/filters/components/useFilterContext';
import { defaultQualificationQuestions, utils } from '~/v2/common/utils';
import Layout from '~/v2/common/Layout';
import NavigationBlocker from '~/v2/features/filters/components/NavigationBlocker';
import { FilterProvider } from '~/v2/features/filters/components/FilterProvider';
import { QualificationProvider } from '~/v2/features/filters/components/QualificationProvider';
import { TrainQualifications } from './components/TrainQualifications';
import ICPSelect from '~/v2/common/ICPSelect';
import { SelectAlertDialog } from '../SelectAlertDialog';
import {
  useAddAccountQualificationMutation,
  useDeleteAccountQualificationMutation,
  useDuplicateAccountQualificationMutation,
  useEditAccountQualificationMutation,
  useGetAccountQualificationsQuery,
} from '~/v2/redux/services/accountQualification';
import {
  getSelectedAccountQualification,
  setSelectedAccountQualification,
} from '~/v2/redux/slices/accountQualificationSlice';
import { getSelectedIcp } from '~/v2/redux/slices/icpSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useGetIcpsQuery } from '~/v2/redux/services/icp';
import { useIsDirtyContext } from '~/v2/contexts/useIsDirtyContext';
import { IsDirtyProvider } from '~/v2/contexts/providers/IsDirtyProvider';
import { getUser } from '~/v2/redux/slices/userSlice';

function AccountQualification() {
  useAuthenticate();
  const { handleSyncError } = useErrorHandler();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const selectRef = useRef();

  const team = useSelector(getSelectedTeam);
  const user = useSelector(getUser) || {};
  const { data: accountQualifications } = useGetAccountQualificationsQuery(
    { teamId: get(team, '_id') },
    { skip: isUndefined(get(team, '_id')), refetchOnMountOrArgChange: true }
  );
  const { data: icps } = useGetIcpsQuery(
    { teamId: team?._id },
    { skip: isUndefined(team?._id), refetchOnMountOrArgChange: true }
  );
  const selectedAccountQualification = useSelector(
    getSelectedAccountQualification
  );
  const [addAccountQualification, { isLoading: isSavingAccountQualification }] =
    useAddAccountQualificationMutation();
  const [editAccountQualification, { isLoading: isEditLoading }] =
    useEditAccountQualificationMutation();
  const [
    duplicateAccountQualification,
    { isLoading: isDuplicatingAccountQualification },
  ] = useDuplicateAccountQualificationMutation();
  const [deleteAccountQualification] = useDeleteAccountQualificationMutation();

  const selectedIcp = useSelector(getSelectedIcp);
  const { isQualificationDirty, setIsQualificationDirty } = useIsDirtyContext();
  const { setTrainView, setCompanyFilters, setLeadFilters } =
    useFilterContext();

  const [showPrompt, setShowPrompt] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isPending, setIsPending] = useState(
    get(location, 'state.isPending', false)
  );

  const ref = useRef(null);

  const selectOptions = useMemo(() => {
    return accountQualifications?.map((accountQualification) => ({
      value: accountQualification._id,
      label: accountQualification.name,
    }));
  }, [accountQualifications]);

  const selectValue = useMemo(() => {
    if (!selectedAccountQualification) return null;

    return {
      value: selectedAccountQualification._id,
      label: selectedAccountQualification.name,
    };
  }, [selectedAccountQualification]);

  const handleChange = async (option) => {
    if (isQualificationDirty) {
      setShowPrompt(true);
      setSelectedOption(option.value);
      return;
    }
    await dispatch(
      setSelectedAccountQualification(
        accountQualifications.find(({ _id }) => option.value === _id)
      )
    );
    ref.current.handleResetQualifications();
    setIsPending(false);
    if (location?.state?.isPending) {
      delete location.state.isPending;
    }
  };

  const alertUser = handleSyncError((event) => {
    if (isQualificationDirty) {
      event.preventDefault();
      event.returnValue = '';
    }
  });

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  });

  useEffect(() => {
    setTrainView('qualification');
  }, [setTrainView]);

  useEffect(() => {
    setCompanyFilters(selectedIcp?.companyFilterStatements || []);
    setLeadFilters(selectedIcp?.personFilterStatements || []);
  }, [
    selectedIcp?.companyFilterStatements,
    selectedIcp?.personFilterStatements,
    setCompanyFilters,
    setLeadFilters,
  ]);

  return (
    <Layout
      title="Account Qualification"
      subtitle="To qualify prospects, define questions you’d scan the account’s websites for"
      withBackBtn={location.state?.fromLauchAutopilotPage}
      onBackClick={() => {
        navigate('/autopilot', {
          state: { settings: location.state?.settings },
        });
      }}
      extraHeaderContent={
        <>
          <Spacer />
          <ICPSelect
            selectOptions={selectOptions}
            selectedOption={selectValue}
            onChange={handleChange}
            type="accountQualification"
            data={accountQualifications}
            currentSelected={selectedAccountQualification}
            createFn={addAccountQualification}
            onCreate={() => {
              ref.current.handleResetQualifications();
              selectRef?.current.blur();
              setIsPending(true);
            }}
            selectRef={selectRef}
            isCreateLoading={isSavingAccountQualification}
            editFn={editAccountQualification}
            isEditLoading={isEditLoading}
            duplicateFn={duplicateAccountQualification}
            isDuplicateLoading={isDuplicatingAccountQualification}
            deleteFn={deleteAccountQualification}
            onDelete={async (newSelectedItem) => {
              await dispatch(setSelectedAccountQualification(newSelectedItem));
              ref.current.handleResetQualifications();
              setIsPending(false);
            }}
          />
        </>
      }
    >
      <Box pb="56px" pt={4} w="100%">
        <NavigationBlocker
          shouldBlock={isQualificationDirty || isPending}
          onConfirm={async () => {
            if (isPending) {
              const oldAccountQualification = selectedAccountQualification;
              const newSelectedItem = accountQualifications.find(
                (item) => item._id !== oldAccountQualification._id
              );
              dispatch(setSelectedAccountQualification(newSelectedItem));
              await deleteAccountQualification({
                _id: oldAccountQualification._id,
                teamId: team._id,
                userId: user.id,
              });
            }
          }}
        />
        <Helmet title={'Truebase | Qualification'} defer={false} />

        {selectedAccountQualification?._id && icps ? (
          <TrainQualifications
            icps={icps}
            selectedAccountQualification={selectedAccountQualification}
            ref={ref}
            setIsPending={setIsPending}
          />
        ) : (
          <Box p="16px" bg="white">
            <Skeleton h="496px" w="100%" speed={0.6} />
          </Box>
        )}
      </Box>

      <SelectAlertDialog
        showPrompt={showPrompt}
        setShowPrompt={setShowPrompt}
        onConfirm={() => {
          dispatch(
            setSelectedAccountQualification(
              accountQualifications.find(({ _id }) => selectedOption === _id)
            )
          );
          setIsQualificationDirty(false);
          setShowPrompt(false);
        }}
      />
    </Layout>
  );
}

export const AccountQualificationWithContext = () => {
  const selectedIcp = useSelector(getSelectedIcp);
  const selectedAccountQualification = useSelector(
    getSelectedAccountQualification
  );

  return (
    <IsDirtyProvider>
      <FilterProvider
        initialCompanyFilters={selectedIcp?.companyFilterStatements || []}
        initialLeadFilters={selectedIcp?.personFilterStatements || []}
      >
        <QualificationProvider
          initialQualificationQuestions={
            utils.formatQualificationsFromServer(
              selectedAccountQualification?.qualificationQuestions
            ) || defaultQualificationQuestions
          }
        >
          <AccountQualification />
        </QualificationProvider>
      </FilterProvider>
    </IsDirtyProvider>
  );
};
