import { useEffect, useRef, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { chakraComponents, Select } from 'chakra-react-select';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getSelectedTeam } from '../redux/slices/teamsSlice';
import { ReactComponent as TooltipArrow } from '../assets/tooltip arrow.svg';
import { webkitScrollbarConfig } from './constants/webkitScrollbar';
import { ReactComponent as Add } from '../assets/icons/add/add-small.svg';
import { ReactComponent as EditIcon } from '../assets/icons/edit/edit-1.svg';
import { ReactComponent as RenameIcon } from '../assets/icons/edit/edit-2.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete.svg';
import { ReactComponent as DuplicateIcon } from '../assets/icons/duplicate.svg';
import { ReactComponent as InfoIcon } from '../assets/icons/info.svg';
import useErrorHandler from '../hooks/useErrorHandler';
import { getUser } from '../redux/slices/userSlice';
import { generateUniqueName } from './utils';

const labels = {
  icp: {
    name: 'ICP',
    createNewLabel: 'Create new ICP',
    renameLabel: 'Rename ICP',
    formInputTitle: 'ICP Name',
    formInputPlaceholder: 'E.g. West Coast SaaS Companies',
    cloneLabel: 'Clone Filters',
    cloneTitle: 'Duplicate ICP Filters',
  },
  accountQualification: {
    name: 'Account Qualification',
    createNewLabel: 'Create new account qualification',
    renameLabel: 'Rename account qualification',
    formInputTitle: 'Account qualification Name',
    formInputPlaceholder: 'Account qualification Name',
    cloneLabel: 'Clone Settings',
    cloneTitle: 'Duplicate account qualification settings',
  },
  sequenceTemplate: {
    name: 'Sequence Template',
    createNewLabel: 'Create new sequence template',
    renameLabel: 'Rename sequence template',
    formInputTitle: 'Sequence template Name',
    formInputPlaceholder: 'Sequence template Name',
    cloneLabel: 'Clone Settings',
    cloneTitle: 'Duplicate sequence template settings',
  },
};

export default function ICPSelect({
  selectedOption,
  selectOptions,
  onChange,
  type,
  data,
  currentSelected,
  createFn,
  onCreate,
  isCreateLoading,
  editFn,
  isEditLoading,
  duplicateFn,
  isDuplicateLoading,
  deleteFn,
  onDelete,
  selectRef,
}) {
  const { handleAsyncError } = useErrorHandler();

  const user = useSelector(getUser) || {};
  const team = useSelector(getSelectedTeam);

  const [controlShouldRenderValue, setControlShouldRenderValue] =
    useState(true);

  const cancelRef = useRef();

  const currentType = labels[type];

  const notify = ({ message, status }) =>
    toast({
      title: message,
      status,
      duration: 1000,
    });

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const {
    isOpen: isDupOpen,
    onOpen: onDupOpen,
    onClose: onDupClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteOpen,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useDisclosure();

  const {
    register: registerDuplication,
    handleSubmit: handleDupSubmit,
    formState: { errors: dupErrors },
    reset: resetDupForm,
  } = useForm({
    defaultValues: { name: '' },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    register: registerEdit,
    handleSubmit: handleEditSubmit,
    formState: { errors: editErrors },
    reset: resetEditForm,
  } = useForm({
    defaultValues: { name: currentSelected?.name },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const handleSubmitEditForm = handleAsyncError(async (values) => {
    const res = await editFn({
      _id: currentSelected._id,
      name: values.name,
    })?.unwrap();
    notify({
      message: res?.response?.data?.isOk
        ? 'Edited successfully!'
        : 'Something went wrong!',
      status: res?.response?.data?.isOk ? 'success' : 'error',
    });
    onEditClose();
    resetEditForm();
  });

  const handleSubmitDupForm = handleAsyncError(async (values) => {
    const res = await duplicateFn({
      _id: currentSelected._id,
      name: values.name,
    });
    notify({
      message: res.data?.isOk
        ? 'Duplicated successfully!'
        : 'Something went wrong!',
      status: res.data?.isOk ? 'success' : 'error',
    });
    onDupClose();
    resetDupForm();
  });

  const handleDeleteClick = handleAsyncError(async () => {
    const newSelectedItem = data.find(
      (item) => item._id !== currentSelected._id
    );
    onDelete(newSelectedItem);
    onDeleteClose();
    const response = await deleteFn({
      _id: currentSelected._id,
      teamId: team._id,
      userId: user.id,
    }).unwrap();

    notify({
      message: response?.isOk
        ? 'Deleted successfully!'
        : 'Something went wrong!',
      status: response?.isOk ? 'success' : 'error',
    });
  });

  const toast = useToast();

  const handleCreateNew = handleAsyncError(async () => {
    const response = await createFn({
      name: generateUniqueName(currentType.name, data),
      teamId: team._id,
    }).unwrap();
    onCreate?.();
    toast({
      title: response?.isOk ? 'Added Successfully!' : 'Something went wrong!',
      status: response?.isOk ? 'success' : 'error',
      duration: 1000,
    });
  });

  useEffect(() => {
    resetEditForm({ name: currentSelected?.name });
  }, [resetEditForm, currentSelected?.name]);

  return (
    <>
      <Flex alignItems="center">
        <Menu variant="truebase">
          <MenuButton
            onClick={(e) => e.stopPropagation()}
            as={IconButton}
            w="40px"
            h="40px"
            minW={0}
            sx={{
              bg: 'white',
              svg: { fill: 'trueDim' },
              borderRadius: '4px 0 0 4px',
              border: '1px solid',
              borderRight: 'none',
              borderColor: 'trueLight',
              _hover: { bg: 'transparent', borderColor: '#CBD5E0' },
              _active: { bgColor: 'transparent' },
            }}
            icon={<EditIcon />}
          />
          <MenuList zIndex="999">
            <MenuItem onClick={onDupOpen}>
              <DuplicateIcon />
              <Text>{currentType.cloneLabel}</Text>
            </MenuItem>
            <MenuItem onClick={onEditOpen}>
              <RenameIcon />
              <Text>Rename</Text>
            </MenuItem>
            <MenuItem isDisabled={data?.length === 1} onClick={onDeleteOpen}>
              <DeleteIcon />
              <Text>Delete</Text>
            </MenuItem>
          </MenuList>
        </Menu>

        <Select
          placeholder="Select ICP"
          size="lg"
          chakraStyles={{
            control: (baseStyle, state) => ({
              ...baseStyle,
              // width: '100%',
              borderColor: state.isFocused ? 'trueLink' : 'trueLight',
              boxShadow: state.isFocused
                ? '0px 0px 10px rgba(2, 128, 245, 0.25) !important'
                : 'none',
              borderRadius: '0 4px 4px 0',
              minHeight: '40px',
              maxHeight: '40px',
              width: '260px',
              cursor: 'pointer',
              bg: 'white',
            }),
            valueContainer: (baseStyle) => ({
              ...baseStyle,
              fontSize: '12px',
              color: 'trueSpace',
              lineHeight: '14px',
              fontWeight: '500',
            }),
            singleValue: (baseStyle) => ({
              ...baseStyle,
              color: 'trueSpace',
              fontSize: '12px',
              lineHeight: '14px',
              svg: {
                display: 'none',
              },
            }),
            dropdownIndicator: () => ({
              padding: '8px 16px',
              color: 'trueDim',
            }),
            menuList: (baseStyle) => ({
              ...baseStyle,
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              overflowY: 'overlay',
              p: 0,
              // WebkitOverflowScrolling: 'unset',
              borderRadius: 0,
              boxShadow: 'none',
              maxH: '200px',
              ...webkitScrollbarConfig(),
            }),
            menu: (baseStyle) => ({
              ...baseStyle,
              mt: 0,
              overflow: 'hidden',
              zIndex: '999',
            }),
            option: (baseStyle, { isSelected }) => ({
              ...baseStyle,
              h: '40px',
              backgroundColor: 'white',
              p: '8px 16px',
              color: 'trueSpace',
              fontSize: '12px',
              lineHeight: '14px',
              fontWeight: '500',
              svg: {
                color: 'trueDim',
              },
              '&:hover': {
                backgroundColor: '#c3e6fa',
                svg: {
                  color: 'trueLink',
                },
              },
              ...(isSelected && {
                color: 'trueSpace !important',
              }),
            }),
            placeholder: (baseStyle) => ({
              ...baseStyle,
              color: 'trueMedium',
              fontSize: '12px',
              lineHeight: '14px',
            }),
          }}
          defaultValue={{
            value: selectedOption?.value,
            label: selectedOption?.label,
          }}
          value={{
            value: selectedOption?.value,
            label: selectedOption?.label,
          }}
          selectedOptionColorScheme="white"
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: (props) => (
              <chakraComponents.DropdownIndicator {...props}>
                <TooltipArrow />
              </chakraComponents.DropdownIndicator>
            ),
            MenuList: (props) => (
              <>
                <chakraComponents.MenuList {...props}>
                  <Box>{props.children}</Box>
                </chakraComponents.MenuList>
                <Button
                  sx={{
                    w: '100%',
                    h: '40px',
                    color: 'trueLink',
                    fontSize: '12px',
                    lineHeight: '14px',
                    p: '8px 16px',
                    bgColor: 'white',
                    borderWidth: '0 1px 1px 1px',
                    borderRadius: '0 0 4px 4px',
                    fontWeight: '500',
                    justifyContent: 'space-between',
                    _hover: { bgColor: 'white' },
                    opacity: '1 !important',
                  }}
                  rightIcon={<Add />}
                  isLoading={isCreateLoading}
                  onClick={handleCreateNew}
                >
                  {currentType.createNewLabel}
                </Button>
              </>
            ),
          }}
          onChange={onChange}
          controlShouldRenderValue={controlShouldRenderValue}
          onMenuOpen={() => setControlShouldRenderValue(false)}
          onMenuClose={() => setControlShouldRenderValue(true)}
          options={selectOptions}
          blurInputOnSelect
          ref={selectRef}
        />
      </Flex>
      <Modal
        motionPreset="slideInRight"
        size="3xl"
        isCentered
        variant="truebase"
        isOpen={isDupOpen}
        onClose={onDupClose}
      >
        <ModalOverlay />
        <form onSubmit={handleDupSubmit(handleSubmitDupForm)}>
          <ModalContent>
            <ModalHeader fontWeight="700" py="20px" px="16px">
              {currentType.cloneTitle}
            </ModalHeader>
            <ModalCloseButton top="16px" h="24px" w="24px" />
            <ModalBody display="flex" gap="4px" flexDirection="column">
              <Text fontWeight="500" color="trueMedium"></Text>
              <FormControl isInvalid={dupErrors.name}>
                <FormLabel variant="truebase">
                  {currentType.formInputTitle}
                </FormLabel>
                <Input
                  h="40px"
                  variant="truebase"
                  size="lg"
                  placeholder={currentType.formInputPlaceholder}
                  {...registerDuplication('name', {
                    required: 'You must enter a name',
                    validate: (value) => {
                      const isUnique = data.every(
                        (item) => item.name !== value
                      );
                      return isUnique || 'Name must be unique';
                    },
                  })}
                />
                <FormErrorMessage variant="truebase">
                  {dupErrors.name && dupErrors.name.message}
                </FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Flex gap="16px">
                <Button
                  w="120px"
                  h="40px"
                  variant="truebaseOutline"
                  onClick={onDupClose}
                >
                  Cancel
                </Button>
                <Button
                  w="120px"
                  h="40px"
                  type="submit"
                  variant="truebase"
                  isLoading={isDuplicateLoading}
                >
                  Duplicate
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
      <Modal
        motionPreset="slideInRight"
        size="3xl"
        isCentered
        variant="truebase"
        isOpen={isEditOpen}
        onClose={onEditClose}
      >
        <ModalOverlay />
        <form onSubmit={handleEditSubmit(handleSubmitEditForm)}>
          <ModalContent>
            <ModalHeader fontWeight="700" py="20px" px="16px">
              {currentType.renameLabel}
            </ModalHeader>
            <ModalCloseButton top="16px" h="24px" w="24px" />
            <ModalBody display="flex" gap="4px" flexDirection="column">
              <Text fontWeight="500" color="trueMedium"></Text>
              <FormControl isInvalid={editErrors.name}>
                <FormLabel variant="truebase">
                  {currentType.formInputTitle}
                </FormLabel>
                <Input
                  h="40px"
                  variant="truebase"
                  size="lg"
                  placeholder={currentType.formInputPlaceholder}
                  {...registerEdit('name', {
                    required: 'You must enter a name',
                    validate: (value) => {
                      const isUnique = data
                        .filter((item) => item._id !== currentSelected?._id)
                        .every((item) => item.name !== value);
                      return isUnique || 'Name must be unique';
                    },
                  })}
                />
                <FormErrorMessage variant="truebase">
                  {editErrors.name && editErrors.name.message}
                </FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                w="120px"
                h="40px"
                variant="truebaseOutline"
                mr={3}
                onClick={onEditClose}
              >
                Cancel
              </Button>
              <Button
                w="120px"
                h="40px"
                type="submit"
                variant="truebase"
                isLoading={isEditLoading}
              >
                Done
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
      <AlertDialog
        size="xl"
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onDeleteClose}
        isOpen={isDeleteOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogBody p={4} paddingInlineStart={0}>
            <Flex alignItems="center" gap="16px">
              <Box sx={{ svg: { fill: 'trueWarning' } }}>
                <InfoIcon />
              </Box>
              <Text
                color="trueSpace"
                fontSize="14px"
                fontWeight={500}
                lineHeight="20px"
              >
                You’re about to delete{' '}
                <Text as="span" color="trueExplain">
                  {selectedOption?.label}
                </Text>{' '}
                {currentType.name}.
                <br />
                Are you sure you want to continue?
              </Text>
              <Spacer />
            </Flex>
          </AlertDialogBody>
          <AlertDialogFooter
            px="16px"
            py="8px"
            sx={{
              borderTopWidth: '1px',
              borderTopColor: 'trueLight',
            }}
          >
            <Flex
              justifyContent="flex-end"
              gap="16px"
              alignItems="center"
              w="100%"
            >
              <Button
                variant="truebaseText"
                ref={cancelRef}
                w="54px"
                h="32px"
                onClick={onDeleteClose}
              >
                Cancel
              </Button>
              <Button
                variant="truebaseTextHighlight"
                ref={cancelRef}
                w="54px"
                h="32px"
                onClick={handleDeleteClick}
              >
                Delete
              </Button>
            </Flex>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
