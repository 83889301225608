import {
  Flex,
  HStack,
  Input,
  Step,
  StepIndicator,
  StepSeparator,
  Stepper,
  Text,
  useRadioGroup,
} from '@chakra-ui/react';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CustomRadio } from '~/v2/common/Truebase/TruebaseRadio';
import { AnimatePresence, motion } from 'framer-motion';
import {
  AUTOPILOT_FREQUENCY_OPTIONS,
  FORMATTED_EXPORT_OPTIONS,
} from '~/v2/constants/common';

export const LaunchAutopilot = ({
  selectedMode,
  autopilotsResponse,
  autopilotName,
  setAutopilotName,
  steps,
  setFrequency,
  leadsCount,
  setLeadsCount,
  setExportProvider,
  setContactEnrichmentVerificationOptions,
  setIsQualifyEnabled,
  setPanel,
  setIsFilterStepEnabled,
  templatesResponse,
  setIsVerifyEnabled,
  isQualifyEnabled,
  isFilterStepEnabled,
  isVerifyEnabled,
}) => {
  const location = useLocation();

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'frequency',
    defaultValue: get(
      location,
      'state.settings.frequency',
      AUTOPILOT_FREQUENCY_OPTIONS[0]
    ).value,
    onChange: setFrequency,
  });
  const group = getRootProps();

  useEffect(() => {
    setLeadsCount(
      get(location, 'state.settings.leadsCount', { label: '10', value: '10' })
    );
    setPanel({
      acceptedFiles: [],
      originalFileName: '',
      leadsCount: 0,
      error: false,
    });
    setFrequency(
      get(location, 'state.settings.frequency', AUTOPILOT_FREQUENCY_OPTIONS[0])
        .value
    );
    setExportProvider(
      FORMATTED_EXPORT_OPTIONS.find(
        (option) =>
          option.value ===
          get(
            location,
            'state.settings.exportProviderValue',
            FORMATTED_EXPORT_OPTIONS[0].value
          )
      )
    );
    setIsQualifyEnabled(
      get(location, 'state.settings.isQualifyEnabled', false)
    );
    setIsVerifyEnabled(get(location, 'state.settings.isVerifyEnabled', false));
    setIsFilterStepEnabled(
      get(
        location,
        'state.settings.isFilterStepEnabled',
        get(
          get(templatesResponse, 'result.templates', []).find(
            ({ name }) => name === selectedMode
          ),
          'steps',
          []
        ).find(({ name }) => name === 'filter')?.isRequired
      )
    );
    setContactEnrichmentVerificationOptions(
      get(location, 'state.settings.contactEnrichmentVerificationOptions', [
        'workEmail',
      ])
    );
  }, [
    location,
    setLeadsCount,
    setFrequency,
    setContactEnrichmentVerificationOptions,
    setExportProvider,
    setIsQualifyEnabled,
    setIsFilterStepEnabled,
    setPanel,
    selectedMode,
    templatesResponse,
    setIsVerifyEnabled,
  ]);

  useEffect(() => {
    setAutopilotName(
      get(
        location,
        'state.settings.autopilotName',
        `My Autopilot ${autopilotsResponse?.result?.totalCount + 1}`
      )
    );
  }, [location, autopilotsResponse?.result?.totalCount, setAutopilotName]);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        style={{ height: '100%' }}
      >
        <Flex
          flexDir="column"
          justifyContent="space-between"
          pt={4}
          w="100%"
          pos="relative"
          h="100%"
        >
          <Flex
            p="unset"
            maxW="1152px"
            w="100%"
            flexDir="column"
            pb="56px"
            pt="8px"
          >
            <Flex ml="66px" mb="16px" flexDir="column" gap="4px">
              <Text
                fontWeight="400"
                fontSize="13px"
                lineHeight="16px"
                color="trueDim"
              >
                Autopilot name
              </Text>
              <Flex alignItems="center" gap="24px">
                <Input
                  w="256px"
                  bg="white"
                  value={autopilotName}
                  onChange={(e) => {
                    setAutopilotName(e.target.value);
                  }}
                  fontWeight="500"
                  fontSize="12px"
                  lineHeight="14px"
                  placeholder="Enter autopilot name"
                  errorBorderColor="trueAttention"
                />
                {['buildList', 'emailOutbound'].includes(selectedMode) && (
                  <Flex alignItems="center" gap="16px">
                    <Text
                      color="trueDim"
                      fontWeight="400"
                      fontSize="13px"
                      lineHeight="16px"
                    >
                      Run
                    </Text>
                    <HStack {...group}>
                      {AUTOPILOT_FREQUENCY_OPTIONS.map(({ label, value }) => {
                        const radio = getRadioProps({ label, value });
                        return (
                          <CustomRadio key={value} {...radio}>
                            <Text
                              fontWeight="500"
                              fontSize="12px"
                              lineHeight="14px"
                            >
                              {label}
                            </Text>
                          </CustomRadio>
                        );
                      })}
                    </HStack>
                  </Flex>
                )}
              </Flex>
            </Flex>

            <Stepper orientation="vertical" gap="24px" ml="8px">
              {steps.map((step, index) => (
                <Step key={index} w="100%" gap="24px">
                  <StepIndicator
                    w="34px"
                    h="34px"
                    borderColor={
                      get(step, 'isDisabled', false) ||
                      {
                        filter: !isFilterStepEnabled,
                        qualify: !isQualifyEnabled,
                        verify: !isVerifyEnabled,
                      }[step.name]
                        ? 'trueDim !important'
                        : 'trueSpace !important'
                    }
                    bg={
                      get(step, 'isDisabled', false) ||
                      {
                        filter: !isFilterStepEnabled,
                        qualify: !isQualifyEnabled,
                        verify: !isVerifyEnabled,
                      }[step.name]
                        ? 'trueDim'
                        : 'trueSpace'
                    }
                    color="white"
                    fontSize="13px"
                    lineHeight="16px"
                    fontWeight={500}
                    zIndex={100}
                    mt="16px"
                  >
                    {index + 1}
                  </StepIndicator>
                  <StepSeparator
                    sx={{
                      maxH: '100% !important',
                      top: '40px !important',
                      bg: 'transparent',
                      borderRight: '1px dashed',
                      borderColor: 'trueDim',
                    }}
                  />
                  {step.component}
                </Step>
              ))}
            </Stepper>
          </Flex>
        </Flex>
      </motion.div>
    </AnimatePresence>
  );
};
