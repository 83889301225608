import { Center, SlideFade, Spinner, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { getStatus } from '../../../redux/slices/statusSlice';

const GeneratingStatus = ({ lead, account, priority }) => {
  const curriedGetStatus = useSelector(getStatus);
  let statusToShow, leadStatus, accountStatus;
  if (lead && priority === 'leads') {
    leadStatus = curriedGetStatus(lead.personId);
    statusToShow = leadStatus?.[0]?.message;
    if (!statusToShow && account) {
      accountStatus = curriedGetStatus(account.companyId);
      statusToShow = accountStatus?.[0]?.message;
    }
  }
  if (account && priority === 'accounts') {
    accountStatus = curriedGetStatus(account.companyId);
    statusToShow = accountStatus?.[0]?.message;
    if (!statusToShow && account?.leads) {
      for (const l of account.leads) {
        leadStatus = curriedGetStatus(l.personId);
        statusToShow = leadStatus?.[0]?.message;
        if (statusToShow) break;
      }
    }
  }

  return (
    statusToShow && (
      <Center gap="8px">
        <Spinner size="sm" />
        <SlideFade in={statusToShow} offsetX="20px" offsetY="0">
          <Text lineHeight="14px" fontSize="12px" color="trueSpace">
            {statusToShow}
          </Text>
        </SlideFade>
      </Center>
    )
  );
};

export default GeneratingStatus;
