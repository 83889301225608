import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { chakraComponents } from 'chakra-react-select';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getSelectedTeam } from '~/v2/redux/slices/teamsSlice';
import TruebaseSelect from '~/v2/common/Truebase/TruebaseSelect';
import { ReactComponent as SendIcon } from '~/v2/assets/icons/mail/send.svg';
import { ReactComponent as Close } from '~/v2/assets/icons/close/close-large.svg';
import { get, isEmpty } from 'lodash';
import { useState } from 'react';

const ControlWithFormLabel = ({ children, ...props }) => {
  return (
    <chakraComponents.Control {...props}>
      <FormLabel
        variant="truebase"
        sx={{
          display: 'flex',
          color: 'trueDim',
          fontWeight: 500,
          m: 'unset',
          pl: '16px',
        }}
      >
        From email
      </FormLabel>
      {children}
    </chakraComponents.Control>
  );
};

export const TestEmailModal = ({ isOpen, onClose, handleTestEmail }) => {
  const [isLoading, setIsLoading] = useState(false);
  const team = useSelector(getSelectedTeam);
  const {
    watch,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      from: get(team, 'emailAccounts.0._id'),
    },
  });
  const formValues = watch();

  const onSubmit = async (e) => {
    setIsLoading(true);
    e?.preventDefault();
    if (!isEmpty(errors)) return;
    await handleTestEmail?.({ ...formValues, teamId: team._id });
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal
      motionPreset="slideInRight"
      isCentered
      variant="truebase"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent minW="600px">
        <form>
          <ModalHeader
            borderBottomWidth="1px"
            borderBottomColor="trueLight"
            p="18px 24px"
            fontWeight="500"
            fontSize="16px"
            lineHeight="20px"
            display="flex"
          >
            <Flex alignItems="center" gap="12px">
              <Icon as={SendIcon} w="24px" h="24px" fill="trueSpace" />
              <Text fontSize="14px" fontWeight={500} color="trueSpace">
                Send test email
              </Text>
            </Flex>
            <Spacer />
            <Box>
              <IconButton
                onClick={onClose}
                variant="ghost"
                sx={{ w: '32px', h: '32px', minW: 0, svg: { fill: 'trueDim' } }}
              >
                <Close />
              </IconButton>
            </Box>
          </ModalHeader>
          <ModalBody display="flex" gap="16px" flexDirection="column">
            <FormControl isRequired isInvalid={errors.from}>
              <TruebaseSelect
                variant="truebase"
                options={get(team, 'emailAccounts', []).map((email) => ({
                  label: email._id,
                  value: email._id,
                }))}
                {...register('from', {
                  required: true,
                })}
                getOptionLabel={(option) => (
                  <span className="option-label">{option.label}</span>
                )}
                onChange={(option) => {
                  setValue('from', option.value);
                }}
                value={{
                  label: formValues.from,
                  value: formValues.from,
                }}
                additionalSelectComponents={{ Control: ControlWithFormLabel }}
              />
            </FormControl>
            <FormControl isRequired isInvalid={errors.to}>
              <InputGroup>
                <Input
                  pl="104px"
                  variant="truebase"
                  placeholder="Type an email address"
                  type="email"
                  {...register('to', {
                    required: 'Email is required',
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: 'Please enter a valid email',
                    },
                  })}
                  sx={{
                    h: '40px',
                    fontSize: '12px',
                    lineHeight: '14px',
                    borderRadius: '4px',
                  }}
                  _placeholder={{
                    color: 'trueDim',
                    fontSize: '12px',
                    lineHeight: '14px',
                  }}
                />
                <InputLeftElement left="16px" m="unset" w="51px">
                  <FormLabel
                    variant="truebase"
                    sx={{
                      display: 'flex',
                      color: 'trueDim',
                      fontWeight: 500,
                      m: 'unset',
                    }}
                  >
                    Send to
                  </FormLabel>
                </InputLeftElement>
              </InputGroup>
              <FormErrorMessage variant="truebase">
                {errors.to?.message}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Flex gap="16px">
              <Button
                w="120px"
                h="40px"
                variant="truebaseOutline"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                isDisabled={
                  !formValues.from || !formValues.to || !isEmpty(errors)
                }
                onClick={onSubmit}
                isLoading={isLoading}
                w="120px"
                h="40px"
                variant="truebase"
              >
                Send
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
