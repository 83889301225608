import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
  InputGroup,
  InputRightElement,
  IconButton,
  Spinner,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  useLazyGetCurrentUserQuery,
  useLazyGetMeQuery,
  useLoginMutation,
} from '../../redux/services/user';
import { ButtonWithGoogle } from './ButtonWithGoogle';
import LoginLayout from './LoginLayout';
import { ReactComponent as MailIcon } from '../../assets/icons/mail/mail-1.svg';
import { ReactComponent as EyeOnIcon } from '../../assets/icons/view/view-large.svg';
import { ReactComponent as EyeOffIcon } from '../../assets/icons/view/view-large-off.svg';
import { safeJSONParse } from '~/v2/common/utils';

const Separator = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  color: #0f0f4d;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #7e8bad;
  }

  &::before {
    margin-right: 0.25em;
  }
  &::after {
    margin-left: 0.25em;
  }
`;

export default function Login() {
  // const [hasInviteLink, setHasInviteLink] = useState(false);
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [login] = useLoginMutation();
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const [getMe] = useLazyGetMeQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [isLoadingGoogleRes, setIsLoadingGoogleRes] = useState(false);
  const { handleAsyncError } = useErrorHandler();

  useEffect(() => {
    const fetchData = handleAsyncError(async () => {
      const tokenString = new URLSearchParams(location.search).get('t');
      const error = new URLSearchParams(location.search).get('e');
      if (error) {
        switch (error) {
          case 'notFound':
          case 'notFound#':
            toast({
              title: 'Login failed',
              description: 'User was not found, please try again',
              status: 'error',
              duration: 9000,
              isClosable: true,
            });
            break;
          case 'dup':
            toast({
              title: 'Login failed',
              description: 'User already exists, please try again',
              status: 'error',
              duration: 9000,
              isClosable: true,
            });
            break;
          default:
            toast({
              title: 'Login failed',
              // description: 'Invalid credentials, please try again',
              status: 'error',
              duration: 9000,
              isClosable: true,
            });
        }
        localStorage.removeItem('AccessToken');
        return;
      }
      if (tokenString) {
        setIsLoadingGoogleRes(true);
        const token = safeJSONParse(
          decodeURIComponent(tokenString.replace('#', ''))
        );
        localStorage.setItem('AccessToken', token?.authToken);
        const userResponse = await getCurrentUser().unwrap();
        const currentUser = userResponse.result.user;
        if (currentUser)
          localStorage.setItem('User', JSON.stringify(currentUser));

        setIsLoadingGoogleRes(false);
        navigate('/autopilot');
      } else {
        const { data: user } = await getMe();
        if (user) {
          location.state?.from
            ? navigate(location.state.from)
            : history.state?.form
            ? navigate(history.state?.form)
            : navigate('/autopilot');
        }
      }
    });
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onSubmit = handleAsyncError(async ({ email, password }, e) => {
    e.preventDefault();

    const user = await login({
      email,
      password,
      authProvider: 'truebase',
      src: 'app',
    }).unwrap();
    if (user.isOk) {
      localStorage.setItem('AccessToken', user.result.authToken);
      const userResponse = await getCurrentUser().unwrap();
      const currentUser = userResponse.result.user;
      if (currentUser)
        localStorage.setItem('User', JSON.stringify(currentUser));

      navigate('/autopilot');
    } else {
      toast({
        title: 'Login failed',
        description: 'Invalid credentials, please try again',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setError(
        'email',
        {
          type: 'custom',
          message: 'Invalid credentials, please try again',
        },
        true
      );
    }
  });

  const loginWithGoogle = handleAsyncError(async () => {
    let searchParams = '';
    const response = await login({
      authProvider: 'google',
      ...(searchParams && {
        searchParams,
      }),
    }).unwrap();
    if (response.isOk) {
      window.location.href = response.result.url;
    }
  });

  return (
    <LoginLayout title="Welcome back" titleProps={{ mb: 'unset' }}>
      <Helmet title={'Truebase | Login'} defer={false} />
      {isLoadingGoogleRes ? (
        <Spinner
          color="trueBlue"
          w="50px"
          h="50px"
          sx={{ alignSelf: 'center', mt: '80px' }}
          speed="0.65s"
        />
      ) : (
        <>
          <ButtonWithGoogle mt="80px" mb="48px" onClick={loginWithGoogle}>
            Login with Google
          </ButtonWithGoogle>
          <Separator>&nbsp;&nbsp;OR&nbsp;&nbsp;</Separator>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex flexDir="column" gap="24px" mt="48px">
              <FormControl isInvalid={errors.email}>
                <FormLabel variant="truebase">Email Address</FormLabel>
                <InputGroup>
                  <Input
                    variant="truebase"
                    type="email"
                    placeholder="Type your email address"
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Please enter a valid email',
                      },
                    })}
                  />
                  <InputRightElement h="48px">
                    <MailIcon fill="var(--chakra-colors-trueDim)" />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage variant="truebase">
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.password}>
                <FormLabel variant="truebase">Password</FormLabel>
                <InputGroup>
                  <Input
                    variant="truebase"
                    type={isPasswordShown ? 'text' : 'password'}
                    placeholder="Type your password"
                    autoComplete="off"
                    className="form-control"
                    {...register('password', {
                      required: 'Password is required',
                    })}
                  />
                  <InputRightElement h="48px">
                    <IconButton
                      sx={{
                        bg: 'transparent',
                        _hover: {
                          bg: 'transparent',
                        },
                        svg: {
                          path: {
                            fill: 'trueDim',
                          },
                        },
                      }}
                      icon={isPasswordShown ? <EyeOnIcon /> : <EyeOffIcon />}
                      onClick={() => {
                        setIsPasswordShown(!isPasswordShown);
                      }}
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage variant="truebase">
                  {errors.password && errors.password.message}
                </FormErrorMessage>
              </FormControl>

              <Button variant="truebase" type="submit">
                Login
              </Button>
            </Flex>
          </form>
          <span>
            <Box
              display="flex"
              flexDir="column"
              gap="24px"
              py="48px"
              alignItems="center"
            >
              <Box fontSize="13px" lineHeight="15px" color="trueSpace">
                Forgot password?
              </Box>
              <div>
                <Button
                  h="32px"
                  variant="truebaseText"
                  as={Link}
                  to="/forgot"
                  color="trueLink"
                >
                  Recover it
                </Button>
              </div>
            </Box>
            <Box display="flex" flexDir="column" gap={2} alignItems="center">
              <Box fontSize="13px" lineHeight="15px" color="trueSpace">
                Don&apos;t have an account?
              </Box>
              <div>
                <Button
                  variant="truebaseText"
                  as={Link}
                  to="/onboarding"
                  color="trueLink"
                >
                  Get one
                </Button>
              </div>
            </Box>
          </span>
        </>
      )}
    </LoginLayout>
  );
}
