import { useEffect, useMemo, useRef, useState } from 'react';
import { LeadRowWithTabs } from './LeadRow';
import {
  Box,
  Card,
  CardBody,
  Center,
  IconButton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { webkitScrollbarConfig } from '../../constants/webkitScrollbar';
import { ReactComponent as FlagIcon } from '../../../assets/icons/flag/flag-1.svg';
import { TruebaseTooltip } from '../../TruebaseTooltip';
import { CloseIcon } from '~/v2/assets/CloseIcon';
import { useSelector } from 'react-redux';
import { getStatus } from '~/v2/redux/slices/statusSlice';
import { getSelectedTeam } from '~/v2/redux/slices/teamsSlice';

export default function LeadsContainer({
  readonly,
  leads,
  account,
  showHitsAndMisses,
  companyFilters,
  outDatedLeads,
  setOutDatedLeads,
}) {
  const parentRef = useRef();
  const [expandedRow, setExpandedRow] = useState(null);

  const selectedTeam = useSelector(getSelectedTeam);

  const firstAvailableLeadIndex = useMemo(() => {
    return account.leads.findIndex(
      (lead) =>
        lead.steps?.search?.enqueuedAt === undefined &&
        !Object.keys(selectedTeam?.excludedPersons || {}).includes(
          lead.personId
        )
    );
  }, [account.leads, selectedTeam]);

  const isGeneratingSequence = useSelector(getStatus)(
    account.leads[firstAvailableLeadIndex]?.personId
  )?.find(({ type }) => type === 'sequence');

  useEffect(() => {
    if (isGeneratingSequence) setExpandedRow(firstAvailableLeadIndex);
  }, [isGeneratingSequence, firstAvailableLeadIndex]);

  const handleExpandRow = (index) => () => {
    setExpandedRow((current) => (index !== current ? index : null));
  };

  return (
    leads && (
      <Box
        ref={parentRef}
        sx={{
          maxHeight: '650px',
          overflow: 'overlay',
          ...webkitScrollbarConfig('white'),
        }}
      >
        <Box
          style={{
            width: '100%',
            position: 'relative',
          }}
        >
          {leads
            .filter(
              (lead) =>
                !(outDatedLeads.has(lead._id) || lead.status === 'outdated')
            )
            .map((lead, index) => {
              return (
                <Box key={lead.personId}>
                  {lead.contactOutdated ? (
                    <Card
                      direction={{ base: 'column', sm: 'row' }}
                      overflow="hidden"
                      height="80px"
                      display={{ base: 'flex' }}
                      align={'center'}
                    >
                      <Center>
                        <Center
                          borderRadius="50%"
                          bgColor="orange"
                          width="50px"
                          height="50px"
                        >
                          <FlagIcon width="40px" height="40px" color="white" />
                        </Center>
                      </Center>
                      <Stack>
                        <CardBody>
                          <TruebaseTooltip
                            placement="bottom"
                            label={
                              <>
                                <Text
                                  align={'center'}
                                  fontSize="13px"
                                  fontWeight="400"
                                  lineHeight="16px"
                                >
                                  We removed it from our database.
                                  <br />
                                  Your account was not charged.
                                </Text>
                              </>
                            }
                          >
                            <Text
                              fontSize="14px"
                              fontWeight="500"
                              lineHeight="16px"
                            >
                              {`${lead.name} ${
                                lead.outdatedReason === 'changedJob'
                                  ? `no longer works at ${account.name}`
                                  : `changed their LinkedIn handle recently`
                              }.`}
                            </Text>
                          </TruebaseTooltip>
                        </CardBody>
                      </Stack>
                      <Center
                        sx={{
                          flexGrow: 1,
                          justifyContent: 'flex-end',
                        }}
                      >
                        <IconButton
                          h="32px"
                          w="32px"
                          icon={<CloseIcon />}
                          onClick={() => {
                            setOutDatedLeads((current) => {
                              const newSet = new Set(current);
                              newSet.add(lead._id);
                              return newSet;
                            });
                          }}
                          variant="truebaseIcon"
                        />
                      </Center>
                    </Card>
                  ) : (
                    <LeadRowWithTabs
                      readonly={readonly}
                      account={account}
                      lead={lead}
                      key={lead.personId}
                      handleExpandRow={handleExpandRow(index)}
                      isOpen={expandedRow === index}
                      showHitsAndMisses={showHitsAndMisses}
                      companyFilters={companyFilters}
                    />
                  )}
                </Box>
              );
            })}
        </Box>
      </Box>
    )
  );
}
