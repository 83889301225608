import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  chakra,
  Flex,
  Spacer,
  useToast,
  Text,
  Divider,
  Spinner,
} from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as FilterSearchDropdownAccordionItem } from '~/v2/assets/icons/account-filters.svg';
import { ReactComponent as LeadFilterAccordionItem } from '~/v2/assets/icons/lead-filters.svg';
import { TruebaseTooltip } from '../../../common/TruebaseTooltip';
import CompanyFilterAccordion from './CompanyFilterAccordion';
import LeadFilterAccordion from './LeadFilterAccordion';
import { getParentFromPath } from './utils';
import {
  CompanyFiltersHeader,
  LeadFiltersHeader,
} from './FiltersAccordionHeader';
import produce from 'immer';
import AnimatedNumber from '../../../common/AnimatedNumber';
import { useFilterResultsCount } from '../../../hooks/useFilterResultsCount';
import { useFilterContext } from './useFilterContext';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { get, isEqual } from 'lodash';
import { getSelectedIcp } from '~/v2/redux/slices/icpSlice';
import { getIncludedExcludedFilters } from '~/v2/common/utils';
import { useIsDirtyContext } from '~/v2/contexts/useIsDirtyContext';
import { useLocation } from 'react-router';
import { getUser } from '~/v2/redux/slices/userSlice';

const TruebaseAccordionItem = chakra(AccordionItem, {
  baseStyle: {
    borderRadius: '4px',
    overflow: 'hidden',
    borderTopWidth: '1px',
    borderLeftWidth: '1px',
    borderRightWidth: '1px',
    borderColor: 'trueLight',
  },
});

const AccordionHeaderWrapper = chakra(Box, {
  baseStyle: {
    fontSize: '18px',
    lineHeight: '21px',
    flexWrap: 'wrap',
    bg: 'white',
    width: '100%',
    flex: '1',
    py: 3,
    display: 'flex',
    alignItems: 'center',
    color: 'trueDim',
    span: { minWidth: 'fit-content' },
  },
});

export default function FiltersComponent({
  onSearch,
  onFilterSave,
  showMoreButton,
  isMoreButtonLoading,
  children,
  isEditLoading,
  setIsPending,
}) {
  const { handleSyncError } = useErrorHandler();
  const location = useLocation();
  const selectedIcp = useSelector(getSelectedIcp);
  const user = useSelector(getUser);
  const toast = useToast();
  const [accordionIndex, setAccordionIndex] = useState(-1);
  const { isFilterAccordionDirty, setIsFilterAccordionDirty } =
    useIsDirtyContext();
  const [userUsedDragNDrop, setUserUsedDragNDrop] = useState(false);

  const trySetAccordionIndex = (index) => {
    setAccordionIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const {
    companyFilters,
    leadFilters,
    setCompanyFilters,
    setLeadFilters,
    setRequestId,
    requestId,
    searchExclusivity,
  } = useFilterContext();

  const handleSetCompanyFilter = handleSyncError((filter) => {
    setIsFilterAccordionDirty(true);
    setCompanyFilters(
      produce((draft) => {
        // filters is an array of arrays
        // each array of filters represents a group of filters with the same path

        // if filter type is exclude, add it in a separate bracket
        if (!filter.include) {
          return [...draft, [filter]];
        }

        // if filter type is include, check if an array of filters with the same path exists
        const existingFilterGroupIndex = draft.findIndex(
          (f) =>
            getParentFromPath(f[0].path) === getParentFromPath(filter.path) &&
            filter.include &&
            f[0].include
        );
        const existingFilterGroup = draft[existingFilterGroupIndex];
        if (existingFilterGroup) {
          // if it does, check if the filter is already in the group
          const existingFilter = existingFilterGroup.find(
            (f) => f.path === filter.path
          );
          if (existingFilter) {
            // if it is, remove it
            return draft.map((f) =>
              getParentFromPath(f[0].path) === getParentFromPath(filter.path)
                ? f.map((f) =>
                    getParentFromPath(f.path) === getParentFromPath(filter.path)
                      ? {
                          ...f,
                          include: !f.include,
                        }
                      : f
                  )
                : f
            );
          } else {
            // if it is not, add it ONLY to the first array that has the same path
            draft[existingFilterGroupIndex].push(filter);
          }
        } else {
          // if it does not, add the filter to a new array
          return [...draft, [filter]];
        }
      })
    );
  });

  const deleteFilter = handleSyncError((filter) => {
    setIsFilterAccordionDirty(true);
    setCompanyFilters((filters) => {
      const newFilters = filters
        .map((f) => f.filter((f) => f.path !== filter.path))
        .filter((f) => f.length > 0);
      return newFilters;
    });
  });

  const handleSetLeadFilter = handleSyncError((filter) => {
    setIsFilterAccordionDirty(true);
    setLeadFilters(
      produce((draft) => {
        // filters is an array of arrays
        // each array of filters represents a group of filters with the same path

        // if filter type is exclude, add it in a separate bracket
        if (!filter.include) {
          return [...draft, [filter]];
        }

        // if filter type is include, check if an array of filters with the same path exists
        const existingFilterGroupIndex = draft.findIndex(
          (f) =>
            getParentFromPath(f[0].path) === getParentFromPath(filter.path) &&
            filter.include &&
            f[0].include
        );

        const existingFilterGroup = draft[existingFilterGroupIndex];

        if (existingFilterGroup) {
          // if it does, check if the filter is already in the group
          const existingFilter = existingFilterGroup.find(
            (f) => f.path === filter.path
          );
          if (existingFilter) {
            // if it is, remove it
            return draft.map((fArray) => {
              return fArray.map((f) => {
                return f.path === filter.path
                  ? {
                      ...f,
                      include: filter.include,
                    }
                  : f;
              });
            });
          } else {
            // if it is not, add it ONLY to the first array that has the same path
            draft[existingFilterGroupIndex].push(filter);
          }
        } else {
          // if it does not, add the filter to a new array
          return [...draft, [filter]];
        }
      })
    );
  });

  const handleDeleteLeadFilter = handleSyncError((filter) => {
    setIsFilterAccordionDirty(true);
    setLeadFilters((filters) => {
      const newFilters = filters
        .map((f) => f.filter((f) => f.path !== filter.path))
        .filter((f) => f.length > 0);
      return newFilters;
    });
  });

  const handleFilterSave = useCallback(async () => {
    if (
      !isEqual(get(selectedIcp, 'personFilterStatements', []), leadFilters) ||
      !isEqual(get(selectedIcp, 'companyFilterStatements', []), companyFilters)
    ) {
      const res = await onFilterSave({
        _id: selectedIcp._id,
        companyFilterStatements: companyFilters,
        personFilterStatements: leadFilters,
      })?.unwrap();
      setIsFilterAccordionDirty(false);
      setIsPending(false);
      if (location?.state?.isPending) {
        delete location.state.isPending;
      }
      toast({
        title: res?.response?.data?.isOk
          ? 'Filters saved'
          : 'Something went wrong!',
        status: res?.response?.data?.isOk ? 'success' : 'error',
        duration: 1500,
      });
    } else {
      setIsFilterAccordionDirty(false);
    }
  }, [
    companyFilters,
    leadFilters,
    onFilterSave,
    selectedIcp,
    toast,
    setIsFilterAccordionDirty,
    location,
    setIsPending,
  ]);

  const handleSearchClick = useCallback(
    async (refresh) => {
      let newRequestId;
      if (refresh || !requestId) {
        newRequestId = Math.random();
        setRequestId(newRequestId);
      }

      const response = await onSearch({
        icpId: selectedIcp._id,
        companyFilters,
        leadFilters,
        searchExclusivity,
        requestId: refresh ? newRequestId : requestId,
        type: 'default',
      });
      if (!response.data.data.isOk) {
        return toast({
          title: 'Something went wrong!',
          status: 'error',
          duration: 3000,
        });
      }
      setAccordionIndex(-1);
    },
    [
      companyFilters,
      leadFilters,
      searchExclusivity,
      onSearch,
      requestId,
      selectedIcp?._id,
      setRequestId,
      toast,
    ]
  );

  const resetFilters = () => {
    setCompanyFilters(selectedIcp?.companyFilterStatements || []);
    setLeadFilters(selectedIcp?.personFilterStatements || []);
    setIsFilterAccordionDirty(false);
  };

  const isResetDisabled = useMemo(() => {
    return (
      !isFilterAccordionDirty ||
      (companyFilters.length === 0 && leadFilters.length === 0)
    );
  }, [companyFilters, leadFilters, isFilterAccordionDirty]);

  const companyFilterIds = useMemo(() => {
    return companyFilters.map((fArr) => fArr.map((f) => f._id));
  }, [companyFilters]);

  const leadFilterNames = useMemo(() => {
    return leadFilters.map((fArr) =>
      fArr.map((f) => f?.publicName?.toLowerCase())
    );
  }, [leadFilters]);

  const isCompanyFilterSelected = useCallback(
    (filterId) => {
      if (!filterId) return true;
      return companyFilterIds.some((fArr) => fArr.includes(filterId));
    },
    [companyFilterIds]
  );

  const isLeadFilterSelected = useCallback(
    (filterName) => {
      if (!filterName) return true;
      return leadFilterNames.some((fArr) =>
        fArr.includes(filterName?.toLowerCase())
      );
    },
    [leadFilterNames]
  );

  const DashedDivider = useCallback(({ ...props }) => {
    return (
      <Divider
        orientation="vertical"
        pos="absolute"
        left="17px"
        borderStyle="dashed"
        borderColor="trueDim"
        zIndex="99"
        {...props}
      />
    );
  }, []);

  const Dot = useCallback(() => {
    return (
      <Box
        w="9px"
        h="9px"
        backgroundColor="trueExplain"
        top="20px"
        left="13px"
        pos="absolute"
        borderRadius="8px"
        zIndex="100"
      />
    );
  }, []);

  const { filterResultsCount, requestData } = useFilterResultsCount({
    companyFilters,
    leadFilters,
    selectedIcp,
  });

  const isLoadingState = useMemo(
    () =>
      !!leadFilters.length &&
      (requestData.isUninitialized || requestData.isLoading),
    [leadFilters.length, requestData.isLoading, requestData.isUninitialized]
  );

  const categorizedCompanyFilters = useMemo(
    () => getIncludedExcludedFilters(companyFilters),
    [companyFilters]
  );
  const includedCompanyFilters = useMemo(
    () => categorizedCompanyFilters.includedFilters,
    [categorizedCompanyFilters.includedFilters]
  );
  const excludedCompanyFilters = useMemo(
    () => categorizedCompanyFilters.excludedFilters,
    [categorizedCompanyFilters.excludedFilters]
  );

  const categorizedLeadFilters = useMemo(
    () => getIncludedExcludedFilters(leadFilters),
    [leadFilters]
  );
  const includedLeadFilters = useMemo(
    () => categorizedLeadFilters.includedFilters,
    [categorizedLeadFilters.includedFilters]
  );
  const excludedLeadFilters = useMemo(
    () => categorizedLeadFilters.excludedFilters,
    [categorizedLeadFilters.excludedFilters]
  );

  return (
    <>
      <Box mb="12px">
        <Accordion
          onChange={(index) => setAccordionIndex(index)}
          allowToggle
          index={accordionIndex}
        >
          <TruebaseAccordionItem pos="relative">
            <Dot />
            <DashedDivider top="24px" />
            <Box
              display="flex"
              alignItems="center"
              bg="white"
              pr="16px"
              pl="32px"
              _hover={
                accordionIndex === 0
                  ? {}
                  : {
                      '.filters-header': { background: 'truePreselect' },
                      '.filters-opacity': {
                        backgroundImage:
                          'linear-gradient(to bottom, rgba(255,255,255,0.2), truePreselect)',
                      },
                      background: 'truePreselect',
                    }
              }
              gap="4px"
            >
              <AccordionHeaderWrapper
                onClick={() => trySetAccordionIndex(0)}
                cursor="pointer"
                maxHeight={accordionIndex !== 0 ? '120px' : 'none'}
                p="unset"
                className="filters-header"
              >
                <Text
                  fontSize="13px"
                  lineHeight="15px"
                  color="trueExplain"
                  w="100%"
                  pt="17px"
                >
                  Account Filters
                </Text>

                <CompanyFiltersHeader
                  setFilters={setCompanyFilters}
                  deleteFilter={deleteFilter}
                  filters={includedCompanyFilters}
                  accordionIndex={accordionIndex}
                  userUsedDragNDrop={userUsedDragNDrop}
                  setUserUsedDragNDrop={setUserUsedDragNDrop}
                  user={user}
                  type="include"
                />

                <CompanyFiltersHeader
                  setFilters={setCompanyFilters}
                  deleteFilter={deleteFilter}
                  filters={excludedCompanyFilters}
                  accordionIndex={accordionIndex}
                  userUsedDragNDrop={userUsedDragNDrop}
                  setUserUsedDragNDrop={setUserUsedDragNDrop}
                  user={user}
                  type="exclude"
                />
              </AccordionHeaderWrapper>
              <Box py={2}>
                <TruebaseTooltip
                  label={accordionIndex === 0 ? '' : 'Reveal company filters'}
                >
                  <AccordionButton
                    p={1}
                    borderRadius="4px"
                    sx={{
                      _hover: { bg: 'transparent' },
                      svg: { fill: 'trueDim' },
                    }}
                    onClick={() => trySetAccordionIndex(2)}
                  >
                    <FilterSearchDropdownAccordionItem />
                  </AccordionButton>
                </TruebaseTooltip>
              </Box>
            </Box>
            <AccordionPanel
              paddingInlineEnd={0}
              paddingInlineStart={0}
              bg="white"
              pt={0}
              pb={0}
              pl="16px"
            >
              <CompanyFilterAccordion
                parentFilter={companyFilters}
                handleFilterSelect={handleSetCompanyFilter}
                companyFilterIds={companyFilterIds}
                isCompanyFilterSelected={isCompanyFilterSelected}
              />
            </AccordionPanel>
          </TruebaseAccordionItem>
          <TruebaseAccordionItem pos="relative">
            <Dot />
            <DashedDivider top="0" height="24px" />
            <Box
              display="flex"
              alignItems="center"
              bg="white"
              pr="16px"
              pl="32px"
              _hover={
                accordionIndex === 1
                  ? {}
                  : {
                      '.filters-header': { background: 'truePreselect' },
                      '.filters-opacity': {
                        backgroundImage:
                          'linear-gradient(to bottom, rgba(255,255,255,0.2), truePreselect)',
                      },
                      background: 'truePreselect',
                    }
              }
              gap="4px"
            >
              <AccordionHeaderWrapper
                onClick={() => trySetAccordionIndex(1)}
                cursor="pointer"
                maxHeight={accordionIndex !== 1 ? '120px' : 'none'}
                p="unset"
                className="filters-header"
              >
                <Text
                  fontSize="13px"
                  lineHeight="15px"
                  color="trueExplain"
                  pt="17px"
                  w="100%"
                >
                  Leads Filters
                </Text>

                <LeadFiltersHeader
                  accordionIndex={accordionIndex}
                  deleteFilter={handleDeleteLeadFilter}
                  filters={includedLeadFilters}
                  setFilters={setLeadFilters}
                  userUsedDragNDrop={userUsedDragNDrop}
                  setUserUsedDragNDrop={setUserUsedDragNDrop}
                  user={user}
                  type="include"
                />

                <LeadFiltersHeader
                  accordionIndex={accordionIndex}
                  deleteFilter={handleDeleteLeadFilter}
                  filters={excludedLeadFilters}
                  setFilters={setLeadFilters}
                  userUsedDragNDrop={userUsedDragNDrop}
                  setUserUsedDragNDrop={setUserUsedDragNDrop}
                  user={user}
                  type="exclude"
                />
              </AccordionHeaderWrapper>
              <Box py={2}>
                <TruebaseTooltip
                  label={accordionIndex > 0 ? '' : 'Reveal lead filters'}
                >
                  <AccordionButton
                    p={1}
                    borderRadius="4px"
                    sx={{
                      _hover: { bg: 'transparent' },
                      svg: { fill: 'trueDim' },
                    }}
                    onClick={() => trySetAccordionIndex(1)}
                  >
                    <LeadFilterAccordionItem />
                  </AccordionButton>
                </TruebaseTooltip>
              </Box>
            </Box>
            <AccordionPanel bg="white" opacity={1} p={0} pl="16px">
              <LeadFilterAccordion
                leadFilters={leadFilters}
                handleFilterSelect={handleSetLeadFilter}
                isLeadFilterSelected={isLeadFilterSelected}
              />
            </AccordionPanel>
          </TruebaseAccordionItem>
          <TruebaseAccordionItem>
            <Flex
              pr={4}
              py={2}
              pl="32px"
              bg="white"
              alignItems="center"
              gap="16px"
            >
              <Text
                sx={{
                  fontSize: '12px',
                  color: 'trueSpace',
                  lineHeight: '14px',
                  fontWeight: '500',
                }}
                opacity={isLoadingState ? '0.75' : '1'}
              >
                <Box as="span">
                  {filterResultsCount.leads ? (
                    <AnimatedNumber
                      value={filterResultsCount.leads}
                      springOptions={{ duration: 1500, bounce: 0 }}
                      format
                    />
                  ) : (
                    0
                  )}
                  <Text as="span" color="trueDim">
                    {' '}
                    Leads •{' '}
                  </Text>
                  {filterResultsCount.accounts ? (
                    <AnimatedNumber
                      value={filterResultsCount.accounts}
                      springOptions={{ duration: 1500, bounce: 0 }}
                      format
                    />
                  ) : (
                    0
                  )}
                  <Text as="span" color="trueDim">
                    {' '}
                    Accounts
                  </Text>
                </Box>
              </Text>
              {isLoadingState && <Spinner size="sm" />}
              <Spacer />
              <Box>
                <TruebaseTooltip
                  label={!isResetDisabled && 'Reset to last saved'}
                >
                  <Button
                    size="sm"
                    isDisabled={isResetDisabled}
                    variant="ghost"
                    color="trueDim"
                    _disabled={{
                      color: 'trueDisabled',
                    }}
                    fontSize="13px"
                    lineHeight="15px"
                    fontWeight="medium"
                    onClick={resetFilters}
                    minW="80px"
                  >
                    Reset
                  </Button>
                </TruebaseTooltip>
              </Box>
              <Box>
                <Button
                  size="sm"
                  variant="ghost"
                  color="trueDim"
                  _disabled={{
                    color: 'trueDisabled',
                  }}
                  fontSize="13px"
                  lineHeight="15px"
                  fontWeight="medium"
                  isLoading={isEditLoading}
                  onClick={handleFilterSave}
                  isDisabled={!isFilterAccordionDirty}
                  minW="80px"
                >
                  {!isFilterAccordionDirty ? 'Saved' : 'Save'}
                </Button>
              </Box>
              <Box>
                <TruebaseTooltip
                  label={
                    leadFilters.length === 0
                      ? 'Add at least one lead filter'
                      : ''
                  }
                >
                  <Button
                    size="sm"
                    variant="truebaseOutline"
                    fontSize="13px"
                    lineHeight="15px"
                    fontWeight="medium"
                    isDisabled={leadFilters.length === 0}
                    onClick={() => handleSearchClick(true)}
                    minW="80px"
                  >
                    Test Search
                  </Button>
                </TruebaseTooltip>
              </Box>
            </Flex>
          </TruebaseAccordionItem>
        </Accordion>
      </Box>
      {children}
      {showMoreButton && (
        <Center my="48px">
          <Button
            h="48px"
            w="400px"
            variant="truebase"
            isLoading={isMoreButtonLoading}
            onClick={() => handleSearchClick(false)}
          >
            More
          </Button>
        </Center>
      )}
    </>
  );
}
