import { Box, Flex, Text, Spinner } from '@chakra-ui/react';

import { utils } from '../../utils';

import { ReactComponent as SequenceSubject } from '../../../assets/icons/sequence-subject.svg';
import { ReactComponent as Mail } from '../../../assets/icons/social_icons/mail/mono.svg';
import { ReactComponent as LinkedIn } from '../../../assets/icons/social_icons/linkedin/mono.svg';
import { ReactComponent as Phone } from '../../../assets/icons/social_icons/phone/mono.svg';
import { StreamingTextArea } from '../../Truebase/TruebaseStreamingTextArea';

const sequenceFormatIcon = {
  email: <Mail />,
  inlineEmail: <Mail />,
  linkedinConnect: <LinkedIn />,
  phoneDialerScript: <Phone />,
  subject: <SequenceSubject />,
};

const PersonalizedSequenceInput = ({
  subjectKey,
  sequenceFormat = 'email',
  generatingPath,
  title,
}) => {
  const path =
    subjectKey === 'subject'
      ? 'sequence.subject'
      : `sequence.messages.${subjectKey}`;

  const showSpinner = generatingPath === `${path}.content`;
  return (
    <>
      <Flex direction="column" pos="relative">
        <Flex alignItems="center" w="100%" p="1px" gap="8px">
          <Box
            as="span"
            sx={{
              svg: {
                w: '16px',
                h: '16px',
                path: { fill: 'trueDim' },
              },
            }}
          >
            {sequenceFormatIcon[sequenceFormat]}
          </Box>
          <Text
            sx={{
              fontSize: '12px',
              lineHeight: '14px',
              fontWeight: '500',
              color: 'trueDim',
            }}
          >
            {utils.formatSelectValue(title).label}
          </Text>
          {showSpinner && (
            <Flex>
              <Spinner size="sm" />
            </Flex>
          )}
        </Flex>
        <Flex direction="column" gap="16px" pos="relative">
          <TextArea isDisabled isGenerating={generatingPath} path={path} />
        </Flex>
      </Flex>
    </>
  );
};

export default PersonalizedSequenceInput;

const TextArea = ({ isGenerating, isDisabled, path, isRequired }) => {
  return (
    <StreamingTextArea
      sx={{
        fontSize: '12px',
        lineHeight: '16px',
      }}
      _placeholder={{
        fontSize: '12px',
        lineHeight: '16px',
      }}
      name={path}
      isRequired={isRequired}
      isDisabled={isGenerating || isDisabled}
      bg="trueBg02"
    />
  );
};
