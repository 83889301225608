import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { ReactComponent as TruebaseOverLight } from '../../assets/icons/logo-over-light.svg';
import Footer from '../../common/Footer';

export default function LoginLayout({
  title,
  children,
  logoContainerProps,
  flexContainerProps,
  titleProps,
}) {
  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'space-between',
          left: '50%',
          alignItems: 'center',
          transform: 'translateX(-50%)',
        }}
        p="90px 24px 0 24px"
        {...logoContainerProps}
      >
        <TruebaseOverLight />
      </Box>
      <Flex
        sx={{
          bgColor: '#f5f9fa',
          minH: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
          pb: '70px',
        }}
        pt="203px"
        {...flexContainerProps}
      >
        <Flex flexDir="column" minW="504px" pb="70px">
          <Text
            sx={{
              fontWeight: '700',
              fontSize: '40px',
              lineHeight: 'normal',
              textAlign: 'center',
              color: 'trueSpace',
            }}
            mb="16px"
            {...titleProps}
          >
            {title}
          </Text>
          {children}
        </Flex>
        <Footer />
      </Flex>
    </>
  );
}
