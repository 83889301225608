import React from 'react';
import { Box } from '@chakra-ui/react';
import { chakraComponents, Select } from 'chakra-react-select';
import { webkitScrollbarConfig } from './constants/webkitScrollbar';
import { ReactComponent as TooltipArrow } from '../assets/tooltip arrow.svg';

const TeamSelect = ({ selectedOption, selectOptions, onChange }) => {
  return (
    <Select
      placeholder="Select Team"
      size="lg"
      chakraStyles={{
        control: (baseStyle, state) => ({
          ...baseStyle,
          borderColor: state.isFocused ? 'trueLink' : 'trueLight',
          boxShadow: state.isFocused
            ? '0px 0px 10px rgba(2, 128, 245, 0.25) !important'
            : 'none',
          borderRadius: '4px',
          minHeight: '40px',
          maxHeight: '40px',
          width: '100%',
          cursor: 'pointer',
          bg: 'white',
        }),
        valueContainer: (baseStyle) => ({
          ...baseStyle,
          fontSize: '12px',
          color: 'trueSpace',
          lineHeight: '14px',
          fontWeight: '500',
        }),
        singleValue: (baseStyle) => ({
          ...baseStyle,
          color: 'trueSpace',
          fontSize: '12px',
          lineHeight: '14px',
          svg: {
            display: 'none',
          },
        }),
        dropdownIndicator: () => ({
          padding: '8px 16px',
          color: 'trueDim',
        }),
        menuList: (baseStyle) => ({
          ...baseStyle,
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          overflowY: 'overlay',
          p: 0,
          borderRadius: 0,
          boxShadow: 'none',
          maxH: '200px',
          ...webkitScrollbarConfig(),
        }),
        menu: (baseStyle) => ({
          ...baseStyle,
          mt: 0,
          overflow: 'hidden',
          zIndex: '999',
        }),
        option: (baseStyle, { isSelected }) => ({
          ...baseStyle,
          h: '40px',
          backgroundColor: 'white',
          p: '8px 16px',
          color: 'trueSpace',
          fontSize: '12px',
          lineHeight: '14px',
          fontWeight: '500',
          svg: {
            color: 'trueDim',
          },
          '&:hover': {
            backgroundColor: '#c3e6fa',
            svg: {
              color: 'trueLink',
            },
          },
          ...(isSelected && {
            color: 'trueSpace !important',
          }),
        }),
        placeholder: (baseStyle) => ({
          ...baseStyle,
          color: 'trueMedium',
          fontSize: '12px',
          lineHeight: '14px',
        }),
      }}
      defaultValue={{
        value: selectedOption?.value,
        label: selectedOption?.label,
      }}
      value={{
        value: selectedOption?.value,
        label: selectedOption?.label,
      }}
      selectedOptionColorScheme="white"
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: (props) => (
          <chakraComponents.DropdownIndicator {...props}>
            <TooltipArrow />
          </chakraComponents.DropdownIndicator>
        ),
        MenuList: (props) => (
          <>
            <chakraComponents.MenuList {...props}>
              <Box>{props.children}</Box>
            </chakraComponents.MenuList>
          </>
        ),
      }}
      onChange={onChange}
      options={selectOptions}
      blurInputOnSelect
    />
  );
};

export default TeamSelect;
