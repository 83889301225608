import { camelCase } from 'lodash';

export const valueToTag = (propertyName) => {
  const valueTagObj = {
    decisionMaker: 'DM',
    influencer: 'I',
    economicBuyer: 'EB',
    recommender: 'R',
    endUser: 'EU',
    include: 'IN',
    exclude: 'EX',
  };
  return valueTagObj[propertyName] || propertyName.charAt(0).toUpperCase();
};

export const defaultRoles = [
  { value: 'decisionMaker', label: 'Decision Maker' },
  { value: 'influencer', label: 'Influencer' },
  { value: 'economicBuyer', label: 'Economic Buyer' },
  { value: 'recommender', label: 'Recommender' },
  { value: 'endUser', label: 'End User' },
];

export const getAllRolesSelectOptions = (customRoles = []) => {
  const formattedRoles = customRoles.map(({ name }) => ({
    value: camelCase(name),
    label: name,
  }));
  return [...defaultRoles, ...formattedRoles];
};

export const getParentFromPath = (path) => {
  const splitPath = path.split('|');
  return splitPath[0];
};
export const getSubCategoryFromPath = (path) => {
  const splitPath = path.split('|');
  return splitPath[1];
};

export const roleKeyMap = {
  decisionMaker: 'Decision Maker',
  influencer: 'Influencer',
  economicBuyer: 'Economic Buyer',
  recommender: 'Recommender',
  endUser: 'End User',
};

export const formatRequestObj = ({
  lead,
  account,
  status,
  updatedByFullname,
}) => {
  return {
    leadId: lead._id,
    personId: lead.personId,
    // personScore: lead.score,
    status: status,
    // customerPersonRoleKeys: lead.customerPersonRoleKeys,
    companyId: account.companyId || account._id,
    // companyScore: account.score,
    updatedByFullname,
  };
};

export class NotificationQueue {
  constructor() {
    this.queue = [];
    this.isProcessing = false;
  }

  enqueue(callback) {
    return new Promise((resolve, reject) => {
      this.queue.push({ callback, resolve, reject });
      this.processQueue();
    });
  }

  async processQueue() {
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;

    while (this.queue.length > 0) {
      const { callback, resolve, reject } = this.queue.shift();

      try {
        await callback();
        resolve();
      } catch (error) {
        reject(error);
      }
    }

    this.isProcessing = false;
  }
}
