import { useMemo, useState } from 'react';
import { Box, Center, Button } from '@chakra-ui/react';
import { every } from 'lodash';
import { useSelector } from 'react-redux';
import { useLazyLoadMoreLeadsQuery } from '../../../redux/services/accounts';
import LeadsContainer from './LeadsContainer';
import LeadRowSkeleton from '../components/LeadRowSkeleton';
import { TruebaseTooltip } from '../../TruebaseTooltip';
import TabEmptyState from '../components/TabEmptyState';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { getSelectedIcp } from '~/v2/redux/slices/icpSlice';
import { getSelectedTeam } from '~/v2/redux/slices/teamsSlice';

const LeadsTab = ({
  readonly,
  account,
  leadsResponse,
  showHitsAndMisses,
  companyFilters,
}) => {
  const { handleAsyncError } = useErrorHandler();
  const selectedIcp = useSelector(getSelectedIcp);
  const selectedTeam = useSelector(getSelectedTeam);

  const [outDatedLeads, setOutDatedLeads] = useState(new Set());

  const [startLeadMatcher, { isLoading, isFetching }] =
    useLazyLoadMoreLeadsQuery();

  const buttonIsDisabled = useMemo(() => {
    if (!account.companyId) return true;
    if (account.isLoading) return true;
    if (isLoading) return true;
    if (isFetching) return true;
    if (leadsResponse.isLoading) return true;
    if (leadsResponse?.data?.result?.matches) {
      return !every(
        leadsResponse?.data?.result?.matches,
        (lead) =>
          typeof lead.steps?.search?.enqueuedAt !== 'undefined' ||
          Object.keys(selectedTeam?.excludedPersons || {}).includes(
            lead.personId
          )
      );
    }
    if (account.leads) {
      return !every(
        account.leads,
        (lead) =>
          typeof lead.steps?.search?.enqueuedAt !== 'undefined' ||
          Object.keys(selectedTeam?.excludedPersons || {}).includes(
            lead.personId
          )
      );
    }
    return false;
  }, [
    account.companyId,
    account.isLoading,
    account.leads,
    isLoading,
    isFetching,
    leadsResponse.isLoading,
    leadsResponse?.data?.result?.matches,
    selectedTeam?.excludedPersons,
  ]);
  const leads =
    leadsResponse.isUninitialized || leadsResponse.isLoading
      ? account.leads
      : leadsResponse?.data?.result?.matches;

  return (
    <>
      {readonly &&
        !leadsResponse.isLoading &&
        !leadsResponse.isUninitialized &&
        leadsResponse?.data?.result?.matches.length === 0 && (
          <TabEmptyState tabName="leads" p="24px 0 16px 0" />
        )}
      <Box gap={8} display="flex" flexDirection="column" maxHeight="600px">
        <LeadsContainer
          readonly={readonly}
          account={account}
          leads={leads}
          showHitsAndMisses={showHitsAndMisses}
          companyFilters={companyFilters}
          outDatedLeads={outDatedLeads}
          setOutDatedLeads={setOutDatedLeads}
        />
      </Box>
      {(leadsResponse?.data?.isLoading || leadsResponse.isLoading) && (
        <>
          {Array.from({
            length: 1,
          }).map((_, index) => (
            <LeadRowSkeleton hasClouds={false} key={index} />
          ))}
        </>
      )}
    </>
  );
};

export default LeadsTab;
