import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { every } from 'lodash';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Center,
  Flex,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { useLazyLoadMoreLeadsQuery } from '~/v2/redux/services/accounts';
import { CloseIcon } from '~/v2/assets/CloseIcon';
import { ReactComponent as FlagIcon } from '../../../assets/icons/flag/flag-1.svg';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { getSelectedIcp } from '~/v2/redux/slices/icpSlice';

const LeadOutdatedCard = ({ lead, account, removeLeadFromUI }) => {
  const selectedIcp = useSelector(getSelectedIcp);
  const { handleAsyncError } = useErrorHandler();

  const [startLeadMatcher, { isLoading, isFetching }] =
    useLazyLoadMoreLeadsQuery();
  const buttonIsDisabled = useMemo(() => {
    if (!account.companyId) return true;
    if (account.isLoading) return true;
    if (isLoading) return true;
    if (isFetching) return true;

    if (account.leads) {
      return !every(
        account.leads,
        (lead) =>
          typeof lead.status !== 'undefined' &&
          (lead.status !== 'suggested' || lead.contactOutdated)
      );
    }
    return false;
  }, [
    account.isLoading,
    isLoading,
    isFetching,
    account.leads,
    account.companyId,
  ]);

  return (
    <Card
      borderRadius="4px"
      background="white"
      borderWidth="1px"
      borderColor="trueLight"
      boxShadow="none"
    >
      <CardBody p="16px 24px">
        <Flex direction="row" alignItems="center" gap="16px">
          <Center
            borderRadius="50%"
            bgColor="trueWarning"
            width="48px"
            height="48px"
          >
            <FlagIcon width="34px" height="34px" color="white" />
          </Center>
          <Text
            color="trueSpace"
            fontSize="14px"
            fontWeight="500"
            lineHeight="16px"
          >
            {`${lead.name} ${
              lead.outdatedReason === 'changedJob'
                ? `no longer works at ${account.name}`
                : `changed their LinkedIn handle recently`
            }. Your account won't be billed.`}
          </Text>
          <Center
            sx={{
              flexGrow: 1,
              justifyContent: 'flex-end',
            }}
          >
            <IconButton
              h="32px"
              w="32px"
              onClick={removeLeadFromUI}
              variant="truebaseIcon"
            >
              <CloseIcon />
            </IconButton>
          </Center>
        </Flex>
      </CardBody>
      <CardFooter p="16px 24px">
        <Button
          margin="auto"
          w="120px"
          h="32px"
          variant="truebaseOutline"
          isDisabled={buttonIsDisabled}
          onClick={handleAsyncError(async () => {
            await startLeadMatcher({
              icpId: selectedIcp._id,
              accountId: account.companyId,
            });
          })}
        >
          More
        </Button>
      </CardFooter>
    </Card>
  );
};
export default LeadOutdatedCard;
