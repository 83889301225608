import { Box } from '@chakra-ui/react';
import { capitalize, every } from 'lodash';
import React from 'react';
import { matchScoreExplanations } from '~/v2/common/constants/explanations';
import { TruebaseTooltip } from '../../TruebaseTooltip';
import EmptyMatchScore from './EmptyMatchScore';
import ProgressBar from './ProgressBar';

export default function MatchScoresPanel({ subScores = [] }) {
  const shouldShowEmptyMatchScore = every(
    subScores,
    (score) => score.scoreValue === 0
  );
  return shouldShowEmptyMatchScore ? (
    <EmptyMatchScore />
  ) : (
    <Box
      margin="auto 16px"
      display="flex"
      minHeight="248px"
      alignItems="flex-end"
      justifyContent="space-between"
      gap={5}
    >
      {subScores.map((score) => {
        const title = capitalize(score.topic.split('-')[1]);
        return (
          <Box
            display="flex"
            flexDirection="column"
            textAlign="center"
            maxWidth="200px"
            pb={4}
            sx={{
              h2: {
                margin: 0,
                fontWeight: 500,
                fontSize: '13px',
                lineHeight: '15px',
              },
              h3: {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '158px',
                fontWeight: 400,
                fontSize: '13px',
                color: 'trueMedium',
              },
            }}
            key={`score-${title}`}
          >
            <TruebaseTooltip
              placement="right"
              size="lg"
              hasArrow
              gutter={-50}
              label={
                <>
                  {title.toUpperCase()}
                  <br />
                  {matchScoreExplanations[title]?.desc}
                </>
              }
            >
              <Box>
                <ProgressBar value={score.scoreValue * 100} />
              </Box>
            </TruebaseTooltip>
            <h2 key={score.topic}>{title}</h2>
            <h3>{matchScoreExplanations[title]?.desc}</h3>
          </Box>
        );
      })}
    </Box>
  );
}
