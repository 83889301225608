import { useEffect, useMemo } from 'react';
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react';
import { get } from 'lodash';
import { FormProvider } from 'react-hook-form';
import TruebaseChatContainer from '../../TruebaseChatContainer';
import TruebaseCenteredClouds from '../../Truebase/TruebaseCenteredClouds';
import useStreamingForm from '../../../hooks/useStreamingForm';
import TabEmptyState from '../components/TabEmptyState';
import { useQualificationContext } from '~/v2/features/filters/components/useQualificationContext';

export default function QualifyTab({ readonly, account }) {
  const toast = useToast();

  const { qualificationQuestions } = useQualificationContext();

  const defaultVals = useMemo(() => {
    return {
      ...qualificationQuestions?.reduce(
        (acc, cv) => ({
          ...acc,
          [cv.id]: {
            content: get(account.qualificationAnswers, `${cv.id}.content`, ''),
            label: cv.content,
          },
        }),
        {}
      ),
    };
  }, [account.qualificationAnswers, qualificationQuestions]);

  const {
    methods: accountScoringMethods,
    isGenerating: isAccountScoringGenerating,
    isLoading: isAccountScoringLoading,
    setIsGenerating: setIsAccountScoringGenerating,
    setIsLoading: setIsAccountScoringLoading,
  } = useStreamingForm({
    localIdentifier: account?.companyId,
    failedListener:
      () =>
      ({ identifier }) => {
        if (identifier !== account?.companyId) return;
        if (isAccountScoringGenerating) setIsAccountScoringGenerating(false);
        if (isAccountScoringLoading) setIsAccountScoringLoading(false);
        if (!toast.isActive(`accountScoring-failed-${identifier}`)) {
          toast({
            id: `accountScoring-failed-${identifier}`,
            title: 'Error',
            description: 'Something went wrong, please try again later',
            status: 'error',
            duration: 5000,
            isClosable: false,
          });
        }
      },
    socketIdentifiers: {
      inProgress: 'accountScoring-inProgress',
      complete: 'accountScoring-completed',
      failed: 'accountScoring-failed',
    },
    shouldResetOnInProgress: () => {
      reset({
        ...qualificationQuestions?.reduce(
          (acc, cv) => ({
            ...acc,
            [cv.id]: {
              content: '',
              label: cv.content,
            },
          }),
          {}
        ),
      });
    },
  });

  const { register, reset, watch } = accountScoringMethods;
  const vals = watch();

  useEffect(() => {
    reset({ ...defaultVals });
  }, [defaultVals, reset]);

  return (
    <>
      {readonly && !account.qualificationAnswers ? (
        <TabEmptyState tabName="score" pb="16px" />
      ) : (
        <FormProvider {...accountScoringMethods}>
          <Flex flexDir="column" gap="16px" pos="relative">
            {Object.keys(vals).length === 0 ? (
              <TruebaseChatContainer position="relative" minH="120px">
                <TruebaseCenteredClouds text="Qualify this account based on your ICP qualification questions"></TruebaseCenteredClouds>
              </TruebaseChatContainer>
            ) : (
              <Flex flexDir="column" gap="16px" pos="relative">
                {qualificationQuestions.map(({ id, content }) => {
                  return (
                    <FormControl variant="truebase" key={id}>
                      <FormLabel>{content}</FormLabel>
                      <Input
                        variant="truebaseFakeDisabled"
                        isDisabled
                        {...register(`${id}.content`)}
                      ></Input>
                    </FormControl>
                  );
                })}
              </Flex>
            )}
          </Flex>
        </FormProvider>
      )}
    </>
  );
}
