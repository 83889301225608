import {
  Box,
  Card,
  CardBody,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactComponent as InfoMono } from '../../../assets/icons/social_icons/info/mono.svg';
import { utils } from '../../../common/utils';
import { TruebaseTooltip } from '~/v2/common/TruebaseTooltip';
import { companyCategoryIconMap, leadCategoryIconMap } from './filterIcons';

const CompanyFilterListItem = ({
  subCategory,
  publicName,
  description,
  link,
  profilesCount,
  path,
  isSelected,
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  return (
    <>
      <Flex gap="4px" alignItems="center">
        <Box w="24px" h="24px" color={isSelected ? 'trueDisabled' : 'trueDim'}>
          {companyCategoryIconMap?.[path?.split('|')?.[0]]}
        </Box>
        <Text fontWeight="400">
          {subCategory}
          {': '}
        </Text>
        <Text fontWeight="500">{publicName}</Text>
      </Flex>
      <Popover
        isLazy
        placement="bottom"
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        eventListeners={{
          scroll: true,
        }}
      >
        <PopoverTrigger>
          <IconButton
            className="show-on-hover"
            w="24px"
            h="24px"
            minW={0}
            variant="ghost"
            _hover={{}}
            _active={{}}
            onClick={(e) => {
              if (!description) return;
              e.stopPropagation();
              onToggle();
            }}
            sx={{ svg: { fill: 'trueDim', w: '16px', h: '16px' } }}
          >
            <InfoMono />
          </IconButton>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            w="100%"
            maxW="560px"
            onClick={(e) => e.stopPropagation()}
          >
            <PopoverArrow bgColor="trueSpace" />
            <PopoverBody
              zIndex={1000}
              borderRadius="4px"
              fontSize="13px"
              lineHeight="16px"
              fontWeight="500"
              bgColor="trueSpace"
              color="white"
              boxShadow="0px 0px 10px rgba(2, 128, 245, 0.25)"
              p="12px 10px 14px 16px"
            >
              <>
                <Text
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {description}
                </Text>
                {link && (
                  <Box mt="8px">
                    <Text
                      color="trueLink"
                      textDecoration="underline"
                      as="a"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={utils.decodeURIComponentRecursively(link)}
                    >
                      Read more
                    </Text>
                  </Box>
                )}
              </>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
      <Spacer />
      <Flex alignItems="center" gap="16px">
        <TruebaseTooltip label="Count of companies associated with this filter">
          <Box>
            {!isNaN(profilesCount) && (
              <Text fontWeight="400" color="trueExplain" textAlign="right">
                {utils.formatNumber(profilesCount)}
              </Text>
            )}
          </Box>
        </TruebaseTooltip>
      </Flex>
    </>
  );
};

const LeadFilterListItem = ({
  publicName,
  subCategory,
  link,
  description, // description,link,profilesCount is left for future use
  path,
  isSelected,
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  return (
    <>
      <Flex gap="4px" alignItems="center">
        <Box w="24px" h="24px" color={isSelected ? 'trueDisabled' : 'trueDim'}>
          {leadCategoryIconMap?.[path?.split('|')?.[0]]}
        </Box>
        <Text fontWeight="400">
          {subCategory}
          {': '}
        </Text>
        <Text fontWeight="500">{publicName}</Text>
      </Flex>

      <Popover
        isLazy
        placement="bottom"
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        eventListeners={{
          scroll: true,
        }}
      >
        <PopoverTrigger>
          <IconButton
            className="show-on-hover"
            w="24px"
            h="24px"
            minW={0}
            variant="ghost"
            _hover={{}}
            _active={{}}
            onClick={(e) => {
              if (!description) return;
              e.stopPropagation();
              onToggle();
            }}
            sx={{ svg: { fill: 'trueDim', w: '16px', h: '16px' } }}
          >
            <InfoMono />
          </IconButton>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            w="100%"
            maxW="560px"
            onClick={(e) => e.stopPropagation()}
          >
            <PopoverArrow bgColor="trueSpace" />
            <PopoverBody
              zIndex={1000}
              borderRadius="4px"
              fontSize="13px"
              lineHeight="16px"
              fontWeight="500"
              bgColor="trueSpace"
              color="white"
              boxShadow="0px 0px 10px rgba(2, 128, 245, 0.25)"
              p="12px 10px 14px 16px"
            >
              <>
                <Text
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {description}
                </Text>
                {link && (
                  <Box mt="8px">
                    <Text
                      color="trueLink"
                      textDecoration="underline"
                      as="a"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={utils.decodeURIComponentRecursively(link)}
                    >
                      Read more
                    </Text>
                  </Box>
                )}
              </>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  );
};

export const FilterListItem = ({
  isLoaderRow,
  isCategoryItem,
  onClick,
  hasNextPage,
  filter,
  isSelected,
  type,
  ...rest
}) => {
  const filterSlices = filter?._id.split('|');
  const isNotSelectable = isCategoryItem || isSelected;
  return !filter ? (
    <></>
  ) : (
    <Card
      sx={{
        boxShadow: 'none',
        maxW: '742px',
        borderRadius: '4px',
        borderWidth: '1px',
        borderColor: 'trueLight',
        cursor: isNotSelectable ? 'inherit' : 'pointer',
        fontSize: '13px',
        lineHeight: '16px',
        color: isSelected ? 'trueDisabled' : 'trueSpace',
        textTransform: isCategoryItem ? 'capitalize' : 'none',
        userSelect: 'none',
        '.show-on-hover': {
          visibility: 'hidden',
        },
        ':hover': {
          '.show-on-hover': {
            visibility: filter.description ? 'visible' : 'hidden',
          },
        },
      }}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick={isNotSelectable ? () => {} : onClick}
      {...rest}
    >
      <CardBody
        sx={{
          py: '12px',
          px: '12px',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 2,
        }}
      >
        {isLoaderRow
          ? hasNextPage && 'Loading more...'
          : filter && (
              <>
                {type === 'company' ? (
                  <CompanyFilterListItem {...filter} isSelected={isSelected} />
                ) : (
                  <LeadFilterListItem
                    filterSlices={filterSlices}
                    {...filter}
                    isSelected={isSelected}
                  />
                )}
              </>
            )}
      </CardBody>
    </Card>
  );
};
