import {
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Link,
  Text,
} from '@chakra-ui/react';
import { LEAD_IMPORT_SAMPLE_URL } from '~/v2/common/utils';
import { ReactComponent as DownloadIcon } from '~/v2/assets/icons/download.svg';
import { ReactComponent as ImportIcon } from '~/v2/assets/icons/import.svg';
import { ImportPanel } from '~/v2/features/icps/components/ImportPanel';
import { AutopilotStepCard } from '../AutopilotStepCard';

export const AutopilotImportStep = ({ panel, setPanel }) => {
  return (
    <AutopilotStepCard
      title={
        <Text fontWeight="500" fontSize="14px" lineHeight="16px">
          Import *
        </Text>
      }
      subtitle="Import your list of leads and enrich further"
      icon={ImportIcon}
      iconColor={
        panel.error || !panel?.acceptedFiles?.length ? 'trueCorral' : 'trueDim'
      }
      content={
        <Flex flexDir="column" p="16px 24px 16px 0" w="100%">
          <Flex alignItems="center" mb="4px" justifyContent="space-between">
            <Text
              sx={{
                fontWeight: 500,
                fontSize: '13px',
                lineHeight: '16px',
              }}
            >
              Required column(s) in order of accuracy (at least 1 of 5):
            </Text>
            <ButtonGroup
              as={Link}
              href={LEAD_IMPORT_SAMPLE_URL}
              target="_blank"
              rel="noopener noreferrer"
              alignItems="center"
              gap="4px"
              isAttached
              variant="link"
            >
              <Button
                sx={{
                  fontSize: '13px',
                  lineHeight: '14px',
                  color: 'trueLink',
                  fontWeight: 500,
                }}
              >
                Get Sample
              </Button>
              <IconButton
                h="16px"
                w="16px"
                minW="16px"
                color="trueLink"
                icon={<DownloadIcon />}
              />
            </ButtonGroup>
          </Flex>

          <Flex
            flexDir="column"
            fontWeight="400"
            fontSize="12px"
            lineHeight="14px"
            color="trueDim"
            mb="24px"
          >
            <Text>1. Lead Linkedin URL</Text>
            <Text>2. Lead Personal Email</Text>
            <Text>3. Lead Work Email</Text>
            <Text>4. Lead First Name, Lead Last Name, Account Domain 1</Text>
            <Text>5. Lead First Name, Lead Last Name, Account Name</Text>
            <Text>
              6. Lead First Name, Lead Last Name, Lead Location, Account Name
            </Text>
          </Flex>

          <ImportPanel panel={panel} setPanel={setPanel} />
        </Flex>
      }
    />
  );
};
