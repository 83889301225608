import { Box, Spacer } from '@chakra-ui/react';
import { get, isUndefined } from 'lodash';
import { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { useAuthenticate } from '~/v2/common/requiresAuth.jsx';
import { getSelectedTeam } from '~/v2/redux/slices/teamsSlice';
import { useFilterContext } from '~/v2/features/filters/components/useFilterContext.js';
import { defaultQualificationQuestions, utils } from '~/v2/common/utils.js';
import Layout from '~/v2/common/Layout.jsx';
import NavigationBlocker from '~/v2/features/filters/components/NavigationBlocker.jsx';
import { FilterProvider } from '~/v2/features/filters/components/FilterProvider.jsx';
import FiltersAccordion from '~/v2/features/filters/components/FiltersAccordion';
import { useLazySearchQuery } from '~/v2/redux/services/accounts';
import ICPSelect from '~/v2/common/ICPSelect';
import TestResults from '../TestResults';
import { SelectAlertDialog } from '../SelectAlertDialog';
import {
  useAddIcpMutation,
  useDeleteIcpMutation,
  useDuplicateIcpMutation,
  useEditIcpMutation,
  useGetIcpsQuery,
} from '~/v2/redux/services/icp';
import { getSelectedIcp, setSelectedIcp } from '~/v2/redux/slices/icpSlice';
import { QualificationProvider } from '../../../filters/components/QualificationProvider';
import { getSelectedAccountQualification } from '~/v2/redux/slices/accountQualificationSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { IsDirtyProvider } from '~/v2/contexts/providers/IsDirtyProvider';
import { useIsDirtyContext } from '~/v2/contexts/useIsDirtyContext';
import { getUser } from '~/v2/redux/slices/userSlice';

function ICPPage() {
  useAuthenticate();
  const { handleSyncError } = useErrorHandler();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectRef = useRef();

  const [showPrompt, setShowPrompt] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isPending, setIsPending] = useState(
    get(location, 'state.isPending', false)
  );

  const selectedTeam = useSelector(getSelectedTeam);
  const user = useSelector(getUser) || {};

  const { data: icps } = useGetIcpsQuery(
    { teamId: selectedTeam?._id },
    { skip: isUndefined(selectedTeam?._id), refetchOnMountOrArgChange: true }
  );

  const selectedIcp = useSelector(getSelectedIcp);
  const { isFilterAccordionDirty, setIsFilterAccordionDirty } =
    useIsDirtyContext();
  const { setTrainView, setCompanyFilters, setLeadFilters } =
    useFilterContext();
  const [addIcp, { isLoading: isCreateLoading }] = useAddIcpMutation();
  const [editIcp, { isLoading: isEditLoading }] = useEditIcpMutation();
  const [duplicateIcp, { isLoading: isDuplicateLoading }] =
    useDuplicateIcpMutation();
  const [deleteIcp] = useDeleteIcpMutation();
  const [getSearchResults, response] = useLazySearchQuery();

  const selectOptions = useMemo(() => {
    return icps?.map((icp) => ({
      value: icp._id,
      label: icp.name,
    }));
  }, [icps]);

  const icpSelectItem = useMemo(() => {
    if (!selectedIcp) return null;

    return {
      value: selectedIcp._id,
      label: selectedIcp.name,
    };
  }, [selectedIcp]);

  const handleChange = async (option) => {
    if (isFilterAccordionDirty) {
      setShowPrompt(true);
      setSelectedOption(option.value);
      return;
    }
    dispatch(setSelectedIcp(icps.find(({ _id }) => option.value === _id)));
    setIsPending(false);
    if (location?.state?.isPending) {
      delete location.state.isPending;
    }
  };

  const alertUser = handleSyncError((event) => {
    if (isFilterAccordionDirty) {
      event.preventDefault();
      event.returnValue = '';
    }
  });

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  });

  useEffect(() => {
    setTrainView('icp');
  }, [setTrainView]);

  useEffect(() => {
    setCompanyFilters(selectedIcp?.companyFilterStatements || []);
    setLeadFilters(selectedIcp?.personFilterStatements || []);
  }, [
    selectedIcp?.companyFilterStatements,
    selectedIcp?.personFilterStatements,
    setCompanyFilters,
    setLeadFilters,
  ]);

  return (
    <Layout
      title="ICP Definition"
      subtitle="Define, edit and save your ICP. You can create as many as you like"
      withBackBtn={location.state?.fromLauchAutopilotPage}
      onBackClick={() => {
        navigate('/autopilot', {
          state: { settings: location.state?.settings },
        });
      }}
      extraHeaderContent={
        <>
          <Spacer />
          <ICPSelect
            selectOptions={selectOptions}
            selectedOption={icpSelectItem}
            onChange={handleChange}
            type="icp"
            data={icps}
            currentSelected={selectedIcp}
            editFn={editIcp}
            isEditLoading={isEditLoading}
            duplicateFn={duplicateIcp}
            isDuplicateLoading={isDuplicateLoading}
            deleteFn={deleteIcp}
            createFn={addIcp}
            isCreateLoading={isCreateLoading}
            onDelete={(newSelectedItem) => {
              dispatch(setSelectedIcp(newSelectedItem));
              setIsPending(false);
            }}
            onCreate={() => {
              selectRef?.current.blur();
              setIsPending(true);
            }}
            selectRef={selectRef}
          />
        </>
      }
    >
      <Box pb="56px" pt={4} w="100%">
        <Helmet title={'Truebase | ICP'} defer={false} />
        <NavigationBlocker
          shouldBlock={isFilterAccordionDirty || isPending}
          onConfirm={async () => {
            if (isPending) {
              const oldIcp = selectedIcp;
              const newSelectedItem = icps.find(
                (item) => item._id !== oldIcp._id
              );
              dispatch(setSelectedIcp(newSelectedItem));
              await deleteIcp({
                _id: oldIcp._id,
                teamId: selectedTeam._id,
                userId: user.id,
              });
            }
          }}
        />
        <Box>
          <FiltersAccordion
            onSearch={getSearchResults}
            onFilterSave={editIcp}
            showMoreButton={
              !response?.data?.isStreamingAccounts &&
              response?.data?.result?.accounts?.length > 0 &&
              response?.originalArgs?.icpId === selectedIcp?._id
            }
            isMoreButtonLoading={
              !response?.data?.newAccounts || response.isFetching
            }
            isEditLoading={isEditLoading}
            setIsPending={setIsPending}
          >
            <TestResults response={response} page="icp" />
          </FiltersAccordion>
        </Box>
      </Box>
      <SelectAlertDialog
        showPrompt={showPrompt}
        setShowPrompt={setShowPrompt}
        onConfirm={() => {
          dispatch(
            setSelectedIcp(icps.find(({ _id }) => selectedOption === _id))
          );
          setIsFilterAccordionDirty(false);
          setShowPrompt(false);
        }}
      />
    </Layout>
  );
}

export const ICPPageWithContext = () => {
  const selectedIcp = useSelector(getSelectedIcp);
  const selectedAccountQualification = useSelector(
    getSelectedAccountQualification
  );

  return (
    <IsDirtyProvider>
      <FilterProvider
        initialCompanyFilters={selectedIcp?.companyFilterStatements || []}
        initialLeadFilters={selectedIcp?.personFilterStatements || []}
      >
        <QualificationProvider
          initialQualificationQuestions={
            utils.formatQualificationsFromServer(
              selectedAccountQualification?.qualificationQuestions
            ) || defaultQualificationQuestions
          }
        >
          <ICPPage />
        </QualificationProvider>
      </FilterProvider>
    </IsDirtyProvider>
  );
};
