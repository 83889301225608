import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './services/api';
import { filtersApi } from './services/filters';
import { teamsApi } from './services/teams';
import { accountsApi } from './services/accounts';
import { titlesApi } from './services/titles';
import { usersApi } from './services/user';
import teamsSlice from './slices/teamsSlice';
import userSlice from './slices/userSlice';
import { leadsApi } from './services/leads';
import statusSlice from './slices/statusSlice';
import { personApi } from './services/person';
import { companyApi } from './services/company';
import sequenceTemplateSlice from './slices/sequenceTemplateSlice';
import accountQualificationSlice from './slices/accountQualificationSlice';
import icpSlice from './slices/icpSlice';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    status: statusSlice,
    teams: teamsSlice,
    customUser: userSlice,
    icps: icpSlice,
    accountQualifications: accountQualificationSlice,
    sequenceTemplates: sequenceTemplateSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(filtersApi.middleware)
      .concat(accountsApi.middleware)
      .concat(usersApi.middleware)
      .concat(titlesApi.middleware)
      .concat(teamsApi.middleware)
      .concat(personApi.middleware)
      .concat(companyApi.middleware)
      .concat(leadsApi.middleware),
});
