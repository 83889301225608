import { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  IconButton,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { ReactComponent as InfoIcon } from '~/v2/assets/icons/info.svg';
import { ReactComponent as CloseIcon } from '~/v2/assets/icons/close/close-large.svg';

export const SelectAlertDialog = ({ showPrompt, setShowPrompt, onConfirm }) => {
  const cancelRef = useRef();

  return (
    <AlertDialog
      size="xl"
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      isOpen={showPrompt}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogBody p={4} paddingInlineStart={0}>
          <Flex alignItems="center" gap="16px">
            <Box sx={{ svg: { fill: 'trueWarning' } }}>
              <InfoIcon />
            </Box>
            <Text
              color="trueSpace"
              fontSize="14px"
              fontWeight={500}
              lineHeight="20px"
            >
              Do you want to switch ICP without saving your changes?
            </Text>
            <Spacer />
            <IconButton
              h="32px"
              w="32px"
              onClick={() => setShowPrompt(false)}
              variant="truebaseIcon"
            >
              <CloseIcon />
            </IconButton>
          </Flex>
        </AlertDialogBody>
        <AlertDialogFooter
          px="16px"
          py="8px"
          sx={{
            borderTopWidth: '1px',
            borderTopColor: 'trueLight',
          }}
        >
          <Flex justifyContent="flex-end" alignItems="center" w="100%">
            <Button
              variant="truebaseTextHighlight"
              ref={cancelRef}
              w="54px"
              h="32px"
              onClick={onConfirm}
            >
              Yes
            </Button>
          </Flex>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
