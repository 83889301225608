/* eslint-disable no-restricted-globals */
class Rest {
  static async post(query, body, nonProtected = false, files = []) {
    const request = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };

    if (files.length) {
      request.headers = {};
      const data = new FormData();
      data.append('file', files[0]);
      for (let key in body) {
        data.append(key, body[key]);
      }
      request.body = data;
    }

    if (!nonProtected) {
      const accessToken = localStorage.getItem('AccessToken') || '';
      request.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return fetch(`${process.env.REACT_APP_API_ENDPOINT}${query}`, request).then(
      async (e) => {
        const contentType = e.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') === -1)
          return;
        const requestId = e.headers.get('X-Request-Id');

        const result = await e.json();
        return { ...result, requestId };
      }
    );
  }

  static async patch(query, body, nonProtected = false) {
    const request = {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    if (!nonProtected) {
      const accessToken = localStorage.getItem('AccessToken') || '';
      request.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return fetch(`${process.env.REACT_APP_API_ENDPOINT}${query}`, request).then(
      async (e) => {
        const requestId = e.headers.get('X-Request-Id');

        const result = await e.json();
        return { ...result, requestId };
      }
    );
  }

  static async put(query, body, nonProtected = false) {
    const request = {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    if (!nonProtected) {
      const accessToken = localStorage.getItem('AccessToken') || '';
      request.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return fetch(`${process.env.REACT_APP_API_ENDPOINT}${query}`, request).then(
      async (e) => {
        const requestId = e.headers.get('X-Request-Id');

        const result = await e.json();
        return { ...result, requestId };
      }
    );
  }

  static async get(query, nonProtected = false) {
    const request = {};
    if (!nonProtected) {
      const accessToken = localStorage.getItem('AccessToken') || '';
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${accessToken}`);
      request.headers = headers;
    }
    return fetch(`${process.env.REACT_APP_API_ENDPOINT}${query}`, request)
      .then(async (e) => {
        const contentType = e.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') === -1)
          return;
        const requestId = e.headers.get('X-Request-Id');

        const result = await e.json();
        return { ...result, requestId };
      })
      .catch((e) => {
        throw e;
      });
  }

  static async delete(query, nonProtected = false) {
    const request = {
      method: 'DELETE',
    };

    if (!nonProtected) {
      const accessToken = localStorage.getItem('AccessToken') || '';
      if (!accessToken)
        return location.assign(process.env.PUBLIC_URL + '/login');
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${accessToken}`);
      request.headers = headers;
    }

    return fetch(`${process.env.REACT_APP_API_ENDPOINT}${query}`, request).then(
      async (e) => {
        const contentType = e.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') === -1)
          return;
        const requestId = e.headers.get('X-Request-Id');

        const result = await e.json();
        if (result.code.toString() !== '200' && query === 'user')
          return location.assign(process.env.PUBLIC_URL + '/login');
        return { ...result, requestId };
      }
    );
  }
}

export default Rest;
