import { useState, useMemo, useEffect } from 'react';
import {
  Accordion,
  AccordionPanel,
  AccordionItem,
  AccordionButton,
  Flex,
  Spacer,
  Text,
} from '@chakra-ui/react';
import AvatarSwitcher from '../components/AvatarSwitcher';
import { CompanyInfo } from '../components/CompanyInfo';
import { CompanySocialInfo } from '../components/CompanyInfo';
import AccountInLeadViewTabs from './AccountInLeadViewTabs';
import { socialProfilesOrder } from '~/utils';
import GeneratingStatus from '../components/GeneratingStatus';
import { useSelector } from 'react-redux';
import { getStatus } from '~/v2/redux/slices/statusSlice';
import { useFilterContext } from '~/v2/features/filters/components/useFilterContext';

const AccountTab = ({
  readonly,
  lead,
  account,
  showHitsAndMisses,
  companyFilters,
}) => {
  const { trainView } = useFilterContext();
  const isPersonalizationView = trainView === 'personalization';

  const socialProfiles = useMemo(() => {
    if (!lead.socialProfiles) return [];

    return lead.socialProfiles.slice().sort((a, b) => {
      return (
        socialProfilesOrder.indexOf(a.service) -
        socialProfilesOrder.indexOf(b.service)
      );
    });
  }, [lead.socialProfiles]);

  const [accordionOpen, setAccordionOpen] = useState(-1);
  const [accountTabIndex, setAccountTabIndex] = useState(0);

  const isGeneratingAccountScore = useSelector(getStatus)(
    account.companyId
  )?.find(({ type }) => type === 'accountScoring');

  useEffect(() => {
    if (isGeneratingAccountScore && accordionOpen === -1) setAccordionOpen(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGeneratingAccountScore, setAccordionOpen]);

  return (
    <>
      <Accordion index={accordionOpen}>
        <AccordionItem border={0}>
          <AccordionButton
            _hover={{}}
            _focus={{}}
            _active={{}}
            as="div"
            p={0}
            cursor="pointer"
            onClick={() => {
              isPersonalizationView
                ? {}
                : setAccordionOpen(accordionOpen === 0 ? -1 : 0);
            }}
          >
            <Flex flexDir="column" w="100%">
              <Flex flexDir="row" w="100%">
                <Flex alignItems="center" gap="16px" py="16px" w="100%">
                  <AvatarSwitcher
                    type="company"
                    src={account.avatar}
                    name={account.name}
                    domain={account.domains?.[0]}
                  />
                  <Flex flexDir="column" gap={1} w="100%">
                    <Flex alignItems="center" gap="8px" w="100%">
                      <CompanySocialInfo
                        socialProfiles={socialProfiles}
                        account={account}
                      />
                      <GeneratingStatus
                        account={{ ...account, leads: undefined }}
                        priority="accounts"
                      />
                    </Flex>
                    <CompanyInfo account={account} />
                  </Flex>
                  <Spacer />
                  {account?.qualificationScore !== undefined &&
                    account.status !== 'rejected' && (
                      <Text
                        sx={{
                          fontSize: '12px',
                          lineHeight: '14px',
                          fontWeight: 500,
                          color: 'trueExplain',
                          whiteSpace: 'pre',
                        }}
                      >
                        Score {(account.qualificationScore * 100).toFixed(1)}%
                      </Text>
                    )}
                </Flex>
              </Flex>
            </Flex>
          </AccordionButton>
          <AccordionPanel p={0}>
            <Flex ml="32px" pl="32px">
              <Flex pb={4} w="100%">
                <AccountInLeadViewTabs
                  readonly={readonly}
                  showHitsAndMisses={showHitsAndMisses}
                  companyFilters={companyFilters}
                  account={account}
                  lead={lead}
                  tabIndex={accountTabIndex}
                  setTabIndex={setAccountTabIndex}
                />
              </Flex>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default AccountTab;
