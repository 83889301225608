import { Box, Spacer } from '@chakra-ui/react';
import { get, isUndefined } from 'lodash';
import { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { getSelectedTeam } from '~/v2/redux/slices/teamsSlice';
import NavigationBlocker from '~/v2/features/filters/components/NavigationBlocker';
import Layout from '~/v2/common/Layout.jsx';
import { useAuthenticate } from '~/v2/common/requiresAuth.jsx';
import SequenceTemplateSettings from './components/SequenceTemplateSettings';
import { SelectAlertDialog } from '../SelectAlertDialog';
import ICPSelect from '~/v2/common/ICPSelect';
import {
  useAddSequenceTemplateMutation,
  useDeleteSequenceTemplateMutation,
  useDuplicateSequenceTemplateMutation,
  useEditSequenceTemplateMutation,
  useGetSequenceTemplatesQuery,
} from '~/v2/redux/services/sequenceTemplate';
import {
  getSelectedSequenceTemplate,
  setSelectedSequenceTemplate,
} from '~/v2/redux/slices/sequenceTemplateSlice';
import { Helmet } from 'react-helmet';
import { useIsDirtyContext } from '~/v2/contexts/useIsDirtyContext';
import { IsDirtyProvider } from '~/v2/contexts/providers/IsDirtyProvider';
import { getUser } from '~/v2/redux/slices/userSlice';

function SequenceTemplate() {
  useAuthenticate();
  const { handleSyncError } = useErrorHandler();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const selectRef = useRef();

  const team = useSelector(getSelectedTeam);
  const user = useSelector(getUser) || {};

  const { data: sequenceTemplates } = useGetSequenceTemplatesQuery(
    { teamId: team?._id },
    { skip: isUndefined(team?._id), refetchOnMountOrArgChange: true }
  );
  const selectedSequenceTemplate = useSelector(getSelectedSequenceTemplate);
  const [addSequenceTemplate, { isLoading: isSavingSequenceTemplate }] =
    useAddSequenceTemplateMutation();
  const [editSequenceTemplate, { isLoading: isEditLoading }] =
    useEditSequenceTemplateMutation();
  const [
    duplicateSequenceTemplate,
    { isLoading: isDuplicatingSequenceTemplate },
  ] = useDuplicateSequenceTemplateMutation();
  const [deleteSequenceTemplate] = useDeleteSequenceTemplateMutation();

  const { isSequenceTemplateDirty, setIsSequenceTemplateDirty } =
    useIsDirtyContext();

  const [showPrompt, setShowPrompt] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isPending, setIsPending] = useState(
    get(location, 'state.isPending', false)
  );

  const selectOptions = useMemo(() => {
    return sequenceTemplates?.map((st) => ({
      value: st._id,
      label: st.name,
    }));
  }, [sequenceTemplates]);

  const selectValue = useMemo(() => {
    if (!selectedSequenceTemplate) return null;

    return {
      value: selectedSequenceTemplate._id,
      label: selectedSequenceTemplate.name,
    };
  }, [selectedSequenceTemplate]);

  const handleChange = async (option) => {
    if (isSequenceTemplateDirty) {
      setShowPrompt(true);
      setSelectedOption(option.value);
      return;
    }
    dispatch(
      setSelectedSequenceTemplate(
        sequenceTemplates.find(({ _id }) => option.value === _id)
      )
    );
    setIsPending(false);
    if (location?.state?.isPending) {
      delete location.state.isPending;
    }
  };

  const alertUser = handleSyncError((event) => {
    if (isSequenceTemplateDirty) {
      event.preventDefault();
      event.returnValue = '';
    }
  });

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  });

  return (
    <Layout
      title="Email Sequence Templates"
      subtitle="Create one or more outreach templates"
      withBackBtn={location.state?.fromLauchAutopilotPage}
      onBackClick={() => {
        navigate('/autopilot', {
          state: { settings: location.state?.settings },
        });
      }}
      extraHeaderContent={
        <>
          <Spacer />
          <ICPSelect
            selectOptions={selectOptions}
            selectedOption={selectValue}
            onChange={handleChange}
            type="sequenceTemplate"
            data={sequenceTemplates}
            currentSelected={selectedSequenceTemplate}
            createFn={addSequenceTemplate}
            isCreateLoading={isSavingSequenceTemplate}
            editFn={editSequenceTemplate}
            isEditLoading={isEditLoading}
            duplicateFn={duplicateSequenceTemplate}
            isDuplicateLoading={isDuplicatingSequenceTemplate}
            deleteFn={deleteSequenceTemplate}
            onDelete={(newSelectedItem) => {
              dispatch(setSelectedSequenceTemplate(newSelectedItem));
              setIsPending(false);
            }}
            onCreate={() => {
              selectRef?.current.blur();
              setIsPending(true);
            }}
            selectRef={selectRef}
          />
        </>
      }
    >
      <Box pb="56px" pt={4} w="100%">
        <NavigationBlocker
          shouldBlock={isSequenceTemplateDirty || isPending}
          onConfirm={async () => {
            if (isPending) {
              const oldSequenceTemplate = selectedSequenceTemplate;
              const newSelectedItem = sequenceTemplates.find(
                (item) => item._id !== oldSequenceTemplate._id
              );
              dispatch(setSelectedSequenceTemplate(newSelectedItem));
              await deleteSequenceTemplate({
                _id: oldSequenceTemplate._id,
                teamId: team._id,
                userId: user.id,
              });
            }
          }}
        />
        <Helmet title={'Truebase | Email Sequence Templates'} defer={false} />
        <Box pt="24px">
          <SequenceTemplateSettings
            selectedSequenceTemplate={selectedSequenceTemplate}
            setIsPending={setIsPending}
          />
        </Box>
      </Box>
      <SelectAlertDialog
        onConfirm={() => {
          dispatch(
            setSelectedSequenceTemplate(
              sequenceTemplates.find(({ _id }) => selectedOption === _id)
            )
          );
          setIsSequenceTemplateDirty(false);
          setShowPrompt(false);
        }}
        showPrompt={showPrompt}
        setShowPrompt={setShowPrompt}
      />
    </Layout>
  );
}

export const SequenceTemplateWithContext = () => {
  return (
    <IsDirtyProvider>
      <SequenceTemplate />
    </IsDirtyProvider>
  );
};

export default SequenceTemplate;
