import { useEffect } from 'react';

// don't use an object as the dependency
const useScrollTop = (dependency) => {
  useEffect(() => {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }, [dependency]);
};

export default useScrollTop;
