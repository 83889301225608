import {
  Box,
  Divider,
  IconButton,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as InputClearIcon } from '../../../assets/icons/clear/clear-back.svg';
import { ReactComponent as InputSearchIcon } from '../../../assets/icons/search.svg';
import { webkitScrollbarConfig } from '../../../common/constants/webkitScrollbar';
import { TruebaseBadge } from '../../../common/TruebaseBadge';
import TruebaseComboBox from '../../../common/TruebaseComboBox';
import { utils } from '../../../common/utils';
import { useVirtualScrollWithSearchTerm } from '../../../hooks/useVirtualScrollWithSearchTerm';
import {
  useLazyGetFiltersQuery,
  useLazyGetStatsQuery,
} from '../../../redux/services/filters';
import FiltersEmptyState from './FiltersEmptyState';

import {
  FilterSearchDropdownWrapper,
  LeftListWrapper,
  LeftPanelWrapper,
  MainPanel,
  RightListWrapper,
  RightPanelWrapper,
  SearchInput,
  SearchInputWrapper,
  SelectWrapper,
  TwoPanelsWrapper,
} from './FilterComponents';
import { FilterListItem } from './FilterListItem';
import ListItem from './FilterCategoryItem';

export default function CompanyFilterAccordion({
  handleFilterSelect,
  isCompanyFilterSelected,
}) {
  const {
    parentRef,
    rowVirtualizer,
    data,
    search,
    setSearch,
    statsResponse,
    selectedStatId,
    setSelectedStatId,
    hasNextPage,
  } = useVirtualScrollWithSearchTerm(
    useLazyGetFiltersQuery,
    useLazyGetStatsQuery
  );

  const leftListWrapperRef = useRef();

  const [include, setInclude] = useState({
    value: 'include',
    label: 'Include',
  });
  const [leftWrapperScrollTop, setLeftWrapperScrollTop] = useState(false);

  const selectFilter = (filter) => {
    handleFilterSelect({
      ...filter,
      include: include.value === 'include',
    });
  };

  const isEmptyState = selectedStatId === 'all' && !search;

  useEffect(() => {
    const handleScroll = ({ target }) => {
      setLeftWrapperScrollTop(target.scrollTop);
    };

    const leftListWrapper = leftListWrapperRef?.current;

    leftListWrapper.addEventListener('scroll', handleScroll);

    return () => {
      leftListWrapper.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <FilterSearchDropdownWrapper>
      <MainPanel>
        <TwoPanelsWrapper>
          <LeftPanelWrapper>
            <SelectWrapper>
              <TruebaseComboBox
                value={include}
                onChange={(val) => setInclude(val)}
                formatOptionLabel={(option) => (
                  <Box display="flex" alignItems="center">
                    <Box w="32px">
                      <TruebaseBadge
                        variant={option.value === 'include' ? 'green' : 'red'}
                        width="100%"
                        w="24px"
                      >
                        {option.value === 'include' ? 'IN' : 'EX'}
                      </TruebaseBadge>
                    </Box>
                    <Text className="option-label">{option.label}</Text>
                  </Box>
                )}
                options={[
                  { value: 'include', label: 'Include' },
                  { value: 'exclude', label: 'Exclude' },
                ]}
              />
            </SelectWrapper>
            {leftWrapperScrollTop > 0 && (
              <Divider w="328px" color="trueLight" alignSelf="center" />
            )}
            <LeftListWrapper
              sx={{ '::-webkit-scrollbar': { display: 'none' } }}
              ref={leftListWrapperRef}
            >
              {statsResponse?.data?.map((stat) => {
                return (
                  <ListItem
                    onClick={() => {
                      setSelectedStatId((prevSelectedStatId) =>
                        prevSelectedStatId === stat._id ? 'all' : stat._id
                      );
                    }}
                    selectedStat={selectedStatId}
                    selected={selectedStatId === stat._id}
                    publicName={stat.publicName}
                    smart={stat.smart}
                    key={stat._id}
                    _id={stat._id}
                    count={utils.formatNumber(stat.count)}
                    path={stat._id}
                    type="company"
                  />
                );
              })}
            </LeftListWrapper>
          </LeftPanelWrapper>
          <RightPanelWrapper>
            <SearchInputWrapper>
              <InputGroup>
                <SearchInput
                  minH="48px"
                  placeholder="Search & filter by anything"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  pr="56px"
                  sx={
                    search.length > 100 && {
                      border: '1px solid',
                      borderColor: 'trueAttention',
                      _focus: { borderColor: 'trueAttention' },
                      _hover: { borderColor: 'trueAttention' },
                    }
                  }
                />
                <InputRightElement width="fit-content" height="100%" pr={4}>
                  {search ? (
                    <IconButton
                      size="xs"
                      px={0}
                      variant="ghost"
                      _active={{}}
                      _hover={{}}
                      onClick={() => setSearch('')}
                    >
                      <InputClearIcon fill="var(--chakra-colors-trueDim)" />
                    </IconButton>
                  ) : (
                    <InputSearchIcon fill="var(--chakra-colors-trueDim)" />
                  )}
                </InputRightElement>
              </InputGroup>
              {search.length > 100 && (
                <Text
                  color="trueAttention"
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="14px"
                  mt="8px"
                >
                  Please keep this under 100 characters
                </Text>
              )}
            </SearchInputWrapper>
            {!isEmptyState && parentRef?.current?.scrollTop > 0 && (
              <Divider w="742px" color="trueLight" alignSelf="center" />
            )}
            <RightListWrapper
              sx={{
                ...webkitScrollbarConfig('white'),
              }}
              ref={parentRef}
            >
              {isEmptyState ? (
                <FiltersEmptyState />
              ) : (
                <Box
                  style={{
                    height: `${rowVirtualizer.getTotalSize()}px`,
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                    const isLoaderRow = virtualRow.index > data.length - 1;
                    const filter = data[virtualRow.index];
                    return (
                      <AnimatePresence key={virtualRow.index}>
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{
                            opacity: 1,
                          }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            // height: `${virtualRow.size}px`,
                            transform: `translateY(${virtualRow.start}px)`,
                          }}
                        >
                          <FilterListItem
                            type="company"
                            className="filter-list-item"
                            isCategoryItem={filter?.path === 'category'}
                            isLoaderRow={isLoaderRow}
                            onClick={() => selectFilter(filter)}
                            filter={filter}
                            hasNextPage={hasNextPage}
                            isSelected={isCompanyFilterSelected(filter?._id)}
                          />
                        </motion.div>
                      </AnimatePresence>
                    );
                  })}
                </Box>
              )}
            </RightListWrapper>
          </RightPanelWrapper>
        </TwoPanelsWrapper>
      </MainPanel>
    </FilterSearchDropdownWrapper>
  );
}
