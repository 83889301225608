import Layout from '../../../common/Layout';
import { useAuthenticate } from '../../../common/requiresAuth';
import { useEffect, useMemo, useState } from 'react';
import { Text, Flex, Icon, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { FilterProvider } from '../../filters/components/FilterProvider';
import { getSelectedIcp, setSelectedIcp } from '~/v2/redux/slices/icpSlice';
import { SelectAutopilotMode } from './SelectAutopilotMode';
import { ReactComponent as AutomationIcon } from '~/v2/assets/icons/automation/automation-1.svg';
import { LaunchAutopilot } from './LaunchAutopilot';
import { useLocation, useNavigate } from 'react-router-dom';
import { ceil, get, isUndefined } from 'lodash';
import { getSelectedTeam } from '~/v2/redux/slices/teamsSlice';
import { getSelectedAccountQualification } from '~/v2/redux/slices/accountQualificationSlice';
import {
  useCreateAutopilotMutation,
  useGetAutopilotsQuery,
  useGetAutopilotTemplatesQuery,
} from '~/v2/redux/services/autopilots';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { AnimatePresence, motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { AUTOPILOT_FREQUENCY_OPTIONS } from '~/v2/constants/common';
import { AutopilotSearchStep } from './steps/Search';
import { AutopilotFilterStep } from './steps/Filter';
import { AutopilotQualifyStep } from './steps/Qualify';
import { AutopilotImportStep } from './steps/Import';
import { AutopilotVerifyStep } from './steps/Verify';
import { AutopilotExportStep } from './steps/Export';
import { AutopilotEnrichStep } from './steps/Enrich';
import { AutopilotEngageStep } from './steps/Engage';
import { LaunchAutopilotFooter } from './LaunchAutopilotFooter';
import { getSelectedSequenceTemplate } from '~/v2/redux/slices/sequenceTemplateSlice';
import { useGetSequenceTemplatesQuery } from '~/v2/redux/services/sequenceTemplate';
import { useGetIcpsQuery } from '~/v2/redux/services/icp';
import { useGetAccountQualificationsQuery } from '~/v2/redux/services/accountQualification';

const LaunchAutopilotPage = () => {
  const team = useSelector(getSelectedTeam);
  const selectedIcp = useSelector(getSelectedIcp);
  const selectedAccountQualification = useSelector(
    getSelectedAccountQualification
  );
  const selectedSequenceTemplate = useSelector(getSelectedSequenceTemplate);

  const location = useLocation();
  const { handleAsyncError } = useErrorHandler();
  const toast = useToast();
  const navigate = useNavigate();

  const [selectedMode, setSelectedMode] = useState(
    get(location, 'state.settings.selectedMode', '')
  );
  const [leadsCount, setLeadsCount] = useState({});
  const [panel, setPanel] = useState({
    acceptedFiles: [],
    originalFileName: '',
    leadsCount: 0,
    error: false,
  });
  const [autopilotName, setAutopilotName] = useState();
  const [frequency, setFrequency] = useState('');
  const [exportProvider, setExportProvider] = useState({});
  const [
    contactEnrichmentVerificationOptions,
    setContactEnrichmentVerificationOptions,
  ] = useState([]);

  const [isQualifyEnabled, setIsQualifyEnabled] = useState(false);
  const [isVerifyEnabled, setIsVerifyEnabled] = useState(false);
  const [isFilterStepEnabled, setIsFilterStepEnabled] = useState(false);
  const [selectedIcpSetting, setSelectedIcpSetting] = useState({});
  const [selectedQualificationSetting, setSelectedQualificationSetting] =
    useState({});
  const [
    selectedSequenceTemplateSettings,
    setSelectedSequenceTemplateSettings,
  ] = useState(selectedSequenceTemplate);

  const { data: autopilotsResponse } = useGetAutopilotsQuery(
    { teamId: team?._id },
    { skip: isUndefined(team?._id), refetchOnMountOrArgChange: true }
  );
  const { data: templatesResponse } = useGetAutopilotTemplatesQuery();
  const { data: icps } = useGetIcpsQuery(
    { teamId: get(team, '_id') },
    { skip: isUndefined(get(team, '_id')), refetchOnMountOrArgChange: true }
  );
  const { data: accountQualifications } = useGetAccountQualificationsQuery(
    { teamId: get(team, '_id') },
    { skip: isUndefined(get(team, '_id')), refetchOnMountOrArgChange: true }
  );
  const { data: sequenceTemplates } = useGetSequenceTemplatesQuery(
    { teamId: team?._id },
    { skip: isUndefined(team?._id), refetchOnMountOrArgChange: true }
  );

  const [createAutopilot, { isLoading: isCreateAutopilotLoading }] =
    useCreateAutopilotMutation();

  const isLoading = useMemo(
    () =>
      !autopilotsResponse ||
      !team ||
      !selectedIcp ||
      !selectedAccountQualification ||
      !selectedSequenceTemplate,
    [
      autopilotsResponse,
      selectedAccountQualification,
      selectedSequenceTemplate,
      selectedIcp,
      team,
    ]
  );

  const navigationState = useMemo(() => {
    return {
      fromLauchAutopilotPage: true,
      settings: {
        autopilotName,
        selectedMode,
        frequency: AUTOPILOT_FREQUENCY_OPTIONS.find(
          (option) => option.value === frequency
        ),
        leadsCount,
        exportProviderValue: exportProvider.value,
        contactEnrichmentVerificationOptions,
        isQualifyEnabled,
        isVerifyEnabled,
        isFilterStepEnabled,
      },
    };
  }, [
    autopilotName,
    contactEnrichmentVerificationOptions,
    exportProvider.value,
    frequency,
    isQualifyEnabled,
    leadsCount,
    selectedMode,
    isFilterStepEnabled,
    isVerifyEnabled,
  ]);

  const scoreMapTotal = useMemo(
    () =>
      get(selectedQualificationSetting, 'qualificationQuestions', [])?.reduce(
        (totalSum, item) => {
          const scoreMap = item.scoreMap;
          const sum = Object.values(scoreMap).reduce(
            (sum, value) => sum + parseFloat(value),
            0
          );
          return totalSum + sum;
        },
        0
      ),
    [selectedQualificationSetting]
  );

  const isSequenceTemplateSetupIncomplete = useMemo(
    () =>
      !selectedSequenceTemplateSettings?.sequenceSteps?.length ||
      selectedSequenceTemplateSettings?.sequenceSteps?.find(
        (st, idx) => (idx === 0 && !st.subject) || !st.message
      ),
    [selectedSequenceTemplateSettings?.sequenceSteps]
  );

  const stepsMapper = useMemo(() => {
    return {
      search: { name: 'search', component: <AutopilotSearchStep /> },
      import: {
        name: 'import',
        component: <AutopilotImportStep panel={panel} setPanel={setPanel} />,
      },
      enrich: { name: 'enrich', component: <AutopilotEnrichStep /> },
      filter: {
        name: 'filter',
        isDisabled:
          !get(selectedIcpSetting, 'personFilterStatements', []).length &&
          isFilterStepEnabled,
        component: (
          <AutopilotFilterStep
            setSelectedIcpSetting={setSelectedIcpSetting}
            setSelectedIcp={setSelectedIcp}
            selectedIcpSetting={selectedIcpSetting}
            navigationState={navigationState}
            isFilterStepEnabled={isFilterStepEnabled}
            setIsFilterStepEnabled={setIsFilterStepEnabled}
            selectedMode={selectedMode}
            templatesResponse={templatesResponse}
            icps={icps}
          />
        ),
      },
      qualify: {
        name: 'qualify',
        isDisabled:
          !get(selectedQualificationSetting, 'qualificationQuestions') &&
          isQualifyEnabled,
        component: (
          <AutopilotQualifyStep
            isQualifyEnabled={isQualifyEnabled}
            setIsQualifyEnabled={setIsQualifyEnabled}
            selectedQualificationSetting={selectedQualificationSetting}
            setSelectedQualificationSetting={setSelectedQualificationSetting}
            navigationState={navigationState}
            accountQualifications={accountQualifications}
          />
        ),
      },
      verify: {
        name: 'verify',
        component: (
          <AutopilotVerifyStep
            contactEnrichmentVerificationOptions={
              contactEnrichmentVerificationOptions
            }
            setContactEnrichmentVerificationOptions={
              setContactEnrichmentVerificationOptions
            }
            isVerifyEnabled={isVerifyEnabled}
            setIsVerifyEnabled={setIsVerifyEnabled}
          />
        ),
      },
      export: {
        name: 'export',
        component: (
          <AutopilotExportStep
            selectedMode={selectedMode}
            leadsCount={leadsCount}
            setLeadsCount={setLeadsCount}
            exportProvider={exportProvider}
            setExportProvider={setExportProvider}
          />
        ),
      },
      engage: {
        name: 'engage',
        isDisabled:
          isSequenceTemplateSetupIncomplete ||
          !get(team, 'emailAccounts', []).length,
        component: (
          <AutopilotEngageStep
            selectedMode={selectedMode}
            leadsCount={leadsCount}
            setLeadsCount={setLeadsCount}
            selectedSequenceTemplateSettings={selectedSequenceTemplateSettings}
            setSelectedSequenceTemplateSettings={
              setSelectedSequenceTemplateSettings
            }
            navigationState={navigationState}
            sequenceTemplates={sequenceTemplates}
          />
        ),
      },
    };
  }, [
    navigationState,
    selectedIcpSetting,
    isQualifyEnabled,
    selectedQualificationSetting,
    contactEnrichmentVerificationOptions,
    exportProvider,
    leadsCount,
    selectedMode,
    panel,
    selectedSequenceTemplateSettings,
    team,
    isFilterStepEnabled,
    templatesResponse,
    isVerifyEnabled,
    icps,
    accountQualifications,
    sequenceTemplates,
    isSequenceTemplateSetupIncomplete,
  ]);

  const steps = useMemo(
    () =>
      get(
        get(templatesResponse, 'result.templates', []).find(
          ({ name }) => name === selectedMode
        ),
        'steps',
        []
      ).map(({ name }) => stepsMapper[name]),
    [stepsMapper, selectedMode, templatesResponse]
  );

  const handleLaunchAutopilot = handleAsyncError(async () => {
    const payload = {
      buildList: {
        firstStepName: 'search',
        finalStepName: 'export',
        requestedCount: leadsCount.value,
        exportProvider: exportProvider.value,
      },
      inboundEnrichment: {
        firstStepName: 'import',
        finalStepName: 'export',
        exportProvider: exportProvider.value,
        file: panel.acceptedFiles[0],
      },
      inboundToOutbound: {
        firstStepName: 'import',
        finalStepName: 'engage',
        file: panel.acceptedFiles[0],
      },
      emailOutbound: {
        firstStepName: 'search',
        finalStepName: 'engage',
        requestedCount: leadsCount.value,
      },
    };

    const response = await createAutopilot({
      name: autopilotName,
      frequency,
      teamId: team._id,
      contactEnrichmentVerificationOptions: isVerifyEnabled
        ? contactEnrichmentVerificationOptions
        : [],
      ...(isQualifyEnabled && {
        qualificationMinScore:
          (get(selectedQualificationSetting, 'qualificationMinScore', 0) ||
            ceil(scoreMapTotal / 2)) / scoreMapTotal,
      }),
      icpId: selectedIcpSetting._id,
      accountQualificationId: selectedQualificationSetting._id,
      sequenceTemplateId: selectedSequenceTemplateSettings._id,
      filterStepEnabled: isFilterStepEnabled,
      ...payload[selectedMode],
    });

    if (get(response, 'data.isOk')) {
      navigate('/autopilots', { state: { defaultAutopilotIndex: 0 } });
    } else {
      toast({
        title: 'Error',
        description: 'Something went wrong!',
        status: 'error',
        duration: 1500,
        isClosable: true,
      });
    }
  });

  useEffect(() => {
    setSelectedIcpSetting(selectedIcp);
    setSelectedQualificationSetting(selectedAccountQualification);
    setSelectedSequenceTemplateSettings(selectedSequenceTemplate);
  }, [selectedIcp, selectedSequenceTemplate, selectedAccountQualification]);

  return (
    <Layout
      footer={
        selectedMode &&
        !isLoading && (
          <LaunchAutopilotFooter
            selectedMode={selectedMode}
            setSelectedMode={setSelectedMode}
            handleLaunchAutopilot={handleLaunchAutopilot}
            isCreateAutopilotLoading={isCreateAutopilotLoading}
            steps={steps}
            autopilotName={autopilotName}
            panel={panel}
          />
        )
      }
    >
      <Flex flexDir="column" mt="24px" h="100%">
        <Helmet title={'Truebase | Autopilot'} defer={false} />
        <AnimatePresence>
          <motion.div
            initial={{ x: 200, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
          >
            <Flex alignItems="center" gap="18px">
              <Icon as={AutomationIcon} w="48px" h="48px" />
              <Flex flexDir="column">
                <Text fontWeight="700" fontSize="22px" lineHeight="26px">
                  {selectedMode
                    ? {
                        buildList: 'Build List',
                        inboundEnrichment: 'Inbound Enrichment',
                        inboundToOutbound: 'Inbound To Outbound',
                        emailOutbound: 'Email Outbound',
                      }[selectedMode]
                    : 'Launch Autopilot'}
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="13px"
                  lineHeight="16px"
                  color="trueExplain"
                >
                  {selectedMode
                    ? {
                        buildList: '',
                        inboundEnrichment:
                          'Import your own leads and enrich them further with insights and contact information.',
                        inboundToOutbound:
                          'Import your own leads and enrich them further with insights and contact information.',
                        emailOutbound: '',
                      }[selectedMode]
                    : 'Launch Autopilot'}
                </Text>
              </Flex>
            </Flex>
          </motion.div>
        </AnimatePresence>

        {selectedMode && !isLoading ? (
          <LaunchAutopilot
            selectedMode={selectedMode}
            setSelectedMode={setSelectedMode}
            autopilotsResponse={autopilotsResponse}
            autopilotName={autopilotName}
            setAutopilotName={setAutopilotName}
            steps={steps}
            setFrequency={setFrequency}
            leadsCount={leadsCount}
            setLeadsCount={setLeadsCount}
            setExportProvider={setExportProvider}
            setContactEnrichmentVerificationOptions={
              setContactEnrichmentVerificationOptions
            }
            isFilterStepEnabled={isFilterStepEnabled}
            isQualifyEnabled={isQualifyEnabled}
            isVerifyEnabled={isVerifyEnabled}
            setIsQualifyEnabled={setIsQualifyEnabled}
            setIsVerifyEnabled={setIsVerifyEnabled}
            setIsFilterStepEnabled={setIsFilterStepEnabled}
            setPanel={setPanel}
            templatesResponse={templatesResponse}
          />
        ) : (
          <SelectAutopilotMode
            selectedMode={selectedMode}
            setSelectedMode={setSelectedMode}
            isLoading={isLoading}
          />
        )}
      </Flex>
    </Layout>
  );
};

const LaunchAutopilotWithProviders = () => {
  useAuthenticate();
  const selectedIcp = useSelector(getSelectedIcp);

  return (
    <FilterProvider
      initialCompanyFilters={selectedIcp?.companyFilterStatements || []}
      initialLeadFilters={selectedIcp?.personFilterStatements || []}
    >
      <LaunchAutopilotPage selectedIcp={selectedIcp} />
    </FilterProvider>
  );
};

export default LaunchAutopilotWithProviders;
