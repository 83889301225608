import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { socialProfilesOrder } from '~/utils';
import AvatarSwitcher from '../components/AvatarSwitcher';
import { useMemo, useState, useEffect, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { DropdownToggle } from '../TruebaseAccountAccordion/DropdownToggle';
import { CompanySocialInfo } from '../components/CompanyInfo';
import LeadEmailDiscovery from '../TruebaseAccountAccordion/LeadEmailDiscovery';
import { useFilterContext } from '~/v2/features/filters/components/useFilterContext';
import LeadTabs from './LeadTabs';
import LeadOutdatedCard from './LeadOutdatedCard';
import GeneratingStatus from '../components/GeneratingStatus';
import { ReactComponent as CheckOff } from '~/v2/assets/icons/clear/clear-large.svg';
import { getStatus } from '../../../redux/slices/statusSlice';
import { useEngageLeadMutation } from '../../../redux/services/leads';
import { capitalize, isEqual } from 'lodash';
import { ReactComponent as RemoveIcon } from '~/v2/assets/icons/clear/clear-large.svg';
import { ReactComponent as EngagedIcon } from '~/v2/assets/icons/mail-attention.svg';
import { ReactComponent as UnsubscribeIcon } from '~/v2/assets/icons/mail-unsubscribed.svg';
import { ReactComponent as OpenMailIcon } from '~/v2/assets/icons/mail-open.svg';
import { ReactComponent as MailIcon } from '~/v2/assets/icons/mail/mail-1.svg';
import { ReactComponent as CheckOn } from '~/v2/assets/icons/done/done-large.svg';
import { TagIcon } from '~/v2/assets/Tag';
import { getSelectedIcp } from '~/v2/redux/slices/icpSlice';
import { getSelectedTeam } from '~/v2/redux/slices/teamsSlice';

function TruebaseLeadAccordion({
  readonly,
  accordionKey,
  setOpen,
  isOpen,
  lead,
  account,
  showHitsAndMisses = false,
  accordionItemProps = {
    borderWidth: '1px',
    borderColor: 'trueLight',
    borderRadius: '4px',
    overflow: 'hidden',
    bgColor: 'white',
    px: '16px',
  },
  isExpandable = true,
  chosenTags,
  isSmall,
}) {
  const toast = useToast();

  const [engageLead] = useEngageLeadMutation();
  const [internalIndex, setInternalIndex] = useState(0);
  const [didOpen, setDidOpen] = useState(false);
  const [removedOutDatedLeads, setRemovedOutDatedLeads] = useState(new Set());
  const { companyFilters, leadFilters, requestId } = useFilterContext();
  const icp = useSelector(getSelectedIcp);
  const selectedTeam = useSelector(getSelectedTeam);

  const isGeneratingAccountScore = useSelector(getStatus)(
    account.companyId
  )?.find(({ type }) => type === 'accountScoring');

  const isGeneratingSequence = useSelector(getStatus)(lead.personId)?.find(
    ({ type }) => type === 'sequence'
  );

  const [isLeadQueued] = useState(
    lead.state === 'pending' && lead.steps?.search?.enqueuedAt
  );

  const [isLeadExcluded] = useState(
    Object.keys(selectedTeam?.excludedPersons || {}).includes(lead.personId)
  );

  const [isAccountExcluded, setIsAccountExcluded] = useState(
    Object.keys(selectedTeam?.excludedCompanies || {}).includes(
      account.companyId
    )
  );

  useEffect(() => {
    setIsAccountExcluded(
      Object.keys(selectedTeam?.excludedCompanies || {}).includes(
        account.companyId
      )
    );
  }, [selectedTeam?.excludedCompanies, account.companyId]);

  const socialProfiles = useMemo(() => {
    if (!lead.socialProfiles) return [];

    return lead.socialProfiles.slice().sort((a, b) => {
      return (
        socialProfilesOrder.indexOf(a.service) -
        socialProfilesOrder.indexOf(b.service)
      );
    });
  }, [lead.socialProfiles]);

  const sentMessages = lead?.steps?.engage?.sequence?.messages?.filter(
    (message) => !!message?.sentAt
  );

  const handleOpen = useCallback(
    (isExpanded, currentKey) => {
      if (isExpandable) {
        if (isExpanded) {
          if (internalIndex !== currentKey) {
            setInternalIndex(currentKey);
          } else if (internalIndex === currentKey) {
            setOpen(accordionKey);
          }
        } else {
          setOpen(accordionKey);
          setInternalIndex(currentKey);
        }
      }
    },
    [accordionKey, internalIndex, setOpen, isExpandable]
  );

  const getOptions = useCallback(
    (lead, icp, chosenTags) => {
      const options = [];

      const addOption = (condition, option) => {
        if (condition) options.push(option);
      };

      const handleCopyThreadLink = async (e) => {
        e.stopPropagation();
        await navigator.clipboard.writeText(
          `https://mail.google.com/mail/u/#inbox/${lead?.steps?.engage?.sequence?.threadId}`
        );
        toast({
          title: 'Copied to clipboard',
          status: 'success',
          duration: 1000,
        });
      };

      const handleEngageLead = (action, e) => {
        e.stopPropagation();
        engageLead({
          lead: lead,
          teamId: lead.teamId,
          icpId: icp._id,
          action: action,
          chosenTags,
        });
      };

      const isScheduledOrActive = lead.status.some((status) =>
        ['scheduled', 'active'].includes(status)
      );

      addOption(
        !lead.status.includes('scheduled') &&
          lead?.steps?.engage?.sequence?.threadId,
        {
          label: 'Copy Thread Link',
          onClick: handleCopyThreadLink,
          icon: <OpenMailIcon />,
        }
      );

      addOption(
        isScheduledOrActive ||
          (lead.status.includes('exited') &&
            !lead.status.includes('unsubscribed') &&
            !lead.status.includes('bounced')),
        {
          label: 'Mark Engaged',
          onClick: (e) => handleEngageLead('engage', e),
          icon: <EngagedIcon />,
        }
      );

      addOption(isScheduledOrActive, [
        {
          label: 'Unsubscribe',
          onClick: (e) => handleEngageLead('unsubscribe', e),
          icon: <UnsubscribeIcon />,
        },
        {
          label: 'Remove from sequence',
          onClick: (e) => handleEngageLead('remove', e),
          icon: <RemoveIcon />,
        },
      ]);

      return options.flat();
    },
    [engageLead, toast]
  );

  const dropdownOptions = getOptions(lead, icp, chosenTags);

  useEffect(() => {
    if (
      (isGeneratingSequence || isGeneratingAccountScore) &&
      !isOpen &&
      !didOpen
    ) {
      setDidOpen(true);
      handleOpen(false, 2);
    }
  }, [
    isGeneratingSequence,
    isGeneratingAccountScore,
    handleOpen,
    isOpen,
    didOpen,
  ]);

  const removeLeadFromUI = () => {
    setRemovedOutDatedLeads((current) => {
      const newSet = new Set(current);
      newSet.add(lead._id);
      return newSet;
    });
  };
  if (lead.contactOutdated && !removedOutDatedLeads.has(lead._id))
    return (
      <LeadOutdatedCard
        lead={lead}
        account={account}
        removeLeadFromUI={removeLeadFromUI}
      />
    );
  else if (!removedOutDatedLeads.has(lead._id))
    return (
      <>
        <Accordion
          sx={{
            '.show-on-hover': {
              transition: 'opacity 0.2s ease',
              opacity: isOpen ? 1 : 0,
              '&:hover': {
                opacity: 1,
              },
            },
          }}
          _hover={{
            '.show-on-hover': {
              opacity: 1,
            },
          }}
          allowToggle
          onChange={() => {
            if (isExpandable) {
              setOpen(accordionKey);
            }
          }}
          index={isOpen ? 0 : -1}
        >
          <AccordionItem {...accordionItemProps}>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  _hover={{}}
                  _focus={{}}
                  _active={{}}
                  p={0}
                  onClick={() => handleOpen(isExpanded, 2)}
                  as="div"
                  h={isSmall ? '64px' : '80px'}
                >
                  <Flex
                    alignItems="center"
                    w="100%"
                    bg="white"
                    borderColor="trueLight"
                    gap="16px"
                  >
                    <Button
                      width="fit-content"
                      _hover={{}}
                      _focus={{}}
                      _active={{}}
                      variant="ghost"
                      p={0}
                      cursor="default"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpen(isExpanded, 2);
                      }}
                    >
                      <AvatarSwitcher
                        type="person"
                        src={lead.avatar}
                        matchName={lead.name}
                        isSmall={isSmall}
                      />
                    </Button>
                    <Flex flexDir="column">
                      <Flex mb={1} gap="8px" alignItems="center">
                        <CompanySocialInfo
                          socialProfiles={socialProfiles}
                          account={lead}
                          type="lead"
                          isSmall={isSmall}
                        />
                        <Flex gap="8px">
                          <Flex className="show-on-hover" gap="8px">
                            <LeadEmailDiscovery
                              lead={lead}
                              key={lead.personId}
                            />
                          </Flex>
                          <GeneratingStatus
                            lead={lead}
                            account={!isOpen && account}
                            priority="leads"
                          />
                        </Flex>
                      </Flex>
                      <Box
                        sx={{
                          fontSize: isSmall ? '12px' : '14px',
                          lineHeight: isSmall ? '14px' : '16px',
                          fontWeight: '400',
                          display: 'flex',
                          alignItems: 'center',
                          color: 'trueDim',
                        }}
                      >
                        {lead.title} @ {account.name}
                      </Box>
                    </Flex>
                    <Spacer />
                    <Flex alignItems="center" columnGap="16px">
                      {lead.status.map((status) => {
                        return (
                          <Flex
                            key={status}
                            alignItems="center"
                            justifyContent="center"
                            gap="7px"
                            border="1px solid"
                            borderColor={
                              status === 'bounced' ? 'trueCorral' : 'trueLight'
                            }
                            p="5px 0px"
                            minW="118px"
                            borderRadius="4px"
                          >
                            <TagIcon
                              color="#7E8BAD"
                              width="14px"
                              height="14px"
                            />
                            <Text
                              color="trueDim"
                              fontWeight="500"
                              fontSize="12px"
                              lineHeight="14px"
                            >
                              {capitalize(status)}
                            </Text>
                          </Flex>
                        );
                      })}
                      {readonly ? (
                        <>
                          {lead.status.includes('active') && (
                            <Flex alignItems="center" gap="4px">
                              <MailIcon fill="var(--chakra-colors-trueDim)" />
                              <Text
                                color="trueDim"
                                fontWeight="500"
                                fontSize="13px"
                                lineHeight="16px"
                              >
                                {sentMessages?.length}
                              </Text>
                            </Flex>
                          )}
                          {!!dropdownOptions.length && (
                            <DropdownToggle
                              showOnHover={false}
                              options={dropdownOptions}
                            />
                          )}
                        </>
                      ) : (
                        <Flex
                          gap="16px"
                          alignItems="center"
                          className="show-on-hover"
                        >
                          {isLeadQueued && !isAccountExcluded && (
                            <Box sx={{ svg: { fill: 'trueGrass' } }}>
                              <CheckOn />
                            </Box>
                          )}
                          {(isLeadExcluded || isAccountExcluded) && (
                            <Box sx={{ svg: { fill: 'trueCorral' } }}>
                              <CheckOff />
                            </Box>
                          )}
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </AccordionButton>
                <AnimatePresence>
                  {(() => {
                    switch (internalIndex) {
                      case 2:
                        return (
                          <motion.div
                            initial={{ y: 248, height: 0, opacity: 0 }}
                            animate={{ y: 0, height: 'auto', opacity: 1 }}
                            exit={{ y: 248, height: 0, opacity: 0 }}
                            transition="linear"
                            key="three"
                          >
                            <AccordionPanel
                              pb="16px"
                              pt={0}
                              px={0}
                              key="two"
                              bg="white"
                            >
                              <LeadTabs
                                readonly={readonly}
                                showHitsAndMisses={showHitsAndMisses}
                                lead={lead}
                                account={account}
                                companyFilters={companyFilters}
                                leadFilters={leadFilters}
                                requestId={requestId}
                              />
                            </AccordionPanel>
                          </motion.div>
                        );
                    }
                  })()}
                </AnimatePresence>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </>
    );
}

const MemoizedTruebaseAccordion = memo(
  TruebaseLeadAccordion,
  (prevProps, nextProps) => {
    const { accordionKey, isOpen, account, lead, chosenTags } = prevProps;
    const {
      accordionKey: nextAccordionKey,
      isOpen: nextIsOpen,
      account: nextAccount,
      lead: nextLead,
      chosenTags: nextChosenTags,
    } = nextProps;
    return (
      nextAccordionKey === accordionKey &&
      isOpen === nextIsOpen &&
      isEqual(account, nextAccount) &&
      isEqual(lead, nextLead) &&
      isEqual(chosenTags, nextChosenTags)
    );
  }
);

export default MemoizedTruebaseAccordion;
