import React from 'react';

import { useRegisterSW } from 'virtual:pwa-register/react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Button,
  Flex,
  Text,
  Spacer,
} from '@chakra-ui/react';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';

function ReloadPrompt() {
  const {
    offlineReady: [_offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      // eslint-disable-next-line prefer-template
      console.log('SW Registered: ' + r);
    },
    onRegisterError(error) {
      console.log('SW registration error', error);
    },
  });

  const close = () => {
    setOfflineReady(false);
    setNeedRefresh(false);
  };

  return (
    <AlertDialog
      onClose={close}
      isOpen={needRefresh}
      isCentered
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay />
      <AlertDialogContent maxW="500px">
        <AlertDialogBody p={4} paddingInlineStart={0}>
          <Flex gap="16px">
            <Box sx={{ svg: { fill: 'trueWarning' } }}>
              <InfoIcon />
            </Box>
            <Text
              color="trueSpace"
              fontSize="14px"
              lineHeight="16px"
              fontWeight="500"
            >
              A fresh update of Truebase is out now.
              <br /> Reload the page to keep your prospecting experience smooth.
            </Text>
            <Spacer />
          </Flex>
        </AlertDialogBody>
        <AlertDialogFooter
          px="16px"
          py="8px"
          sx={{
            borderTopWidth: '1px',
            borderTopColor: 'trueLight',
          }}
        >
          <Button
            variant="truebaseTextNeutral"
            h="32px"
            onClick={close}
            mr="16px"
          >
            Cancel
          </Button>
          <Button
            variant="truebaseTextHighlight"
            h="32px"
            onClick={() => updateServiceWorker(true)}
          >
            Reload
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default ReloadPrompt;
