/**
 * send a message to a chrome extension using extension id
 * @param {object} message
 */

export const sendChromeExtensionMessage = (message) => {
  if (window.chrome && process.env.REACT_APP_CHROME_EXTENSION_ID) {
    try {
      window.chrome.runtime.sendMessage(
        process.env.REACT_APP_CHROME_EXTENSION_ID,
        message
      );
    } catch (err) {
      // if an error occurs it means that the user doesn't have the chrome extension installed
      window.open(
        'https://chrome.google.com/webstore/detail/truebase/ahlmkaafohhhbocahhjlcgofddbhcaef',
        '_blank'
      );
    }
  }
};

export const tabsToOpenOnInstall = [
  { url: 'https://www.linkedin.com/company/truebase/' },
  { url: 'https://www.linkedin.com/in/wissamtabbara/', active: true },
];
