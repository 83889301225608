import {
  Alert,
  AlertIcon,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Spinner,
  Text,
  useToast,
  InputGroup,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { first, last } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  useLazyGetCurrentUserQuery,
  useLoginMutation,
  useResetPasswordMutation,
  useVerifyUserMutation,
} from '../../redux/services/user';
import LoginLayout from './LoginLayout';
import { useForm } from 'react-hook-form';
import { ReactComponent as EyeOnIcon } from '../../assets/icons/view/view-large.svg';
import { ReactComponent as EyeOffIcon } from '../../assets/icons/view/view-large-off.svg';
import useErrorHandler from '~/v2/hooks/useErrorHandler';

export default function ResetPassword() {
  const { handleAsyncError } = useErrorHandler();
  const [invalidToken, setInvalidToken] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [verifyUser] = useVerifyUserMutation();
  const [resetPassword] = useResetPasswordMutation();
  const [login] = useLoginMutation();
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const toast = useToast();
  const [email, setEmail] = useState('');
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    const verifyToken = handleAsyncError(async () => {
      const token = first(last(location.pathname.split('/')).split('?'));
      const response = await verifyUser({ token }).unwrap();
      if (response.isOk) {
        setEmail(response.result.email);
      } else {
        setInvalidToken(true);
      }
    });
    verifyToken();
  }, [location.pathname, verifyUser, handleAsyncError]);

  const onSubmit = handleAsyncError(async ({ password }, e) => {
    e.preventDefault();
    const token = first(last(location.pathname.split('/')).split('?'));
    const response = await resetPassword({
      password,
      token,
    }).unwrap();
    if (response.isOk) {
      const loginResponse = await login({
        email,
        password,
        authProvider: 'truebase',
      }).unwrap();
      if (loginResponse.isOk) {
        localStorage.setItem('AccessToken', loginResponse.result.authToken);
        const userResponse = await getCurrentUser().unwrap();
        const user = userResponse.result.user;
        if (user) localStorage.setItem('User', JSON.stringify(user));
        navigate('/');
      } else {
        toast({
          title: 'Error',
          description: loginResponse.error.message,
          status: 'error',
        });
      }
    }
  });
  return (
    <LoginLayout title={'Reset password'} mb="64">
      <Helmet title={'Truebase | Reset Password'} defer={false} />
      {email === '' && !invalidToken ? (
        <Spinner />
      ) : invalidToken ? (
        <Flex justifyContent="center" flexDir="column" gap="24px">
          <Alert status="error">
            <AlertIcon />
            This token has already been used to reset a password.
          </Alert>
          <Center>
            <Button
              variant="truebaseText"
              as={RouterLink}
              to="/login"
              color="trueLink"
            >
              Back to login
            </Button>
          </Center>
        </Flex>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex gap="24px" flexDir="column">
            <FormControl isInvalid={errors.password}>
              <Flex justifyContent="space-between" mb={2}>
                <FormLabel as={Text} mb={0} variant="label">
                  New password
                </FormLabel>
                <FormHelperText mt={0}>At least 8 characters</FormHelperText>
              </Flex>
              <InputGroup>
                <Input
                  variant="truebase"
                  type={isPasswordShown ? 'text' : 'password'}
                  placeholder="Choose a password"
                  {...register('password', {
                    required: 'Password is required',
                    minLength: {
                      value: 8,
                      message: 'Password must be at least 8 characters',
                    },
                  })}
                />
                <InputRightElement h="48px">
                  <IconButton
                    sx={{
                      bg: 'transparent',
                      _hover: {
                        bg: 'transparent',
                      },
                      svg: {
                        path: {
                          fill: 'trueDim',
                        },
                      },
                    }}
                    icon={isPasswordShown ? <EyeOnIcon /> : <EyeOffIcon />}
                    onClick={() => {
                      setIsPasswordShown(!isPasswordShown);
                    }}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage variant="truebase">
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.confirmPassword}>
              <FormLabel as={Text} mb={2} variant="label">
                Confirm password
              </FormLabel>
              <InputGroup>
                <Input
                  variant="truebase"
                  type={isConfirmPasswordShown ? 'text' : 'password'}
                  placeholder="Choose a password"
                  {...register('confirmPassword', {
                    required: 'Password confirmation is required',
                    validate: (val) => {
                      if (watch('password') !== val) {
                        return 'Password confirmation does not match';
                      }
                    },
                  })}
                />
                <InputRightElement h="48px">
                  <IconButton
                    sx={{
                      bg: 'transparent',
                      _hover: {
                        bg: 'transparent',
                      },
                      svg: {
                        path: {
                          fill: 'trueDim',
                        },
                      },
                    }}
                    icon={
                      isConfirmPasswordShown ? <EyeOnIcon /> : <EyeOffIcon />
                    }
                    onClick={() => {
                      setIsConfirmPasswordShown(!isConfirmPasswordShown);
                    }}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage variant="truebase">
                {errors.confirmPassword && errors.confirmPassword.message}
              </FormErrorMessage>
            </FormControl>
            <Button variant="truebase" type="submit">
              Reset Password
            </Button>
            <Flex justifyContent="center" mt="80px">
              <Text textAlign="center" fontSize="18px" lineHeight="25px">
                By continuing, you agree to
                <br /> our{' '}
                <Link
                  color="trueLink"
                  textDecoration="underline"
                  href="https://truebase.io/terms-of-use"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </Link>{' '}
                and{' '}
                <Link
                  color="trueLink"
                  textDecoration="underline"
                  href="https://truebase.io/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </Link>
              </Text>
            </Flex>
          </Flex>
        </form>
      )}
    </LoginLayout>
  );
}
