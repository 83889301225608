import { apiSlice } from './api';

export const companyApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCompanySignupDetails: builder.mutation({
      query: ({ companyId, notificationId, intercomId }) => ({
        url: `companies/${encodeURIComponent(companyId)}/getSignupDetails`,
        body: { notificationId, intercomId },
        method: 'POST',
      }),
    }),
    generateOffering: builder.mutation({
      query: ({ companyId, companyRecord, notificationId }) => ({
        url: `companies/${encodeURIComponent(companyId)}/generateOffering`,
        body: { companyRecord, notificationId },
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetCompanySignupDetailsMutation,
  useGenerateOfferingMutation,
} = companyApi;
