import {
  Box,
  Flex,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Divider,
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import TruebaseSelect from '~/v2/common/Truebase/TruebaseSelect';
import SequenceMessageTextEditor from './SequenceMessageTextEditor';
import { Controller, useFormContext } from 'react-hook-form';
import { daysOptions } from '~/v2/constants/personalization';
import { ReactComponent as ThreeDots } from '~/v2/assets/icons/more/more-vertical.svg';
import { ReactComponent as DeleteIcon } from '~/v2/assets/icons/delete.svg';
import SequenceSubjectTextEditor from './SequenceSubjectTextEditor';

const SequenceStepCard = ({
  index,
  onRemove,
  isRemovable = false,
  handleTestEmail,
}) => {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useFormContext();

  const sequenceSteps = watch('sequenceSteps');
  const sequenceStep = sequenceSteps?.[index];

  return (
    <Box
      p="16px"
      bg="trueBg02"
      borderRadius="2px"
      border="1px solid"
      borderColor="trueLight"
    >
      <Flex direction="column" gap="16px">
        <Flex alignItems="center">
          <Flex
            sx={{
              borderRadius: '50%',
              bg: 'trueDim',
              w: '24px',
              h: '24px',
              color: 'white',
              mr: '12px',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '13px',
              lineHeight: '16px',
            }}
          >
            {index + 1}
          </Flex>
          <Text
            color="trueExplain"
            fontSize="13px"
            lineHeight="16px"
            fontWeight="500"
          >
            Step {index + 1}
          </Text>
          <Spacer />
          <Menu variant="truebase">
            <MenuButton
              onClick={(e) => e.stopPropagation()}
              as={IconButton}
              w="32px"
              h="32px"
              minW={0}
              className="showOnHover"
              sx={{
                svg: { fill: 'trueDim' },
              }}
              variant="ghost"
              icon={<ThreeDots />}
            />
            <MenuList>
              <MenuItem isDisabled={!isRemovable} onClick={onRemove}>
                <DeleteIcon />
                <Text>Remove</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <Divider borderColor="trueLight" opacity="1" />
        <Grid gap="16px" templateColumns="repeat(2, 1fr)">
          <GridItem>
            <FormControl
              isInvalid={errors.sequenceSteps?.[index]?.daysSinceLastMessage}
            >
              <FormLabel
                variant="truebase"
                sx={{
                  display: 'flex',
                  color: 'trueDim',
                  fontWeight: 500,
                }}
              >
                Send On
              </FormLabel>
              <TruebaseSelect
                variant="truebase"
                options={daysOptions.filter(
                  ({ value }) =>
                    value >
                      (sequenceSteps?.[index - 1]?.daysSinceLastMessage || 0) &&
                    value <
                      (sequenceSteps?.[index + 1]?.daysSinceLastMessage ||
                        (index === 1
                          ? daysOptions.length
                          : daysOptions.length + 1))
                )}
                key={`sequenceSteps.${index}.daysSinceLastMessage`}
                {...register(`sequenceSteps.${index}.daysSinceLastMessage`)}
                getOptionLabel={(option) => (
                  <span className="option-label">{option.label}</span>
                )}
                onChange={(option) => {
                  setValue(
                    `sequenceSteps.${index}.daysSinceLastMessage`,
                    option.value,
                    { shouldDirty: true }
                  );
                }}
                value={{
                  label: `Day ${sequenceStep.daysSinceLastMessage}`,
                  value: sequenceStep.daysSinceLastMessage,
                }}
                isDisabled={index === 0}
                menuPortalTarget={
                  !document.querySelector('.chakra-modal__content-container') &&
                  document.body
                }
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              isRequired={!index}
              isInvalid={errors.sequenceSteps?.[index]?.subject}
            >
              <FormLabel
                variant="truebase"
                sx={{
                  color: 'trueDim',
                  fontWeight: 500,
                }}
              >
                Subject
              </FormLabel>
              <Controller
                name={`sequenceSteps.${index}.subject`}
                control={control}
                rules={{
                  required: !index ? 'Required' : false,
                }}
                render={({ field: { onChange, value } }) => (
                  <SequenceSubjectTextEditor
                    index={index}
                    value={value}
                    onChange={onChange}
                    isError={errors.sequenceSteps?.[index]?.subject}
                    placeholder={
                      !index
                        ? 'E.g. Permission to send a short video about our technology'
                        : 'Type a new subject or leave blank to reply under the same thread'
                    }
                  />
                )}
              />
              <FormErrorMessage variant="truebase">
                {errors.sequenceSteps?.[index]?.subject?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <FormControl
              isRequired
              isInvalid={errors.sequenceSteps?.[index]?.message}
            >
              <Controller
                name={`sequenceSteps.${index}.message`}
                control={control}
                rules={{ required: 'Required' }}
                render={({ field: { onChange, value } }) => (
                  <SequenceMessageTextEditor
                    index={index}
                    value={value}
                    onChange={onChange}
                    isError={errors.sequenceSteps?.[index]?.message}
                    handleTestEmail={handleTestEmail}
                  />
                )}
              />
              <FormErrorMessage variant="truebase">
                {errors.sequenceSteps?.[index]?.message?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Flex>
    </Box>
  );
};

export default SequenceStepCard;
