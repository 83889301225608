import styled from 'styled-components';

export const MaintenanceWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MainText = styled.p`
  color: var(--chakra-colors-trueSpace);
  text-align: center;
  margin-bottom: 20px;
`;
export const AltText = styled.p`
  color: var(--chakra-colors-trueSpace);
  text-align: center;
  margin-bottom: 40px;
`;
