import { Flex, Icon, Text } from '@chakra-ui/react';
import { AutopilotStepCard } from '../AutopilotStepCard';
import { ReactComponent as ExportedIcon } from '~/v2/assets/icons/exported.svg';
import ContactsLinkIcon from '~/v2/assets/ContactsLinkIcon';
import { LeadCountSelector } from '../LeadCountSelector';
import { useState } from 'react';
import { webkitScrollbarConfig } from '~/v2/common/constants/webkitScrollbar';
import { Select, chakraComponents } from 'chakra-react-select';
import { ReactComponent as TooltipArrow } from '~/v2/assets/tooltip arrow.svg';
import { FORMATTED_EXPORT_OPTIONS } from '~/v2/constants/common';

export const AutopilotExportStep = ({
  selectedMode,
  leadsCount,
  setLeadsCount,
  exportProvider,
  setExportProvider,
}) => {
  const [controlShouldRenderValue, setControlShouldRenderValue] =
    useState(true);

  return (
    <AutopilotStepCard
      title={
        <Text fontWeight="500" fontSize="14px" lineHeight="16px">
          Export *
        </Text>
      }
      subtitle="Download a CSV and engage with the leads the way you’re used to"
      icon={ExportedIcon}
      content={
        <Flex
          flexDir="column"
          gap="16px"
          w="100%"
          p="16px 16px 16px 0"
          alignSelf="end"
        >
          {selectedMode !== 'inboundEnrichment' && (
            <>
              <Text
                sx={{
                  fontWeight: 400,
                  fontSize: '13px',
                  lineHeight: '16px',
                  color: 'trueDim',
                }}
              >
                Choose how many leads you need that passed all checkpoints of
                the automation
              </Text>
              <Flex alignItems="center" gap="16px">
                <Flex alignItems="center" gap="8px">
                  <Icon
                    as={ContactsLinkIcon}
                    color="trueDim"
                    w="24px"
                    h="24px"
                  />
                  <LeadCountSelector
                    leadsCount={leadsCount}
                    setLeadsCount={setLeadsCount}
                  />
                </Flex>
                <Text
                  sx={{
                    fontWeight: 500,
                    fontSize: '12px',
                    lineHeight: '14px',
                    color: 'trueDim',
                  }}
                >
                  Leads
                </Text>
              </Flex>
            </>
          )}
          <Text
            sx={{
              fontWeight: 400,
              fontSize: '13px',
              lineHeight: '16px',
              color: 'trueDim',
            }}
          >
            Pick a CSV type between full data or tailored for your CRM or
            Outreach tool
          </Text>
          <Select
            value={exportProvider}
            options={FORMATTED_EXPORT_OPTIONS}
            onChange={(option) => {
              setExportProvider(option);
            }}
            getOptionLabel={(option) => (
              <Flex alignItems="center" gap="8px">
                <Icon as={option.icon} w="24px" h="24px" />
                <span className="option-label">{option.label}</span>
              </Flex>
            )}
            blurInputOnSelect
            placeholder="Select CSV type"
            controlShouldRenderValue={controlShouldRenderValue}
            onMenuOpen={() => setControlShouldRenderValue(false)}
            onMenuClose={() => setControlShouldRenderValue(true)}
            chakraStyles={{
              control: (baseStyle) => ({
                ...baseStyle,
                border: 'none',
                borderRadius: '0',
                minHeight: '24px',
                maxHeight: '24px',
                width: '228px',
                cursor: 'pointer',
                bg: 'white',
              }),
              valueContainer: (baseStyle) => ({
                ...baseStyle,
                fontSize: '12px',
                color: 'trueSpace',
                lineHeight: '14px',
                fontWeight: '500',
                p: 0,
              }),
              dropdownIndicator: () => ({ color: 'trueDim' }),
              menuList: (baseStyle) => ({
                ...baseStyle,
                borderRadius: '4px',
                overflowY: 'overlay',
                p: 0,
                boxShadow: 'none',
                maxH: '200px',
                ...webkitScrollbarConfig(),
              }),
              menu: (baseStyle) => ({
                ...baseStyle,
                mt: 0,
                maxW: '300px',
                overflow: 'hidden',
                zIndex: '999',
              }),
              option: (baseStyle, { isSelected }) => ({
                ...baseStyle,
                h: '40px',
                backgroundColor: 'white',
                p: '8px 16px',
                color: 'trueSpace',
                fontSize: '12px',
                lineHeight: '14px',
                fontWeight: '500',
                svg: { color: 'trueDim' },
                '&:hover': {
                  backgroundColor: '#c3e6fa',
                  svg: { color: 'trueLink' },
                },
                ...(isSelected && { color: 'trueSpace !important' }),
              }),
            }}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: (props) => (
                <chakraComponents.DropdownIndicator {...props}>
                  <TooltipArrow />
                </chakraComponents.DropdownIndicator>
              ),
            }}
            zIndex="999"
          />
        </Flex>
      }
    />
  );
};
