import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const truebase = defineStyle({
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: '400',
  color: 'trueGray',
});

export const formLabelTheme = defineStyleConfig({
  variants: { truebase },
  defaultProps: {
    variant: 'truebase',
  },
});
