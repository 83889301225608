import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import Layout from '../../../common/Layout';
import { useAuthenticate } from '../../../common/requiresAuth';
import AutopilotCards from './AutopilotCards';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FilterProvider } from '../../filters/components/FilterProvider';
import { useFilterContext } from '../../filters/components/useFilterContext';
import { AutopilotCardIcon } from '~/v2/assets/icons/AutopilotCardIcon';
import { getSelectedIcp } from '~/v2/redux/slices/icpSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetAutopilotsQuery } from '~/v2/redux/services/autopilots';
import { isUndefined } from 'lodash';
import { getSelectedTeam } from '~/v2/redux/slices/teamsSlice';
import { ReactComponent as CloudsImage } from '~/v2/assets/cloud-single.svg';

const AutopilotsPageWithoutProviders = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const selectedIcp = useSelector(getSelectedIcp);
  const selectedTeam = useSelector(getSelectedTeam);

  const { setCompanyFilters, setLeadFilters } = useFilterContext();

  const [page, setPage] = useState(0);

  const {
    data: autopilotsResponse,
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetAutopilotsQuery(
    { teamId: selectedTeam?._id, page, limit: 10 },
    { skip: isUndefined(selectedTeam?._id), refetchOnMountOrArgChange: true }
  );

  const showEmptyState = useMemo(
    () =>
      !isUninitialized &&
      !isLoading &&
      !isFetching &&
      !autopilotsResponse?.result?.autopilots?.length,
    [
      autopilotsResponse?.result?.autopilots?.length,
      isFetching,
      isLoading,
      isUninitialized,
    ]
  );

  useEffect(() => {
    setCompanyFilters(selectedIcp?.companyFilterStatements || []);
    setLeadFilters(selectedIcp?.personFilterStatements || []);
  }, [
    selectedIcp?.companyFilterStatements,
    selectedIcp?.personFilterStatements,
    setCompanyFilters,
    setLeadFilters,
  ]);

  useEffect(() => {
    const onBeforeUnload = () => {
      location.state = {};
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [location]);

  return (
    <Layout>
      <Box w="100%" h="100%">
        <Helmet title={'Truebase | Autopilots'} defer={false} />
        <Flex
          justifyContent="space-between"
          alignItems="center"
          m="24px 0 26px"
          pos="relative"
        >
          <Flex alignItems="center" gap="16px">
            <AutopilotCardIcon color="#0F0F4D" width={48} height={48} />
            <Box gap="4px">
              <Text
                fontWeight="700"
                fontSize="22px"
                lineHeight="26px"
                color="trueSpace"
              >
                Autopilots
              </Text>
              <Text fontSize="14px" lineHeight="26px" color="trueDim">
                Track the results of your AI copilot
              </Text>
            </Box>
          </Flex>
        </Flex>
        {showEmptyState ? (
          <Flex flexDir="column" gap="24px" mt="132px">
            <CloudsImage />
            <Text fontSize="16px" fontWeight="500" lineHeight="20px">
              You’ll see detailed stats of your Autopilots here once you launch
              the first one.
            </Text>
            <Button
              variant="truebaseOutline"
              w="fit-content"
              onClick={() => {
                navigate(`/autopilot`);
              }}
            >
              Launch Autopilot
            </Button>
            <Text fontSize="14px" fontWeight="400" lineHeight="16px">
              If you don’t feel ready just yet, you can start by defining your
              ICP or setting scoring criteria.
            </Text>
            <Flex gap="16px">
              <Button
                variant="truebaseOutlineNeutral"
                onClick={() => {
                  navigate(`/settings/icp`);
                }}
              >
                ICP Definition
              </Button>
              <Button
                variant="truebaseOutlineNeutral"
                onClick={() => {
                  navigate(`/settings/qualification`);
                }}
              >
                Account Qualification
              </Button>
            </Flex>
          </Flex>
        ) : (
          <AutopilotCards
            autopilotsResponse={autopilotsResponse}
            isLoading={isLoading}
            isFetching={isFetching}
            isUninitialized={isUninitialized}
            setPage={setPage}
          />
        )}
      </Box>
    </Layout>
  );
};
const AutopilotsPageWithProviders = () => {
  useAuthenticate();
  const selectedIcp = useSelector(getSelectedIcp);

  return (
    <FilterProvider
      initialCompanyFilters={selectedIcp?.companyFilterStatements || []}
      initialLeadFilters={selectedIcp?.personFilterStatements || []}
    >
      <AutopilotsPageWithoutProviders />
    </FilterProvider>
  );
};

export default AutopilotsPageWithProviders;
