import { TruebaseTooltip } from '../../TruebaseTooltip';
import { Box } from '@chakra-ui/react';

export default function InfoWithTooltip({ label, children }) {
  return (
    <TruebaseTooltip
      size="lg"
      hasArrow
      placement="bottom"
      label={label}
      maxWidth="700px"
    >
      <Box as="span">{children}</Box>
    </TruebaseTooltip>
  );
}
