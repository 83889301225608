import { Box, Text } from '@chakra-ui/react';
import { AnimatePresence, backInOut, motion } from 'framer-motion';
import React from 'react';

export default function OnboardingTitle({ children, id }) {
  return (
    <AnimatePresence mode="sync">
      <Box
        key={id}
        fontSize={{ base: '24px', md: '32px' }}
        fontWeight={700}
        lineHeight={{ base: '28px', md: '34px' }}
      >
        <motion.div
          initial={{
            opacity: 0,
            x: 100,
          }}
          animate={{
            opacity: 1,
            x: 0,
            // transition: {
            //   x: {
            //     type: '',
            //     easings: ['backInOut'],
            //     duration: 3,
            //     repeat: 3,
            //   },
            // },
          }}
          transition={{
            type: 'spring',
            stiffness: 75,
            duration: 1,
          }}
          // exit={{ opacity: 0, x: -50 }}
        >
          {children}
        </motion.div>
      </Box>
    </AnimatePresence>
  );
}
