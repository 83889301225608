import { useEffect, useState } from 'react';
import { Flex, useToast, Button } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useStartGenerateInsightsMutation } from '~/v2/redux/services/leads';
import useStreamingForm from '../../../hooks/useStreamingForm';
import { ReactComponent as AIMagic } from '../../../assets/icons/AI magic.svg';
import { get } from 'lodash';
import TabEmptyState from '../components/TabEmptyState';
import { StreamingTextArea } from '../../Truebase/TruebaseStreamingTextArea';
import TruebaseCenteredClouds from '../../Truebase/TruebaseCenteredClouds';
import TruebaseChatContainer from '../../TruebaseChatContainer';
import { isEmpty } from 'lodash';
import useErrorHandler from '~/v2/hooks/useErrorHandler';

const insightsFields = [
  'metrics',
  'challenges',
  'goals',
  'skills',
  'achievements',
];

const LeadInsights = ({ readonly, lead }) => {
  const { handleAsyncError } = useErrorHandler();
  const [generateInsights] = useStartGenerateInsightsMutation();
  const toast = useToast();

  const { methods } = useStreamingForm({
    inProgressListener:
      ({ reset, setValue, getValues }) =>
      ({ path, text, loading, identifier }) => {
        if (identifier !== lead?.personId) return;
        const currentVal = getValues(`leadInsights.content`);
        if (loading) {
          setGeneratingPath('leadInsights.content');
          reset({});
        } else {
          const pathText = getPathText(path);
          setGeneratingPath('leadInsights.content');
          setValue(
            'leadInsights.content',
            (currentVal || '') +
              (currentVal.includes(`${pathText}:`)
                ? ''
                : `${currentVal ? '\n\n' : ''}${pathText}:\n`) +
              text
          );
        }
      },
    completeListener:
      () =>
      ({ identifier }) => {
        if (identifier !== lead?.personId) return;
        setGeneratingPath(false);
      },
    failedListener:
      () =>
      ({ identifier }) => {
        if (identifier !== lead?.personId) return;
        if (generatingPath) setGeneratingPath(false);
        if (!toast.isActive(`personalizationUpdated-failed-${identifier}`)) {
          toast({
            id: `personalizationUpdated-failed-${identifier}`,
            title: 'Error',
            description: 'Something went wrong, please try again later',
            status: 'error',
            duration: 5000,
            isClosable: false,
          });
        }
      },
    socketIdentifiers: {
      inProgress: 'personInsights-inProgress',
      complete: 'personInsights-completed',
      failed: 'personalizationUpdated-failed',
    },
  });
  const leadInsightsContent = methods.watch('leadInsights.content');
  const hasOverview = !isEmpty(lead.insights || leadInsightsContent);

  function getPathText(path) {
    const name = path?.substring(0, path.indexOf('.'));
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  useEffect(() => {
    let insights = '';

    if (!isEmpty(lead?.insights)) {
      insightsFields.map((field) => {
        insights += `${field.charAt(0).toUpperCase() + field.slice(1)}:\n${
          lead?.insights?.[field]?.content
        }\n\n`;
      });
    }

    methods.reset({ leadInsights: { content: insights } });
  }, [lead?.insights, methods]);

  const [generatingPath, setGeneratingPath] = useState(false);

  const handleStartLazyQuery = handleAsyncError(() => {
    methods.reset({ leadInsights: { content: '' } });
    setGeneratingPath('leadInsights.content');
    generateInsights({
      personId: lead.personId,
      companyId: lead.companyId,
    });
  });

  return (
    <>
      {readonly && !get(lead, 'insights') ? (
        <TabEmptyState tabName="leadInsights" />
      ) : (
        <>
          <FormProvider {...methods}>
            <form>
              <Flex
                direction="column"
                gap={isEmpty(lead?.insights) ? '16px' : '0'}
                mb={isEmpty(lead?.insights) ? '0' : '16px'}
              >
                {!generatingPath && !hasOverview ? (
                  <TruebaseChatContainer position="relative" minH="120px">
                    <TruebaseCenteredClouds text="Generate up-to-date lead insights in real time" />
                  </TruebaseChatContainer>
                ) : (
                  <StreamingTextArea
                    isDisabled
                    label="Lead Insights"
                    name="leadInsights"
                  />
                )}

                {!readonly && isEmpty(lead?.insights) && (
                  <Button
                    w="fit-content"
                    h="32px"
                    variant="truebaseOutlineNeutral"
                    onClick={handleStartLazyQuery}
                    isDisabled={!!generatingPath}
                    leftIcon={<AIMagic />}
                    pl="9px"
                  >
                    Generate
                  </Button>
                )}
              </Flex>
            </form>
          </FormProvider>
        </>
      )}
    </>
  );
};

export default LeadInsights;
