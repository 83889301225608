import { useEffect, useMemo } from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import HitsAndMisses from '../components/HitsAndMisses';
import { useSelector } from 'react-redux';
import QualifyTab from './QualifyTab';
import LeadsTab from './LeadsTab';
import { getStatus } from '~/v2/redux/slices/statusSlice';
import { useFilterContext } from '~/v2/features/filters/components/useFilterContext';
import { useSpotlightSearchContext } from '../../SpotlightSearch/useSpotlightSearchContext';
import AccountInsights from './AccountInsights';
import { getSelectedTeam } from '~/v2/redux/slices/teamsSlice';

export default function AccountTabs({
  readonly,
  showHitsAndMisses = false,
  account,
  companyFilters,
  leadsResponse,
  tabIndex,
  setTabIndex,
}) {
  const isGeneratingAccountScore = useSelector(getStatus)(
    account.companyId
  )?.find(({ type }) => type === 'accountScoring');

  const { trainView } = useFilterContext();
  const isQualificationView = trainView === 'qualification';
  const { isSpotlightSearchMode } = useSpotlightSearchContext();

  useEffect(() => {
    if (isGeneratingAccountScore && !isSpotlightSearchMode) {
      setTabIndex(0);
    }
  }, [isGeneratingAccountScore, setTabIndex, isSpotlightSearchMode]);

  return (
    <Tabs
      isLazy
      lazyBehavior="keepMounted"
      position="relative"
      variant="truebase"
      index={tabIndex}
      onChange={(i) => setTabIndex(i)}
    >
      <TabList>
        {!isQualificationView && !isSpotlightSearchMode && <Tab>Leads</Tab>}
        {!isQualificationView && !isSpotlightSearchMode && <Tab>Insights</Tab>}
        {isQualificationView && <Tab>Score</Tab>}
        {!isQualificationView &&
          showHitsAndMisses &&
          !isSpotlightSearchMode && <Tab>Search Evaluation</Tab>}
      </TabList>
      <TabPanels>
        {!isQualificationView && !isSpotlightSearchMode && (
          <TabPanel p="unset">
            <LeadsTab
              readonly={readonly}
              account={account}
              showHitsAndMisses={showHitsAndMisses}
              companyFilters={companyFilters}
              leadsResponse={leadsResponse}
            />
          </TabPanel>
        )}
        {!isQualificationView && !isSpotlightSearchMode && (
          <TabPanel>
            <AccountInsights account={account} readonly={readonly} />
          </TabPanel>
        )}
        {isQualificationView && (
          <TabPanel display="flex" gap="16px" flexDir="column">
            <QualifyTab readonly={readonly} account={account} />
          </TabPanel>
        )}
        {!isQualificationView &&
          showHitsAndMisses &&
          !isSpotlightSearchMode && (
            <TabPanel>
              <HitsAndMisses
                companyFilters={companyFilters}
                companyId={account.companyId}
              />
            </TabPanel>
          )}
      </TabPanels>
    </Tabs>
  );
}
