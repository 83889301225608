import { useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { useIsVisible } from '~/v2/hooks/useIsVisible';
import SequencesList from './SequencesList';

const SequencesListWrapper = ({
  chosenTags,
  contactsData,
  contactsState,
  setPage,
}) => {
  const loadMoreRef = useRef(null);
  const isLoadMoreVisible = useIsVisible(loadMoreRef);

  useEffect(() => {
    if (
      isLoadMoreVisible &&
      !contactsData?.isLoading &&
      !contactsData?.isFetching &&
      contactsData?.result?.matches?.length !== contactsData?.result?.totalCount
    ) {
      setPage((p) => p + 1);
    }
  }, [
    contactsData?.isFetching,
    contactsData?.isLoading,
    contactsData?.result?.matches?.length,
    contactsData?.result?.totalCount,
    isLoadMoreVisible,
    setPage,
  ]);

  return (
    <>
      <SequencesList
        contactsData={contactsData}
        contactsState={contactsState}
        chosenTags={chosenTags}
      />
      <Box
        ref={loadMoreRef}
        display={
          contactsData?.result?.matches?.length ===
          contactsData?.result?.totalCount
            ? 'none'
            : 'block'
        }
      />
    </>
  );
};
export default SequencesListWrapper;
