import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import {
  Flex,
  Box,
  Text,
  Spacer,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  Divider,
  useDisclosure,
  Portal,
} from '@chakra-ui/react';
import {
  removeEmptyStringsFromObject,
  removeNullValues,
} from '~/v2/common/utils';
import {
  useAddEmailMutation,
  useUpdateTeamMutation,
} from '~/v2/redux/services/teams';
import EmailSetupModal from './EmailSetupModal';
import { ReactComponent as GmailIcon } from '~/v2/assets/icons/provider_icons/gmail.svg';
import { ReactComponent as ThreeDots } from '~/v2/assets/icons/more/more-vertical.svg';
import { ReactComponent as MailDeliveredIcon } from '~/v2/assets/icons/mail/mail-delivered.svg';
import { ReactComponent as EditIcon } from '~/v2/assets/icons/edit/edit-1.svg';
import { ReactComponent as PauseIcon } from '~/v2/assets/icons/pause.svg';
import { ReactComponent as ResumeIcon } from '~/v2/assets/icons/play-individual.svg';
import { ReactComponent as DisconnectIcon } from '~/v2/assets/icons/clear/clear-large.svg';

const StyledText = ({ children, ...props }) => (
  <Text
    fontSize="14px"
    lineHeight="15px"
    fontWeight={500}
    color="trueSpace"
    textAlign="center"
    {...props}
  >
    {children}
  </Text>
);

const StyledDivider = () => (
  <Divider
    borderColor="trueLight"
    opacity="1"
    orientation="vertical"
    h="24px"
  />
);

export const EmailPreview = ({
  field,
  emailSentCounts = {},
  index,
  showMenu,
  onEditClick,
  onDeleteClick,
  onToggleActivity,
  ...props
}) => (
  <Box
    p="16px"
    bg="trueBg02"
    borderRadius="2px"
    border="1px solid"
    borderColor="trueLight"
    {...props}
  >
    <Flex alignItems="center" gap="16px">
      <Box sx={{ svg: { h: '24px', w: '24px' } }}>
        <GmailIcon />
      </Box>
      <StyledText color="trueExplain">{field._id}</StyledText>
      <Spacer />
      <StyledText color={!field.isActive && 'trueExplain'} w="48px">
        {field.isActive ? 'Active' : 'Paused'}
      </StyledText>
      <StyledDivider />
      <StyledText w="88px">
        {emailSentCounts?.sentToday}/{field.dailyLimit} Daily
      </StyledText>
      <StyledDivider />
      <Flex
        alignItems="center"
        gap="4px"
        sx={{ svg: { w: '17px', h: '17px' } }}
        w="92px"
      >
        <MailDeliveredIcon />
        <StyledText ml="4px">{emailSentCounts?.sentAllTime}</StyledText>
      </Flex>
      {showMenu && (
        <>
          <StyledDivider />
          <Menu variant="truebase">
            <MenuButton
              onClick={(e) => e.stopPropagation()}
              as={IconButton}
              w="24px"
              h="24px"
              minW={0}
              className="showOnHover"
              sx={{
                svg: { fill: 'trueDim' },
              }}
              variant="ghost"
              icon={<ThreeDots />}
            />
            <Portal>
              <MenuList>
                <MenuItem onClick={() => onEditClick(index)}>
                  <EditIcon />
                  <Text>Edit</Text>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onToggleActivity(index);
                  }}
                >
                  {field.isActive ? <PauseIcon /> : <ResumeIcon />}
                  <Text>{field.isActive ? 'Pause' : 'Activate'}</Text>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onDeleteClick(index);
                  }}
                >
                  <DisconnectIcon />
                  <Text>Disconnect</Text>
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        </>
      )}
    </Flex>
  </Box>
);

const EmailAccountsPreview = ({ teamId, emailAccounts, emailStats }) => {
  const emailAccountsMethods = useForm({
    defaultValues: { emailAccounts },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { fields, remove, update } = useFieldArray({
    control: emailAccountsMethods.control,
    name: 'emailAccounts',
  });
  const [addEmail, { isLoading: isAddEmailLoading }] = useAddEmailMutation();
  const [updateTeam, { isLoading: isUpdateTeamLoading }] =
    useUpdateTeamMutation();
  const toast = useToast();
  const [selectedEmailIndex, setSelectedEmailIndex] = useState(null);
  const {
    onOpen: onSetupEmailOpen,
    isOpen: isSetupEmailOpen,
    onClose: onSetupEmailClose,
  } = useDisclosure();

  const handleUpdateTeam = async (emailAccounts) => {
    const res = await updateTeam({
      teamId,
      emailAccounts: removeEmptyStringsFromObject(emailAccounts),
    });
    if (!res?.data?.isOk) {
      toast({
        title: 'Error',
        description: 'Something went wrong!',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      throw new Error('Something went wrong!');
    }
  };

  const onEditClick = (index) => {
    setSelectedEmailIndex(index);
    onSetupEmailOpen();
  };

  const onToggleActivity = async (index) => {
    const newEmailAccounts = fields.map((email, i) =>
      index === i
        ? { ...fields[index], isActive: !fields[index].isActive, id: undefined }
        : { ...email, id: undefined }
    );
    await handleUpdateTeam(newEmailAccounts);
    update(index, { ...fields[index], isActive: !fields[index].isActive });
  };

  const onDeleteClick = async (index) => {
    const newEmailAccounts = fields
      .filter((_el, i) => i !== index)
      .map((email) => ({ ...email, id: undefined }));
    await handleUpdateTeam(newEmailAccounts);
    remove(index);
  };

  const handleModalClose = () => {
    emailAccountsMethods.reset();
    onSetupEmailClose();
  };

  const handleAddEmailAccount = async () => {
    try {
      const { data: url } = await addEmail({ teamId });
      window.location.href = url;
    } catch {
      toast({
        title: 'Error',
        description: 'Something went wrong!',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const onSubmit = async (values, e) => {
    e.preventDefault();
    try {
      await handleUpdateTeam(
        values.emailAccounts.map((email) =>
          removeNullValues({ ...email, id: null })
        )
      );
      emailAccountsMethods.reset({ ...values });
      toast({
        description: 'Email Account updated successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch {
      emailAccountsMethods.reset();
    }
    onSetupEmailClose();
  };

  const AddEmailAccountButton = () => (
    <Button
      sx={{
        p: '16px',
        border: '1px dashed',
        borderColor: 'trueSky',
        borderRadius: '2px',
        bg: 'transparent',
        width: '100%',
        alignItems: 'center',
        justifyContent: isAddEmailLoading ? 'center' : 'start',
        fontSize: '14px',
        lineHeight: '15px',
        fontWeight: 400,
        color: 'trueDim',
        h: 'min-content',
        svg: {
          w: '24px',
          h: '24px',
        },
      }}
      leftIcon={<GmailIcon />}
      onClick={handleAddEmailAccount}
      isLoading={isAddEmailLoading}
    >
      Connect a Gmail account
    </Button>
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const error = searchParams.get('error');
    const newEmail = searchParams.get('configure');
    if (newEmail && fields) {
      const newSelectedIndex = fields.findIndex(
        (email) => email._id === newEmail
      );
      setSelectedEmailIndex(newSelectedIndex);
      onSetupEmailOpen();
    } else if (error) {
      toast({
        title: 'Error',
        description:
          error === 'dup' ? 'Email already exists!' : 'Failed to add email!',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      id="emailAccountsForm"
      onSubmit={emailAccountsMethods.handleSubmit(onSubmit)}
    >
      <Flex direction="column" gap="16px">
        {fields.map((field, index) => (
          <EmailPreview
            field={field}
            emailSentCounts={emailStats[field._id]}
            index={index}
            showMenu
            key={field._id}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            onToggleActivity={onToggleActivity}
          />
        ))}
        <AddEmailAccountButton />
        <FormProvider {...emailAccountsMethods}>
          <EmailSetupModal
            selectedEmailIndex={selectedEmailIndex}
            isOpen={isSetupEmailOpen}
            onClose={handleModalClose}
            isUpdateTeamLoading={isUpdateTeamLoading}
            emailSentCounts={emailStats[fields?.[selectedEmailIndex]?._id]}
          />
        </FormProvider>
      </Flex>
    </form>
  );
};

export default EmailAccountsPreview;
