import { Select, chakraComponents } from 'chakra-react-select';
import { ReactComponent as TooltipArrow } from '../assets/icons/arrow/dropdown.svg';
import React from 'react';

export default function TruebaseComboBox({
  value,
  onChange,
  formatOptionLabel,
  options,
  styles,
  components,
  ...rest
}) {
  return (
    <Select
      isSearchable={false}
      menuPortalTarget={document.body}
      chakraStyles={{
        control: (baseStyle, state) => ({
          ...baseStyle,
          borderColor: state.isFocused ? 'trueLink' : 'trueLight',
          boxShadow: state.isFocused
            ? '0px 0px 10px rgba(2, 128, 245, 0.25) !important'
            : 'none',
          bgColor: 'white',
          borderRadius: '4px',
          h: '48px',
        }),
        valueContainer: (baseStyle) => ({
          ...baseStyle,
          padding: '8px 16px',
          fontSize: '13px',
          lineHeight: '15px',
          color: 'trueMedium',
          fontWeight: '500',
        }),
        singleValue: (baseStyle) => ({
          ...baseStyle,
          color: 'trueSpace',
          fontSize: '12px',
          lineHeight: '14px',
          svg: {
            display: 'none',
          },
        }),
        dropdownIndicator: (prev, { selectProps }) => ({
          padding: '8px 16px',
          color: 'trueDim',
          '> svg': {
            transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
          },
        }),
        menuList: (baseStyle) => ({
          ...baseStyle,
          borderRadius: '4px',
        }),
        menu: (baseStyle) => ({
          ...baseStyle,
          mt: 0,
        }),
        menuPortal: (baseStyle) => ({
          ...baseStyle,
          top: 0,
          zIndex: 1000,
        }),
        option: (baseStyle) => ({
          ...baseStyle,
          backgroundColor: 'white',
          '.option-label': {
            color: 'trueSpace',
            fontSize: '12px',
            lineHeight: '14px',
            fontWeight: '500',
          },
          padding: '13px 16px',
          '&:hover': {
            backgroundColor: 'trueSkylight',
          },
        }),
        ...styles,
      }}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: (props) => (
          <chakraComponents.DropdownIndicator {...props}>
            <TooltipArrow />
          </chakraComponents.DropdownIndicator>
        ),
        ...components,
      }}
      value={value}
      onChange={onChange}
      formatOptionLabel={formatOptionLabel}
      options={options}
      blurInputOnSelect
      {...rest}
    />
  );
}
