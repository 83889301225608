/* eslint-disable no-restricted-globals */
import ReactGA from 'react-ga';

export const logout = () => {
  localStorage.removeItem('AccessToken');
  localStorage.removeItem('User');
  localStorage.removeItem('inviteLinkData');
};

// don't track internal users
export const isEmailDomainFromTruebase = (email) => {
  return email ? email.endsWith('@truebase.io') : false;
};

export const getQueryParam = (path = '', paramName = '') => {
  if (!path || !paramName) return '';
  const urlIndex = path.indexOf(`${paramName}=`);
  if (urlIndex > -1) {
    const paramlength = paramName.length + 1;
    const endIndex = path.indexOf('&', urlIndex + 1);
    if (endIndex > -1) {
      return path.substr(
        urlIndex + paramlength,
        endIndex - (urlIndex + paramlength)
      );
    } else {
      return path.substr(urlIndex + paramlength);
    }
  }
  return '';
};

export const initIntercom = () => {
  if (process.env.REACT_APP_INTERCOM_APP_ID) {
    const intercomOptions = {
      hide_default_launcher: true,
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    };
    window.Intercom('boot', intercomOptions);
    const checkIntercomBoot = setInterval(() => {
      if (window.Intercom('getVisitorId')) {
        clearInterval(checkIntercomBoot);
        localStorage.setItem(
          'INTERCOM_VISITOR_ID',
          window.Intercom('getVisitorId')
        );
      }
    }, 100);
    setTimeout(() => {
      clearInterval(checkIntercomBoot);
    }, 60 * 1000);
  }
};

export const updateIntercom = (user) => {
  if (process.env.REACT_APP_INTERCOM_APP_ID) {
    const intercomOptions = {
      hide_default_launcher: false,
      user_id: user?.id,
      email: user?.email,
      _truebase_has_access_to_manage_app: isEmailDomainFromTruebase(
        user?.email
      ),
    };
    if (user?.firstName && user?.lastName)
      intercomOptions.name = `${user?.firstName} ${user?.lastName}`;
    window.Intercom('update', intercomOptions);
  }
};

export const addAttributeToIntercom = (attr) => {
  window.Intercom('boot', attr);
};

export const trackEvent = (category, action, label, trackers = ['ga']) => {
  trackers.forEach((tracker) => {
    switch (tracker) {
      case 'ga':
        ReactGA.event({
          category: category,
          action: action,
          label: label,
        });
        break;
      case 'intercom':
        window.Intercom('trackEvent', label, action);
        break;
      default:
        break;
    }
  });
};

export const trackPage = (page) => {
  ReactGA.pageview(page);
};

export const trackCurrentUser = (user) => {
  if (user) {
    const { id } = user;
    ReactGA.set({
      userId: id,
    });
  }
};

export const limitStr = (str, limit) => {
  return str.length > limit ? str.substr(0, limit - 3) + '...' : str;
};

export const encodeURL = (str) => {
  return encodeURIComponent(str).replace('(', '%28').replace(')', '%29');
};

export const getEmailFromString = (str) => {
  const email = str.match(
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
  );
  if (email) return email.join();
  return '';
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function capitalizeFirstLetter(string) {
  if (string) return string.charAt(0).toUpperCase() + string.slice(1);
}

export function cleanUpSessionStorageByKeys(keys) {
  return keys.forEach((key) => sessionStorage.removeItem(key));
}

export function getInitials(name) {
  if (!name) return '';
  const hasTokens = name.indexOf(' ') !== -1;
  const isEdgeCase = name.indexOf('(') !== -1;
  let arrayForStrings = name.split(' ');
  if (isEdgeCase) {
    return (
      name.substring(0, hasTokens ? 1 : 2) +
      (hasTokens ? arrayForStrings[arrayForStrings.length - 2][0] : '')
    );
  }
  return (
    name.substring(0, hasTokens ? 1 : 2) +
    (hasTokens ? name.charAt(name.lastIndexOf(' ') + 1) : '')
  );
}

export function showICPLabel(icpLength) {
  return !icpLength || icpLength <= 1 ? 'ICP' : 'ICPs';
}

export function eventKeyComparisons(eventKey, comparison, method = 'equal') {
  if (method === 'diff') {
    return comparison !== eventKey;
  }

  return (
    comparison === eventKey ||
    comparison + 1 === eventKey ||
    comparison + 2 === eventKey
  );
}

export function generateEventKeyForICPcard(id) {
  return `icp-card-for-${id}`;
}

export function randomNumberFromRange(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

export function transformLabeltoTitleCase(label) {
  if (!label) return '';
  const result = label.replace(/([A-Z]{1,})/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}
