import { Box, Text } from '@chakra-ui/react';

import { ReactComponent as CloudsImage } from '../../../assets/Clouds.svg';

const FiltersEmptyState = () => {
  return (
    <Box
      position="absolute"
      left="50%"
      top="50%"
      transform="translate(-50%, -50%)"
    >
      <Text
        fontWeight="500"
        fontSize="13px"
        lineHeight="15px"
        pos="relative"
        zIndex="2"
        whiteSpace="nowrap"
      >
        Search within all filters or browse into specific categories on the left
      </Text>
      <Box
        position="absolute"
        left="50%"
        top="50%"
        transform="translate(-50%, -50%)"
      >
        <CloudsImage />
      </Box>
    </Box>
  );
};

export default FiltersEmptyState;
