import { Badge, useStyleConfig } from '@chakra-ui/react';
import React from 'react';

export const TruebaseBadge = (props) => {
  const { ...rest } = props;
  const styles = useStyleConfig('TruebaseBadge', {
    variant: props.variant || 'basic',
  });
  return (
    <Badge sx={styles} {...rest}>
      {props.children}
    </Badge>
  );
};
