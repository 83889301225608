import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const truebase = definePartsStyle({
  field: {
    h: '48px',
    borderColor: 'trueLight',
    borderWidth: '1px',
    color: 'trueSpace',
    fontSize: '12px',
    lineHeight: '14px',
    borderRadius: '4px',
    py: '12px',
    px: '16px',
    fontWeight: 500,
    _placeholder: {
      color: 'trueMedium',
      fontSize: '12px',
      lineHeight: '14px',
    },
    '&:focus': {
      borderColor: 'trueLink',
      color: 'trueLink',
      boxShadow: ' 0px 0px 10px rgba(2, 128, 245, 0.25) !important',
      borderRadius: '4px',
    },
    '&:disabled': {
      opacity: 1,
      borderColor: 'trueDisabled',
      boxShadow: 'unset',
      color: 'trueDisabled',
    },
    '&[aria-invalid=true]': {
      borderColor: 'trueAttention',
      focusBorderColor: 'trueAttention',
    },
  },
});

const truebaseFakeDisabled = definePartsStyle({
  field: {
    borderColor: 'trueLight',
    borderWidth: '1px',
    color: 'trueSpace',
    fontSize: '12px',
    lineHeight: '16px',
    py: '12px',
    px: '16px',
    fontWeight: 500,
    borderRadius: '4px',
    _placeholder: {
      color: 'trueMedium',
      fontSize: '12px',
      lineHeight: '16px',
    },
    '&:focus': {
      borderColor: 'trueLink',
      color: 'trueLink',
      boxShadow: ' 0px 0px 10px rgba(2, 128, 245, 0.25) !important',
      borderRadius: '4px',
    },
    '&:disabled': {
      opacity: 1,
      boxShadow: 'unset',
      bgColor: 'white',
    },
    '&[aria-invalid=true]': {
      borderColor: 'trueAttention',
      focusBorderColor: 'trueAttention',
    },
  },
});

const truebaseSmallFakeDisabled = definePartsStyle({
  field: {
    borderColor: 'trueLight',
    borderWidth: '1px',
    color: 'trueSpace',
    fontSize: '12px',
    lineHeight: '14px',
    borderRadius: '4px',
    py: '12px',
    px: '16px',
    fontWeight: 500,
    _placeholder: {
      color: 'trueDim',
      fontSize: '12px',
      lineHeight: '14px',
    },
    '&:focus': {
      borderColor: 'trueLink',
      color: 'trueLink',
      boxShadow: ' 0px 0px 10px rgba(2, 128, 245, 0.25) !important',
      borderRadius: '4px',
    },
    '&:disabled': {
      opacity: 1,
      boxShadow: 'unset',
      bgColor: 'white',
    },
    '&[aria-invalid=true]': {
      borderColor: 'trueAttention',
      focusBorderColor: 'trueAttention',
    },
  },
});

const truebaseSmall = definePartsStyle({
  field: {
    borderColor: 'trueLight',
    borderWidth: '1px',
    color: 'trueSpace',
    fontSize: '12px',
    lineHeight: '14px',
    borderRadius: '4px',
    py: '12px',
    px: '16px',
    fontWeight: 500,
    _placeholder: {
      color: 'trueDim',
      fontSize: '12px',
      lineHeight: '14px',
    },
    '&:focus': {
      borderColor: 'trueLink',
      color: 'trueLink',
      boxShadow: ' 0px 0px 10px rgba(2, 128, 245, 0.25) !important',
      borderRadius: '4px',
    },
    '&:disabled': {
      opacity: 1,
      borderColor: 'trueDisabled',
      boxShadow: 'unset',
      color: 'trueDisabled',
    },
    '&[aria-invalid=true]': {
      borderColor: 'trueAttention',
      focusBorderColor: 'trueAttention',
    },
  },
});

export const inputTheme = defineMultiStyleConfig({
  variants: {
    truebase,
    truebaseSmall,
    truebaseFakeDisabled,
    truebaseSmallFakeDisabled,
  },
});
