import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const truebase = defineStyle({
  borderColor: 'trueLight',
  borderStyle: 'solid',
  borderWidth: '1px',
  color: 'trueSpace',
  fontSize: '12px',
  lineHeight: '14px',
  py: '12px',
  px: '16px',
  fontWeight: 500,
  _placeholderShown: {
    color: 'trueMedium',
  },
  _placeholder: {
    color: 'trueMedium',
    fontSize: '12px',
    lineHeight: '14px',
  },
  '&:focus': {
    borderColor: 'trueLink',
    color: 'trueLink',
    boxShadow: ' 0px 0px 10px rgba(2, 128, 245, 0.25) !important',
    borderRadius: '4px',
  },
  '&:disabled': {
    opacity: 1,
    borderColor: 'trueDisabled',
    boxShadow: 'unset',
    color: 'trueDisabled',
  },
  '&[aria-invalid=true]': {
    borderColor: 'trueAttention',
    focusBorderColor: 'trueAttention',
  },
});

const truebaseFakeDisabled = defineStyle({
  borderColor: 'trueLight',
  borderStyle: 'solid',
  borderWidth: '1px',
  color: 'trueSpace',
  fontSize: '12px',
  lineHeight: '14px',
  py: '12px',
  px: '16px',
  fontWeight: 500,
  whiteSpace: 'pre-wrap',
  _placeholderShown: {
    color: 'trueMedium',
  },
  _placeholder: {
    color: 'trueMedium',
    fontSize: '12px',
    lineHeight: '14px',
  },
  '&:focus': {
    borderColor: 'trueLink',
    color: 'trueLink',
    boxShadow: ' 0px 0px 10px rgba(2, 128, 245, 0.25) !important',
    borderRadius: '4px',
  },
  _disabled: {
    color: 'trueSpace',
    borderColor: 'trueLight',
    borderStyle: 'solid',
    borderWidth: '1px',
    opacity: 1,
    bgColor: 'white',
  },
  '&[aria-invalid=true]': {
    borderColor: 'trueAttention',
    focusBorderColor: 'trueAttention',
  },
});

export const textareaTheme = defineStyleConfig({
  variants: { truebase, truebaseFakeDisabled },
  defaultProps: {
    variant: 'truebase',
  },
});
