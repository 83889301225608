import { Text } from '@chakra-ui/react';
import { AutopilotStepCard } from '../AutopilotStepCard';
import { ReactComponent as SearchIcon } from '~/v2/assets/icons/search-global.svg';
import { ReactComponent as AutopilotSelectLogo } from '~/v2/assets/icons/autopilot-select.svg';

export const AutopilotSearchStep = () => {
  return (
    <AutopilotStepCard
      title={
        <Text fontWeight="500" fontSize="14px" lineHeight="16px">
          Search *
        </Text>
      }
      subtitle="We’ll automatically search for leads based on your ICP"
      icon={SearchIcon}
      selectLogo={AutopilotSelectLogo}
      content={<></>}
    />
  );
};
