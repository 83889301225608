import { safeJSONParse } from '~/v2/common/utils';
import { apiSlice } from './api';

export const usersApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['User'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMe: builder.query({
        async queryFn(arg, queryApi, extraOptions, baseQuery) {
          const userFromStorage = localStorage.getItem('User') || '';
          const accessToken = localStorage.getItem('AccessToken') || '';
          let user;
          if (!accessToken) return { data: null };
          if (arg?.forceLoading || !userFromStorage) {
            const response = await baseQuery({ url: 'user' });
            if (response.data.isOk) {
              return response;
            } else {
              return { data: null };
            }
          } else {
            user = safeJSONParse(userFromStorage);
            return { data: user };
          }
        },
      }),
      login: builder.mutation({
        query: (payload) => ({
          url: 'user/signin',
          method: 'POST',
          body: payload,
        }),
      }),
      getCurrentUser: builder.query({
        query: () => ({
          url: 'user',
        }),
        providesTags: ['User'],
      }),
      signup: builder.mutation({
        query: (payload) => ({
          url: 'user/signup',
          method: 'POST',
          body: { ...payload, nonProtected: undefined },
          nonProtected: payload.nonProtected,
        }),
      }),
      verifyAccessToken: builder.query({
        query: (accessToken) => ({
          url: `user/verify-access-token?accessToken=${accessToken}`,
          nonProtected: true,
        }),
      }),
      verifyUser: builder.mutation({
        query: (payload) => ({
          url: 'user/verify-email',
          method: 'POST',
          body: payload,
          nonProtected: true,
        }),
      }),
      forgotPassword: builder.mutation({
        query: (payload) => ({
          url: 'user/request-reset-password',
          method: 'POST',
          body: payload,
          nonProtected: true,
        }),
      }),
      confirmUser: builder.mutation({
        query: (payload) => ({
          url: 'user/confirm',
          method: 'POST',
          body: payload,
          nonProtected: true,
        }),
      }),
      resetPassword: builder.mutation({
        query: (payload) => ({
          url: 'user/reset-password',
          method: 'POST',
          body: payload,
          nonProtected: true,
        }),
      }),
      edit: builder.mutation({
        query: (payload) => ({
          url: 'user',
          method: 'PATCH',
          body: payload,
        }),
        invalidatesTags: ['User'],
      }),
      sendVerificationCode: builder.mutation({
        query: (payload) => ({
          url: 'user/request-verify-email',
          method: 'POST',
          body: payload,
        }),
      }),
      sendMobileOnboardingEmail: builder.mutation({
        query: (payload) => ({
          url: 'user/send-mobile-onboarding-email',
          method: 'POST',
          body: payload,
        }),
      }),
      subscribeToNewsletter: builder.mutation({
        query: (payload) => ({
          url: 'user/subscribe-to-newsletter',
          method: 'POST',
          body: payload,
        }),
      }),
      upgradeLeadsCount: builder.mutation({
        query: (payload) => ({
          url: 'user/upgrade-leads-count',
          method: 'POST',
          body: payload,
        }),
      }),
      upgradeImportFile: builder.mutation({
        query: (payload) => ({
          url: 'user/upgrade-import-file',
          method: 'POST',
          body: payload,
        }),
      }),
    }),
  });

export const {
  useGetStatsQuery,
  useLoginMutation,
  useLazyGetCurrentUserQuery,
  useLazyGetMeQuery,
  useSignupMutation,
  useVerifyUserMutation,
  useLazyVerifyAccessTokenQuery,
  useResetPasswordMutation,
  useForgotPasswordMutation,
  useSendVerificationCodeMutation,
  useConfirmUserMutation,
  useEditMutation,
  useGetCurrentUserQuery,
  useSendMobileOnboardingEmailMutation,
  useSubscribeToNewsletterMutation,
  useUpgradeLeadsCountMutation,
  useUpgradeImportFileMutation,
} = usersApi;
