/* eslint-disable no-case-declarations */
import { ObjectId } from 'bson';
import configLocations from '@truebase/common/config/locations.json';
import * as _ from 'lodash';

export const locationTypeMap = {
  1: 'continent',
  2: 'continentRegion',
  3: 'country',
  4: 'countryRegion',
  5: 'area',
  6: 'state',
  7: 'city',
};

const checkIfAbsoluteUrl = (url) => {
  const regex = /^(?:[a-z0-9]+:)?\/\/([\w-]+(?:\.[\w-]+(:[0-9]+)*)+)/g;
  if (url.match(regex)) return true;
  return false;
};

export const id = {
  new: () => new ObjectId(),
};

export const getLocationValue = (location) => {
  if (location.value) return location.value;
  const locationArray = location.split('>');
  let type = locationTypeMap[locationArray.length];
  let intendedLocation = locationArray.pop();

  switch (type) {
    case 'continent':
      return Object.values(configLocations.continents).find(
        (c) => c.continent.toLowerCase() === intendedLocation.toLowerCase()
      ).continent;
    case 'continentRegion':
      return Object.values(configLocations.continentsRegions).find(
        (c) =>
          c.continentRegion.toLowerCase() === intendedLocation.toLowerCase()
      ).continentRegion;
    case 'country':
      return Object.values(configLocations.countries).find(
        (c) => c.country.toLowerCase() === intendedLocation.toLowerCase()
      ).country;
    case 'countryRegion':
      return Object.values(configLocations.countriesRegions).find(
        (c) => c.countryRegion.toLowerCase() === intendedLocation.toLowerCase()
      ).countryRegion;
    case 'area':
      return Object.values(configLocations.areas).find(
        (c) => c.area.toLowerCase() === intendedLocation.toLowerCase()
      ).area;
    case 'state':
      const stateValue = Object.values(configLocations.states).find(
        (c) => c.state.toLowerCase() === intendedLocation.toLowerCase()
      );
      const countryValue = Object.values(configLocations.countries).find(
        (c) => c.country.toLowerCase() === stateValue.country.toLowerCase()
      );
      return `${stateValue.state}, ${countryValue.abbreviation3}`; // state, country(abbreviation) -> Washington, USA
    case 'city':
      const cityValue = Object.values(configLocations.cities).find(
        (c) => c.city.toLowerCase() === intendedLocation.toLowerCase()
      );
      if (locationArray[2].toLowerCase() === 'united states of america') {
        const stateValue = Object.values(configLocations.states).find(
          (c) => c.state.toLowerCase() === cityValue.state.toLowerCase()
        );
        return `${cityValue.city}, ${stateValue.abbreviation}`; // city, state(abbreviation) -> New York, NY
      }
      return `${cityValue.city}, ${cityValue.country}`; // city, country(abbreviation) -> Delhi, India
    default:
      break;
  }
};

export const tryGetUrl = (string, throwOnError = false) => {
  try {
    if (_.isEmpty(string)) return;
    let cleanString = _.trim(string);
    cleanString = cleanString.replace(/^(www\.)/, '');
    cleanString = _.trimEnd(cleanString, '/');
    if (!cleanString.startsWith('http')) cleanString = 'https://' + cleanString;
    if (!checkIfAbsoluteUrl(cleanString)) return;
    return cleanString;
  } catch (error) {
    if (throwOnError) throw new Error('invalid - failed to get url');
  }
};

export const tryGetNumber = (value, throwOnError = false) => {
  try {
    // let validatorFunction
    // if (min) validatorFunction = validatorFunction.min(min)
    // if (max) validatorFunction = validatorFunction.max(max)
    if (_.isObject(value) || _.isFunction(value)) return;
    if (_.isNumber(value)) return value;
    if (_.isString(value))
      return _.toNumber(_.trim(value).replace(/[,%]/g, ''));
  } catch (error) {
    if (throwOnError) throw new Error('invalid - failed to get number');
  }
};

export const tryGetString = (
  value,
  changeToCamelCase = false,
  throwOnError = false
) => {
  try {
    // let validatorFunction = validator.string().required()
    // if (min) validatorFunction = validatorFunction.min(min)
    // if (max) validatorFunction = validatorFunction.max(max)
    if (changeToCamelCase) {
      try {
        value = _.camelCase(value);
      } catch (error) {
        if (error.name === 'invalid') {
          value = encodeURI(value);
          value = _.camelCase(value);
        } else throw Error('Failed at changeToCamelCase' + value);
      }
    }
    if (_.isObject(value) || _.isFunction(value)) return;
    return _.trim(_.toString(value));
  } catch (error) {
    if (throwOnError) throw new Error('invalid - failed to get string');
  }
};

export const tryGetStringWithValidation = (
  value,
  schemaConfigKey,
  camelCase = true
) => {
  try {
    if (camelCase) return _.camelCase(value);
    else if (!camelCase) return tryGetString(value);
  } catch (error) {
    /* empty */
  }
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const tryGetRevenueRange = (value, throwOnError = false) => {
  try {
    const employeeRangeMap = {
      '0-1M': { from: 0, to: 1000000 },
      '1M-10M': { from: 1000000, to: 10000000 },
      '10M-50M': { from: 10000001, to: 50000000 },
      '50M-100M': { from: 50000001, to: 100000000 },
      '100M-500M': { from: 100000001, to: 500000000 },
      '500M-1B': { from: 500000001, to: 1000000000 },
      '1B-10B': { from: 1000000001, to: 10000000000 },
      '10B+': { from: 10000000001, to: Number.MAX_SAFE_INTEGER },
    };
    value = tryGetNumber(value);
    return tryGetStringWithValidation(
      Object.keys(employeeRangeMap).find(
        (k) =>
          employeeRangeMap[k].from <= value && employeeRangeMap[k].to > value
      ),
      'revenueRange',
      false
    );
  } catch (error) {
    if (throwOnError) throw new Error('invalid - failed to get revenueRange');
  }
};

export const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9+.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
};

export const socialProfilesOrder = [
  'website',
  'linkedin',
  'crunchbase',
  'salesnavigator',
  'facebook',
  'twitter',
  'instagram',
  'angellist',
  'github',
  'medium',
  'youtube',
  'quora',
  'reddit',
];
