import { Box, Center, Flex, Text } from '@chakra-ui/react';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as EmptyStateGraphic } from '~/v2/assets/icons/Empty state graphic.svg';
import { get } from 'lodash';
import { utils } from '~/v2/common/utils';
import TruebaseAccordionSkeleton from '~/v2/common/TruebaseAccordion/components/TruebaseAccordionSkeleton';
import TruebaseAccordion from '~/v2/common/TruebaseAccordion/TruebaseAccountAccordion/TruebaseAccordion';
import { getSelectedIcp } from '~/v2/redux/slices/icpSlice';

export default function TestResults({
  response,
  filteringFn,
  page = 'icp',
  icpId,
}) {
  const dispatch = useDispatch();
  const selectedIcp = useSelector(getSelectedIcp);
  const [open, setOpen] = useState({});

  const accounts = useMemo(
    () =>
      filteringFn
        ? filteringFn(get(response, 'data.result.accounts', []))
        : get(response, 'data.result.accounts', []),

    [filteringFn, response]
  );

  const setOpenSpecific = useCallback(
    (key) => {
      setOpen({
        ...Object.keys(open).reduce((acc, cv) => {
          return {
            ...acc,
            [cv]: false,
          };
        }, {}),
        [key]: !open[key],
      });
    },
    [open, setOpen]
  );

  const isSameIcpId = [icpId, selectedIcp?._id].includes(
    response?.originalArgs?.icpId
  );

  const shouldShowEmptyState =
    (response.isUninitialized ||
      (response.isSuccess && !response.data?.data?.isOk) ||
      !isSameIcpId) &&
    page === 'icp';

  const shouldShowLoadingState =
    !response.isUninitialized && // request has been made
    !shouldShowEmptyState &&
    ((typeof response?.data?.isStreamingAccounts === 'undefined' && // request is not streaming yet
      accounts.length === 0) || // no accounts have been returned
      response?.data?.isStreamingAccounts || // request is streaming
      response.isFetching);

  const shouldShowNoResultsState =
    !response.isUninitialized &&
    !response.isFetching &&
    response?.data?.isStreamingAccounts === false &&
    accounts.length === 0;

  return (
    <>
      {shouldShowEmptyState && (
        <Flex
          justifyContent="center"
          alignItems="center"
          mt="48px"
          flexDirection="column"
          gap="48px"
          zIndex="2"
          pos="relative"
        >
          <EmptyStateGraphic />
          <Text
            color="trueSpace"
            fontWeight="400"
            fontSize="20px"
            lineHeight="23px"
            textAlign="center"
          >
            To start a search, add some account filters and select who you’re
            targeting within those accounts
          </Text>
        </Flex>
      )}
      <Box
        gap={4}
        display="flex"
        flexDirection="column"
        zIndex="2"
        pos="relative"
      >
        {!response.isLoading &&
          isSameIcpId &&
          accounts.map((account, key) => (
            <TruebaseAccordion
              key={account.companyId}
              showHitsAndMisses
              setOpen={setOpenSpecific}
              isOpen={open[account.companyId]}
              accordionKey={account.companyId}
              account={account}
              leads={response?.data?.result?.leads}
              accountIndex={key}
            />
          ))}
        {shouldShowLoadingState &&
          Array.from({
            length:
              utils.limitSelectOptions[0].value -
              (response?.data?.newAccounts ?? 0),
          }).map((_, index) => <TruebaseAccordionSkeleton key={index} />)}
        {shouldShowNoResultsState && (
          <Center mixBlendMode="multiply" mt="48px">
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box mt="50px">
                <Text
                  fontWeight="700"
                  fontSize="20px"
                  lineHeight="23px"
                  textAlign="center"
                  color="trueSpace"
                >
                  Oops, we couldn’t find any account matching your search
                  criteria.
                </Text>
                <Text
                  mt="16px"
                  fontWeight="400"
                  fontSize="20px"
                  lineHeight="23px"
                  textAlign="center"
                  color="trueSpace"
                >
                  Please try a different combination of filters
                </Text>
              </Box>
            </Flex>
          </Center>
        )}
      </Box>
    </>
  );
}
