import { Flex, Switch, Text } from '@chakra-ui/react';
import { AutopilotStepCard } from '../AutopilotStepCard';
import { ReactComponent as AutopilotSelectLogo } from '~/v2/assets/icons/autopilot-select.svg';
import { ReactComponent as EnrichIcon } from '~/v2/assets/icons/enrich.svg';

export const AutopilotEnrichStep = () => {
  return (
    <AutopilotStepCard
      title={
        <Flex alignItems="center" gap="8px">
          <Switch variant="truebaseAutopilot" isChecked isDisabled />
          <Text fontWeight="500" fontSize="14px" lineHeight="16px">
            Enrich *
          </Text>
        </Flex>
      }
      subtitle="Enhance leads with additional demographic and firmographic information"
      icon={EnrichIcon}
      selectLogo={AutopilotSelectLogo}
      content={<></>}
    />
  );
};
