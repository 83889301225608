import React, { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';

import {
  Box,
  Button,
  Divider,
  IconButton,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  useLazyGetPersonFiltersQuery,
  useLazyGetPersonStatsQuery,
  useLazyGeneratePersonTitlesQuery,
} from '../../../redux/services/filters';

import { ReactComponent as ClearIcon } from '../../../assets/icons/clear/clear-back.svg';
import { ReactComponent as InputSearchIcon } from '../../../assets/icons/search.svg';
import { useVirtualScrollWithSearchTerm } from '../../../hooks/useVirtualScrollWithSearchTerm';
import { AnimatePresence, motion } from 'framer-motion';
import { TruebaseBadge } from '../../../common/TruebaseBadge';
import {
  FilterSearchDropdownWrapper,
  LeftListWrapper,
  LeftPanelWrapper,
  MainPanel,
  RightListWrapper,
  RightPanelWrapper,
  SearchInput,
  SearchInputWrapper,
  SelectWrapper,
  TwoPanelsWrapper,
} from './FilterComponents';
import TruebaseComboBox from '../../../common/TruebaseComboBox';
import { utils } from '../../../common/utils';
import { webkitScrollbarConfig } from '../../../common/constants/webkitScrollbar';
import { FilterListItem } from './FilterListItem';
import ListItem from './FilterCategoryItem';
import FiltersEmptyState from './FiltersEmptyState';
import { ReactComponent as AIMagic } from '~/v2/assets/icons/AI magic.svg';
import { sleep } from '~/utils';

export default function LeadFilterAccordion({
  leadFilters = [],
  handleFilterSelect,
  isLeadFilterSelected,
}) {
  const {
    parentRef,
    rowVirtualizer,
    data,
    search,
    setSearch,
    statsResponse,
    selectedStatId,
    setSelectedStatId,
  } = useVirtualScrollWithSearchTerm(
    useLazyGetPersonFiltersQuery,
    useLazyGetPersonStatsQuery
  );
  const toast = useToast();

  const leftListWrapperRef = useRef();

  const [include, setInclude] = useState({
    value: 'include',
    label: 'Include',
  });
  const [leftWrapperScrollTop, setLeftWrapperScrollTop] = useState(false);
  const [generateTitlesLoadingMessage, setGenerateTitlesLoadingMessage] =
    useState('');

  const [generateTitles] = useLazyGeneratePersonTitlesQuery();

  const titleAlreadyExists = leadFilters.find((bracket) =>
    bracket.find(
      (filter) => filter.publicName?.toLowerCase() === search?.toLowerCase()
    )
  );

  const showGenerateTitlesButton =
    (search?.length > 2 && include.value === 'include') ||
    generateTitlesLoadingMessage;

  const selectFilter = (filter) => {
    handleFilterSelect({
      ...filter,
      include: include.value === 'include',
    });
  };

  const handleAddClick = () => {
    const _id = `${search}|${include.value}`;
    const includeQuery = include.value === 'include';
    //check if already exists
    if (!titleAlreadyExists) {
      handleFilterSelect({
        _id,
        publicName: search,
        include: includeQuery,
        path: `title|${search}`,
        freeForm: true,
        subCategory: 'Title',
        ...(includeQuery && {
          includeMongoQuery: {
            phrase: {
              query: [search],
              slop: 1,
              path: 't',
            },
          },
        }),
        ...(!includeQuery && {
          excludeMongoQuery: {
            compound: {
              mustNot: {
                phrase: {
                  query: [search],
                  slop: 1,
                  path: 't',
                },
              },
            },
          },
        }),
      });
    }
    setSearch('');
  };

  const submitForm = (e) => {
    e.preventDefault();
    handleAddAndGenerateClick();
  };

  const handleAddIncludeTitle = useCallback(
    (title) => {
      handleFilterSelect({
        _id: `${title}|include`,
        publicName: title,
        include: true,
        path: `title|${title}`,
        freeForm: true,
        subCategory: 'Title',
        includeMongoQuery: {
          phrase: {
            query: [title],
            slop: 1,
            path: 't',
          },
        },
      });
    },
    [handleFilterSelect]
  );

  const handleAddAndGenerateClick = async () => {
    !titleAlreadyExists && handleAddIncludeTitle(search);
    setGenerateTitlesLoadingMessage('Identifiying similar titles...');
    const excludeList = [];
    leadFilters.forEach((bracket) => {
      bracket.forEach((filter) => {
        if (filter.subCategory === 'Title') {
          excludeList.push(filter.publicName);
        }
      });
    });
    const res = await generateTitles({
      title: search,
      ...(excludeList.length && { excludeList }),
    });
    const titles = _.get(res, 'data.result.titles');
    if (titles?.length) {
      for (const title of titles) {
        handleAddIncludeTitle(title);
        await sleep(250);
      }
      setGenerateTitlesLoadingMessage('');
    } else {
      setGenerateTitlesLoadingMessage('');
      toast({
        title: 'Error',
        description: 'Something went wrong!',
        status: 'error',
        duration: 1500,
        isClosable: true,
      });
    }
  };

  const isEmptyState = selectedStatId === 'all' && !search;

  useEffect(() => {
    const handleScroll = ({ target }) => {
      setLeftWrapperScrollTop(target.scrollTop);
    };

    const leftListWrapper = leftListWrapperRef?.current;

    leftListWrapper.addEventListener('scroll', handleScroll);

    return () => {
      leftListWrapper.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <FilterSearchDropdownWrapper>
      <MainPanel>
        <TwoPanelsWrapper>
          <LeftPanelWrapper>
            <SelectWrapper>
              <TruebaseComboBox
                value={include}
                onChange={(val) => setInclude(val)}
                formatOptionLabel={(option) => (
                  <Box display="flex" alignItems="center">
                    <Box w="32px">
                      <TruebaseBadge
                        variant={option.value === 'include' ? 'green' : 'red'}
                        width="100%"
                        w="24px"
                      >
                        {option.value === 'include' ? 'IN' : 'EX'}
                      </TruebaseBadge>
                    </Box>
                    <Text className="option-label">{option.label}</Text>
                  </Box>
                )}
                options={[
                  { value: 'include', label: 'Include' },
                  { value: 'exclude', label: 'Exclude' },
                ]}
              />
            </SelectWrapper>
            {leftWrapperScrollTop > 0 && (
              <Divider w="328px" color="trueLight" alignSelf="center" />
            )}
            <LeftListWrapper
              sx={{ '::-webkit-scrollbar': { display: 'none' } }}
              ref={leftListWrapperRef}
            >
              {statsResponse?.data?.map((stat) => {
                return (
                  <ListItem
                    onClick={() => {
                      setSelectedStatId((prevSelectedStatId) =>
                        prevSelectedStatId === stat._id ? 'all' : stat._id
                      );
                    }}
                    selectedStat={selectedStatId}
                    selected={selectedStatId === stat._id}
                    publicName={stat.publicName}
                    smart={stat.smart}
                    key={stat._id}
                    _id={stat._id}
                    count={utils.formatNumber(stat.count)}
                    path={stat._id}
                    type="person"
                  />
                );
              })}
            </LeftListWrapper>
          </LeftPanelWrapper>
          <RightPanelWrapper>
            <SearchInputWrapper>
              <form onSubmit={submitForm}>
                <InputGroup>
                  <SearchInput
                    minH="48px"
                    placeholder="Search & filter by anything"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    pr="128px"
                    sx={
                      search.length > 100 && {
                        border: '1px solid',
                        borderColor: 'trueAttention',
                        _focus: { borderColor: 'trueAttention' },
                        _hover: { borderColor: 'trueAttention' },
                      }
                    }
                    isReadOnly={!!generateTitlesLoadingMessage}
                  />
                  <InputRightElement width="fit-content" height="100%" pr={4}>
                    {search ? (
                      <>
                        <Button
                          onClick={handleAddClick}
                          bgColor="trueDim"
                          color="white"
                          size="xs"
                          fill="var(--chakra-colors-trueDim)"
                          _hover={{}}
                          _active={{}}
                          isDisabled={
                            search.length > 100 || generateTitlesLoadingMessage
                          }
                        >
                          Add
                        </Button>
                        <Box px="16px" height="24px">
                          <Divider color="trueLight" orientation="vertical" />
                        </Box>
                        <IconButton
                          px={0}
                          size="xs"
                          variant="ghost"
                          _active={{}}
                          _hover={{}}
                          onClick={() => setSearch('')}
                          isDisabled={generateTitlesLoadingMessage}
                        >
                          <ClearIcon fill="var(--chakra-colors-trueDim)" />
                        </IconButton>
                      </>
                    ) : (
                      <InputSearchIcon fill="var(--chakra-colors-trueDim)" />
                    )}
                  </InputRightElement>
                </InputGroup>
                {search.length > 100 && (
                  <Text
                    color="trueAttention"
                    fontWeight="400"
                    fontSize="12px"
                    lineHeight="14px"
                    mt="8px"
                  >
                    Please keep this under 100 characters
                  </Text>
                )}
              </form>
            </SearchInputWrapper>
            {!isEmptyState && parentRef?.current?.scrollTop > 0 && (
              <Divider w="742px" color="trueLight" alignSelf="center" />
            )}
            <RightListWrapper
              sx={{
                ...webkitScrollbarConfig('white'),
              }}
              ref={parentRef}
            >
              {isEmptyState ? (
                <FiltersEmptyState />
              ) : (
                <Box
                  sx={{
                    height: `${rowVirtualizer.getTotalSize()}px`,
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  {showGenerateTitlesButton && (
                    <Button
                      sx={{
                        p: '12px',
                        boxShadow: 'none',
                        w: '100%',
                        maxW: '742px',
                        borderRadius: '4px',
                        borderWidth: '1px',
                        borderColor: 'trueLight',
                        fontSize: '13px',
                        lineHeight: '16px',
                        fontWeight: '400',
                        color: 'trueMedium',
                        userSelect: 'none',
                        bg: 'trueBg02',
                        justifyContent: 'start',
                        _hover: {
                          bg: 'truePreselect',
                        },
                        _disabled: {
                          opacity: 1,
                          _hover: {
                            bg: 'trueBg02',
                          },
                        },
                      }}
                      onClick={handleAddAndGenerateClick}
                      leftIcon={<AIMagic />}
                      isLoading={generateTitlesLoadingMessage}
                      loadingText={generateTitlesLoadingMessage}
                      spinner={
                        <Spinner
                          color="trueBlue"
                          w="16px"
                          h="16px"
                          speed="0.65s"
                        />
                      }
                    >
                      {titleAlreadyExists ? (
                        'Generate more similar titles'
                      ) : (
                        <>
                          <Text as="span" pr="3px">
                            Add:
                          </Text>
                          <Text
                            as="span"
                            fontWeight={700}
                            color="trueBlue"
                            pr="3px"
                          >
                            {search}
                          </Text>
                          & generate similar titles
                        </>
                      )}
                    </Button>
                  )}
                  {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                    const isLoaderRow = virtualRow.index > data.length - 1;
                    const filter = data[virtualRow.index];

                    if (typeof filter?.publicName === 'undefined') return null;
                    return (
                      <AnimatePresence key={virtualRow.index}>
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{
                            opacity: 1,
                          }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            // height: `${virtualRow.size}px`,
                            transform: `translateY(${
                              showGenerateTitlesButton
                                ? virtualRow.start + 56
                                : virtualRow.start
                            }px)`,
                            ':WebkitScrollbarThumb': {
                              background: 'rgb(225, 230, 243)',
                              height: '100px',
                              borderRadius: '4px',
                            },
                            ':WebkitScrollbar': {
                              width: '4px',
                            },
                            ':WebkitScrollbarTrack': {
                              margin: '10px 0px',
                            },
                          }}
                        >
                          <FilterListItem
                            type="lead"
                            className="filter-list-item"
                            isCategoryItem={filter?.path === 'category'}
                            isLoaderRow={isLoaderRow}
                            isSelected={isLeadFilterSelected(
                              filter?.publicName
                            )}
                            filter={filter}
                            onClick={() => selectFilter(filter)}
                          />
                        </motion.div>
                      </AnimatePresence>
                    );
                  })}
                </Box>
              )}
            </RightListWrapper>
          </RightPanelWrapper>
        </TwoPanelsWrapper>
      </MainPanel>
    </FilterSearchDropdownWrapper>
  );
}
