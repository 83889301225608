/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useState } from 'react';

export const QualificationContext = createContext({
  qualificationQuestions: {},
  setQualificationQuestions: () => {},
  minScore: 0,
  setMinScore: () => {},
});

export const QualificationProvider = ({
  children,
  initialQualificationQuestions,
}) => {
  const [qualificationQuestions, setQualificationQuestions] = useState(
    initialQualificationQuestions
  );
  const [minScore, setMinScore] = useState(0);

  return (
    <QualificationContext.Provider
      value={{
        qualificationQuestions,
        setQualificationQuestions,
        minScore,
        setMinScore,
      }}
    >
      {children}
    </QualificationContext.Provider>
  );
};
