import { useMemo } from 'react';
import {
  Box,
  VStack,
  HStack,
  Tag,
  Text,
  Button,
  useToast,
  CloseButton,
} from '@chakra-ui/react';

import { useRequestQuotaIncreaseMutation } from '~/v2/redux/services/teams';
import { ReactComponent as CheckDone } from '~/v2/assets/check-done.svg';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import {
  accountStatusToReadable,
  backendStatOrder,
  leadStatusToReadable,
} from '~/v2/common/icp/IcpOverviewStat.utils';
import { ICPOverviewStat } from '~/v2/common/icp/IcpOverviewStat';

const OverviewPanel = ({ team, ...rest }) => {
  const { handleAsyncError } = useErrorHandler();
  const toast = useToast();
  const leadStats = useMemo(() => {
    const stats = team.leadStats || [];
    return backendStatOrder.reduce((acc, cv) => {
      return {
        ...acc,
        [cv]: {
          count: stats.find((s) => s.status === cv)?.count || 0,
        },
      };
    }, {});
  }, [team.leadStats]);
  const [requestQuotaIncrease] = useRequestQuotaIncreaseMutation();
  const accountStats = useMemo(() => {
    const stats = team.accountStats || [];
    return backendStatOrder.reduce((acc, cv) => {
      return {
        ...acc,
        [cv]: {
          count: stats.find((s) => s.status === cv)?.count || 0,
        },
      };
    }, {});
  }, [team.accountStats]);

  const subscriptionTypes = {
    trial: 'Free Trial',
    starter: 'Starter',
    growth: 'Growth',
  };

  const ICPStatDisplayTextStyle = {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    color: 'trueDim',
  };
  const ICPStatTextStyle = {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '22px',
    color: 'trueSpace',
    textAlign: 'left',
    minW: '140px',
  };

  const handleRequestMore = handleAsyncError(
    async ({ teamId, userEmail, quotaType }) => {
      const res = await requestQuotaIncrease({
        teamId,
        userEmail,
        quotaType,
      }).unwrap();
      if (res.isOk) {
        toast({
          duration: 5000,
          position: 'bottom',
          render: ({ onClose }) => (
            <Box bg="trueAnason" p="16px 12px" borderRadius="4px">
              <HStack spacing="16px" alignItems="center">
                <CheckDone style={{ width: '16px', height: '16px' }} />
                <Text
                  sx={{
                    fontSize: '14px',
                    lineHeight: '24px',
                    fontWeight: '500',
                    color: 'trueSpace',
                  }}
                >
                  Message sent. We&apos;ll get back to you as soon as possible
                </Text>
                <CloseButton size="sm" onClick={onClose} />
              </HStack>
            </Box>
          ),
        });
      }
    }
  );

  return (
    <Box {...rest}>
      <VStack spacing={4} alignItems="flex-start" justifyContent="center">
        <ICPOverviewStat
          displayName="Members"
          spacing="42px"
          textProps={{ ...ICPStatDisplayTextStyle }}
        >
          <Text {...ICPStatTextStyle}>{team.members?.length}</Text>
        </ICPOverviewStat>
        <ICPOverviewStat
          displayName="Accounts"
          spacing="42px"
          textProps={{ ...ICPStatDisplayTextStyle }}
        >
          <HStack spacing="16px">
            {backendStatOrder.map((stat) => (
              <Tag as="div" key={stat} variant="icpNotClickable">
                {accountStats[stat]?.count} {accountStatusToReadable[stat]}
              </Tag>
            ))}
          </HStack>
        </ICPOverviewStat>
        <ICPOverviewStat
          displayName="Leads"
          stat={team.companyDomain}
          spacing="42px"
          textProps={{ ...ICPStatDisplayTextStyle }}
        >
          <HStack spacing="16px">
            {backendStatOrder.map((stat) => (
              <Tag as="div" key={stat} variant="icpNotClickable">
                {leadStats[stat]?.count} {leadStatusToReadable[stat]}
              </Tag>
            ))}
          </HStack>
        </ICPOverviewStat>
        <ICPOverviewStat
          displayName="Current Plan"
          spacing="42px"
          textProps={{ ...ICPStatDisplayTextStyle }}
        >
          <Text {...ICPStatTextStyle}>
            {subscriptionTypes[team.subscriptionType]}
          </Text>
        </ICPOverviewStat>

        <ICPOverviewStat
          displayName="LinkedIn"
          spacing="42px"
          textProps={{ ...ICPStatDisplayTextStyle }}
        >
          <Text {...ICPStatTextStyle}>
            {team.linkedIn} of
            {team.linkedInAccountPerMonth === 'infinite'
              ? ' ꝏ/month'
              : team.linkedInAccountPerMonth}
          </Text>
        </ICPOverviewStat>
        <ICPOverviewStat
          displayName="Phones"
          spacing="42px"
          textProps={{ ...ICPStatDisplayTextStyle }}
        >
          <Text {...ICPStatTextStyle}>
            {team.phones} of
            {team.phonesPerMonth === 'infinite'
              ? ' ꝏ/month'
              : team.phonesPerMonth}
          </Text>
          <Button
            variant="link"
            sx={{
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '22px',
              letterSpacing: '0em',
              textAlign: 'left',
              color: '#0280F5',
            }}
            onClick={() => {
              handleRequestMore({
                teamId: team._id,
                userEmail: team.members[0].email,
                quotaType: 'phones',
              });
            }}
          >
            Request More
          </Button>
        </ICPOverviewStat>
        <ICPOverviewStat
          displayName="Emails"
          spacing="42px"
          textProps={{ ...ICPStatDisplayTextStyle }}
        >
          <Text {...ICPStatTextStyle}>
            {team.emails} of
            {team.emailsPerMonth === 'infinite'
              ? ' ꝏ/month'
              : team.emailsPerMonth}
          </Text>
          <Button
            variant="link"
            sx={{
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '22px',
              letterSpacing: '0em',
              textAlign: 'left',
              color: '#0280F5',
            }}
            onClick={() => {
              handleRequestMore({
                teamId: team._id,
                userEmail: team.members[0].email,
                quotaType: 'emails',
              });
            }}
          >
            Request More
          </Button>
        </ICPOverviewStat>
      </VStack>
    </Box>
  );
};

export default OverviewPanel;
