import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import socket from '../utility/socket';
import useErrorHandler from './useErrorHandler';

/**
 *
 * @param {*} localIdentifier - only needed if you don't implement custom listeners
 * @returns
 */
const useStreamingForm = ({
  formOptions,
  localIdentifier,
  inProgressListener,
  completeListener,
  failedListener,
  additionalInProgressListener,
  additionalCompleteListener,
  socketIdentifiers: {
    inProgress: inProgressIdentifier,
    complete: completeIdentifier,
    failed: failedIdentifier,
  } = {},
  shouldResetOnInProgress,
}) => {
  const { handleSyncError } = useErrorHandler();
  if (
    !localIdentifier &&
    (!inProgressListener || !completeListener || !failedListener)
  )
    throw new Error(
      'localIdentifier or (in progress and complete listener) is required'
    );
  const [isGenerating, setIsGenerating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [didReset, setDidReset] = useState(false);
  const methods = useForm(formOptions);
  const { setValue, getValues, clearErrors } = methods;
  const curriedCompleteListener = completeListener?.(methods);
  const curriedInProgressListener = inProgressListener?.(methods);
  const curriedFailedListener = failedListener?.(methods);

  useEffect(() => {
    const defaultListener = handleSyncError(
      ({ identifier, message, path, loading, loadingMessage }) => {
        if (localIdentifier !== identifier) return;
        if (!didReset && shouldResetOnInProgress) {
          shouldResetOnInProgress();
          setDidReset(true);
        }
        setIsLoading(loading);
        if (loading) {
          setLoadingMessage(message);
        } else if (loadingMessage) {
          setLoadingMessage('');
        }
        setIsGenerating(true);
        const currentVal = getValues(path);
        if (path) {
          setValue(path, (!currentVal ? '' : currentVal) + message);
        }
        additionalInProgressListener?.(methods)({
          identifier,
          message,
          path,
          loading,
          loadingMessage,
        });
      }
    );
    const defaultCompleteListener = handleSyncError(({ identifier }) => {
      if (localIdentifier !== identifier) return;
      setIsGenerating(false);
      clearErrors();
      if (isGenerating) setIsGenerating(false);
      if (isLoading) setIsLoading(false);
      if (loadingMessage) setLoadingMessage('');
      setDidReset(false);
      additionalCompleteListener?.(methods)({
        identifier,
      });
    });
    const defaultFailedListener = handleSyncError(({ identifier }) => {
      if (localIdentifier !== identifier) return;
      setIsGenerating(false);
      clearErrors();
      setDidReset(false);
    });

    socket.on(
      inProgressIdentifier,
      inProgressListener ? curriedInProgressListener : defaultListener
    );
    socket.on(
      completeIdentifier,
      completeListener ? curriedCompleteListener : defaultCompleteListener
    );
    socket.on(
      failedIdentifier,
      failedListener ? curriedFailedListener : defaultFailedListener
    );

    return () => {
      socket.off(
        inProgressIdentifier,
        inProgressListener ? curriedInProgressListener : defaultListener
      );
      socket.off(
        completeIdentifier,
        completeListener ? curriedCompleteListener : defaultCompleteListener
      );
    };
  }, [
    handleSyncError,
    setValue,
    clearErrors,
    setIsGenerating,
    getValues,
    localIdentifier,
    completeListener,
    inProgressListener,
    curriedCompleteListener,
    curriedInProgressListener,
    completeIdentifier,
    inProgressIdentifier,
    failedListener,
    curriedFailedListener,
    failedIdentifier,
    loadingMessage,
    additionalInProgressListener,
    additionalCompleteListener,
    methods,
    isLoading,
    isGenerating,
    didReset,
    shouldResetOnInProgress,
  ]);

  return {
    methods,
    isLoading,
    loadingMessage,
    setLoadingMessage,
    isGenerating,
    setIsLoading,
    setIsGenerating,
  };
};

export default useStreamingForm;
