import { forwardRef, useCallback, useImperativeHandle } from 'react';
import OnboardingTitle from '../shared/OnboardingTitle';
import { Text } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import LoadingMessage from '../shared/LoadingMessage';
import {
  OnboardingBackButton,
  OnboardingButton,
} from '../shared/OnboardingButtons';
import { StreamingTextArea } from '../../../../common/Truebase/TruebaseStreamingTextArea';
import { EMPTY_INSIGHTS_VALUE } from '../shared/utils';
import { LayoutGroup, motion } from 'framer-motion';

const OfferingStep = forwardRef(
  ({ stepInfo, loadingMessage, isGenerating }, ref) => {
    const {
      setValue,
      trigger,
      formState: { errors },
    } = useFormContext();
    const isButtonDisabled =
      isGenerating || !!loadingMessage || errors.senderCompanyInsights;

    const handleClick = useCallback(async () => {
      const isValid = await trigger('senderCompanyInsights');
      if (isValid) stepInfo.setStep((s) => s + 1);
    }, [stepInfo, trigger]);

    const handleBack = () => {
      stepInfo.setStep((s) => s - 1);
      setValue('senderCompanyInsights', EMPTY_INSIGHTS_VALUE);
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          enterBtnHandler() {
            // disable handling enter key press when a textarea is focused
            document.activeElement.tagName === 'TEXTAREA' || isButtonDisabled
              ? null
              : handleClick();
          },
        };
      },
      [isButtonDisabled, handleClick]
    );

    return (
      <LayoutGroup>
        <OnboardingTitle id="company-step">
          <Text>We&apos;re all ears!</Text>
          <Text>Tell us about your offering.</Text>
        </OnboardingTitle>
        {!isGenerating && <LoadingMessage loadingMessage={loadingMessage} />}
        <motion.div
          style={{ display: 'flex', gap: '24px', flexDirection: 'column' }}
          layout="preserve-aspect"
          transition={{
            type: 'spring',
            stiffness: 75,
            duration: 1,
          }}
        >
          <StreamingTextArea
            isRequired
            label="What are you selling?"
            name="senderCompanyInsights.offering"
            variant="truebase"
            minH="60px"
            isDisabled={loadingMessage || isGenerating}
          />
          <StreamingTextArea
            isRequired
            label="Who is your ideal buyer?"
            name="senderCompanyInsights.targetMarket"
            variant="truebase"
            minH="60px"
            isDisabled={loadingMessage || isGenerating}
          />
          <StreamingTextArea
            isRequired
            label="How can your offer help buyers?"
            name="senderCompanyInsights.metricsToImprove"
            variant="truebase"
            minH="60px"
            isDisabled={loadingMessage || isGenerating}
          />
          <StreamingTextArea
            isRequired
            label="What sets your product apart?"
            name="senderCompanyInsights.uniqueValueProposition"
            variant="truebase"
            minH="60px"
            isDisabled={loadingMessage || isGenerating}
          />
          <Text fontSize="12px" fontWeight={400} color="trueExplain">
            Modify as desired. You can also edit later and even create more
            buyers&apos; profiles.
          </Text>
          <OnboardingButton isDisabled={isButtonDisabled} onClick={handleClick}>
            Next
          </OnboardingButton>
          <OnboardingBackButton
            isDisabled={isButtonDisabled}
            onClick={handleBack}
          />
        </motion.div>
      </LayoutGroup>
    );
  }
);
export default OfferingStep;
