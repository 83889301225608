import { useEffect, useState } from 'react';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { Button, Checkbox, Flex, Icon, Link, Text } from '@chakra-ui/react';
import { ReactComponent as CircleIcon } from '~/v2/assets/icons/circle.svg';
import { ReactComponent as CheckIcon } from '~/v2/assets/icons/check/check-mark.svg';
import { ReactComponent as CheckOff } from '~/v2/assets/icons/clear/clear-large.svg';
import { useSelector } from 'react-redux';
import {
  useGetCurrentUserQuery,
  useSubscribeToNewsletterMutation,
} from '~/v2/redux/services/user';
import socket from '~/v2/utility/socket';
import { useNavigate } from 'react-router-dom';
import LoadingMessage from '../shared/LoadingMessage';
import { useSetupMutation } from '~/v2/redux/services/teams';
import { getSelectedAccountQualification } from '~/v2/redux/slices/accountQualificationSlice';
import { getSelectedIcp } from '~/v2/redux/slices/icpSlice';
import { getSelectedSequenceTemplate } from '~/v2/redux/slices/sequenceTemplateSlice';

const AutoConifgureStep = ({ getValues }) => {
  const [loadingMessage, setLoadingMessage] = useState(
    'Creating your ideal customer profile (ICP)'
  );
  const [checklistItems, setChecklistItems] = useState([
    {
      id: 'search',
      title: 'Setting up search',
      subtitle: 'We are finding the optimal path to pinpoint your buyers.',
      isDone: false,
    },
    {
      id: 'qualification',
      title: 'Generating qualification criteria',
      subtitle: 'Your prospect prioritisation strategy is being crafted.',
      isDone: false,
    },
    {
      id: 'contactDiscovery',
      title: 'Configuring lead contact discovery',
      subtitle: 'We are activating add-ons for email and phone accuracy.',
      isDone: false,
    },
  ]);
  const [setupStarted, setSetupStarted] = useState(false);
  const [subscribeToNewsletterChecked, setSubscribeToNewsletterChecked] =
    useState(true);

  const navigate = useNavigate();

  const [setupTeam] = useSetupMutation();
  const [subscribeToNewsletter] = useSubscribeToNewsletterMutation();

  const icp = useSelector(getSelectedIcp);
  const accountQualification = useSelector(getSelectedAccountQualification);
  const sequenceTemplate = useSelector(getSelectedSequenceTemplate);

  const { data: userResponse } = useGetCurrentUserQuery();
  const user = userResponse?.result?.user;

  const { handleAsyncError } = useErrorHandler();

  useEffect(() => {
    const setupTeamSettings = handleAsyncError(async () => {
      setSetupStarted(true);

      await setupTeam({
        icpId: icp._id,
        accountQualificationId: accountQualification._id,
        sequenceTemplateId: sequenceTemplate._id,
        teamId: user.teamId,
      });
    });

    if (
      !setupStarted &&
      user?.teamId &&
      accountQualification?._id &&
      sequenceTemplate?._id &&
      icp?._id
    ) {
      setupTeamSettings();
    }
  }, [
    getValues,
    user,
    setupStarted,
    handleAsyncError,
    accountQualification?._id,
    sequenceTemplate?._id,
    icp?._id,
    setupTeam,
  ]);

  useEffect(() => {
    const handleInProgressNotif = ({
      completedStepId,
      loadingMessage: newLoadingMessage,
    }) => {
      setLoadingMessage(newLoadingMessage);
      setChecklistItems((oldItems) => {
        const index = oldItems.findIndex((item) => item.id === completedStepId);
        let newArr = [...oldItems];
        newArr[index] = {
          ...newArr[index],
          isDone: true,
        };
        return newArr;
      });
    };
    const handleCompleteNotif = () => {
      setLoadingMessage(null);
      setChecklistItems((oldItems) => {
        let newArr = [...oldItems];
        newArr[oldItems.length - 1] = {
          ...newArr[oldItems.length - 1],
          isDone: true,
        };
        return newArr;
      });
    };
    const handleFailedNotif = () => {
      setLoadingMessage(null);
    };

    socket.on('setupTeam-inProgress', handleInProgressNotif);
    socket.on('setupTeam-completed', handleCompleteNotif);
    socket.on('setupTeam-failed', handleFailedNotif);

    return () => {
      socket.off('setupTeam-inProgress', handleInProgressNotif);
      socket.off('setupTeam-completed', handleCompleteNotif);
      socket.off('setupTeam-failed', handleFailedNotif);
    };
  }, []);

  return (
    <Flex flexDir="column" gap="24px">
      <Flex flexDir="column" gap="16px">
        <Text
          as="span"
          fontWeight={700}
          fontSize="32px"
          lineHeight="34px"
          color="trueSpace"
        >
          We are on it! Setting up your account for speedy prospecting
        </Text>
        <LoadingMessage loadingMessage={loadingMessage} />
      </Flex>
      <Flex flexDir="column" gap="16px">
        {checklistItems.map(({ title, subtitle, isDone }, index) => (
          <Flex key={index} flexDir="column" gap="8px">
            <Flex gap="8px" alignItems="center">
              <Icon
                as={isDone ? CheckIcon : loadingMessage ? CircleIcon : CheckOff}
                fill={
                  isDone
                    ? 'trueGrass'
                    : loadingMessage
                    ? 'trueSpace'
                    : 'trueAttention'
                }
                w="20px"
                h="20px"
              />
              <Text
                as="span"
                color="trueSpace"
                fontWeight={500}
                fontSize="14px"
                lineHeight="16px"
              >
                {title}
              </Text>
            </Flex>
            <Text as="span" color="trueDim" variant="label" mb={0}>
              {subtitle}
            </Text>
          </Flex>
        ))}
      </Flex>
      <Text as="span" color="trueExplain" variant="label">
        Our AI kickstarted your account, and you can refine with your touches.
      </Text>
      <Flex flexDir="column" gap="16px">
        <Button
          variant="truebase"
          isDisabled={loadingMessage}
          onClick={async () => {
            const intercomId = localStorage.getItem('TRUEBASE_LEAD_ID');
            if (subscribeToNewsletterChecked && intercomId)
              await subscribeToNewsletter({ intercomId });
            localStorage.removeItem('TRUEBASE_LEAD_ID');
            navigate('/autopilot');
          }}
        >
          Start My Free Trial
        </Button>
        <Checkbox
          variant="truebase"
          defaultChecked
          onChange={(e) => setSubscribeToNewsletterChecked(e.target.checked)}
          sx={{
            '& .chakra-checkbox__label': {
              ml: '8px',
              color: 'trueDim',
              fontSize: '13px',
              fontWeight: 500,
            },
          }}
        >
          Join our update list. We&apos;ll only reach out when it matters.
        </Checkbox>
        {!loadingMessage && (
          <Text color="trueDim" fontSize="13px" fontWeight={500} as="span">
            By completing the sign up, you&apos;re agreeing to our{' '}
            <Link
              textDecor="underline"
              href="https://truebase.io/terms-of-use"
              target="_blank"
            >
              Terms of Use
            </Link>{' '}
            and{' '}
            <Link
              textDecor="underline"
              href="https://truebase.io/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </Link>
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default AutoConifgureStep;
