import { formErrorAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const truebase = definePartsStyle({
  text: {
    fontSize: '12px',
    lineHeight: '14px',
    color: 'trueAttention',
  },
});

export const formErrorTheme = defineMultiStyleConfig({
  variants: { truebase },
  defaultProps: {
    variant: 'truebase',
  },
});
