import React, { useEffect } from 'react';
import { Button, Flex, useToast } from '@chakra-ui/react';
import useStreamingForm from '~/v2/hooks/useStreamingForm';
import { isEmpty } from 'lodash';
import { FormProvider } from 'react-hook-form';
import TabEmptyState from '../components/TabEmptyState';
import TruebaseChatContainer from '../../TruebaseChatContainer';
import TruebaseCenteredClouds from '../../Truebase/TruebaseCenteredClouds';
import { StreamingTextArea } from '../../Truebase/TruebaseStreamingTextArea';
import { useLazyStartGetCompanyOverviewQuery } from '~/v2/redux/services/accounts';
import useErrorHandler from '~/v2/hooks/useErrorHandler';
import { ReactComponent as AIMagic } from '../../../assets/icons/AI magic.svg';

const overviewDefaultVals = {
  companyInsights: {
    content: '',
    isOverwrite: false,
  },
};

const AccountInsights = ({ account = {}, readonly }) => {
  const toast = useToast();
  const { handleAsyncError } = useErrorHandler();
  const [startCompanyOverviewLazyQuery] = useLazyStartGetCompanyOverviewQuery();
  const { methods, isGenerating, setIsGenerating, isLoading, setIsLoading } =
    useStreamingForm({
      formOptions: {
        defaultValues: {
          companyInsights: {
            ...overviewDefaultVals.companyInsights,
            ...account.companyInsights,
          },
        },
      },
      localIdentifier: account.companyId,
      failedListener:
        () =>
        ({ identifier }) => {
          if (identifier !== account?.companyId) return;
          methods.resetField('companyInsights');
          if (isGenerating) setIsGenerating(false);
          if (isLoading) setIsLoading(false);
          if (!toast.isActive(`companyInsights-failed-${identifier}`)) {
            toast({
              id: `companyInsights-failed-${identifier}`,
              title: 'Error',
              description: 'Something went wrong, please try again later',
              status: 'error',
              duration: 3000,
              isClosable: false,
            });
          }
        },
      socketIdentifiers: {
        inProgress: 'companyInsights-inProgress',
        complete: 'companyInsights-completed',
        failed: 'companyInsights-failed',
      },
    });
  const companyInsights = methods.watch('companyInsights.content');
  const hasOverview = !isEmpty(account.companyInsights || companyInsights);

  useEffect(() => {
    methods.reset({
      companyInsights: { ...account.companyInsights },
    });
  }, [account.companyInsights, methods]);

  return (
    <FormProvider {...methods}>
      <Flex gap="16px" flexDir="column">
        {!isGenerating && !hasOverview ? (
          readonly ? (
            <TabEmptyState tabName="insights" />
          ) : (
            <TruebaseChatContainer position="relative" minH="120px">
              <TruebaseCenteredClouds text="Generate up-to-date company insights in real time" />
            </TruebaseChatContainer>
          )
        ) : (
          <StreamingTextArea
            isDisabled
            label="Company insights"
            name="companyInsights"
          />
        )}
        <Flex gap="16px">
          {!readonly && (
            <Button
              h="32px"
              variant={
                !isGenerating && !hasOverview
                  ? 'truebaseOutline'
                  : 'truebaseOutlineNeutral'
              }
              isDisabled={isGenerating}
              disabled={isGenerating}
              leftIcon={<AIMagic />}
              onClick={handleAsyncError(async () => {
                const response = await startCompanyOverviewLazyQuery({
                  companyId: account.companyId,
                });
                if (!response.data?.isOk) {
                  methods.reset({
                    companyInsights: {
                      ...overviewDefaultVals.companyInsights,
                      ...account.companyInsights,
                    },
                  });
                  if (
                    !toast.isActive(
                      `companyInsights-failed-${account.companyId}`
                    )
                  ) {
                    toast({
                      id: `companyInsights-failed-${account.companyId}`,
                      title: 'Error',
                      description:
                        'Something went wrong, please try again later',
                      status: 'error',
                      duration: 3000,
                      isClosable: false,
                    });
                  }
                }
              })}
              sx={
                !isGenerating && !hasOverview
                  ? { padding: '0 20px 0 16px' }
                  : {}
              }
            >
              Generate
            </Button>
          )}
        </Flex>
      </Flex>
    </FormProvider>
  );
};

export default AccountInsights;
