import { Box, Flex, Skeleton, SkeletonCircle, Spacer } from '@chakra-ui/react';

const noFlashingColors = {
  startColor: 'trueLighter',
  endColor: 'trueLighter',
};

export default function AutopilotCardSkeleton({ isLoading = false }) {
  return (
    <Box pt="12px">
      <Flex h="76px" w="100%" p="16px" bg="white">
        <Flex alignItems="center" gap="12px" w="100%" py="12px">
          <SkeletonCircle
            {...(!isLoading ? noFlashingColors : {})}
            width="32px"
            height="32px"
          />
          <Flex flexDirection="column" gap="4px" justifyContent="center">
            <Skeleton
              {...(!isLoading ? noFlashingColors : {})}
              width="130px"
              height="16px"
            />
            <Skeleton
              {...(!isLoading ? noFlashingColors : {})}
              width="106px"
              height="16px"
            />
          </Flex>
          <Spacer />
          <Flex justifyContent="center" alignItems="center" gap="16px">
            <SkeletonCircle
              {...(!isLoading ? noFlashingColors : {})}
              width="110px"
              height="24px"
            />
            <SkeletonCircle
              {...(!isLoading ? noFlashingColors : {})}
              width="24px"
              height="24px"
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
