import { apiSlice } from './api';
import { omit } from 'lodash';

// Define a service using a base URL and expected endpoints
export const accountQualificationApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAccountQualifications: builder.query({
      query: (args) => ({
        url: `accountQualifications/${args.teamId}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.result.accountQualifications;
      },
      providesTags: ['AccountQualifications'],
    }),
    addAccountQualification: builder.mutation({
      query: (body) => ({
        url: 'accountQualifications',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['AccountQualifications'],
    }),
    duplicateAccountQualification: builder.mutation({
      query: (body) => ({
        url: 'accountQualifications/duplicate',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['AccountQualifications'],
    }),
    deleteAccountQualification: builder.mutation({
      query: ({ _id, teamId }) => ({
        url: `accountQualifications/${_id}?teamId=${teamId}`,
        method: 'DELETE',
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const patchGetAccountQualification = dispatch(
          apiSlice.util.updateQueryData(
            'getAccountQualifications',
            { teamId: arg.teamId },
            (draft) => {
              draft = draft.filter(
                (accountQualification) => accountQualification._id !== arg._id
              );
              return draft;
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (e) {
          patchGetAccountQualification?.undo();
        }
      },
    }),
    editAccountQualification: builder.mutation({
      async queryFn(args, queryApi, extraOptions, baseQuery) {
        const response = await baseQuery({
          url: `accountQualifications/${args._id}`,
          method: 'PATCH',
          body: {
            ...omit(args, 'meta'),
            name: args.name || undefined,
          },
        });
        return { data: { response, _id: args._id } };
      },
      invalidatesTags: (_, __, args) => {
        return args.meta?.noRefresh ? [] : ['AccountQualifications'];
      },
    }),
    testQualification: builder.mutation({
      query: ({
        icpId,
        companyIds,
        qualificationMinScore,
        qualificationQuestions,
      }) => ({
        url: `accountQualifications/${icpId}/testQualification`,
        method: 'POST',
        body: {
          companyIds,
          qualificationMinScore,
          qualificationQuestions,
        },
      }),
    }),
  }),
});

export const {
  useGetAccountQualificationsQuery,
  useLazyGetAccountQualificationsQuery,
  useAddAccountQualificationMutation,
  useEditAccountQualificationMutation,
  useDeleteAccountQualificationMutation,
  useDuplicateAccountQualificationMutation,
  useTestQualificationMutation,
} = accountQualificationApi;
