import React from 'react';
import { ReactComponent as Account2 } from '../../../assets/icons/accounts/accounts-2.svg';
import { ReactComponent as Topics } from '../../../assets/icons/topics.svg';
import { ReactComponent as Finance } from '../../../assets/icons/finance.svg';
import { ReactComponent as BusinessModelIcon } from '../../../assets/icons/business model.svg';
import { ReactComponent as IndustriesIcon } from '../../../assets/icons/industries.svg';
import { ReactComponent as Leads1 } from '../../../assets/icons/leads/leads-1.svg';
import { ReactComponent as LocationIcon } from '../../../assets/icons/location.svg';
import { ReactComponent as AllCategIcon } from '../../../assets/icons/filter/filter-on.svg';
import { ReactComponent as LeadTitleIcon } from '../../../assets/icons/lead-title.svg';
import { ReactComponent as LeadSeniorityIcon } from '../../../assets/icons/lead-seniority.svg';
import { ReactComponent as LeadRoleIcon } from '../../../assets/icons/lead-roles.svg';
import { ReactComponent as DepartmentsAndTeamsIcon } from '../../../assets/icons/departments-and-teams.svg';
import { ReactComponent as WebsiteIcon } from '../../../assets/icons/web.svg';

export const companyCategoryIconMap = {
  all: <AllCategIcon />,
  // category icons -> taken from path 'aboutAndFormation|about' -> aboutAndFormation
  topics: <Topics />,
  finance: <Finance />,
  locationAndRegion: <LocationIcon />,
  // businessModel: <BusinessModelIcon />,
  headcount: <Leads1 />,
  // industryAndVertical: <IndustriesIcon />,
  vertical: <IndustriesIcon />,
  website: <WebsiteIcon />,
};

export const leadCategoryIconMap = {
  all: <AllCategIcon />,
  title: <LeadTitleIcon />,
  seniority: <LeadSeniorityIcon />,
  role: <LeadRoleIcon />,
  locationAndRegion: <LocationIcon />,
  departmentsAndTeams: <DepartmentsAndTeamsIcon />,
};
