import { Flex, Text } from '@chakra-ui/react';
import { CloseIcon } from '~/v2/assets/CloseIcon';
import { TagIcon } from '~/v2/assets/Tag';

const TagCapsule = ({ label, onClick, isDisabled }) => {
  return (
    <Flex
      w="fit-content"
      bg="white"
      p="5.5px 9px"
      justifyContent="space-between"
      gap="5px"
      borderWidth="1px"
      borderColor="trueLight"
      borderRadius="24px"
      onClick={onClick}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
    >
      <TagIcon color="#7E8BAD" width="14px" height="14px" />
      <Text
        marginRight="3px"
        fontSize="12px"
        fontWeight="500"
        lineHeight="14px"
        color={isDisabled ? 'trueDim' : 'trueSpace'}
      >
        {label}
      </Text>
      <CloseIcon color="#7E8BAD" width="12px" height="12px" />
    </Flex>
  );
};
export default TagCapsule;
