import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  HStack,
  useToast,
  Text,
  Spacer,
  CloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import SequenceStepCard from './SequenceStepCard';
import { ReactComponent as Add } from '~/v2/assets/icons/add/add-box.svg';
import { ReactComponent as CheckDone } from '~/v2/assets/check-done.svg';
import { isEmpty } from 'lodash';
import { useIsDirtyContext } from '~/v2/contexts/useIsDirtyContext';
import { useTestEmailMutation } from '~/v2/redux/services/teams';
import { removeHTMLMargins } from '~/v2/common/utils';
import { TestEmailModal } from './TestEmailModal';

const SequenceStepsForm = () => {
  const {
    watch,
    formState: { dirtyFields },
    control,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sequenceSteps',
  });

  const sequenceSteps = watch('sequenceSteps');

  const [selectedTestIndex, setSelectedTestIndex] = useState(0);

  const {
    isOpen: isTestEmailOpen,
    onOpen: onTestEmailOpen,
    onClose: onTestEmailClose,
  } = useDisclosure();

  const [testEmail] = useTestEmailMutation();

  const toast = useToast();

  const handleTestEmail = async (values) => {
    const sequenceStep = sequenceSteps[selectedTestIndex];
    let subject = sequenceStep.subject;
    if (!subject) {
      if (sequenceSteps[0].subject) subject = `RE: ${sequenceSteps[0].subject}`;
    }
    const payload = {
      ...values,
      subject: subject || 'Truebase Test Email Subject',
      message: removeHTMLMargins(sequenceStep.message),
    };
    const response = await testEmail(payload);
    if (response?.data?.isOk) {
      toast({
        duration: 2000,
        position: 'bottom',
        render: ({ onClose }) => (
          <Box bg="trueAnason" p="16px 12px" borderRadius="4px">
            <HStack spacing="16px" alignItems="center">
              <CheckDone style={{ width: '16px', height: '16px' }} />
              <Text
                sx={{
                  fontSize: '14px',
                  lineHeight: '24px',
                  fontWeight: '500',
                  color: 'trueSpace',
                }}
              >
                Email sent
              </Text>
              <Spacer />
              <CloseButton size="sm" onClick={onClose} />
            </HStack>
          </Box>
        ),
      });
    } else {
      toast({
        title: 'Error',
        description: 'Something went wrong!',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const { setIsSequenceTemplateDirty } = useIsDirtyContext();

  useEffect(() => {
    setIsSequenceTemplateDirty(!isEmpty(dirtyFields));
  }, [dirtyFields, setIsSequenceTemplateDirty]);

  const handleAddSequenceClick = () => {
    append({
      type: 'email',
      subject: null,
      message: null,
      daysSinceLastMessage:
        sequenceSteps?.[sequenceSteps.length - 1]?.daysSinceLastMessage + 3 ||
        1,
    });
  };

  const AddSequenceButton = () => (
    <Button
      sx={{
        p: '16px',
        border: '1px dashed',
        borderColor: 'trueSky',
        borderRadius: '2px',
        bg: 'transparent',
        width: '100%',
        alignItems: 'baseline',
        justifyContent: 'start',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 400,
        color: 'trueDim',
      }}
      leftIcon={<Add />}
      onClick={handleAddSequenceClick}
    >
      New step
    </Button>
  );

  return (
    <Flex direction="column" gap="12px" onClick={(e) => e.stopPropagation()}>
      {fields.map((field, index) => (
        <SequenceStepCard
          index={index}
          step={field}
          key={index}
          isRemovable={
            index === sequenceSteps?.length - 1 && sequenceSteps.length > 1
          }
          onRemove={() => remove(index)}
          handleTestEmail={() => {
            setSelectedTestIndex(index);
            onTestEmailOpen();
          }}
        />
      ))}
      <AddSequenceButton />
      <TestEmailModal
        isOpen={isTestEmailOpen}
        onClose={() => {
          setSelectedTestIndex(0);
          onTestEmailClose();
        }}
        handleTestEmail={handleTestEmail}
      />
    </Flex>
  );
};

export default SequenceStepsForm;
